import { useQuery } from "convex/react";
import { useEffect } from "react";
import { format } from "date-fns";
import { useOnce } from "frontend-shared/src/util";
import { api } from "shared/be/convex/_generated/api";
import { createStore, createEvent, sample } from "effector";
import { useStore, useUnit } from "effector-react";
import { useMyId } from "web-shared/src/web-context";

export class AvailabilityVM {
  // Events
  readonly setSelectedDate = createEvent<Date>();
  readonly setSelectedDuration = createEvent<number>();

  // Stores
  readonly $selectedDate = createStore(new Date());
  readonly $selectedDuration = createStore(60);
  readonly $unixDate = this.$selectedDate.map((date) =>
    Math.floor(date.getTime() / 1000)
  );

  constructor() {
    // Setup samples
    sample({
      clock: this.setSelectedDate,
      target: this.$selectedDate,
    });

    sample({
      clock: this.setSelectedDuration,
      target: this.$selectedDuration,
    });
  }
}

export const Availability: React.FC = () => {
  const vm = useOnce(() => new AvailabilityVM());
  const myId = useMyId();

  const selectedDate = useUnit(vm.$selectedDate);
  const selectedDuration = useUnit(vm.$selectedDuration);
  const unixDate = useUnit(vm.$unixDate);

  const availability = useQuery(
    api.Scheduling.SchedulingFns.getHpAvailability,
    {
      hpId: myId, // TODO: Replace with actual HP ID
      date: unixDate,
      durationMinutes: selectedDuration,
    }
  );

  if (!availability) {
    return <div>Loading...</div>;
  }

  return (
    <div className="flex flex-col gap-4 p-4">
      <h1 className="text-2xl font-bold">Therapist Availability</h1>

      <div className="flex flex-col gap-2">
        <label className="text-sm font-medium">Date</label>
        <input
          type="date"
          value={format(selectedDate, "yyyy-MM-dd")}
          onChange={(e) => vm.setSelectedDate(new Date(e.target.value))}
          className="rounded border p-2"
        />
      </div>

      <div className="flex flex-col gap-2">
        <label className="text-sm font-medium">Duration (minutes)</label>
        <select
          value={selectedDuration}
          onChange={(e) => vm.setSelectedDuration(Number(e.target.value))}
          className="rounded border p-2"
        >
          <option value={30}>30 minutes</option>
          <option value={45}>45 minutes</option>
          <option value={60}>60 minutes</option>
          <option value={90}>90 minutes</option>
        </select>
      </div>

      <div className="flex flex-col gap-2">
        <h2 className="text-lg font-semibold">Available Time Slots</h2>
        <div className="grid grid-cols-3 gap-2">
          {availability.slots.map((slot: { startTime: number }) => (
            <button
              key={slot.startTime}
              className="rounded bg-blue-500 p-2 text-white hover:bg-blue-600"
              onClick={() => {
                // TODO: Implement booking logic
                console.log("Booking slot:", slot);
              }}
            >
              {format(new Date(slot.startTime * 1000), "h:mm a")}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};
