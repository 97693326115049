import React, { useMemo } from "react";
import { Outlet } from "react-router-dom";
import { useUMgr } from "web-shared/src/web-context";
import {
  HpStateContext,
  PortalWebStateMgr,
} from "web-shared/src/domains/hp/dashboard/hp.webstatemgr";

export const HpLayout: React.FC = () => {
  return <MyLayoutContent />;
};

const MyLayoutContent: React.FC = () => {
  const uMgr = useUMgr();
  const state = useMemo(() => new PortalWebStateMgr(uMgr), [uMgr]);

  return (
    <HpStateContext.Provider value={state}>
      <Outlet />
    </HpStateContext.Provider>
  );
};
