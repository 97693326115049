// calendar/types/calendar.types.ts
import * as S from "@effect/schema/Schema";
import { v } from "convex/values";
import { format } from "date-fns";
import { Match, Option } from "effect";
import { type Doc, type Id } from "../_generated/dataModel";
import type { SimpleUser } from "../User/User.Types";
import { capitalizeFirstLetter } from "../../../util";

// Type alias to support both old and new table names
export type CalEventInstanceId = Id<"calEvents">;

const CalEventParticipantStatus = S.Union(
  S.Literal("INVITED"),
  S.Literal("ACCEPTED"),
  S.Literal("DECLINED"),
  S.Literal("REMOVED"),
  S.Literal("HOST")
);
export type CalEventParticipantStatus = typeof CalEventParticipantStatus.Type;

const ParticipantRole = S.Union(
  S.Literal("ORGANIZER"),
  S.Literal("CO_ORGANIZER"),
  S.Literal("PARTICIPANT")
);
type ParticipantRole = typeof ParticipantRole.Type;

export const attendeeRole = v.union(
  v.literal("ORGANIZER"),
  v.literal("CO_ORGANIZER"),
  v.literal("PARTICIPANT")
);
export type AttendeeRole = typeof attendeeRole.type;

const MyInstanceAttendanceStatus = S.Union(
  S.Literal("ATTENDING"),
  S.Literal("DECLINED"),
  S.Literal("MAYBE"),
  S.Literal("CANCELLED"),
  S.Literal("HOST")
);
export type MyInstanceAttendanceStatus = typeof MyInstanceAttendanceStatus.Type;

export const attendanceStatus = v.union(
  v.literal("ATTENDING"),
  v.literal("DECLINED"),
  v.literal("MAYBE"),
  v.literal("CANCELLED"),
  v.literal("HOST")
);
export type AttendanceStatus = typeof attendanceStatus.type;

export interface HcCalInstanceSchema {
  template: {
    _id: Id<"calEventTemplates">;
    title: string;
  };
  hp: SimpleUser;
  client: SimpleUser;
  instance: {
    _id: CalEventInstanceId;
    startAt: number;
    endsAt: number;
  };
}

const BaseTemplateInfo = S.Struct({
  _id: S.String,
  title: S.String,
  description: S.NullOr(S.String),
  rrule: S.NullOr(S.String),
});

const BaseCommunityInfo = S.Struct({
  _id: S.String,
  slug: S.String,
  name: S.String,
});

export class CalEventInstanceST extends S.Class<CalEventInstanceST>(
  "CalEventInstanceST"
)({
  template: BaseTemplateInfo,
  participants: S.Array(
    S.Struct({
      _userId: S.String,
      user: S.Struct({
        id: S.String,
        name: S.String,
        email: S.optional(S.String),
        profilePhoto: S.NullOr(S.String),
      }),
      role: ParticipantRole,
      attendanceStatus: S.NullOr(MyInstanceAttendanceStatus),
    })
  ),
  instance: S.Struct({
    _id: S.String,
    startTime: S.Number,
    endTime: S.Number,
    sessionId: S.NullOr(S.String),
  }),
}) {
  static from(data: typeof CalEventInstanceST.Encoded): CalEventInstanceST {
    const participants = data.participants.map((p) => ({
      ...p,
    }));
    return CalEventInstanceST.make(
      { ...data, participants },
      { disableValidation: true }
    );
  }

  get encoded(): typeof CalEventInstanceST.Encoded {
    return S.encodeUnknownSync(CalEventInstanceST)(this);
  }

  get startTime(): number {
    return this.instance.startTime;
  }

  get templateId(): Id<"calEventTemplates"> {
    return this.template._id as Id<"calEventTemplates">;
  }

  get rrule(): string | null {
    return this.template.rrule;
  }

  static fromDocData = (p: {
    participantsWithUserInfo: Array<
      {
        user: SimpleUser;
      } & Doc<"calEventTemplateParticipants">
    >;
    template: Doc<"calEventTemplates">;
    instance: Doc<"calEvents">;
  }): CalEventInstanceST => {
    return CalEventInstanceST.make(
      {
        template: p.template,
        instance: p.instance,
        participants: p.participantsWithUserInfo.map((pwu) => ({
          ...pwu,
          user: pwu.user,
          _userId: pwu.user.id,
          attendanceStatus:
            pwu.attendanceStatus as MyInstanceAttendanceStatus | null,
        })),
      },
      { disableValidation: true }
    );
  };

  getMyParticipationInfo(p: { myUserId: Id<"users"> }): Option.Option<{
    user: SimpleUser;
    role: ParticipantRole;
    attendanceStatus: MyInstanceAttendanceStatus | null;
  }> {
    return Option.fromNullable(
      this.participants.find(
        (participant) => participant._userId === p.myUserId
      )
    ).pipe(
      Option.map((v) => ({
        ...v,
        user: { ...v.user, id: v._userId as Id<"users"> },
      }))
    );
  }

  isInStartableWindow(p: { now: number }): boolean {
    const thirtyMinutes = 1000 * 60 * 30;

    // Check if we're within the 10-minute window before or after start time
    return (
      p.now >= this.startTime - thirtyMinutes && // after or at 30 minutes before start
      p.now <= this.startTime + thirtyMinutes // before or at 30 minutes after start
    );
  }

  getMyCta(p: {
    myUserId: Id<"users">;
    now: number;
    mbSessionLifecycle: Doc<"rtcSessionLifecycle"> | null;
  }): CommunityEventInstanceCtaST {
    const myParticipationInfo = this.getMyParticipationInfo(p);
    return myParticipationInfo.pipe(
      Option.match({
        onNone: () => CommunityEventInstanceCtaST.asRsvp(),
        onSome: (v) => {
          const isInStartableWindow = this.isInStartableWindow(p);

          const amIHost = v.role === "ORGANIZER";

          if (isInStartableWindow) {
            if (amIHost) {
              if (p.mbSessionLifecycle?.startedAt) {
                return CommunityEventInstanceCtaST.asJoinSession({
                  mode: {
                    tag: "JOIN",
                    baseSessionIdStr: p.mbSessionLifecycle.rtcSessionId,
                  },
                });
              } else {
                return CommunityEventInstanceCtaST.asJoinSession({
                  mode: { tag: "START" },
                });
              }
            } else {
              if (this.instance.sessionId === null) {
                return CommunityEventInstanceCtaST.asJoinSession({
                  mode: { tag: "WAIT_FOR_HOST" },
                });
              } else {
                return CommunityEventInstanceCtaST.asJoinSession({
                  mode: {
                    tag: "JOIN",
                    baseSessionIdStr: this.instance.sessionId,
                  },
                });
              }
            }
          }

          return Match.value(v.attendanceStatus).pipe(
            Match.when("HOST", () => CommunityEventInstanceCtaST.asNone()),
            Match.when(null, () => CommunityEventInstanceCtaST.asRsvp()),
            Match.orElse((status) => CommunityEventInstanceCtaST.asView(status))
          );
        },
      })
    );
  }

  getMyCtaButtonInfo(p: {
    myUserId: Id<"users">;
    now: number;
    mbSessionLifecycle: Doc<"rtcSessionLifecycle"> | null;
  }): {
    title: string;
    isDisabled: boolean;
    cta: typeof CommunityCalEventInstanceCtaAction.Type;
    style: "INVERSE" | "REGULAR";
  } {
    const myCta = this.getMyCta(p);
    return {
      title: myCta.title,
      isDisabled: Match.value(myCta.action).pipe(
        Match.when({ tag: "VIEW" }, () => true),
        Match.when({ tag: "RSVP" }, () => false),
        Match.when({ tag: "NONE" }, () => true),
        Match.when({ tag: "JOIN_SESSION" }, () => false),
        Match.exhaustive
      ),
      cta: myCta.action,
      style: Match.value(myCta.action).pipe(
        Match.when({ tag: "VIEW" }, () => "REGULAR" as const),
        Match.when({ tag: "RSVP" }, () => "INVERSE" as const),
        Match.when({ tag: "NONE" }, () => "REGULAR" as const),
        Match.when({ tag: "JOIN_SESSION" }, () => "INVERSE" as const),
        Match.exhaustive
      ),
    };
  }
}

export class CommunityEventInstanceST extends S.Class<CommunityEventInstanceST>(
  "CommunityEventInstanceST"
)({
  instanceST: CalEventInstanceST,
  community: BaseCommunityInfo,
}) {
  static from(
    data: typeof CommunityEventInstanceST.Encoded
  ): CommunityEventInstanceST {
    return CommunityEventInstanceST.make(
      {
        instanceST: CalEventInstanceST.from(data.instanceST),
        community: data.community,
      },
      { disableValidation: true }
    );
  }

  static fromCalEventInstanceST = (
    instanceSTEncoded: typeof CalEventInstanceST.Encoded,
    community: Doc<"communityInfo">
  ): CommunityEventInstanceST => {
    const instanceST = CalEventInstanceST.from(instanceSTEncoded);
    return CommunityEventInstanceST.make(
      {
        instanceST,
        community,
      },
      { disableValidation: true }
    );
  };

  get encoded(): typeof CommunityEventInstanceST.Encoded {
    return S.encodeUnknownSync(CommunityEventInstanceST)(this);
  }

  get startTime(): number {
    return this.instanceST.startTime;
  }

  get endTime(): number {
    return this.instanceST.instance.endTime;
  }

  get durationInMinutes(): number {
    const diffInMs = this.endTime - this.startTime;
    return Math.floor(diffInMs / 60000);
  }

  get templateId(): Id<"calEventTemplates"> {
    return this.instanceST.templateId as Id<"calEventTemplates">;
  }

  get rrule(): string | null {
    return this.instanceST.rrule;
  }

  static fromDocData = (p: {
    participantsWithUserInfo: Array<
      {
        user: SimpleUser;
      } & Doc<"calEventTemplateParticipants">
    >;
    template: Doc<"calEventTemplates">;
    community: Doc<"communityInfo">;
    instance: Doc<"calEvents">;
    baseSessionId: Id<"rtcSession"> | null;
  }): CommunityEventInstanceST => {
    const instanceST = CalEventInstanceST.fromDocData(p);
    return CommunityEventInstanceST.make(
      {
        instanceST,
        community: p.community,
        baseSessionId: p.baseSessionId,
      },
      { disableValidation: true }
    );
  };
}

const StartOrJoinMode = S.Union(
  S.Struct({ tag: S.Literal("JOIN"), baseSessionIdStr: S.String }),
  S.Struct({ tag: S.Literal("START") }),
  S.Struct({ tag: S.Literal("WAIT_FOR_HOST") })
);

const startOrJoinMode = v.union(
  v.object({ tag: v.literal("JOIN"), baseSessionIdStr: v.string() }),
  v.object({ tag: v.literal("START") }),
  v.object({ tag: v.literal("WAIT_FOR_HOST") })
);

export const CommunityCalEventInstanceCtaAction = S.Union(
  S.Struct({ tag: S.Literal("VIEW"), status: MyInstanceAttendanceStatus }),
  S.Struct({ tag: S.Literal("RSVP") }),
  S.Struct({ tag: S.Literal("NONE") }),
  S.Struct({
    tag: S.Literal("JOIN_SESSION"),
    mode: StartOrJoinMode,
  })
);
export type CommunityCalEventInstanceCtaAction =
  typeof CommunityCalEventInstanceCtaAction.Type;

export const communityEventInstanceCtaAction = v.union(
  v.object({ tag: v.literal("VIEW"), status: attendanceStatus }),
  v.object({ tag: v.literal("RSVP") }),
  v.object({ tag: v.literal("NONE") }),
  v.object({ tag: v.literal("JOIN_SESSION"), mode: startOrJoinMode })
);

export class CommunityEventInstanceCtaST {
  constructor(public readonly action: CommunityCalEventInstanceCtaAction) {}

  static fromAction(
    action: CommunityCalEventInstanceCtaAction
  ): CommunityEventInstanceCtaST {
    return new CommunityEventInstanceCtaST(action);
  }

  get title(): string {
    return Match.value(this.action).pipe(
      Match.when({ tag: "VIEW" }, ({ status }) =>
        capitalizeFirstLetter(status)
      ),
      Match.when({ tag: "RSVP" }, () => "RSVP"),
      Match.when({ tag: "NONE" }, () => "Attending"),
      Match.when({ tag: "JOIN_SESSION" }, ({ mode }) =>
        Match.value(mode).pipe(
          Match.when({ tag: "JOIN" }, () => "Join"),
          Match.when({ tag: "START" }, () => "Start"),
          Match.when({ tag: "WAIT_FOR_HOST" }, () => "Waiting for host"),
          Match.exhaustive
        )
      ),
      Match.exhaustive
    );
  }

  static asView(
    status: MyInstanceAttendanceStatus
  ): CommunityEventInstanceCtaST {
    return new CommunityEventInstanceCtaST({ tag: "VIEW", status });
  }

  static asRsvp(): CommunityEventInstanceCtaST {
    return new CommunityEventInstanceCtaST({ tag: "RSVP" });
  }

  static asNone(): CommunityEventInstanceCtaST {
    return new CommunityEventInstanceCtaST({ tag: "NONE" });
  }

  static asJoinSession(p: {
    mode: typeof StartOrJoinMode.Type;
  }): CommunityEventInstanceCtaST {
    return new CommunityEventInstanceCtaST({
      tag: "JOIN_SESSION",
      mode: p.mode,
    });
  }
}

export const calEventParticipantInviteStatus = v.union(
  v.literal("INVITED"),
  v.literal("ACCEPTED"),
  v.literal("DECLINED"),
  v.literal("REMOVED"),
  v.literal("HOST")
);

export type KnownCalendarApptTag = "COMMUNITY_EVENT" | "HC_EVENT";

export abstract class KnownCalendarAppt {
  abstract tag: KnownCalendarApptTag;
  abstract defaultColor: string;

  instanceId: string;
  title: string;
  startTime: number;
  endTime: number;

  constructor(p: {
    title: string;
    startTime: number;
    endTime: number;
    instanceId: string;
  }) {
    this.title = p.title;
    this.startTime = p.startTime;
    this.endTime = p.endTime;
    this.instanceId = p.instanceId;
  }
}

export class CommunityEventAppt extends KnownCalendarAppt {
  tag: KnownCalendarApptTag = "COMMUNITY_EVENT";
  defaultColor = "purple";

  get encoded() {
    return {
      tag: this.tag,
      defaultColor: this.defaultColor,
      instanceId: this.instanceId,
      title: this.title,
      startTime: this.startTime,
      endTime: this.endTime,
    };
  }
}

export class HcEventAppt extends KnownCalendarAppt {
  tag: KnownCalendarApptTag = "HC_EVENT";
  defaultColor = "blue";

  get encoded() {
    return {
      tag: this.tag,
      defaultColor: this.defaultColor,
      instanceId: this.instanceId,
      title: this.title,
      startTime: this.startTime,
      endTime: this.endTime,
    };
  }
}

export interface CommunityEventDetailsPanelST {
  templateId: Id<"calEventTemplates">;
  title: string;
  description: string | null;
  occurenceMessage: string;
  button: {
    title: string;
    isDisabled: boolean;
    cta: CommunityCalEventInstanceCtaAction;
    style: "INVERSE" | "REGULAR";
  };
  attendees: Array<{
    user: {
      profilePhoto: string | null;
    };
    attendanceStatus: MyInstanceAttendanceStatus | null;
  }>;
}

export const calEventTemplateType = v.union(
  v.literal("HC"),
  v.literal("COMMUNITY")
);
export type CalEventTemplateType = typeof calEventTemplateType.type;

export const eventInviteResponseCta = v.union(
  v.literal("ACCEPT"),
  v.literal("REJECT"),
  v.literal("CANCEL"),
  v.literal("RESCHEDULE")
);
export type EventInviteResponseCta = typeof eventInviteResponseCta.type;

export type CalEventType =
  | { tag: "COMMUNITY"; communitySlug: Id<"communityInfo"> }
  | { tag: "HC"; hpId: Id<"users">; clientId: Id<"users"> };

export type ParticipantWithUserInfo = {
  user: SimpleUser;
  _userId: Id<"users">;
} & Doc<"calEventTemplateParticipants">;

export type ParticipantWithUserInfoAndSessionInfo = {
  user: SimpleUser;
  _userId: Id<"users">;
} & Doc<"calEventTemplateParticipants">;
