import { useSetupHpClientsDash } from "frontend-shared/src/users/hp/clients.vms";
import React from "react";
import "react-calendar/dist/Calendar.css";
import "react-clock/dist/Clock.css";
import "react-datetime-picker/dist/DateTimePicker.css";
import type { Id } from "shared/be/convex/_generated/dataModel";
import type { PastHcApptsRowItemETO } from "shared/be/convex/Screens/Hp/Dashboard/Clients/Clients.Types";
import { UROUTES } from "shared/routes/u.routes";
import { InversePrimaryButton } from "web-shared/src/components/button";
import { FullContainerLoadingSpinner } from "web-shared/src/components/loading";
import { SimpleTableList } from "web-shared/src/components/table";
import { RegisterNewClientForm } from "web-shared/src/domains/hp/clients/register-new-client.form";
import { StartASessionForm } from "web-shared/src/domains/hp/clients/start-a-session-form.fc";
import { useWebGlobalDisplayVM } from "web-shared/src/web-context";
import {
  activeClientsTableProps,
  ongoingHcApptsTableProps,
  pastHcApptsTableProps,
  pendingClientsTableProps,
  upcomingHcApptsTableProps,
} from "./clients-page.components";
import { AvatarCircle } from "../../../components/avatar.tc";
import { ImageSrc } from "shared/types/miscellaneous.types";

type OngoingHcAppt = {
  clientInfo: {
    name: string;
    profilePhoto: string | null;
  };
  rejoinCta: {
    onClick: () => void;
  };
  endSessionCta: {
    onClick: () => void;
  };
};

type UpcomingHcAppt = {
  clientInfo: { name: string; profilePhoto: string | null };
  clientId: Id<"users">;
  appt: {
    date: Date;
  };
};

type PendingClient = {
  clientInfo: {
    name: string;
    profilePhoto: string | null;
  };
  cta: {
    title: string;
    onClick: () => void;
  };
};

type PendingReqForMeToBePractitioner = {
  relationshipId: Id<"practitionerRelationships">;
  clientInfo: {
    name: string;
    profilePhoto: string | null;
  };
};

type ActiveClient = {
  clientInfo: {
    name: string;
    profilePhoto: string | null;
  };
  clientId: Id<"users">;
  startSession: {
    onClick: () => void;
  };
};

type PastHcAppt = PastHcApptsRowItemETO;

export const HpClientsDash: React.FC<{
  nav: (route: string) => void;
}> = ({ nav }) => {
  const webDisplayVM = useWebGlobalDisplayVM();

  const {
    resendInviteClientEmail,
    myPendingReqsForMeToBePractitioner,
    myActiveClients,
    myOngoingHcSessions,
    myUpcomingHcAppts,
    myPastHcAppts,
    endHcSession,
    respondToClientRequest,
  } = useSetupHpClientsDash();

  if (
    myOngoingHcSessions === undefined ||
    myPendingReqsForMeToBePractitioner === undefined ||
    myActiveClients === undefined ||
    myUpcomingHcAppts === undefined ||
    myPastHcAppts === undefined
  ) {
    return <FullContainerLoadingSpinner />;
  }

  if (
    myOngoingHcSessions.length === 0 &&
    myActiveClients.clients.length === 0 &&
    myPendingReqsForMeToBePractitioner.length === 0 &&
    myUpcomingHcAppts.length === 0
  ) {
    return <EmptyState />;
  }

  return (
    <div className="relative">
      <div className="flex justify-between items-center pb-8">
        <h3 className="font-outfit text-[24px] text-vid-black-900">
          Appointments
        </h3>
        <div>
          <InversePrimaryButton
            title="Invite a client"
            onClick={() => {
              webDisplayVM.globalSheetVM.openSheet(
                {
                  title: "Invite a client",
                  type: { tag: "create", icon: "profile-add" },
                },
                <RegisterNewClientForm />
              );
            }}
          />
        </div>
      </div>

      <div className="">
        <SimpleTableList<
          [
            PendingReqForMeToBePractitioner,
            OngoingHcAppt,
            UpcomingHcAppt,
            PendingClient,
            ActiveClient,
            PastHcAppt,
          ]
        >
          tables={[
            {
              data: myPendingReqsForMeToBePractitioner.map((c) => ({
                relationshipId: c.request._id,
                clientInfo: {
                  name: c.client.name,
                  profilePhoto: c.client.profilePhoto,
                },
              })),
              columns: [
                {
                  key: "clientInfo",
                  header: "Client request",
                  render: (item) => (
                    <div className="flex items-center gap-2">
                      <AvatarCircle
                        mbProfilePhoto={ImageSrc.fromMbUrl(
                          item.clientInfo.profilePhoto
                        )}
                        size={40}
                      />
                      <div>
                        <p>{item.clientInfo.name}</p>
                      </div>
                    </div>
                  ),
                },
                {
                  key: "cta",
                  header: "Action",
                  render: (item) => {
                    return (
                      <InversePrimaryButton
                        title="Accept client"
                        onClick={() => {
                          respondToClientRequest({
                            relationshipId: item.relationshipId,
                            accept: true,
                          });
                        }}
                      />
                    );
                  },
                },
              ],
            },
            ongoingHcApptsTableProps(
              myOngoingHcSessions.map((ohc) => ({
                clientInfo: {
                  name: ohc.clientInfo.name,
                  profilePhoto: ohc.clientInfo.image,
                },
                rejoinCta: {
                  onClick: () => {
                    nav(
                      UROUTES.SESSIONS.SESSION_ID.RTC.buildPath({
                        sessionId: ohc.baseSessionId,
                      })
                    );
                  },
                },
                endSessionCta: {
                  onClick: () => {
                    endHcSession({ baseSessionId: ohc.baseSessionId }).then(
                      (r) => {
                        console.log("RESULT OF ENDING HC SESSION! ", r);
                        webDisplayVM.showToast({
                          id: "end-hc-session",
                          title: "Successfully ended session",
                        });
                      }
                    );
                  },
                },
              }))
            ),
            upcomingHcApptsTableProps(
              myUpcomingHcAppts.map((u) => ({
                clientInfo: u.clientInfo,
                clientId: u.clientInfo.id,
                appt: {
                  date: new Date(u.startsAt),
                },
              }))
            ),
            pendingClientsTableProps(
              myPendingReqsForMeToBePractitioner.map((c) => ({
                clientInfo: {
                  name: c.client.name,
                  profilePhoto: c.client.profilePhoto,
                },
                cta: {
                  title: "Resend invitation",
                  onClick: () => {
                    resendInviteClientEmail({
                      clientEmail: c.client.email!,
                    }).then((r) => {
                      console.log(
                        "RESULT OF RESENDING INVITE CLIENT EMAIL! ",
                        r
                      );
                      webDisplayVM.showToast({
                        id: "resend-invite-client-email",
                        title: "Invite resent!",
                      });
                    });
                  },
                },
              }))
            ),
            activeClientsTableProps(
              (c) => {
                nav(
                  UROUTES.HP.MY.DASHBOARD.CLIENTS.CLIENT_ID.PAST_SESSIONS.buildPath(
                    {
                      clientId: c.clientId,
                    }
                  )
                );
              },
              myActiveClients.clients.map((c) => ({
                clientInfo: {
                  name: c.name!,
                  profilePhoto: c.profilePhoto ?? null,
                },
                clientId: c.clientId,
                startSession: {
                  onClick: () => {
                    webDisplayVM.globalSheetVM.openSheet(
                      {
                        title: "Start a session",
                        type: { tag: "create" },
                      },
                      <StartASessionForm
                        forClient={{
                          _tag: "EXISTING",
                          client: {
                            id: c.clientId,
                            name: c.name!,
                            profilePhoto: c.profilePhoto ?? null,
                          },
                        }}
                        onClose={() => {
                          window.location.reload();
                        }}
                      />
                    );
                  },
                },
              }))
            ),
            pastHcApptsTableProps(myPastHcAppts),
          ]}
        />
      </div>
    </div>
  );
};

const EmptyState: React.FC = () => {
  const webDisplayVM = useWebGlobalDisplayVM();
  return (
    <div className="flex flex-col flex-1 justify-center items-center">
      <div className="flex gap-8">
        <AddAClientCard
          onClick={() => {
            webDisplayVM.globalSheetVM.openSheet(
              {
                title: "Invite a client",
                type: { tag: "create", icon: "profile-add" },
              },
              <RegisterNewClientForm />
            );
          }}
        />
      </div>
    </div>
  );
};

const AddAClientCard: React.FC<{ onClick: () => void }> = ({ onClick }) => {
  return (
    <div
      onClick={onClick}
      className="flex flex-col justify-center items-center cursor-pointer p-8"
    >
      <h4 className="font-outfit font-medium text-default-purple text-4xl leading-9 mb-4">
        Invite your first client
      </h4>
      <p className="font-sans font-light text-md mb-4">
        We'll send an invitation and let you know when it's accepted.
      </p>
      <div className="bg-vid-purple p-buttons px-24 rounded-buttons text-white flex justify-center items-center">
        {`Invite`}
      </div>
    </div>
  );
};
