import { FullContainerLoadingSpinner } from "web-shared/src/components/loading";
import { useQuery } from "convex/react";
import { api } from "shared/be/convex/_generated/api";
import type { Id } from "shared/be/convex/_generated/dataModel";

export const WrapupSummaryTab: React.FC<{
  sessionId: Id<"rtcSession">;
}> = ({ sessionId }) => {
  const summary = useQuery(
    api.Screens.U.Sessions.ReviewScreenFns.getChronologicalSummary,
    {
      baseSessionId: sessionId,
    }
  );

  if (summary === undefined) {
    return <FullContainerLoadingSpinner />;
  }

  return (
    <div className="flex flex-col gap-4 max-h-[600px] overflow-y-auto">
      {summary.segments.map((segment) => (
        <div
          key={segment.startSis}
          className="flex flex-col gap-2 border p-4 bg-bg-gray rounded-[12px]"
        >
          <div className="text-vid-black-900 text-lg font-semibold">
            {segment.title}
          </div>
          <div className="text-vid-black-900 text-sm">
            {segment.description}
          </div>
        </div>
      ))}
    </div>
  );
};
