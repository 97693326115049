import { v } from "convex/values";
import type { Id } from "../_generated/dataModel";
import type { SimpleUser } from "../User/User.Types";

export const postMention = v.object({
  id: v.string(),
  name: v.string(),
  startIndex: v.number(),
  endIndex: v.number(),
});
export type PostMention = typeof postMention.type;

export type CommunityPostMedia = {
  _id: Id<"communityPostMedia">;
  postId: Id<"communityPosts">;
  publicId: string;
  format: string;
  mediaType: "image" | "video" | "audio";
  uploadedAt: number;
  cachedDownloadUrl?: string;
};

export const NewPostMedia = v.object({
  publicId: v.string(),
  format: v.string(),
  mediaType: v.union(
    v.literal("image"),
    v.literal("video"),
    v.literal("audio")
  ),
});
export type NewPostMedia = typeof NewPostMedia.type;

export type CommunityPostReaction = {
  _id: Id<"communityPostReactions">;
  postId: Id<"communityPosts">;
  authorId: string;
  reactionType: "like" | "heart" | "laugh" | "wow" | "sad" | "angry" | "smile";
  createdAt: number;
};

export type CommunityPostComment = {
  _id: Id<"communityPostComments">;
  postId: Id<"communityPosts">;
  authorId: string;
  content: string;
  repliedAt: number;
  author?: {
    name: string;
    profilePhoto?: string;
  };
};

export type PublishedCommunityPost = {
  _id: Id<"communityPosts">;
  authorId: Id<"users">;
  communitySlug: string;
  content: string;
  embeds?: EmbedContent[];
  postedAt: number;
  author: {
    name: string;
    profilePhoto?: string;
    _id: Id<"users">;
  };
};

export type EnrichedCommunityPost = PublishedCommunityPost & {
  media: CommunityPostMedia | null;
  embededContent: EmbedContent | null;
  comments: CommunityPostComment[];
  reactions: CommunityPostReaction[];
  isLikedByMe: boolean;
  reactionCounts: Record<CommunityPostReaction["reactionType"], number>;
  mentions: PostMention[];
};

export type CommunityFeed = {
  posts: EnrichedCommunityPost[];
  hasMore: boolean;
  cursor?: number;
};

const embedType = v.union(v.literal("youtube"), v.literal("general"));

export const embedContent = v.object({
  url: v.string(),
  type: embedType,
  title: v.optional(v.string()),
  description: v.optional(v.string()),
  thumbnail: v.optional(v.string()),
  metadata: v.optional(v.any()),
});
export type EmbedContent = typeof embedContent.type;

export type CommunityPostSetup = {
  postId: Id<"communityPosts">;
  imageUploadUrl: string;
  videoUploadUrl: string;
  taggableMembers: SimpleUser[];
};
