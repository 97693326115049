import { useMutation } from "convex/react";
import {
  createEvent,
  createStore,
  createEffect,
  sample,
  type StoreWritable,
} from "effector";
import { useOnce, useQueryStore } from "frontend-shared/src/util";
import { useEffect } from "react";
import { api } from "shared/be/convex/_generated/api";
import type { Id } from "shared/be/convex/_generated/dataModel";
import type {
  LessonWithEnrollmentProgressETO,
  ModuleWithLessonsAndProgressETO,
} from "shared/be/convex/Learning/Courses/Courses.Types";

export function useSetupLeftNavStepsCourseTemplateVM(
  courseId: Id<"courseInfo">
) {
  const syncModules = useMutation(
    api.Learning.Courses.CoursesFns.syncModulesWithLessonsAndProgress
  );

  const markLessonCompleted = useMutation(
    api.Learning.Courses.LessonsFns.markLessonCompleted
  );

  const vm = useOnce(
    () =>
      new LeftNavStepsCourseTemplateVM({
        syncModules: () => syncModules({ courseId }),
        courseId,
        markLessonCompleted: (
          lessonId: Id<"courseLessons">,
          completed: boolean
        ) => markLessonCompleted({ lessonId, completed }),
      })
  );

  useEffect(() => {
    vm.syncModulesEvt();
  }, []);

  return vm;
}

export class LeftNavStepsCourseTemplateVM {
  // Events
  setActiveLessonEvt = createEvent<Id<"courseLessons">>();
  toggleModuleEvt = createEvent<Id<"courseModules">>();
  markLessonCompletedEvt = createEvent<{
    lessonId: Id<"courseLessons">;
    completed: boolean;
    nextLessonId: Id<"courseLessons"> | null;
  }>();
  syncModulesEvt = createEvent();

  // Effects
  syncModulesFx = createEffect<
    { courseId: Id<"courseInfo"> },
    ModuleWithLessonsAndProgressETO[]
  >();

  // Stores
  $activeLesson = createStore<Id<"courseLessons"> | null>(null);
  $modules = createStore<ModuleWithLessonsAndProgressETO[] | null>(null);
  $currentLesson = createStore<LessonWithEnrollmentProgressETO | null>(null);
  $expandedModuleIds = createStore<Set<Id<"courseModules">>>(new Set());

  constructor(args: {
    syncModules: () => Promise<ModuleWithLessonsAndProgressETO[]>;
    courseId: Id<"courseInfo">;
    markLessonCompleted: (
      lessonId: Id<"courseLessons">,
      completed: boolean
    ) => Promise<null>;
  }) {
    this.syncModulesFx.use(args.syncModules);

    this.syncModulesEvt.watch(() => {
      console.log("syncing modules");
      this.syncModulesFx({ courseId: args.courseId });
    });

    this.syncModulesFx.doneData.watch((modules) => {
      console.log("modules from sync!!!", modules);
    });

    sample({
      clock: this.syncModulesFx.doneData,
      target: this.$modules,
    });

    // Handle active lesson changes
    sample({
      clock: this.setActiveLessonEvt,
      target: this.$activeLesson,
    });

    // Update current lesson when active lesson changes
    sample({
      clock: [this.$activeLesson, this.$modules],
      source: { activeLesson: this.$activeLesson, modules: this.$modules },
      fn: ({ activeLesson, modules }) => {
        if (!modules || !activeLesson) return null;
        const allLessons = modules.flatMap((m) => m.lessons);
        return allLessons.find((l) => l.id === activeLesson) ?? null;
      },
      target: this.$currentLesson,
    });

    // Handle module expansion toggling
    sample({
      clock: this.toggleModuleEvt,
      source: this.$expandedModuleIds,
      fn: (expandedIds, moduleId) => {
        const newSet = new Set(expandedIds);
        if (newSet.has(moduleId)) {
          newSet.delete(moduleId);
        } else {
          newSet.add(moduleId);
        }
        return newSet;
      },
      target: this.$expandedModuleIds,
    });

    // Handle lesson completion
    sample({
      clock: this.markLessonCompletedEvt,
      source: this.$modules,
      fn: (modules, { lessonId, completed }) => {
        if (!modules) return null;
        return modules.map((module) => ({
          ...module,
          lessons: module.lessons.map((lesson) =>
            lesson.id === lessonId
              ? { ...lesson, isCompleted: completed }
              : lesson
          ),
          completedLessons: completed
            ? module.completedLessons + 1
            : module.completedLessons - 1,
        }));
      },
      target: this.$modules,
    });

    // Navigate to next lesson after marking complete
    sample({
      clock: this.markLessonCompletedEvt,
      filter: ({ completed, nextLessonId }) =>
        completed && nextLessonId !== null,
      fn: ({ nextLessonId }) => nextLessonId!,
      target: this.setActiveLessonEvt,
    });

    // Call backend when lesson completion changes
    this.markLessonCompletedEvt.watch(({ lessonId, completed }) => {
      args.markLessonCompleted(lessonId, completed).then(() => {
        // Sync modules after marking lesson complete to ensure we have latest state
        this.syncModulesEvt();
      });
    });

    // Initial sync
    this.syncModulesEvt();
  }
}
