import { useConvex } from "convex/react";
import { pipe } from "fp-ts/lib/function";
import { CalendarStateMgr } from "frontend-shared/src/mgrs/state-mgrs/calendar.statemgr";
import { useOnce } from "frontend-shared/src/util";
import React, { useEffect } from "react";
import { useTypedSearchParams } from "react-router-typesafe-routes/dom";
import { TE } from "frontend-shared/prelude";
import { api } from "shared/be/convex/_generated/api";
import type { Id } from "shared/be/convex/_generated/dataModel";
import { UROUTES } from "shared/routes/u.routes";
import { NewHCSessionFormForCp } from "web-shared/src/domains/cp/calendar/schedule-hc-session.form";
import { FullCalendar } from "web-shared/src/domains/scheduling/calendar/full-calendar";
import { useWebGlobalDisplayVM } from "web-shared/src/web-context";
import { HcCalEventTemplatePanel } from "web-shared/src/domains/calendar/hc-cal-event-template-panel";
import { ViewHcSessionDetailsPanel } from "web-shared/src/domains/calendar/view-hc-session-details";

export const CpCalendarDash: React.FC = () => {
  const [{ eventTemplateId }] = useTypedSearchParams(
    UROUTES.CP.DASHBOARD.CALENDAR
  );
  const webDisplayVM = useWebGlobalDisplayVM();
  const webConvexReact = useConvex();

  useEffect(() => {
    if (eventTemplateId) {
      webDisplayVM.globalSheetVM.openSheet(
        { title: "Appointment details", type: { tag: "view" } },
        <HcCalEventTemplatePanel
          eventTemplateId={eventTemplateId as Id<"calEventTemplates">}
        />
      );
    }
  }, [eventTemplateId]);

  const stateMgr = useOnce(
    () =>
      new CalendarStateMgr({
        fetchApptsTE: (dayInFocus) =>
          pipe(
            TE.fromTask(() =>
              webConvexReact.query(
                api.Calendar.HcCalEventFns.getMyNearbyMonthCalendarAppts,
                {
                  now: dayInFocus.getTime(),
                  asRole: "CP",
                }
              )
            ),
            TE.chainFirst((r) =>
              TE.fromIO(() => {
                console.log("fetchApptsTE result", r);
              })
            )
          ),
      })
  );
  return (
    <FullCalendar
      stateMgr={stateMgr}
      fetchOnMount
      onApptClick={(appt) => {
        console.log("appt clicked", appt);
        webDisplayVM.globalSheetVM.openSheet(
          {
            title: "Appointment details",
            type: {
              tag: "view",
            },
          },
          <ViewHcSessionDetailsPanel
            instanceId={appt.instanceId as Id<"calEvents">}
          />
        );
      }}
      onDayClick={(day) => {
        console.log(day);
      }}
      newApptButton={{
        onClick: () => {
          webDisplayVM.globalSheetVM.openSheet(
            {
              title: "New appointment",
              type: { tag: "create" },
            },
            <NewHCSessionFormForCp
              onSuccessSubmit={() => {
                webDisplayVM.toastVM.showToast({
                  id: "new-appt-success",
                  title: "Session scheduled successfully",
                  duration: 3000,
                });
                webDisplayVM.globalSheetVM.closeSheet();
                webDisplayVM.reloadDash();
              }}
            />
          );
        },
        title: "New appointment",
      }}
    />
  );
};
