import { combine, createEvent, createStore, sample } from "effector";

type KnownModality = {
  slug: string;
  name: string;
};

export class SelectModalitiesVM {
  // Events
  allKnownModalitiesLoaded = createEvent<KnownModality[]>();
  setInitialModalities = createEvent<string[]>(); // Sets the initial modalities
  selectModality = createEvent<string>(); // Triggered when a modality is selected
  toggleDropdown = createEvent(); // Toggles the dropdown state
  closeDropdown = createEvent(); // Explicitly closes the dropdown
  openDropdown = createEvent(); // Explicitly opens the dropdown

  // Stores
  $allKnownModalities = createStore<KnownModality[]>([]).on(
    this.allKnownModalitiesLoaded,
    (_, modalities) => {
      console.log("Event: allKnownModalitiesLoaded", modalities);
      return modalities;
    }
  );

  $initialSlugs = createStore<string[]>([]).on(
    this.setInitialModalities,
    (_, slugs) => {
      console.log("Event: setInitialModalities", slugs);
      return slugs;
    }
  );

  $userSelectedSlugs = createStore<Set<string>>(new Set())
    .on(this.setInitialModalities, (_, initialSlugs) => {
      // Initialize userSelectedSlugs with initial modalities
      return new Set(initialSlugs);
    })
    .on(this.selectModality, (state, slug) => {
      const newState = new Set(state);
      if (newState.has(slug)) {
        newState.delete(slug);
        console.log(`Event: Deselect modality "${slug}"`);
      } else {
        newState.add(slug);
        console.log(`Event: Select modality "${slug}"`);
      }
      return newState;
    });

  // Derived Stores
  $selectedModalities = createStore<KnownModality[]>([]);

  $availableModalities = createStore<KnownModality[]>([]);

  $isDropdownOpen = createStore<boolean>(false)
    .on(this.toggleDropdown, (state) => {
      console.log("Event: toggleDropdown");
      return !state;
    })
    .on(this.openDropdown, () => {
      console.log("Event: openDropdown");
      return true;
    })
    .on(this.closeDropdown, () => {
      console.log("Event: closeDropdown");
      return false;
    });

  constructor(initialModalities?: string[]) {
    if (initialModalities?.length) {
      // Trigger setInitialModalities if initialModalities were provided
      setTimeout(() => {
        this.setInitialModalities(initialModalities);
      }, 0);
    }

    // Compute $selectedModalities when both $allKnownModalities and $initialSlugs are ready
    sample({
      source: combine({
        allModalities: this.$allKnownModalities,
        userSelectedSlugs: this.$userSelectedSlugs,
      }),
      clock: [
        this.allKnownModalitiesLoaded,
        this.setInitialModalities,
        this.selectModality,
      ],
      fn: ({ allModalities, userSelectedSlugs }) => {
        const selectedModalities = allModalities.filter((modality) =>
          userSelectedSlugs.has(modality.slug)
        );
        return selectedModalities;
      },
      target: this.$selectedModalities,
    });

    // Compute $availableModalities based on $allKnownModalities and $selectedModalities
    sample({
      source: combine({
        allModalities: this.$allKnownModalities,
        selectedModalities: this.$selectedModalities,
      }),
      fn: ({ allModalities, selectedModalities }) => {
        console.log("Computing $availableModalities...");
        console.log("allModalities:", allModalities);
        console.log("selectedModalities:", selectedModalities);

        const selectedSlugs = new Set(selectedModalities.map((m) => m.slug));
        const availableModalities = allModalities.filter(
          (modality) => !selectedSlugs.has(modality.slug)
        );
        console.log("Available modalities:", availableModalities);
        return availableModalities;
      },
      target: this.$availableModalities,
    });
  }

  isSelected(slug: string): boolean {
    return this.$userSelectedSlugs.getState().has(slug);
  }
}
