import { useMemo } from "react";
import type { To } from "react-router-dom";
import { useLocation } from "react-router-dom";

export interface TopLevelTabLinkProps {
  to: string;
  isActive: boolean;
  children: React.ReactNode;
}

export interface SidebarTab {
  tab: string;
  to: string;
  title: string;
}

export const PortalSettingsDashLayout: React.FC<{
  children: React.ReactNode;
  TopLevelTabLink: React.ComponentType<TopLevelTabLinkProps>;
  LinkComponent: React.ComponentType<{
    to: To;
    className?: string;
    children: React.ReactNode;
  }>;
  topTabs: {
    title: string;
    path: string;
    param: string;
  }[];
  sideTabs: SidebarTab[];
  currentHash?: string;
}> = ({
  TopLevelTabLink,
  LinkComponent,
  children,
  topTabs,
  sideTabs,
  currentHash,
}) => {
  const { pathname } = useLocation();
  const isActiveTab = useMemo(() => {
    return (
      topTabs.find((tab) => pathname.includes(tab.param))?.param || "account"
    );
  }, [pathname, topTabs]);

  return (
    <div className="flex flex-col w-full">
      <div className="flex flex-row pl-4 gap-28">
        {topTabs.map((tab) => (
          <TopLevelTabLink
            key={tab.param}
            to={tab.path}
            isActive={isActiveTab === tab.param}
          >
            {tab.title}
          </TopLevelTabLink>
        ))}
      </div>
      <div className="flex-1 flex flex-col pt-8">
        <div className="flex gap-8 w-[80%]">
          <div className="sticky top-0 h-[400px] w-[256px] py-6 flex flex-col gap-4">
            <SidebarTabs
              LinkComponent={LinkComponent}
              tabs={sideTabs.map((tab) => ({
                tab: tab.tab,
                to: tab.to,
                curUrlHash: currentHash ?? "",
                titleForTab: () => tab.title,
                LinkComponent,
              }))}
            />
          </div>
          <div className="flex-1 flex flex-col">{children}</div>
        </div>
      </div>
    </div>
  );
};

interface SidebarTabProps {
  tab: string;
  to: string;
  curUrlHash: string;
  titleForTab: (tab: string) => string;
  LinkComponent: React.ComponentType<{
    to: To;
    className?: string;
    children: React.ReactNode;
  }>;
}

export const SidebarTabs: React.FC<{
  tabs: SidebarTabProps[];
  LinkComponent: SidebarTabProps["LinkComponent"];
}> = ({ tabs, LinkComponent }) => {
  return (
    <div className="flex flex-col gap-4">
      {tabs.map((tab) => {
        const { LinkComponent: _, ...restTab } = tab;
        return (
          <SidebarTab
            key={tab.tab}
            LinkComponent={LinkComponent}
            {...restTab}
          />
        );
      })}
    </div>
  );
};

const SidebarTab: React.FC<SidebarTabProps> = ({
  tab,
  to,
  curUrlHash,
  titleForTab,
  LinkComponent,
}) => {
  return (
    <LinkComponent to={to} className="flex items-center gap-2">
      <div
        className={`rounded-lg pr-4 pl-4 py-2 font-sans ${
          curUrlHash !== undefined && curUrlHash === tab
            ? "bg-vid-black-100"
            : "bg-white"
        }`}
      >
        {titleForTab(tab)}
      </div>
    </LinkComponent>
  );
};
