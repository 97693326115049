import { Outlet } from "react-router-dom";
import { TopLevelTabLink } from "../../components/dashboard/dashboard-layout";
import { PortalSettingsDashLayout } from "../../components/dashboard/settings-dash-layout";
import { useMe, useWebSetupMyProfileForm } from "../../web-context";
import { MyProfileForm } from "../user/my-profile-form";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useMemo } from "react";
import { InfoSection } from "../../components/info-section";
import { useAuthActions } from "@convex-dev/auth/react";
import { SettingsContainer } from "../hp/settings/components/settings.components";
import { LogOut } from "lucide-react";

export const CpSettingsLayout: React.FC<{
  topTabs: { title: string; path: string; param: string }[];
}> = ({ topTabs }) => {
  const { pathname } = useLocation();
  const currentTopTab =
    topTabs.find((tab) => pathname.includes(tab.param))?.param || "account";

  const sideTabs = useMemo(() => {
    switch (currentTopTab) {
      case "account":
        return [
          { tab: "profile", to: "#profile", title: "Profile" },
          { tab: "extra", to: "#extra", title: "Extra" },
          {
            tab: "account-info",
            to: "#account-info",
            title: "Account Information",
          },
          // {
          //   tab: "account-info",
          //   to: "#account-info",
          //   title: "Account Information",
          // },
        ];
      // Add other cases for different top tabs
      default:
        return [];
    }
  }, [currentTopTab]);

  return (
    <PortalSettingsDashLayout
      TopLevelTabLink={TopLevelTabLink}
      LinkComponent={Link}
      topTabs={topTabs}
      sideTabs={sideTabs}
      currentHash={location.hash.slice(1)}
    >
      <Outlet />
    </PortalSettingsDashLayout>
  );
};

export const CpSettingsAccountDash: React.FC = () => {
  const myProfileFormVM = useWebSetupMyProfileForm({
    formConfig: {
      showPsychTodayUrlInput: false,
      showBioInput: false,
      showModalityInput: false,
    },
  });
  const me = useMe();
  return (
    <div className="flex flex-col gap-16 max-w-[1000px] mx-auto">
      <div id="profile">
        <MyProfileForm
          vm={myProfileFormVM}
          onSuccessSubmit={() => {}}
          button={{ title: "Save" }}
        />
      </div>
      <div id="extra">
        <CpSettingsExtraDash />
      </div>
      <div id="account-info">
        <AccountInformationView myEmail={me.email} />
      </div>
    </div>
  );
};

const CpSettingsExtraDash: React.FC = () => {
  return (
    <InfoSection
      header={{
        _tag: "STANDARD",
        title: "Extra",
      }}
    >
      <Link
        to="/u/hp/my/dashboard/clients"
        className="text-vid-purple font-roboto-flex font-semibold"
      >
        Go to my therapy portal
      </Link>
    </InfoSection>
  );
};

const AccountInformationView: React.FC<{
  myEmail: string | undefined;
}> = ({ myEmail }) => {
  const { signOut } = useAuthActions();
  return (
    <SettingsContainer id="account-info" title="Account">
      <div className="w-full h-full flex flex-col gap-12">
        <div className="flex flex-col gap-2">
          <h4 className="text-sm font-bold">Email</h4>
          <p>{myEmail}</p>
        </div>
        <div
          className="flex items-center gap-2 cursor-pointer"
          onClick={() => {
            // Effect.runPromise(hpState.uMgr.signOut).then();
            signOut();
          }}
        >
          <LogOut className="w-5 h-5" />
          <h4 className="text-sm">Sign out</h4>
        </div>
      </div>
    </SettingsContainer>
  );
};
