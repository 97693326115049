import { FullContainerLoadingSpinner } from "web-shared/src/components/loading";
import { Match } from "effect";
import { useSetupMenuVM } from "frontend-shared/src/sessions/live-room.vm";
import { useObservableEagerState } from "observable-hooks";
import type { Id } from "shared/be/convex/_generated/dataModel";
import type {
  CurrentOpenMenu,
  SettingsMenuViewState,
  ToolsMenuViewState,
} from "shared/be/convex/Rtc/Rooms/Live/LiveRoom.Types";
import type { Rx } from "frontend-shared/prelude";
import { SettingsMenu } from "./settings-menu";
import { ToolMenu } from "./tool-menu";
import arrowLeftSvg from "web-shared/src/assets/images/arrow-left.svg";
import closeSvg from "web-shared/src/assets/images/close.svg";

export const LiveRoomRightDrawer: React.FC<{
  baseSessionId: Id<"rtcSession">;
  roomId: Id<"rtcLiveRooms">;
  currentOpenMenu: CurrentOpenMenu | null;
}> = ({ baseSessionId, roomId, currentOpenMenu }) => {
  const {
    closeMenu,
    toolMenuViewState,
    setMyToolMenuViewState,
    settingsMenuViewState$,
    setMySettingsMenuViewState,
  } = useSetupMenuVM({
    baseSessionId,
    roomId,
    currentOpenMenu,
  });

  console.log("RENDERING LIVE ROOM RIGHT DRAWER");

  const settingsMenuViewState = useObservableEagerState(settingsMenuViewState$);

  if (currentOpenMenu === null) {
    return null;
  }

  const onBackClickHandler = () => {
    if (toolMenuViewState?.tag === "TOOL_SELECTION") {
      return () => setMyToolMenuViewState({ tag: "FULL_MENU" });
    }

    console.log("settingsMenuViewState", settingsMenuViewState);
    if (settingsMenuViewState?.tag === "SETTINGS_SELECTION") {
      return () => setMySettingsMenuViewState({ tag: "FULL_MENU" });
    }

    return undefined;
  };

  return (
    <div className="basis-[360px] grow-0 shrink-0 bg-transparent flex flex-col">
      <RightDrawerMenuContainerView
        menuTitle={currentOpenMenu}
        onBackClick={onBackClickHandler()}
        onClose={() => {
          closeMenu();
        }}
        content={
          <MenuContent
            sessionId={baseSessionId}
            roomId={roomId}
            currentOpenMenu={currentOpenMenu}
            viewState={toolMenuViewState}
            setMyToolMenuViewState={setMyToolMenuViewState}
            settingsMenuViewState$={settingsMenuViewState$}
            setMySettingsMenuViewState={setMySettingsMenuViewState}
          />
        }
      />
    </div>
  );
};

const MenuContent: React.FC<{
  sessionId: Id<"rtcSession">;
  roomId: Id<"rtcLiveRooms">;
  currentOpenMenu: CurrentOpenMenu;
  viewState: ToolsMenuViewState | undefined;
  setMyToolMenuViewState: (toolMenuViewState: ToolsMenuViewState) => void;
  settingsMenuViewState$: Rx.BehaviorSubject<SettingsMenuViewState | undefined>;
  setMySettingsMenuViewState: (
    settingsMenuViewState: SettingsMenuViewState
  ) => void;
}> = ({
  sessionId,
  roomId,
  currentOpenMenu,
  viewState,
  setMyToolMenuViewState,
  settingsMenuViewState$,
  setMySettingsMenuViewState,
}) => {
  if (viewState === undefined) {
    return <FullContainerLoadingSpinner />;
  }

  return Match.value(currentOpenMenu).pipe(
    Match.when("TOOLS", () => (
      <ToolMenu
        viewState={viewState}
        setMyToolMenuViewState={setMyToolMenuViewState}
        sessionId={sessionId}
        roomId={roomId}
      />
    )),
    Match.when("SETTINGS", () => (
      <SettingsMenu
        viewState$={settingsMenuViewState$}
        setSettingsMenuViewState={setMySettingsMenuViewState}
        sessionId={sessionId}
      />
    )),
    Match.exhaustive
  );
};

export interface RightDrawerMenuContainerViewProps {
  menuTitle: string;
  onBackClick?: () => void;
  onClose: () => void;
  content: React.ReactNode;
}

export const RightDrawerMenuContainerView: React.FC<
  RightDrawerMenuContainerViewProps
> = ({ menuTitle, onBackClick, onClose, content }) => {
  return (
    <div className="flex-1 w-full md:w-[360px] bg-white rounded-[12px] flex flex-col overflow-y-auto">
      <div className="flex items-center px-4 py-6">
        <div className="w-8">
          {onBackClick && (
            <button
              className="w-8 h-8 flex items-center justify-center"
              onClick={onBackClick}
            >
              <img src={arrowLeftSvg} alt="Back" className="w-6 h-6" />
            </button>
          )}
        </div>
        <h4 className="flex-1 text-center text-[22px] font-sans font-normal leading-[120%] text-vid-black-900">
          {menuTitle}
        </h4>
        <button
          className="w-9 h-9 flex items-center justify-center"
          onClick={() => {
            onClose();
          }}
        >
          <img src={closeSvg} alt="Close" />
        </button>
      </div>
      <div className="flex-1 flex flex-col min-h-0 px-4 overflow-y-auto pb-4">
        {content}
      </div>
    </div>
  );
};
