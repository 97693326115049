import {
  addMonths,
  eachDayOfInterval,
  endOfMonth,
  getDate,
  getMonth,
  getYear,
  startOfMonth,
  subMonths,
} from "date-fns";
import * as Arr from "fp-ts/Array";
import { pipe } from "fp-ts/lib/function";
import * as NE from "fp-ts/NonEmptyArray";
import * as O from "fp-ts/Option";
import * as Rec from "fp-ts/Record";
import { RRule, type Frequency } from "rrule";
import {
  OneIndexedMonth,
  ZeroIndexedMonth,
  type DayOfYear,
  type MonthOfYear,
  type NearbyCalendarMonths,
  type SimpleRecurrenceRule,
} from "./calendar.types";

export namespace CalendarUtils {
  export function getFirstAndLastDaysOfMonth(moy: MonthOfYear): {
    firstDay: Date;
    lastDay: Date;
  } {
    const randomDay = randomDayOfMonth({
      monthIdx: ZeroIndexedMonth(moy.oiMonthIdx - 1),
      year: moy.year,
    });
    const firstDay = startOfMonth(randomDay);
    const lastDay = endOfMonth(randomDay);
    return { firstDay, lastDay };
  }

  export function randomDayOfMonth(p: {
    monthIdx: ZeroIndexedMonth;
    year: number;
  }) {
    const daysForMonth = getDaysForMonth(p);
    const randomDayOfMonth =
      daysForMonth[Math.floor(Math.random() * daysForMonth.length)];
    return randomDayOfMonth;
  }

  export function getCurrentMOY(now?: Date): MonthOfYear {
    const date = now ?? new Date();
    return {
      oiMonthIdx: OneIndexedMonth(getMonth(date) + 1),
      year: getYear(date),
    };
  }

  export function getCurrentDoy(): DayOfYear {
    return asDOY(new Date());
  }

  export function getDaysForMonth(p: {
    monthIdx: ZeroIndexedMonth;
    year?: number;
  }): Date[] {
    const year = p.year ?? new Date().getFullYear();
    const month = startOfMonth(new Date(year, p.monthIdx));
    return eachDayOfInterval({
      start: month,
      end: endOfMonth(month),
    });
  }

  export function nearbyMonthsToRange(moy: MonthOfYear): {
    start: Date;
    end: Date;
  } {
    const { prevMonth, nextMonth } = getNearbyMonths(moy);
    const prevMonthFirstLastDays = getFirstAndLastDaysOfMonth(prevMonth);
    const nextMonthFirstLastDays = getFirstAndLastDaysOfMonth(nextMonth);
    const start = startOfMonth(prevMonthFirstLastDays.firstDay);
    const end = endOfMonth(nextMonthFirstLastDays.lastDay);
    return { start, end };
  }

  export function doyToDate(doy: DayOfYear): Date {
    return new Date(doy.year, doy.oneIndexedMonth - 1, doy.day);
  }

  export function asDOY(d: Date): DayOfYear {
    const month = getMonth(d);
    const year = getYear(d);
    const day = getDate(d);
    return { year, oneIndexedMonth: OneIndexedMonth(month + 1), day };
  }

  export function getNearbyMonths(moy: MonthOfYear): {
    thisMonth: MonthOfYear;
    prevMonth: MonthOfYear;
    nextMonth: MonthOfYear;
  } {
    const { oiMonthIdx, year } = moy;
    const thisMonth = { oiMonthIdx, year };
    const dayOfThisMonth = startOfMonth(new Date(year, oiMonthIdx - 1));
    const dayOfNextMonth = addMonths(dayOfThisMonth, 1);
    const dayOfPrevMonth = subMonths(dayOfThisMonth, 1);
    const nextYear = getYear(dayOfNextMonth);
    const prevYear = getYear(dayOfPrevMonth);
    const nextMonth = {
      oiMonthIdx: OneIndexedMonth(dayOfNextMonth.getMonth() + 1),
      year: nextYear,
    };
    const prevMonth = {
      oiMonthIdx: OneIndexedMonth(dayOfPrevMonth.getMonth() + 1),
      year: prevYear,
    };
    return { thisMonth, prevMonth, nextMonth };
  }

  export function groupEventsByMonth<T>(p: {
    events: T[];
    pickStartTime: (t: T) => Date;
  }): { moy: MonthOfYear; events: T[] }[] {
    return pipe(
      p.events,
      NE.fromArray,
      O.fold(
        () => [],
        (events) =>
          pipe(
            events,
            NE.groupBy((event) => {
              const eventDate = p.pickStartTime(event);
              // Convert to local time for grouping
              const offsetMs = eventDate.getTimezoneOffset() * 60 * 1000;
              const localDate = new Date(eventDate.getTime() + offsetMs);
              const { year, oneIndexedMonth } = asDOY(localDate);
              return `${year}-${oneIndexedMonth}`;
            }),
            Rec.map((events) => events as T[]),
            Rec.toArray,
            Arr.map(([key, events]) => {
              const [year, month] = key.split("-").map(Number);
              return {
                moy: { year, oiMonthIdx: month as OneIndexedMonth },
                events,
              };
            })
          )
      )
    );
  }

  export function groupEventsByDay<T>(p: {
    events: T[];
    pickStartTime: (t: T) => Date;
  }): { doy: DayOfYear; events: T[] }[] {
    return pipe(
      p.events,
      NE.fromArray,
      O.fold(
        () => [],
        (events) =>
          pipe(
            events,
            NE.groupBy((event) => {
              const eventDate = p.pickStartTime(event);
              // No manual offset needed – the Date object handles conversion.
              const localDate = eventDate;
              const doy = asDOY(localDate);
              return `${doy.year}-${doy.oneIndexedMonth}-${doy.day}`;
            }),
            Rec.map((events) => events as T[]),
            Rec.toArray,
            Arr.map(([key, events]) => {
              const [year, oim, day] = key.split("-").map(Number);
              const oneIndexedMonth = OneIndexedMonth(oim);
              return {
                doy: { year, oneIndexedMonth, day },
                events,
              };
            })
          )
      )
    );
  }

  export function asNearbyCalendarMonths<T>(p: {
    moy: MonthOfYear;
    events: T[];
    pickStartTime: (t: T) => Date;
  }): NearbyCalendarMonths<T> {
    const nearbyMonths = getNearbyMonths(p.moy);
    const groupedByMonth = groupEventsByMonth({
      events: p.events,
      pickStartTime: p.pickStartTime,
    }).map((r) => {
      const eventsByDayForMonth = groupEventsByDay({
        events: r.events,
        pickStartTime: p.pickStartTime,
      });
      return {
        moy: r.moy,
        eventsByDay: eventsByDayForMonth,
      };
    });

    const thisMonth = groupedByMonth
      .filter((r) => r.moy.oiMonthIdx === p.moy.oiMonthIdx)
      .flatMap((r) => r.eventsByDay);
    const nextMonth = groupedByMonth
      .filter((r) => r.moy.oiMonthIdx === nearbyMonths.nextMonth.oiMonthIdx)
      .flatMap((r) => r.eventsByDay);
    const prevMonth = groupedByMonth
      .filter((r) => r.moy.oiMonthIdx === nearbyMonths.prevMonth.oiMonthIdx)
      .flatMap((r) => r.eventsByDay);

    return {
      thisMonth,
      nextMonth,
      prevMonth,
    };
  }

  export function mapNearbyMonthVsEvents<T, U>(p: {
    nearbyMonths: NearbyCalendarMonths<T>;
    fmap: (t: T) => U;
  }): NearbyCalendarMonths<U> {
    return {
      thisMonth: p.nearbyMonths.thisMonth.map((v) => ({
        ...v,
        events: v.events.map(p.fmap),
      })),
      nextMonth: p.nearbyMonths.nextMonth.map((v) => ({
        ...v,
        events: v.events.map(p.fmap),
      })),
      prevMonth: p.nearbyMonths.prevMonth.map((v) => ({
        ...v,
        events: v.events.map(p.fmap),
      })),
    };
  }
}

interface BasicCalendarEventInfo {
  title: string;
  start: Date;
  end: Date;

  description?: string;
}

export class CalendarLinks {
  static getGoogleCalendarLink = (p: BasicCalendarEventInfo) => {
    const startDate = encodeURIComponent(
      p.start.toISOString().replace(/-|:|\.\d\d\d/g, "")
    );
    const endDate = encodeURIComponent(
      p.end.toISOString().replace(/-|:|\.\d\d\d/g, "")
    );
    const title = encodeURIComponent(p.title);
    const description = p.description ? encodeURIComponent(p.description) : "";

    const baseUrl = "https://www.google.com/calendar/render";
    const action = "TEMPLATE";

    const queryParams = [
      `action=${action}`,
      `text=${title}`,
      `dates=${startDate}/${endDate}`,
      `details=${description}`,
    ].join("&");

    return `${baseUrl}?${queryParams}`;
  };
}

export class RRuleUtils {
  static asRRuleString = (rrule: SimpleRecurrenceRule) => {
    console.log("GETTING RRULE STRING FROM SIMPLE! ", rrule);
    const rule = new RRule({
      freq: RRule[
        rrule.frequency.toUpperCase() as keyof typeof RRule
      ] as Frequency,
      interval: rrule.interval,
    });
    console.log("RRULE STRING! ", rule);
    return rule.toString();
  };

  static getRRuleSimpleDescription = (rrule: string) => {
    try {
      const rule = RRule.fromString(rrule);
      return rule.toText();
    } catch (error) {
      return "Invalid recurrence rule";
    }
  };
}
