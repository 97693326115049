import { useSetupCalendarVM } from "frontend-shared/src/mgrs/state-mgrs/calendar.statemgr";
import React, { useEffect } from "react";
import { useTypedSearchParams } from "react-router-typesafe-routes/dom";
import type { Id } from "shared/be/convex/_generated/dataModel";
import { UROUTES } from "shared/routes/u.routes";
import { ViewHcSessionDetailsPanel } from "web-shared/src/domains/calendar/view-hc-session-details";
import { NewHCSessionFormForHp } from "web-shared/src/domains/hp/calendar/schedule-hc-session.form";
import { FullCalendar } from "web-shared/src/domains/scheduling/calendar/full-calendar";
import { useWebGlobalDisplayVM } from "web-shared/src/web-context";

export const HpCalendarDash: React.FC = () => {
  const queryParams = useTypedSearchParams(UROUTES.HP.MY.DASHBOARD.CALENDAR);
  const webDisplayVM = useWebGlobalDisplayVM();
  const { vm } = useSetupCalendarVM("HP");

  useEffect(() => {
    if (queryParams.length > 0) {
      const [{ action, clientId, clientName, requestApptId }] = queryParams;
      console.log("queryParams", action);

      switch (action) {
        case "new-appointment":
          // webDisplayVM.globalSheetVM.openSheet(
          //   {
          //     title: "New appointment",
          //     type: { tag: "create" },
          //   },
          //   <NewHCSessionForm />
          // );
          break;
        case "view-appointment":
          console.log("view-appointment", requestApptId);
          break;
        default:
          break;
      }
    }
  }, [queryParams]);

  return (
    <div className="flex-1 flex flex-col max-w-[1100px]">
      <FullCalendar
        stateMgr={vm}
        fetchOnMount
        onDayClick={(day) => {
          console.log(day);
        }}
        onApptClick={(appt) => {
          console.log("appt clicked", appt);
          webDisplayVM.globalSheetVM.openSheet(
            {
              title: "Appointment details",
              type: {
                tag: "view",
              },
            },
            <ViewHcSessionDetailsPanel
              instanceId={appt.instanceId as Id<"calEvents">}
            />
          );
        }}
        newApptButton={{
          onClick: () => {
            webDisplayVM.globalSheetVM.openSheet(
              {
                title: "New appointment",
                type: { tag: "create" },
              },
              <NewHCSessionFormForHp
                onSuccessSubmit={() => {
                  webDisplayVM.toastVM.showToast({
                    id: "new-appt-success",
                    title: "Session scheduled successfully",
                    duration: 3000,
                  });
                  webDisplayVM.globalSheetVM.closeSheet();
                  webDisplayVM.reloadDash();
                }}
              />
            );
          },
          title: "New appointment",
        }}
      />
    </div>
  );
};
