import * as ToastPrimitives from "@radix-ui/react-toast";
import { cva, type VariantProps } from "class-variance-authority";
import { useUnit } from "effector-react";
import type { ToastVM } from "frontend-shared/src/toasts/toast.vm";
import { X } from "lucide-react";
import * as React from "react";
import { cn } from "../../utils/utils";

const ToastProvider = ToastPrimitives.Provider;

interface ToastViewportProps
  extends React.ComponentPropsWithoutRef<typeof ToastPrimitives.Viewport> {
  vm: ToastVM;
}

// Define our custom toast props
export interface CustomToastProps {
  id: string;
  title?: string;
  description?: string;
  duration?: number;
  variant?: "default" | "success" | "error";
  action?: {
    label: string;
    onClick: () => void;
  };
  type?: "default" | "success" | "error";
  position?: "top" | "bottom";
}

export const Toaster: React.FC<{ vm: ToastVM }> = ({ vm }) => {
  return (
    <ToastProvider>
      <ToastViewport vm={vm} position="top" />
      <ToastViewport vm={vm} position="bottom" />
    </ToastProvider>
  );
};

const ToastViewport = React.forwardRef<
  React.ElementRef<typeof ToastPrimitives.Viewport>,
  ToastViewportProps & { position: "top" | "bottom" }
>(({ className, vm, position, ...props }, ref) => {
  const { toasts } = useUnit({
    toasts: vm.$toasts,
  });

  // Filter toasts by position
  const filteredToasts = toasts.filter((t) =>
    position === "top" ? t.position === "top" : t.position !== "top"
  );

  if (filteredToasts.length === 0) return null;

  return (
    <ToastPrimitives.Viewport
      ref={ref}
      className={cn(
        "fixed z-[100] flex max-h-screen w-full p-4 md:max-w-[420px]",
        "left-1/2 -translate-x-1/2 lg:translate-x-[calc(-50%+115px)]",
        position === "top" ? "top-0 flex-col" : "bottom-0 flex-col-reverse",
        className
      )}
      {...props}
    >
      {filteredToasts.map((toast) => (
        <ToastRoot key={toast.id} {...toast} position={position} />
      ))}
    </ToastPrimitives.Viewport>
  );
});
ToastViewport.displayName = ToastPrimitives.Viewport.displayName;

const toastVariants = cva(
  "group pointer-events-auto relative inline-flex h-[55px] items-center justify-between gap-11 overflow-hidden rounded-[30px] border px-2 py-2.5 shadow-lg transition-all data-[swipe=cancel]:translate-x-0 data-[swipe=end]:translate-x-[var(--radix-toast-swipe-end-x)] data-[swipe=move]:translate-x-[var(--radix-toast-swipe-move-x)] data-[swipe=move]:transition-none data-[state=open]:animate-in data-[state=closed]:animate-out data-[swipe=end]:animate-out data-[state=closed]:fade-out-80",
  {
    variants: {
      variant: {
        default: "border-vid-black-800 bg-vid-black-900 text-white",
        success: "border-green-600 bg-green-50 text-green-900",
        error: "border-red-600 bg-red-50 text-red-900",
      },
      position: {
        top: "data-[state=closed]:slide-out-to-top-full data-[state=open]:slide-in-from-top-full",
        bottom:
          "data-[state=closed]:slide-out-to-bottom-full data-[state=open]:slide-in-from-bottom-full",
      },
    },
    defaultVariants: {
      variant: "default",
      position: "bottom",
    },
  }
);

const Toast = React.forwardRef<
  React.ElementRef<typeof ToastPrimitives.Root>,
  React.ComponentPropsWithoutRef<typeof ToastPrimitives.Root> &
    VariantProps<typeof toastVariants>
>(({ className, variant, position, ...props }, ref) => {
  return (
    <ToastPrimitives.Root
      ref={ref}
      className={cn(toastVariants({ variant, position }), className)}
      {...props}
    />
  );
});
Toast.displayName = ToastPrimitives.Root.displayName;

const ToastAction = React.forwardRef<
  React.ElementRef<typeof ToastPrimitives.Action>,
  React.ComponentPropsWithoutRef<typeof ToastPrimitives.Action>
>(({ className, ...props }, ref) => (
  <ToastPrimitives.Action
    ref={ref}
    className={cn(
      "inline-flex h-8 shrink-0 items-center justify-center rounded-[20px]",
      "bg-white/10 px-4 text-sm font-medium",
      "hover:bg-white/20",
      "disabled:pointer-events-none disabled:opacity-50",
      className
    )}
    {...props}
  />
));
ToastAction.displayName = ToastPrimitives.Action.displayName;

const ToastClose = React.forwardRef<
  React.ElementRef<typeof ToastPrimitives.Close>,
  React.ComponentPropsWithoutRef<typeof ToastPrimitives.Close>
>(({ className, ...props }, ref) => (
  <ToastPrimitives.Close
    ref={ref}
    className={cn(
      "absolute right-2 top-2 rounded-md p-1 text-foreground/50 opacity-0 transition-opacity hover:text-foreground focus:opacity-100 focus:outline-none focus:ring-2 group-hover:opacity-100 group-[.destructive]:text-red-300 group-[.destructive]:hover:text-red-50 group-[.destructive]:focus:ring-red-400 group-[.destructive]:focus:ring-offset-red-600",
      className
    )}
    toast-close=""
    {...props}
  >
    <X className="h-4 w-4" />
  </ToastPrimitives.Close>
));
ToastClose.displayName = ToastPrimitives.Close.displayName;

const ToastTitle = React.forwardRef<
  React.ElementRef<typeof ToastPrimitives.Title>,
  React.ComponentPropsWithoutRef<typeof ToastPrimitives.Title>
>(({ className, ...props }, ref) => (
  <ToastPrimitives.Title
    ref={ref}
    className={cn("text-base font-medium leading-[120%]", className)}
    {...props}
  />
));
ToastTitle.displayName = ToastPrimitives.Title.displayName;

const ToastDescription = React.forwardRef<
  React.ElementRef<typeof ToastPrimitives.Description>,
  React.ComponentPropsWithoutRef<typeof ToastPrimitives.Description>
>(({ className, ...props }, ref) => (
  <ToastPrimitives.Description
    ref={ref}
    className={cn("text-sm opacity-90", className)}
    {...props}
  />
));
ToastDescription.displayName = ToastPrimitives.Description.displayName;

const CheckmarkIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="40"
    height="39"
    viewBox="0 0 40 39"
    fill="none"
  >
    <circle cx="20" cy="19.5" r="19.5" fill="#1D1626" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M28.1485 13.8697C28.3736 14.1062 28.5 14.4269 28.5 14.7613C28.5 15.0957 28.3736 15.4165 28.1485 15.653L19.1492 25.1059C19.0302 25.2308 18.889 25.3299 18.7336 25.3976C18.5782 25.4652 18.4117 25.5 18.2435 25.5C18.0753 25.5 17.9087 25.4652 17.7533 25.3976C17.5979 25.3299 17.4567 25.2308 17.3378 25.1059L12.8665 20.4101C12.7519 20.2938 12.6604 20.1546 12.5975 20.0007C12.5346 19.8469 12.5014 19.6814 12.5 19.5139C12.4987 19.3465 12.529 19.1804 12.5894 19.0254C12.6498 18.8704 12.7389 18.7296 12.8517 18.6112C12.9644 18.4928 13.0985 18.3991 13.246 18.3357C13.3936 18.2723 13.5517 18.2404 13.7111 18.2418C13.8705 18.2433 14.0281 18.2781 14.1745 18.3442C14.321 18.4103 14.4535 18.5064 14.5643 18.6268L18.2431 22.491L26.4499 13.8697C26.5614 13.7525 26.6938 13.6595 26.8396 13.5961C26.9853 13.5326 27.1415 13.5 27.2992 13.5C27.4569 13.5 27.6131 13.5326 27.7589 13.5961C27.9046 13.6595 28.037 13.7525 28.1485 13.8697Z"
      fill="white"
    />
  </svg>
);

const SuccessIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="40"
    height="39"
    viewBox="0 0 40 39"
    fill="none"
  >
    <circle cx="20" cy="19.5" r="19.5" fill="#22C55E" fillOpacity="0.1" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M28.1485 13.8697C28.3736 14.1062 28.5 14.4269 28.5 14.7613C28.5 15.0957 28.3736 15.4165 28.1485 15.653L19.1492 25.1059C19.0302 25.2308 18.889 25.3299 18.7336 25.3976C18.5782 25.4652 18.4117 25.5 18.2435 25.5C18.0753 25.5 17.9087 25.4652 17.7533 25.3976C17.5979 25.3299 17.4567 25.2308 17.3378 25.1059L12.8665 20.4101C12.7519 20.2938 12.6604 20.1546 12.5975 20.0007C12.5346 19.8469 12.5014 19.6814 12.5 19.5139C12.4987 19.3465 12.529 19.1804 12.5894 19.0254C12.6498 18.8704 12.7389 18.7296 12.8517 18.6112C12.9644 18.4928 13.0985 18.3991 13.246 18.3357C13.3936 18.2723 13.5517 18.2404 13.7111 18.2418C13.8705 18.2433 14.0281 18.2781 14.1745 18.3442C14.321 18.4103 14.4535 18.5064 14.5643 18.6268L18.2431 22.491L26.4499 13.8697C26.5614 13.7525 26.6938 13.6595 26.8396 13.5961C26.9853 13.5326 27.1415 13.5 27.2992 13.5C27.4569 13.5 27.6131 13.5326 27.7589 13.5961C27.9046 13.6595 28.037 13.7525 28.1485 13.8697Z"
      fill="#22C55E"
    />
  </svg>
);

const ErrorIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="40"
    height="39"
    viewBox="0 0 40 39"
    fill="none"
  >
    <circle cx="20" cy="19.5" r="19.5" fill="#EF4444" fillOpacity="0.1" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20 11C15.0294 11 11 15.0294 11 20C11 24.9706 15.0294 29 20 29C24.9706 29 29 24.9706 29 20C29 15.0294 24.9706 11 20 11ZM18.2929 16.2929C18.6834 15.9024 19.3166 15.9024 19.7071 16.2929L20 16.5858L20.2929 16.2929C20.6834 15.9024 21.3166 15.9024 21.7071 16.2929C22.0976 16.6834 22.0976 17.3166 21.7071 17.7071L21.4142 18L21.7071 18.2929C22.0976 18.6834 22.0976 19.3166 21.7071 19.7071C21.3166 20.0976 20.6834 20.0976 20.2929 19.7071L20 19.4142L19.7071 19.7071C19.3166 20.0976 18.6834 20.0976 18.2929 19.7071C17.9024 19.3166 17.9024 18.6834 18.2929 18.2929L18.5858 18L18.2929 17.7071C17.9024 17.3166 17.9024 16.6834 18.2929 16.2929ZM16.2929 21.2929C16.6834 20.9024 17.3166 20.9024 17.7071 21.2929L23.7071 27.2929C24.0976 27.6834 24.0976 28.3166 23.7071 28.7071C23.3166 29.0976 22.6834 29.0976 22.2929 28.7071L16.2929 22.7071C15.9024 22.3166 15.9024 21.6834 16.2929 21.2929Z"
      fill="#EF4444"
    />
  </svg>
);

// Separate component to handle the conversion of our toast props to Radix UI props
const ToastRoot: React.FC<CustomToastProps> = ({
  title,
  description,
  action,
  variant = "default",
  type,
  position = "bottom",
  ...props
}) => {
  // Map type to variant if type is provided
  const toastVariant = type ? type : variant;

  return (
    <Toast variant={toastVariant} position={position} {...props}>
      <div className="flex items-center gap-4">
        {toastVariant === "default" && <CheckmarkIcon />}
        {toastVariant === "success" && <SuccessIcon />}
        {toastVariant === "error" && <ErrorIcon />}
        <div className="grid gap-1">
          {title && <ToastTitle>{title}</ToastTitle>}
          {description && <ToastDescription>{description}</ToastDescription>}
        </div>
      </div>
      {action && (
        <ToastAction altText={action.label} onClick={action.onClick}>
          {action.label}
        </ToastAction>
      )}
      <ToastClose />
    </Toast>
  );
};

type ToastProps = CustomToastProps;

type ToastActionElement = React.ReactElement<typeof ToastAction>;

export {
  Toast,
  ToastAction,
  ToastClose,
  ToastDescription,
  ToastProvider,
  ToastTitle,
  ToastViewport,
  type ToastActionElement,
  type ToastProps,
};
