import { format } from "date-fns";
import { useUnit } from "effector-react";
import { FormFocusVM } from "frontend-shared/src/form-focus.vm";
import { CalendarPickerVM } from "frontend-shared/src/shared-vms/calendar-picker.vm";
import React, { useEffect, useState } from "react";
import { FormFieldContainer } from "web-shared/src/components/form-field.container";
import arrowLeftSvg from "web-shared/src/assets/images/arrow-left.svg";
import arrowRightSvg from "web-shared/src/assets/images/arrow-right.svg";
import { Popover, PopoverContent, PopoverTrigger } from "../ui/popover";
import { Tooltip, TooltipContent, TooltipTrigger } from "../ui/tooltip";

interface TimeSelectorProps {
  vm: CalendarPickerVM;
  selectedTime: {
    hours: number | null;
    minutes: number | null;
    period: "AM" | "PM" | null;
  } | null;
}

const TimeInput: React.FC<{
  value: string | null;
  onChange: (value: number) => void;
  maxValue?: number;
  isMinutes?: boolean;
}> = ({ value, onChange, isMinutes }) => {
  // Track the raw input value separately from the displayed value
  const [rawInput, setRawInput] = useState<string>("");
  const [isFocused, setIsFocused] = useState(false);

  // Display formatted value when not focused, raw input when focused
  const displayValue = isFocused
    ? rawInput
    : value !== null
      ? value.toString().padStart(2, "0")
      : "";

  return (
    <input
      type="text"
      value={displayValue}
      onChange={(e) => {
        const val = e.target.value.slice(0, 2); // Restrict to 2 digits
        setRawInput(val);

        if (val !== "") {
          const parsed = parseInt(val, 10);
          if (!isNaN(parsed) && parsed >= 0) {
            if (isMinutes) {
              if (parsed <= 59) {
                onChange(parsed);
              }
            } else {
              // Hours: 1-12
              if (parsed >= 1 && parsed <= 12) {
                onChange(parsed);
              }
            }
          }
        }
      }}
      onFocus={() => {
        setIsFocused(true);
        setRawInput("");
      }}
      onBlur={() => {
        setIsFocused(false);
        setRawInput("");
      }}
      className="w-14 p-2 text-center bg-gray-50 rounded-md text-base font-medium"
    />
  );
};

const PeriodToggle: React.FC<{
  selectedPeriod: "AM" | "PM" | null;
  onToggle: (period: "AM" | "PM") => void;
}> = ({ selectedPeriod, onToggle }) => (
  <div className="flex rounded-md bg-gray-100 p-1">
    {["AM", "PM"].map((period) => (
      <button
        key={period}
        onClick={() => onToggle(period as "AM" | "PM")}
        className={`flex h-8 w-[51px] flex-col justify-center items-center flex-shrink-0 rounded font-outfit text-base font-medium leading-[120%] transition-all duration-200 ${
          selectedPeriod === period ? "bg-black text-white" : "text-gray-600"
        }`}
      >
        {period}
      </button>
    ))}
  </div>
);

export const TimeSelector: React.FC<TimeSelectorProps> = ({
  vm,
  selectedTime,
}) => {
  return (
    <div className="flex items-center justify-between">
      <span className="text-[24px] text-vid-black-900">Time</span>

      <div className="flex items-center gap-2">
        <div className="flex items-center gap-0">
          <TimeInput
            value={selectedTime?.hours?.toString().padStart(2, "0") ?? null}
            onChange={(hours) => vm.events.hoursChanged(hours)}
            maxValue={12}
          />
          <span className="text-base font-medium">:</span>
          <TimeInput
            value={selectedTime?.minutes?.toString().padStart(2, "0") ?? null}
            onChange={(minutes) => vm.events.minutesChanged(minutes)}
            isMinutes
          />
        </div>
        <PeriodToggle
          selectedPeriod={selectedTime?.period ?? null}
          onToggle={(period) => vm.events.periodToggled(period)}
        />
      </div>
    </div>
  );
};

export const CalendarDropdown: React.FC<{
  vm: CalendarPickerVM;
  autoOpen?: boolean;
}> = ({ vm, autoOpen }) => {
  const [isOpen, setIsOpen] = useState(autoOpen ?? false);
  const dateTimeDisplayStr = useUnit(vm.$dateTimeDisplayStr);
  const timezone = useUnit(vm.$timezone);
  const focusedField = useUnit(FormFocusVM.getInstance().$focusedField);

  useEffect(() => {
    if (focusedField && focusedField !== "calendar") {
      setIsOpen(false);
    }
  }, [focusedField]);

  return (
    <Popover open={isOpen} onOpenChange={setIsOpen}>
      <PopoverTrigger asChild>
        <div>
          <FormFieldContainer
            label={
              <div className="flex items-center gap-2">
                <span>Date & Time</span>
                <div className="text-sm text-gray-500">({timezone})</div>
                {/* <Tooltip>
                  <TooltipTrigger>
                    <div className="text-sm text-gray-500">({timezone})</div>
                  </TooltipTrigger>
                  <TooltipContent>
                    <p className="text-white">
                      Times are shown in your local timezone
                    </p>
                  </TooltipContent>
                </Tooltip> */}
              </div>
            }
            onClick={() => {
              FormFocusVM.getInstance().setFocusedField("calendar");
            }}
          >
            <div className="cursor-pointer text-vid-black-900">
              {dateTimeDisplayStr}
            </div>
          </FormFieldContainer>
        </div>
      </PopoverTrigger>
      <PopoverContent
        className="w-[var(--radix-popover-trigger-width)] p-0"
        align="start"
        sideOffset={0}
      >
        <CalendarPicker vm={vm} />
      </PopoverContent>
    </Popover>
  );
};

export const CalendarPicker: React.FC<{
  vm: CalendarPickerVM;
}> = ({ vm }) => {
  const {
    currentMonth,
    selectedDay: selectedDate,
    selectedTime,
  } = useUnit(vm.$picker);
  const calendarDays = useUnit(vm.$calendarDays);

  return (
    <div
      className="p-4 bg-white rounded-[12px] w-full"
      style={{
        boxShadow: "0px 3.141px 13.12px 0px rgba(99, 100, 113, 0.27)",
      }}
    >
      <div className="flex justify-between items-center mb-4">
        <button
          onClick={() => vm.events.monthNavigated("prev")}
          className="border border-vid-bg-gray focus:outline-none w-8 h-8 flex justify-center items-center rounded-[8px]"
        >
          <img src={arrowLeftSvg} alt="prev" className="w-2 h-2" />
        </button>
        <h2 className="text-[24px] text-vid-black-900">
          {format(currentMonth, "MMMM")}
        </h2>
        <button
          onClick={() => vm.events.monthNavigated("next")}
          className="border border-vid-bg-gray focus:outline-none w-8 h-8 flex justify-center items-center rounded-[8px]"
        >
          <img src={arrowRightSvg} alt="next" className="w-2 h-2" />
        </button>
      </div>
      <div className="grid grid-cols-7 gap-2 mb-4">
        {["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"].map((day) => (
          <div key={day} className="text-sm text-center font-semibold">
            {day}
          </div>
        ))}
        {calendarDays.map((date, idx) =>
          date === null ? (
            <div key={`empty-${idx}`} className="flex-1" />
          ) : (
            <button
              key={date.toString()}
              onClick={() => vm.events.daySelected(date)}
              className={`flex flex-col items-center justify-center px-4 py-2 rounded-[42px] text-sm focus:outline-none transition-all duration-200 ease-in-out flex-1 hover:bg-vid-black-50 ${
                vm.isSelected(date)
                  ? "bg-vid-purple text-white hover:bg-vid-purple"
                  : vm.isCurrentMonth(date)
                    ? vm.isToday(date)
                      ? "text-black border border-vid-purple bg-white"
                      : "text-black"
                    : "text-gray-300"
              }`}
            >
              {format(date, "d")}
            </button>
          )
        )}
      </div>
      <TimeSelector vm={vm} selectedTime={selectedTime} />
    </div>
  );
};
