import { useMutation, useQuery } from "convex/react";
import React from "react";
import { api } from "shared/be/convex/_generated/api";
import type { Id } from "shared/be/convex/_generated/dataModel";
import type { PastHcSessionInfo } from "shared/be/convex/Rtc/Session.Types";
import type { SimpleUser } from "shared/be/convex/User/User.Types";
import { UROUTES } from "shared/routes/u.routes";
import { ImageSrc } from "shared/types/miscellaneous.types";
import { AvatarCircle } from "web-shared/src/components/avatar.tc";
import { InversePrimaryButton } from "web-shared/src/components/button";
import { FullContainerLoadingSpinner } from "web-shared/src/components/loading";
import {
  SimpleTableList,
  type TableConfig,
} from "web-shared/src/components/table";
import { RequestHpForm } from "web-shared/src/domains/cp/request-hp.form";
import { useWebGlobalDisplayVM } from "web-shared/src/web-context";
import { useCpState } from "./cpdashboard.state";

type OngoingSession = {
  ongoingHcSessions: {
    hpInfo: {
      _id: Id<"rtcSessionParticipant">;
      _creationTime: number;
      role?: "PARTICIPANT" | "HOST" | "CO_HOST";
      name: string;
      image: string | null;
      userId: Id<"users">;
      baseSessionId: Id<"rtcSession">;
      idInSession: number;
      wantsBackgroundBlurred: boolean;
      assignedRoom: any; // You may want to type this more specifically
    };
    sessionId: string;
  };
};

type PendingRequest = {
  pendingHpRequest: {
    hp: SimpleUser;
    relationshipId: Id<"practitionerRelationships">;
  };
};

type ActiveTherapist = {
  name: string;
  profilePhoto: string | null;
};

export const CpHomeDash: React.FC<{
  nav: (route: string) => void;
}> = ({ nav }) => {
  const cpState = useCpState();
  const webDisplayVM = useWebGlobalDisplayVM();
  const myPendingHps = useQuery(
    api.Practitioner.PractitionerRelationshipFns.getMyPendingPractitioners
  );
  const myOngoingHcSessions = useQuery(
    api.User.Cp.CpFns.getMyOngoingHcSessionsForCpPortal
  );
  const pastSessions = useQuery(api.User.Cp.CpFns.getMyPastSessionsWithHps);
  const myActiveHps = useQuery(
    api.Practitioner.PractitionerRelationshipFns.getMyActivePractitioners
  );
  const acceptHpRequest = useMutation(
    api.Practitioner.PractitionerRelationshipFns.respondToClientRequest
  );

  if (
    myPendingHps === undefined ||
    myOngoingHcSessions === undefined ||
    myActiveHps === undefined ||
    pastSessions === undefined
  ) {
    return <FullContainerLoadingSpinner />;
  }

  if (
    myOngoingHcSessions.length === 0 &&
    myActiveHps.practitioners.length === 0 &&
    myPendingHps.length === 0 &&
    pastSessions.length === 0
  ) {
    return <EmptyState />;
  }

  return (
    <div className="relative">
      <div className="flex justify-between items-center pb-8">
        <h3 className="font-outfit text-[24px] text-vid-black-900">
          My Therapists
        </h3>
        <div>
          <InversePrimaryButton
            title="Request a therapist"
            onClick={() => {
              webDisplayVM.globalSheetVM.openSheet(
                {
                  title: "Request a therapist",
                  type: { tag: "create", icon: "profile-add" },
                },
                <RequestHpForm />
              );
            }}
          />
        </div>
      </div>

      <SimpleTableList<
        [OngoingSession, PendingRequest, ActiveTherapist, PastHcSessionInfo]
      >
        tables={[
          {
            columns: [
              {
                key: "ongoingHcSessions",
                header: "Ongoing sessions",
                render: ({ ongoingHcSessions }) => (
                  <div className="flex items-center gap-2">
                    <AvatarCircle
                      mbProfilePhoto={ImageSrc.fromMbUrl(
                        ongoingHcSessions.hpInfo.image
                      )}
                      size={56}
                    />
                    <h4>{ongoingHcSessions.hpInfo.name}</h4>
                  </div>
                ),
              },
              {
                key: "cta",
                header: "Join session",
                render: (s) => (
                  <InversePrimaryButton
                    title="Join session"
                    onClick={() => {
                      const navRoute =
                        UROUTES.SESSIONS.SESSION_ID.RTC.buildPath({
                          sessionId: s.ongoingHcSessions.sessionId,
                        });
                      nav(navRoute);
                    }}
                  />
                ),
              },
            ],
            data: myOngoingHcSessions.map((session) => ({
              ongoingHcSessions: {
                hpInfo: session.hpInfo,
                sessionId: session.baseSessionId,
              },
            })),
          },
          {
            columns: [
              {
                key: "pendingHpRequest",
                header: "Pending requests",
                render: ({ pendingHpRequest }) => (
                  <div className="flex items-center gap-2">
                    <AvatarCircle
                      mbProfilePhoto={ImageSrc.fromMbUrl(
                        pendingHpRequest.hp.profilePhoto
                      )}
                      size={56}
                    />
                    <h4>{pendingHpRequest.hp.name}</h4>
                  </div>
                ),
              },
              {
                key: "cta",
                header: "Accept",
                render: (s) => (
                  <InversePrimaryButton
                    title="Accept"
                    onClick={() => {
                      acceptHpRequest({
                        relationshipId: s.pendingHpRequest.relationshipId,
                        accept: true,
                      }).then((_) => {
                        cpState.dashboardMgr.showBottomToast({
                          msg: "Accepted HP request",
                        });
                      });
                    }}
                  />
                ),
              },
            ],
            data: myPendingHps.map((hp) => ({
              pendingHpRequest: {
                hp: hp.practitioner,
                relationshipId: hp.request._id,
              },
            })),
          },
          {
            columns: [
              {
                key: "name",
                header: "Active therapists",
                render: ({ name, profilePhoto }) => (
                  <div className="flex items-center gap-2">
                    <AvatarCircle
                      mbProfilePhoto={ImageSrc.fromMbUrl(profilePhoto)}
                      size={56}
                    />
                    <h4>{name}</h4>
                  </div>
                ),
              },
            ],
            data: myActiveHps.practitioners.map((hp) => ({
              name: hp.name,
              profilePhoto: hp.profilePhoto,
            })),
          },
          pastHcSessionTable(pastSessions, nav),
        ]}
      />
    </div>
  );
};

const pastHcSessionTable = (
  data: PastHcSessionInfo[],
  nav: (path: string) => void
): TableConfig<PastHcSessionInfo> => {
  return {
    data,
    columns: [
      {
        key: "hpInfo",
        header: "Past sessions",
        render: (d) => (
          <div
            className="flex items-center gap-2"
            onClick={() => {
              nav(`/u/cp/dashboard/pastSessions/${d.sessionId}`);
            }}
          >
            <AvatarCircle
              mbProfilePhoto={ImageSrc.fromMbUrl(d.hpInfo.profilePhoto)}
              size={56}
            />
            <h4>{d.hpInfo.name}</h4>
          </div>
        ),
      },
    ],
  };
};

const EmptyState: React.FC = () => {
  const webDisplayVM = useWebGlobalDisplayVM();
  return (
    <div className="flex flex-col flex-1 justify-center items-center">
      <div className="flex gap-8">
        <RequestTherapistCard
          onClick={() => {
            webDisplayVM.globalSheetVM.openSheet(
              {
                title: "Request a therapist",
                type: { tag: "create", icon: "profile-add" },
              },
              <RequestHpForm />
            );
          }}
        />
      </div>
    </div>
  );
};

const RequestTherapistCard: React.FC<{ onClick: () => void }> = ({
  onClick,
}) => {
  return (
    <div
      onClick={onClick}
      className="flex flex-col justify-center items-center cursor-pointer p-8"
    >
      <h4 className="font-outfit font-medium text-default-purple text-4xl leading-9 mb-4">
        Request your first therapist
      </h4>
      <p className="font-sans font-light text-md mb-4">
        We'll send your request and let you know when they accept.
      </p>
      <div className="bg-vid-purple p-buttons px-24 rounded-buttons text-white flex justify-center items-center">
        {`Request`}
      </div>
    </div>
  );
};
