import { useMutation, useQuery } from "convex/react";
import React, { useEffect } from "react";
import { api } from "shared/be/convex/_generated/api";
import { useScrollToAnchor } from "../../../utils/utils.js";
import { SettingsContainer } from "./components/settings.components.js";

export const HpSettingsClientAndSchedulingDash: React.FC<{
  pathname: string;
  settingsClientAndSchedulingPath: string;
}> = ({ pathname, settingsClientAndSchedulingPath }) => {
  useScrollToAnchor();

  useEffect(() => {
    if (pathname === settingsClientAndSchedulingPath) {
      window.location.hash = "reminders";
    }
  }, [pathname]);

  return (
    <div className="flex flex-col gap-4">
      {/* <ClientIntakeFormsView /> */}
      <AppointmentRemindersView />
      <CalendarSyncingView />
    </div>
  );
};

const AppointmentRemindersView: React.FC = () => {
  const settings = useQuery(api.User.Hp.HpFns.getAppointmentReminderSettings);
  const updateRecipients = useMutation(
    api.User.Hp.HpFns.updateAppointmentReminderRecipients
  ).withOptimisticUpdate((localState, args) => {
    const settings = localState.getQuery(
      api.User.Hp.HpFns.getAppointmentReminderSettings,
      {}
    );

    if (settings) {
      localState.setQuery(
        api.User.Hp.HpFns.getAppointmentReminderSettings,
        {},
        {
          ...settings,
          ...args,
        }
      );
    }
  });

  const updateTiming = useMutation(
    api.User.Hp.HpFns.updateAppointmentReminderTiming
  );

  if (!settings) return null;

  const handleRecipientChange = (
    key: "sendToClient" | "sendToHp",
    value: boolean
  ) => {
    updateRecipients({
      sendToClient: key === "sendToClient" ? value : settings.sendToClient,
      sendToHp: key === "sendToHp" ? value : settings.sendToHp,
    });
  };

  const handleTimeChange = (value: "15_MIN" | "30_MIN" | "1_HR") => {
    updateTiming({
      timeBeforeSession: value,
    });
  };

  return (
    <SettingsContainer id="reminders" title="Appointment reminders">
      <div className="flex flex-col gap-8">
        <div className="flex flex-col gap-4">
          <h3 className="text-vid-purple font-medium">Send reminder to</h3>
          <div className="flex flex-col gap-2">
            <label className="flex items-center gap-2">
              <input
                type="checkbox"
                className="w-5 h-5 rounded-sm border-gray-300 text-vid-purple focus:ring-purple-500"
                checked={settings.sendToClient}
                onChange={(e) =>
                  handleRecipientChange("sendToClient", e.target.checked)
                }
              />
              <span>Client</span>
            </label>
            <label className="flex items-center gap-2">
              <input
                type="checkbox"
                className="w-5 h-5 rounded-sm border-gray-300 text-vid-purple focus:ring-purple-500"
                checked={settings.sendToHp}
                onChange={(e) =>
                  handleRecipientChange("sendToHp", e.target.checked)
                }
              />
              <span>Me</span>
            </label>
          </div>
        </div>

        <div className="flex flex-col gap-4">
          <h3 className="text-vid-purple font-medium">Time before session</h3>
          <div className="flex flex-col gap-2">
            {[
              { value: "15_MIN", label: "15 min" },
              { value: "30_MIN", label: "30 min" },
              { value: "1_HR", label: "1 hr" },
            ].map(({ value, label }) => (
              <label key={value} className="flex items-center gap-2">
                <input
                  type="radio"
                  className="w-5 h-5 border-gray-300 text-vid-purple focus:ring-purple-500"
                  checked={settings.timeBeforeSession === value}
                  onChange={() =>
                    handleTimeChange(value as "15_MIN" | "30_MIN" | "1_HR")
                  }
                />
                <span>{label}</span>
              </label>
            ))}
          </div>
        </div>
      </div>
    </SettingsContainer>
  );
};

const CalendarSyncingView: React.FC = () => {
  return (
    <SettingsContainer id="calendar" title="Calendar Syncing">
      <div className="flex flex-col gap-4">
        <p className="text-gray-600">
          Connect and manage your external calendar integrations.
        </p>
        <div className="bg-gray-50 p-4 rounded-lg">
          <p>Placeholder: Calendar integration settings will go here</p>
        </div>
      </div>
    </SettingsContainer>
  );
};
