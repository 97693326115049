import { useCallStateHooks } from "@stream-io/video-react-sdk";
import { useConvex, type WatchQueryOptions } from "convex/react";
import {
  getFunctionName,
  type ArgsAndOptions,
  type FunctionReference,
} from "convex/server";
import { convexToJson } from "convex/values";
import { Effect, Equal } from "effect";
import {
  createEvent,
  createStore,
  type EventCallable,
  type StoreWritable,
} from "effector";
import { useObservableEagerState, useObservableState } from "observable-hooks";
import React, { useEffect, useMemo, useRef, useState } from "react";
import * as Rx from "rxjs";
import * as RxO from "rxjs/operators";
import { RD, type TE } from "frontend-shared/prelude";

export function createContextAndHook<T>(
  displayName?: string
): [React.Context<T | null>, () => T] {
  const Context = React.createContext<T | null>(null);

  function useContext() {
    const ctx = React.useContext(Context);
    if (ctx === null) {
      throw new Error(
        `use${displayName} must be used within a ${displayName ?? ""}Provider`
      );
    } else {
      return ctx as T;
    }
  }

  return [Context, useContext];
}

export function useOnce<T>(factory: () => T): T {
  const ref = useRef<T | null>(null);

  if (ref.current === null) {
    ref.current = factory();
  }

  return ref.current;
}

export function useNow(): number {
  return useOnce(() => Date.now());
}

export function useStateAsObservable<T>(
  someStateValue: T
): Rx.BehaviorSubject<T> {
  const v$ = useOnce(() => new Rx.BehaviorSubject(someStateValue));

  useEffect(() => {
    v$.next(someStateValue);
  }, [someStateValue]);

  return v$;
}

export function useQuery$<Query extends FunctionReference<"query">>(
  query: Query,
  ...args: ArgsAndOptions<Query, WatchQueryOptions>
): Rx.BehaviorSubject<Query["_returnType"] | undefined> {
  const convex = useConvex();
  const skip = args[0] === "skip";
  const argsObject = skip ? {} : (args[0] ?? {});
  const queryName = getFunctionName(query);
  const serializedArgs = JSON.stringify(convexToJson(argsObject));

  const subjectRef =
    useRef<Rx.BehaviorSubject<Query["_returnType"] | undefined>>();
  if (!subjectRef.current) {
    subjectRef.current = new Rx.BehaviorSubject(undefined);
  }

  useEffect(() => {
    if (skip) return;
    const watch = convex.watchQuery(query, argsObject);
    subjectRef.current!.next(watch.localQueryResult());
    const unsubscribe = watch.onUpdate(() => {
      subjectRef.current!.next(watch.localQueryResult());
    });
    return unsubscribe;
  }, [convex, queryName, serializedArgs, skip]);

  return subjectRef.current;
}

// export function useQuery$<Query extends FunctionReference<"query">>(
//   query: Query,
//   ...args: OptionalRestArgsOrSkip<Query>
// ): Rx.BehaviorSubject<Query["_returnType"] | undefined> {
//   const v = useQuery(query, ...args);
//   const v$ = useOnce(() => new Rx.BehaviorSubject(v));

//   useEffect(() => {
//     v$.next(v);
//   }, [v]);

//   return v$;
// }

export function useQueryStore<Query extends FunctionReference<"query">>(
  query: Query,
  ...args: ArgsAndOptions<Query, WatchQueryOptions>
): EffectorState<Query["_returnType"] | null> {
  const query$ = useQuery$(query, ...args);
  const { store, event } = useOnce(() =>
    createState<Query["_returnType"] | null>(null)
  );

  useEffect(() => {
    const subscription = query$.subscribe((v) => event(v ?? null));
    return () => subscription.unsubscribe();
  }, [query$]);

  return { store, event };
}

export function useKeyOfBehaviorSubjectAsState<V, K extends keyof V>(
  obj$: Rx.BehaviorSubject<V>,
  key: K
): V[K] {
  const value$ = useKeyOfObservable(obj$, key);
  const v = useObservableEagerState(value$);

  return v;
}

export function useKeyOfObservable<V, K extends keyof V>(
  obj$: Rx.Observable<V>,
  key: K
): Rx.Observable<V[K]> {
  const value$ = useMemo(
    () => obj$.pipe(RxO.map((obj) => obj[key])),
    [obj$, key]
  );

  return value$;
}

export function useKeyOfObservableAsState<V, K extends keyof V>(
  obj$: Rx.Observable<V>,
  key: K,
  defaultValue: V[K]
): V[K] {
  const value$ = useKeyOfObservable(obj$, key);
  const v = useObservableState(value$, defaultValue);

  return v;
}

export class FetchableStateAtom<V, E = any> {
  rdValue$: Rx.BehaviorSubject<RD.RemoteData<E, V>>;

  constructor(
    private readonly fetchTE: TE.TaskEither<E, V>,
    initialValue?: V
  ) {
    this.fetchTE = fetchTE;
    this.rdValue$ = new Rx.BehaviorSubject<RD.RemoteData<E, V>>(
      initialValue === undefined ? RD.initial : RD.success(initialValue)
    );
  }

  get value$() {
    return this.rdValue$.asObservable();
  }

  fetchAndSet() {
    this.rdValue$.next(RD.pending);
    this.fetchTE().then((result) => {
      this.rdValue$.next(RD.fromEither(result));
    });
  }
}

export function useRemoteParticipants$() {
  const { useRemoteParticipants } = useCallStateHooks();
  const remoteParticipants = useRemoteParticipants();
  const remoteParticipants$ = useOnce(
    () => new Rx.BehaviorSubject(remoteParticipants)
  );

  useEffect(() => {
    const sub = remoteParticipants$.subscribe();
    return () => sub.unsubscribe();
  }, [remoteParticipants$]);

  return remoteParticipants$;
}

export function getLastEmittedValue<T>(
  stream$: Rx.Observable<T>
): Effect.Effect<T, Error> {
  let sub: Rx.Subscription | undefined = undefined;
  return Effect.tryPromise(() => {
    return new Promise<T>((resolve, reject) => {
      sub = stream$.subscribe({
        next: (value) => {
          resolve(value);
        },
        error: (err) => {
          reject(err);
        },
        complete: () => {
          reject(new Error("Observable completed without emitting a value"));
        },
      });

      // Ensure subscription is cleaned up if Effect is interrupted
      return () => {
        if (sub) {
          sub.unsubscribe();
        }
      };
    });
  });
}

export const distinctUntilChangedEquals = <T>() =>
  RxO.distinctUntilChanged((a: T, b: T) => Equal.equals(a, b));

export interface EffectorState<T> {
  store: StoreWritable<T>;
  event: EventCallable<T>;
}

export const createState = <T>(initialValue: T): EffectorState<T> => {
  const store = createStore<T>(initialValue);
  const event = createEvent<T>();

  store.on(event, (_, value) => value);

  return { store, event };
};

export function useRunEffect<V>(
  eff: Effect.Effect<V, never, never>,
  deps?: any[]
) {
  const [v, setV] = useState<RD.RemoteData<any, V>>(RD.initial);

  useEffect(() => {
    Effect.runPromise(eff).then((v) => setV(RD.success(v)));
  }, deps);

  return v;
}
