import { useQuery } from "convex/react";
import { Option } from "effect";
import { api } from "shared/be/convex/_generated/api";
import type { UpcomingCommunityCalEventInstanceCardData } from "shared/be/convex/Screens/Community/CommunityEventsScreen.Types";
import type { CommunityCardST } from "shared/be/convex/Screens/Community/CommunityScreens.Types";
import type { HpGlobalProfile } from "shared/be/convex/User/Hp/Hp.Types";
import type { SimpleUser } from "shared/be/convex/User/User.Types";
import { asMb } from "shared/util";
import { useOnce } from "../../util";

export function useSetupCommunitiesDash(): {
  loaded: Option.Option<{
    globalProfile: HpGlobalProfile;
    communitiesForMeList: (typeof CommunityCardST.Encoded)[];
    recentlyJoinedUsers: SimpleUser[];
  }>;
  upcomingEventsForMe:
    | {
        cards: UpcomingCommunityCalEventInstanceCardData[];
      }
    | undefined;
} {
  const now = useOnce(() => Date.now());

  const globalProfile = useQuery(api.User.Hp.HpFns.getMyGlobalProfile);

  const upcomingEventsForMe = useQuery(
    api.Community.CommunitiesHomeScreenFns.getUpcomingEventsForMe,
    { now }
  );

  const communitiesForMeList = useQuery(
    api.Community.CommunitiesHomeScreenFns.getCommunitiesForMeList,
    {}
  );

  const recentlyJoinedUsers = useQuery(
    api.Community.CommunitiesHomeScreenFns.getRecentlyJoinedUsers,
    {}
  );

  return {
    loaded: Option.all({
      globalProfile: asMb(globalProfile),
      communitiesForMeList: asMb(communitiesForMeList),
      recentlyJoinedUsers: asMb(recentlyJoinedUsers),
    }),
    upcomingEventsForMe: upcomingEventsForMe,
  };
}
