import { Effect, Match } from "effect";
import { useUnit } from "effector-react";
import {
  CreateCommunityPostVM,
  useInitCreatePostForm,
  useSetupCreateCommunityPostVM,
} from "frontend-shared/src/community/create-community-post.vm";
import { BaseSearchableDropdownVM } from "frontend-shared/src/shared-vms/searchable-dropdown.vm";
import { useOnce, useQuery$ } from "frontend-shared/src/util";
import React, { useEffect, useMemo } from "react";
import { api } from "shared/be/convex/_generated/api";
import type { CommunityPostSetup } from "shared/be/convex/Community/CommunityDiscussion.Types";
import { isNotNullOrUndefined } from "shared/util";
import { PrimaryButton } from "web-shared/src/components/button";
import {
  FullContainerLoadingSpinner,
  LoadingSpinner,
} from "web-shared/src/components/loading";
import {
  domSelectFile,
  FileUploadUtils,
} from "web-shared/src/utils/file.utils";
import { MentionableTextarea } from "../../../../components/mentionable-textarea";
import { SearchableDropdownV2 } from "../../../../components/searchable-dropdown";

export const CreateCommunityPostFormCool: React.FC<{
  communitySlug: string;
  onSuccessSubmit: () => void;
}> = ({ communitySlug, onSuccessSubmit }) => {
  const { postSetup } = useInitCreatePostForm({
    communitySlug,
  });

  if (postSetup === undefined) {
    return <FullContainerLoadingSpinner />;
  }

  return (
    <LoadedCreateCommunityPost
      postSetup={postSetup}
      communitySlug={communitySlug}
      onSuccessSubmit={onSuccessSubmit}
    />
  );
};

const LoadedCreateCommunityPost: React.FC<{
  postSetup: CommunityPostSetup;
  communitySlug: string;
  onSuccessSubmit: () => void;
}> = ({ postSetup, communitySlug, onSuccessSubmit }) => {
  const { vm, mediaInputState } = useSetupCreateCommunityPostVM({
    communitySlug,
    postSetup,
    onSuccessSubmit: () => onSuccessSubmit(),
    uploadFileToCloudinary: (p) =>
      Effect.runPromise(FileUploadUtils.uploadFileLikeToCloudinary(p)),
    selectFile: domSelectFile,
  });

  const text = useUnit(vm.contentVM.$text);
  const isSubmitting = useUnit(vm.$isSubmitting);
  const isSubmitComplete = useUnit(vm.$isSubmitComplete);
  const mentionableUsers = useUnit(vm.contentVM.$mentionableUsers);
  const data = useMemo(() => {
    return mentionableUsers.map((m) => ({
      id: m.id,
      display: m.name,
    }));
  }, [mentionableUsers]);

  return (
    <div className="flex flex-col gap-4 p-4">
      <MentionableTextarea
        value={text}
        onChange={(v) => vm.contentVM.textChanged(v)}
        data={data}
        placeholder="Write something..."
        style={{
          minHeight: "100px",
          padding: "12px",
          borderRadius: "8px",
          backgroundColor: "#F8F8FF",
        }}
      />
      {Match.value(mediaInputState).pipe(
        Match.when({ _tag: "EMBEDDED_IN_CONTENT" }, ({ embedData }) => (
          <iframe src={embedData.url} className="w-full" allowFullScreen />
        )),
        Match.when({ _tag: "USER_WILL_UPLOAD" }, () => (
          <AddMediaSection vm={vm} />
        )),
        Match.exhaustive
      )}
      <div className="flex-1" />
      <div className="flex-1 flex flex-col justify-end">
        <PrimaryButton
          title="Submit post"
          onClick={() => {
            vm.submitPostEvt();
          }}
          disabled={isSubmitting}
        />
      </div>
    </div>
  );
};

const EnterTextSection: React.FC<{
  vm: CreateCommunityPostVM;
}> = ({ vm }) => {
  const [textContent, youtubeEmbedData] = useUnit([
    vm.contentVM.$text,
    vm.contentVM.$youTubeEmbedData,
  ]);

  return (
    <div className="flex flex-col gap-2 px-6 py-5 font-sans border border-vid-black-200 rounded-lg cursor-pointer hover:bg-gray-100">
      <div className="flex items-center gap-2">
        <h4 className="text-vid-black-900">Write something</h4>
      </div>
      <textarea
        value={textContent}
        onChange={(e) => vm.contentVM.textChanged(e.target.value)}
        className="w-full border rounded-lg px-4 py-2 h-[96px] bg-vid-black-100"
      />
    </div>
  );
};

const AddMediaSection: React.FC<{
  vm: CreateCommunityPostVM;
}> = ({ vm }) => {
  const mediaInputState = useUnit(vm.$mediaInputState);
  const mbDisplayUrl = useUnit(vm.mediaVM.$displayMediaUrl);
  const mediaType = useUnit(vm.mediaVM.$mediaType);
  const isProcessing = useUnit(vm.mediaVM.$isProcessing);

  if (mediaInputState._tag === "EMBEDDED_IN_CONTENT") {
    return null;
  }

  if (mbDisplayUrl && mediaType) {
    return (
      <div className="relative h-[200px] w-full">
        {Match.value(mediaType).pipe(
          Match.when("image", () => (
            <img src={mbDisplayUrl} className="w-full object-cover" />
          )),
          Match.when("video", () => (
            <video src={mbDisplayUrl} className="w-full  object-cover" />
          )),
          Match.when("audio", () => (
            <audio src={mbDisplayUrl} className="w-full object-cover" />
          )),
          Match.exhaustive
        )}
        {isProcessing && (
          <div className="absolute inset-0 bg-vid-black-200 opacity-50 flex items-center justify-center">
            <LoadingSpinner />
            <span className="text-vid-purple">Processing...</span>
          </div>
        )}
      </div>
    );
  }

  return (
    <div
      className="flex flex-col gap-2 px-6 py-5 font-sans border border-vid-black-200 rounded-lg cursor-pointer hover:bg-gray-100"
      onClick={() => {
        vm.mediaVM.selectMediaRequested();
      }}
    >
      <AddMediaIcon />
      <span className="text-sm text-vid-black-900">Add a photo or video</span>
    </div>
  );
};

export const SelectCommunityAndThenPostForm: React.FC<{
  onSuccessSubmit: (p: { communitySlug: string }) => void;
}> = ({ onSuccessSubmit }) => {
  const allCommunities$ = useQuery$(
    api.Community.CommunitiesHomeScreenFns.getCommunitiesICanPostTo
  );

  const vm = useOnce(() => new BaseSearchableDropdownVM());

  const selectedCommunity = useUnit(vm.$selectedItem);

  useEffect(() => {
    allCommunities$.subscribe((allCommunities) => {
      if (allCommunities === undefined) return;
      vm.itemsSet(
        allCommunities.map((c) => ({
          id: c.slug,
          label: c.name,
        }))
      );
    });
  }, []);

  return (
    <div className="flex flex-col gap-6 mt-12">
      <div className="mt-8">
        <SearchableDropdownV2 vm={vm} label="Select a community" />
      </div>
      {isNotNullOrUndefined(selectedCommunity) && (
        <CreateCommunityPostFormCool
          communitySlug={selectedCommunity.id}
          onSuccessSubmit={() =>
            onSuccessSubmit({ communitySlug: selectedCommunity.id })
          }
        />
      )}
    </div>
  );
};

const AddMediaIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      d="M9 10C10.1046 10 11 9.10457 11 8C11 6.89543 10.1046 6 9 6C7.89543 6 7 6.89543 7 8C7 9.10457 7.89543 10 9 10Z"
      stroke="#1D1626"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22H15C20 22 22 20 22 15V10"
      stroke="#1D1626"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.75 5H21.25"
      stroke="#1D1626"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <path
      d="M18.5 7.75V2.25"
      stroke="#1D1626"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <path
      d="M2.67188 18.9496L7.60187 15.6396C8.39187 15.1096 9.53187 15.1696 10.2419 15.7796L10.5719 16.0696C11.3519 16.7396 12.6119 16.7396 13.3919 16.0696L17.5519 12.4996C18.3319 11.8296 19.5919 11.8296 20.3719 12.4996L22.0019 13.8996"
      stroke="#1D1626"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const LeftIndentTextIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M3 4.5H21"
      stroke="#1D1626"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3 9.5H12.47"
      stroke="#1D1626"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3 14.5H21"
      stroke="#3A3A3A"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3 19.5H12.47"
      stroke="#3A3A3A"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
