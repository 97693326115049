import { useQuery, useMutation, useConvex } from "convex/react";
import { useUnit } from "effector-react";
import React, { useEffect } from "react";
import { api } from "shared/be/convex/_generated/api";
import type { Id } from "shared/be/convex/_generated/dataModel";
import { ROUTES } from "shared/routes/routes";
import { FullContainerLoadingSpinner } from "../../components/loading";
import { InviteVM } from "./invite.vm";
import { useOnce } from "frontend-shared/src/util";

export const InviteError: React.FC<{
  error: string;
  onReturnHome: () => void;
}> = ({ error, onReturnHome }) => {
  return (
    <div className="flex flex-col items-center justify-center h-screen space-y-4">
      {error.includes("usage limit") ? (
        <>
          <p className="text-red-500 text-xl font-medium">
            This invite link has reached its maximum number of uses.
          </p>
          <button
            onClick={onReturnHome}
            className="px-4 py-2 text-white bg-vid-purple rounded-lg hover:bg-blue-700"
          >
            Return Home
          </button>
        </>
      ) : (
        <p className="text-red-500 text-xl font-medium">{error}</p>
      )}
    </div>
  );
};

export const AuthedInviteHandler: React.FC<{
  inviteId: Id<"inviteLinks">;
  email?: string;
  onNavigate: (path: string) => void;
}> = ({ inviteId, email, onNavigate }) => {
  const me = useQuery(api.User.UserFns.getAssumedSimpleMe);
  const validateAndTrackInvite = useMutation(
    api.Invite.TrackInviteFns.validateAndTrackInvite
  );

  const vm = useOnce(
    () => new InviteVM(async (args) => validateAndTrackInvite(args))
  );
  const [error, inviteResult] = useUnit([vm.$error, vm.$inviteResult]);

  useEffect(() => {
    if (inviteId) {
      vm.validateInviteFx({ inviteId, email });
    }
  }, [inviteId, email]);

  useEffect(() => {
    if (inviteResult) {
      onNavigate(inviteResult.redirectPath);
    }
  }, [inviteResult]);

  if (me === undefined) {
    return <FullContainerLoadingSpinner />;
  }

  if (error) {
    return <InviteError error={error} onReturnHome={() => onNavigate("/u")} />;
  }

  return (
    <div className="w-screen h-screen flex flex-col items-center justify-center">
      <h4>{`Welcome ${me.firstName}. You are being redirected...`}</h4>
    </div>
  );
};

export const UnauthenticatedInviteHandler: React.FC<{
  inviteId: Id<"inviteLinks">;
  email?: string;
  onNavigate: (path: string) => void;
}> = ({ inviteId, email, onNavigate }) => {
  const validateAndTrackInvite = useMutation(
    api.Invite.TrackInviteFns.validateAndTrackInvite
  );
  const vm = useOnce(
    () => new InviteVM(async (args) => validateAndTrackInvite(args))
  );
  const [error, inviteResult] = useUnit([vm.$error, vm.$inviteResult]);

  useEffect(() => {
    const validateAndRedirect = async () => {
      try {
        const result = await vm.validateInviteFx({ inviteId, email });
        vm.storePendingInvite(result);
        onNavigate(ROUTES.AUTH.AUTHIN.path);
      } catch (err: any) {
        if (err.message?.includes("Authentication required")) {
          onNavigate(ROUTES.AUTH.AUTHIN.path);
        }
      }
    };

    validateAndRedirect();
  }, [inviteId, email]);

  if (error) {
    return <InviteError error={error} onReturnHome={() => onNavigate("/u")} />;
  }

  return (
    <div className="h-screen w-full flex flex-col items-center justify-center">
      <h4>Validating invite...</h4>
    </div>
  );
};
