import { useMutation } from "convex/react";
import { Match } from "effect";
import { createEffect, type Store, type StoreWritable } from "effector";
import { useUnit } from "effector-react";
import { createState, useQueryStore } from "frontend-shared/src/util";
import { useMemo, useState } from "react";
import { api } from "shared/be/convex/_generated/api";
import type { Id } from "shared/be/convex/_generated/dataModel";
import type { DraftBreakoutRoom } from "shared/be/convex/Rtc/Rooms/Room.Types";
import { isNullOrUndefined } from "shared/util";
import cloudBeepIcon from "web-shared/src/assets/images/cloud-beep-icon.svg";
import {
  InversePrimaryButton,
  PrimaryButton,
} from "web-shared/src/components/button";
import { InputContainer } from "web-shared/src/components/form/form-inputs.fc";
import { FullContainerLoadingSpinner } from "web-shared/src/components/loading";
import { RadioButtons } from "web-shared/src/components/ui/radio-group";
import { Input } from "../../../../../../../components/ui/input";
import { useWebGlobalDisplayVM } from "../../../../../../../web-context";
import {
  useBreakoutRoomsVM,
  useSetupBreakoutRoomsFormVM,
  type BrSessionInfo,
} from "../../activities/breakout-rooms/setup-breakout-rooms.vm";

export const SetupBreakoutRoomsToolsPanel: React.FC<{
  sessionId: Id<"rtcSession">;
}> = ({ sessionId }) => {
  const { setup, activeSession } = useSetupBreakoutRoomsFormVM();
  const endBreakoutRooms = useMutation(
    api.Rtc.Rooms.Breakout.BreakoutRoomControlFns.endBreakoutRooms
  );

  if (setup === undefined && !activeSession) {
    return <FullContainerLoadingSpinner />;
  }

  return (
    <Loaded
      brSessionInfo={
        activeSession
          ? {
              _id: activeSession._id,
              status: "ACTIVE",
            }
          : setup!
      }
      sessionId={sessionId}
      endBreakoutRooms={endBreakoutRooms}
    />
  );
};

const ToolsSectionContainer: React.FC<{
  title: string;
  content: React.ReactNode;
}> = ({ title, content }) => {
  return (
    <div className="flex flex-col items-start w-full p-6 gap-4 border border-vid-black-200 rounded-lg">
      <h2 className="w-full text-vid-black-900 font-sans text-base font-semibold leading-[120%]">
        {title}
      </h2>
      {content}
    </div>
  );
};

const Loaded: React.FC<{
  brSessionInfo: BrSessionInfo;
  sessionId: Id<"rtcSession">;
  endBreakoutRooms: (args: {
    sessionId: Id<"rtcSession">;
    brSessionId: Id<"rtcBreakoutRoomSessions">;
  }) => Promise<null>;
}> = ({ brSessionInfo, sessionId, endBreakoutRooms }) => {
  const vm = useBreakoutRoomsVM({
    brSessionId: brSessionInfo._id,
  });

  const cancelDraftBreakoutRooms = useMutation(
    api.Rtc.Rooms.Breakout.BreakoutRoomControlFns.cancelDraftBreakoutRooms
  );

  const applyRoomChanges = useMutation(
    api.Rtc.Rooms.Breakout.BreakoutRoomSetupFns.applyRoomChanges
  );

  const returnAllToMainRoom = useMutation(
    api.Rtc.Rooms.Breakout.BreakoutRoomControlFns.returnAllToMainRoom
  );

  const usersInRooms = useUnit(vm.$usersInRooms);

  console.log("RENDERING SETUP BREAKOUT ROOMS TOOLS PANEL", brSessionInfo);

  return (
    <div className="flex-1 flex flex-col gap-4">
      <SetupBreakoutRoomsRightDrawer
        brSessionInfo={brSessionInfo}
        sessionId={sessionId}
      />
      {Match.value(brSessionInfo.status).pipe(
        Match.when("DRAFT", () => (
          <div className="flex flex-col gap-4">
            <PrimaryButton
              title="Launch Breakout Rooms"
              onClick={async () => {
                if (!usersInRooms) return;
                vm.submit();
              }}
            />
            <InversePrimaryButton
              title="Discard Draft"
              onClick={() => {
                cancelDraftBreakoutRooms({
                  sessionId,
                  brSessionId: brSessionInfo._id,
                });
              }}
            />
          </div>
        )),
        Match.when("ACTIVE", () => (
          <div className="flex-1 flex flex-col gap-4">
            {/* Header */}
            <div className="text-[24px] font-semibold">
              Active Breakout Rooms
            </div>

            {/* Broadcast section */}
            <ToolsSectionContainer
              title="Broadcast Message"
              content={
                <div className="flex flex-col gap-2 w-full">
                  <div className="text-gray-500">
                    Send a message to all breakout rooms
                  </div>
                  <BroadcastMessageToAllBreakoutRoomsSection
                    sessionId={sessionId}
                  />
                </div>
              }
            />

            {/* Actions section */}
            <ToolsSectionContainer
              title="Room Controls"
              content={
                <div className="flex flex-col gap-3 w-full">
                  <PrimaryButton
                    title="Apply Room Changes"
                    onClick={() => {
                      applyRoomChanges({
                        sessionId,
                        brSessionId: brSessionInfo._id,
                      });
                    }}
                  />
                  <div className="flex flex-col gap-2">
                    <InversePrimaryButton
                      title="Send all users back to main room"
                      width="100%"
                      onClick={() => {
                        returnAllToMainRoom({
                          sessionId,
                          brSessionId: brSessionInfo._id,
                        });
                      }}
                    />
                    <InversePrimaryButton
                      title="End Breakout Rooms & Return All to Main"
                      width="100%"
                      color="red"
                      onClick={() => {
                        endBreakoutRooms({
                          sessionId,
                          brSessionId: brSessionInfo._id,
                        });
                      }}
                    />
                  </div>
                </div>
              }
            />
          </div>
        )),
        Match.when("ENDED", () => null),
        Match.exhaustive
      )}
    </div>
  );
};

class SetupBreakoutRoomsVM {
  numGroups: Store<number | null>;
  isCustomGroupsVM = createState(false);
  customGroupsInputVM = createState<string>("");

  setNumberOfBreakoutRoomsFx = createEffect<number | null, void, Error>();

  constructor(p: {
    draftBreakoutRooms: StoreWritable<DraftBreakoutRoom[] | null>;
    setNumberOfBreakoutRoomsOnServer: (
      numBreakoutRooms: number | null
    ) => Promise<void>;
  }) {
    this.setNumberOfBreakoutRoomsFx.use(p.setNumberOfBreakoutRoomsOnServer);

    this.numGroups = p.draftBreakoutRooms.map((draftBreakoutRooms) => {
      if (isNullOrUndefined(draftBreakoutRooms)) {
        return null;
      }
      return draftBreakoutRooms.length;
    });
  }

  setNumGroups = (numGroups: number | null) => {
    if (numGroups === null) return;
    this.setNumberOfBreakoutRoomsFx(numGroups);
  };
}

export const SetupBreakoutRoomsRightDrawer: React.FC<{
  brSessionInfo: BrSessionInfo;
  sessionId: Id<"rtcSession">;
}> = ({ brSessionInfo, sessionId }) => {
  const usersInRoomsVM = useQueryStore(
    api.Rtc.Rooms.Breakout.BreakoutRoomSetupFns.getCurrentAssignments,
    { brSessionId: brSessionInfo._id }
  );

  const setNumberOfRooms = useMutation(
    api.Rtc.Rooms.Breakout.BreakoutRoomSetupFns.setNumberOfRooms
  );

  const returnAllToMainRoom = useMutation(
    api.Rtc.Rooms.Breakout.BreakoutRoomControlFns.returnAllToMainRoom
  );

  const randomlyAssignUsers = useMutation(
    api.Rtc.Rooms.Breakout.BreakoutRoomSetupFns.randomlyAssignUsers
  );

  const vm = useMemo(
    () =>
      new SetupBreakoutRoomsVM({
        draftBreakoutRooms: usersInRoomsVM.store,
        setNumberOfBreakoutRoomsOnServer: async (numBreakoutRooms) => {
          if (numBreakoutRooms === null) return;
          await setNumberOfRooms({
            sessionId,
            brSessionId: brSessionInfo._id,
            numRooms: numBreakoutRooms,
          });
        },
      }),
    [brSessionInfo._id, sessionId, setNumberOfRooms]
  );

  if (brSessionInfo.status === "ACTIVE") {
    return null; // Don't render anything in active state
  }

  return (
    <div className="flex flex-col p-6 gap-6">
      <SetupBreakoutRoomsForm
        vm={vm}
        brSessionInfo={brSessionInfo}
        sessionId={sessionId}
        sendAllUsersToMainRoom={() =>
          returnAllToMainRoom({
            sessionId,
            brSessionId: brSessionInfo._id,
          })
        }
        onRandomlyAssign={() =>
          randomlyAssignUsers({
            sessionId,
            brSessionId: brSessionInfo._id,
          })
        }
      />
    </div>
  );
};

export const SetupBreakoutRoomsForm: React.FC<{
  vm: SetupBreakoutRoomsVM;
  brSessionInfo: BrSessionInfo;
  sendAllUsersToMainRoom: () => Promise<null>;
  onRandomlyAssign: () => Promise<null>;
  sessionId: Id<"rtcSession">;
}> = ({ vm, sendAllUsersToMainRoom, onRandomlyAssign }) => {
  const [
    numGroups,
    isCustomGroups,
    setIsCustomGroups,
    customGroupsInput,
    setCustomGroupsInput,
  ] = useUnit([
    vm.numGroups,
    vm.isCustomGroupsVM.store,
    vm.isCustomGroupsVM.event,
    vm.customGroupsInputVM.store,
    vm.customGroupsInputVM.event,
  ]);

  const handleCustomInputChange = (value: string) => {
    setCustomGroupsInput(value);
    const numValue = parseInt(value);
    if (!isNaN(numValue) && numValue > 0) {
      vm.setNumGroups(numValue);
    }
  };

  return (
    <div className="flex flex-col gap-6">
      <InputContainer label={{ text: "Groups", color: "black" }}>
        <div className="flex flex-col gap-6">
          <RadioButtons
            options={[
              {
                name: "2 groups",
                value: 2,
                icon: <CloudBeepIcon />,
              },
              { name: "3 groups", value: 3, icon: <CloudBeepIcon /> },
              { name: "4 groups", value: 4, icon: <CloudBeepIcon /> },
              { name: "Custom", value: "custom", icon: <CloudBeepIcon /> },
            ]}
            value={isCustomGroups ? "custom" : numGroups}
            onChange={(value) => {
              console.log("value!!", value);
              if (value === "custom") {
                setIsCustomGroups(true);
                vm.setNumGroups(null);
              } else {
                setIsCustomGroups(false);
                vm.setNumGroups(parseInt(value as string));
              }
            }}
          />
          {isCustomGroups && (
            <div className="flex flex-col gap-2">
              <input
                type="number"
                min="1"
                value={customGroupsInput}
                onChange={(e) => handleCustomInputChange(e.target.value)}
                className="px-3 py-2 border rounded-md"
                placeholder="Enter number of groups"
              />
            </div>
          )}
          <InversePrimaryButton
            onClick={onRandomlyAssign}
            title="Randomly assign"
            color="black"
            width="100%"
          />
          <InversePrimaryButton
            onClick={() => sendAllUsersToMainRoom()}
            title="Send all users to main room"
            color="black"
            width="100%"
          />
        </div>
      </InputContainer>
    </div>
  );
};

const BroadcastMessageToAllBreakoutRoomsSection: React.FC<{
  sessionId: Id<"rtcSession">;
}> = ({ sessionId }) => {
  const [message, setMessage] = useState("");
  const webDisplayVM = useWebGlobalDisplayVM();

  const broadcastMessageToAllBreakoutRooms = useMutation(
    api.Rtc.Rooms.Breakout.BreakoutRoomFns.broadcastMessageToAllBreakoutRooms
  );

  return (
    <div className="flex flex-col gap-3 mt-2">
      <Input
        type="text"
        value={message}
        onChange={(e) => setMessage(e.target.value)}
        placeholder="Type your message here..."
        className="w-full h-[48px] px-4 rounded-[30px] bg-white border border-gray-200 focus:border-vid-purple focus:ring-1 focus:ring-vid-purple text-[16px]"
      />
      <PrimaryButton
        title="Send Broadcast"
        onClick={() => {
          if (!message.trim()) return;
          broadcastMessageToAllBreakoutRooms({
            sessionId,
            message,
            duration: 8000,
          })
            .then(() => {
              setMessage("");
            })
            .catch((e: { data: string }) => {
              console.log("error!!", e.data);
              webDisplayVM.showToast({
                id: "broadcast-message-error",
                title: "Unable to broadcast message",
                description: e.data,
                position: "top",
                type: "error",
              });
            });
        }}
      />
    </div>
  );
};

const CloudBeepIcon: React.FC = () => {
  return <img src={cloudBeepIcon} />;
};
