import { useMutation } from "convex/react";
import { throttle } from "patronum";
import { useEffect, useState } from "react";
import * as Rx from "rxjs";
import { api } from "shared/be/convex/_generated/api";
import type { Id } from "shared/be/convex/_generated/dataModel";
import type {
  ActivityPlayState,
  EmdrSoundType,
} from "shared/be/convex/Rtc/Rooms/Activity/Activity.Types";
import type { ColorKey } from "shared/utils/color.utils";
import {
  useOnce,
  useQuery$,
  useQueryStore,
  type EffectorState,
} from "../../../util";

export class EmdrActivityVM {
  private lastBallFrequency: number | null = null;

  constructor(
    readonly args: {
      playState$: Rx.BehaviorSubject<ActivityPlayState | null | undefined>;
      startPreparing: () => Promise<null>;
      onPause: () => Promise<null>;
      onStop: () => Promise<null>;
      ballFrequencyVM: EffectorState<number | null>;
      setBallFrequencyOnServer: (ballFrequency: number) => void;
      bounceSoundVM: EffectorState<EmdrSoundType | null>;
      setBounceSoundOnServer: (soundType: EmdrSoundType) => void;
      ballColorVM: EffectorState<ColorKey | null>;
      setBallColorOnServer: (ballColor: ColorKey) => void;
    }
  ) {
    const throttledSliderBallFrequencyEvt = throttle(
      args.ballFrequencyVM.event,
      300
    );

    throttledSliderBallFrequencyEvt.watch((ballFrequency) => {
      console.log("throttledSliderBallFrequencyEvt", ballFrequency);
      if (ballFrequency === null) return;
      if (ballFrequency === this.lastBallFrequency) return;
      this.lastBallFrequency = ballFrequency;
      this.args.setBallFrequencyOnServer(ballFrequency);
    });
  }

  setBallFrequency(ballFrequency: number) {
    if (ballFrequency === this.lastBallFrequency) return;
    this.args.ballFrequencyVM.event(ballFrequency);
  }

  setBallColor(ballColor: ColorKey) {
    this.args.setBallColorOnServer(ballColor);
  }

  setBounceSound(soundType: EmdrSoundType) {
    this.args.setBounceSoundOnServer(soundType);
  }
}

export function useOnOpenEmdrActivityForm(p: {
  baseSessionId: Id<"rtcSession">;
  roomId: Id<"rtcLiveRooms">;
}):
  | {
      activityId: Id<"rtcRoomActivityInstance">;
      sessionId: Id<"rtcRoomEmdrSession">;
    }
  | undefined {
  const [ids, setIds] = useState<
    | {
        activityId: Id<"rtcRoomActivityInstance">;
        sessionId: Id<"rtcRoomEmdrSession">;
      }
    | undefined
  >(undefined);

  const getOrCreateNewEmdrActivity = useMutation(
    api.Rtc.Rooms.Activity.EmdrFns.getOrCreateNewEmdrActivity
  );

  useEffect(() => {
    getOrCreateNewEmdrActivity({
      roomId: p.roomId,
      sessionId: p.baseSessionId,
    }).then((ids) => {
      setIds(ids);
    });
  }, [p.baseSessionId, p.roomId]);

  return ids;
}

export function useSetupEmdrActivityVM(p: {
  activityId: Id<"rtcRoomActivityInstance">;
  sessionId: Id<"rtcRoomEmdrSession">;
}) {
  const ballFrequencyVM = useQueryStore(
    api.Rtc.Rooms.Activity.EmdrFns.getEmdrBallFrequency,
    {
      sessionId: p.sessionId,
    }
  );

  const setBallFrequencyOnServer = useMutation(
    api.Rtc.Rooms.Activity.EmdrFns.setEmdrBallFrequency
  ).withOptimisticUpdate((localStore, args) => {
    localStore.setQuery(
      api.Rtc.Rooms.Activity.EmdrFns.getEmdrBallFrequency,
      {
        sessionId: p.sessionId,
      },
      args.ballFrequency
    );
  });

  const bounceSoundVM = useQueryStore(
    api.Rtc.Rooms.Activity.EmdrFns.getEmdrBounceSound,
    {
      sessionId: p.sessionId,
    }
  );

  const setBounceSoundOnServer = useMutation(
    api.Rtc.Rooms.Activity.EmdrFns.setEmdrSoundType
  ).withOptimisticUpdate((localStore, args) => {
    localStore.setQuery(
      api.Rtc.Rooms.Activity.EmdrFns.getEmdrBounceSound,
      {
        sessionId: p.sessionId,
      },
      args.soundType
    );
  });

  const ballColorVM = useQueryStore(
    api.Rtc.Rooms.Activity.EmdrFns.getEmdrBallColor,
    {
      sessionId: p.sessionId,
    }
  );

  const setBallColorOnServer = useMutation(
    api.Rtc.Rooms.Activity.EmdrFns.setEmdrBallColor
  ).withOptimisticUpdate((localStore, args) => {
    localStore.setQuery(
      api.Rtc.Rooms.Activity.EmdrFns.getEmdrBallColor,
      {
        sessionId: p.sessionId,
      },
      args.ballColor
    );
  });

  // Still need activityId for playState
  const playState$ = useQuery$(
    api.Rtc.Rooms.Activity.RtcRoomActivityFns.getTimedActivityPlayState,
    {
      timedActivityId: p.activityId,
    }
  );

  const startPreparing = useMutation(
    api.Rtc.Rooms.Activity.EmdrFns.onPlayClick
  ).withOptimisticUpdate((localStore) => {
    localStore.setQuery(
      api.Rtc.Rooms.Activity.RtcRoomActivityFns.getTimedActivityPlayState,
      {
        timedActivityId: p.activityId,
      },
      "preparing"
    );
  });

  const onPause = useMutation(api.Rtc.Rooms.Activity.EmdrFns.pauseEmdrActivity);

  const onStop = useMutation(api.Rtc.Rooms.Activity.EmdrFns.finishEmdrActivity);

  const vm = useOnce(
    () =>
      new EmdrActivityVM({
        playState$,
        startPreparing: () => startPreparing({ emdrSessionId: p.sessionId }),
        onPause: () => onPause({ sessionId: p.sessionId }),
        onStop: () => onStop({ sessionId: p.sessionId }),
        ballFrequencyVM,
        setBallFrequencyOnServer: (ballFrequency) =>
          setBallFrequencyOnServer({
            sessionId: p.sessionId,
            ballFrequency,
          }),
        bounceSoundVM,
        setBounceSoundOnServer: (soundType) =>
          setBounceSoundOnServer({
            sessionId: p.sessionId,
            soundType,
          }),
        ballColorVM,
        setBallColorOnServer: (ballColor) =>
          setBallColorOnServer({
            sessionId: p.sessionId,
            ballColor,
          }),
      })
  );

  return vm;
}
