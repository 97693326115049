import logoLoadingSpinner from "web-shared/src/assets/images/logo-loading-spinner.gif";

export const LoadingSpinner: React.FC<{ size?: number }> = ({ size }) => {
  return (
    <img
      src={logoLoadingSpinner}
      style={{
        width: size ?? 80,
        height: size ?? 80,
      }}
    />
  );
};

export const FullContainerLoadingSpinner: React.FC<{ message?: string }> = ({
  message,
}) => {
  return (
    <div className="w-full h-full flex justify-center items-center">
      <div className="flex flex-col gap-4 items-center">
        <LoadingSpinner />
        {message && <p className="text-vid-purple font-bold">{message}</p>}
      </div>
    </div>
  );
};

export const FullScreenLoadingSpinner: React.FC<{ message?: string }> = ({
  message,
}) => {
  return (
    <div className="w-screen h-screen">
      <FullContainerLoadingSpinner message={message} />
    </div>
  );
};
