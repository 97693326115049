import { useMutation, useQuery } from "convex/react";
import { format } from "date-fns";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useTypedParams } from "react-router-typesafe-routes/dom";
import { api } from "shared/be/convex/_generated/api";
import type { Id } from "shared/be/convex/_generated/dataModel";
import type { SimpleUser } from "shared/be/convex/User/User.Types";
import { CPROUTES } from "shared/routes/cp.routes";
import { ImageSrc } from "shared/types/miscellaneous.types";
import { AvatarCircle } from "web-shared/src/components/avatar.tc";
import { TopLevelTabLink } from "web-shared/src/components/dashboard/dashboard-layout";
import { FullContainerLoadingSpinner } from "web-shared/src/components/loading";
import { SimpleTable } from "web-shared/src/components/table";

export const HpInfoDashboardLayout: React.FC = () => {
  const { hpId } = useTypedParams(CPROUTES.DASHBOARD.CHILDREN.HPS.HP_ID);

  const hp = useQuery(api.User.Cp.CpFns.getHpInfo, {
    hpId: hpId as Id<"users">,
  });

  if (hp === undefined) {
    return <FullContainerLoadingSpinner />;
  }

  return (
    <div className="flex flex-col gap-1">
      <div className="flex flex-col gap-8">
        <HpProfileSection hp={hp} />
        <Header />
      </div>
      <div className="flex flex-col py-6">
        <Outlet />
      </div>
    </div>
  );
};

const Header: React.FC = () => {
  return (
    <div className="flex items-center justify-between">
      <Tabs />
      <ActionButtons />
    </div>
  );
};

const Tabs: React.FC = () => {
  const { pathname } = useLocation();

  return (
    <div className="flex gap-4">
      <TopLevelTabLink
        to={"past-sessions"}
        isActive={pathname.includes("past-sessions")}
      >
        Past sessions
      </TopLevelTabLink>
    </div>
  );
};

const HpProfileSection: React.FC<{
  hp: SimpleUser;
}> = ({ hp }) => {
  return (
    <div className="flex items-center gap-4">
      <AvatarCircle
        mbProfilePhoto={ImageSrc.fromMbUrl(hp.profilePhoto)}
        size={100}
      />
      <div className="flex flex-col gap-2">
        <h4 className="text-[20px]">{hp.name}</h4>
        {hp.email && (
          <p className="text-[14px] text-vid-black-700">{hp.email}</p>
        )}
      </div>
    </div>
  );
};

const ActionButtons: React.FC = () => {
  return <div></div>;
};

export const PastSessionsWithHp: React.FC = () => {
  const navigate = useNavigate();
  const { hpId } = useTypedParams(CPROUTES.DASHBOARD.CHILDREN.HPS.HP_ID);

  const pastSessions = useQuery(api.User.Cp.CpFns.getPastSessionsWithHp, {
    hpId: hpId as Id<"users">,
  });

  if (pastSessions === undefined) {
    return <FullContainerLoadingSpinner />;
  }

  return (
    <div>
      <SimpleTable
        action={{
          onClick: (s) => {
            navigate(
              CPROUTES.SESSIONS.SESSION_ID.REVIEW.buildPath({
                sessionId: s.baseSessionId,
              })
            );
          },
        }}
        columns={[
          {
            key: "pastSessions",
            header: "Past Sessions",
            render: (session) => {
              return <div>{session.hpInfo.name}</div>;
            },
          },
          {
            key: "mbEndedAt",
            header: "Ended At",
            render: (session) => {
              return (
                <div>{format(new Date(session.endedAt), "MMM dd, yyyy")}</div>
              );
            },
          },
        ]}
        data={pastSessions}
        roundedTop={true}
      />
    </div>
  );
};
