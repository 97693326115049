import { api } from "shared/be/convex/_generated/api";
import type { Id } from "shared/be/convex/_generated/dataModel";
import { useQuery } from "convex/react";
import { FullContainerLoadingSpinner } from "../../../components/loading";
import { LeftNavStepsCourseTemplate } from "./templates/left-nav-steps.course-template";

export const CourseApp: React.FC<{
  courseId: Id<"courseInfo">;
}> = ({ courseId }) => {
  const myEnrollment = useQuery(
    api.Learning.Courses.CoursesFns.getMyEnrollment,
    {
      courseId,
    }
  );

  if (myEnrollment === undefined) {
    return <FullContainerLoadingSpinner />;
  }

  if (myEnrollment === null) {
    return (
      <div className="flex flex-col items-center justify-center h-screen">
        <div className="text-2xl font-bold">
          You are not enrolled in this course.
        </div>
      </div>
    );
  }

  return <LeftNavStepsCourseTemplate courseId={courseId} />;
};
