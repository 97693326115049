import { route, string } from "react-router-typesafe-routes/dom";

export namespace CPROUTES {
  export namespace DASHBOARD {
    export const CHILDREN = {
      HOME: route("home", {
        searchParams: {
          acceptHpRequest: string(),
        },
      }),
      CALENDAR: route("calendar", {
        searchParams: {
          eventTemplateId: string(),
        },
      }),
      COMMUNITY_TAB: route(
        "community",
        {},
        {
          COMMUNITIES: route(
            "communities",
            {},
            {
              COMMUNITY: route(
                ":community",
                { params: { community: string().defined() } },
                {
                  DISCUSSION: route("discussion"),
                }
              ),
            }
          ),
        }
      ),
      SETTINGS: route(
        "settings",
        {},
        {
          ACCOUNT: route(
            "account",
            {},
            {
              PROFILE: route("profile", {}),
              ACCOUNT_INFO: route("account-info", {}),
            }
          ),
        }
      ),
      HPS: route(
        "hps",
        {},
        {
          HP_ID: route(
            ":hpId",
            { params: { hpId: string().defined() } },
            {
              DETAILS: route("details", {}),
            }
          ),
        }
      ),
    };
    export const ROOT = route("dashboard", {}, { ...CHILDREN });
  }

  export const SESSION_ID = route(
    ":sessionId",
    { params: { sessionId: string().defined() } },
    {
      REVIEW: route("review", {}),
      LIVE: route(
        "live",
        {},
        {
          MAIN_ROOM: route("main-room", {}),
          WAITING_ROOM: route("waiting-room", {}),
        }
      ),
    }
  );

  export const SESSIONS = route(
    "sessions",
    {},
    {
      SESSION_ID,
    }
  );

  export const ROOT = route(
    "cp",
    {},
    {
      DASHBOARD: DASHBOARD.ROOT,
      SESSIONS,
    }
  );
}
