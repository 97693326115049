import {
  BackgroundFiltersProvider,
  StreamCall,
  StreamVideo,
  useBackgroundFilters,
  useCall,
  type Call,
  type StreamVideoClient,
} from "@stream-io/video-react-sdk";
import { useAudioPlayer } from "frontend-shared/src/mgrs/media-player.statemgr";
import type { GenericRtcMgr } from "frontend-shared/src/mgrs/state-mgrs/rtc.statemgr";
import {
  LiveRoomVMContext,
  useLiveRoomVM,
  useSetupLiveRoom,
  useSetupLiveRoomStreamVM,
} from "frontend-shared/src/sessions/live-room.vm";
import { useObservableEagerState } from "observable-hooks";
import { useEffect } from "react";
import type { Id } from "shared/be/convex/_generated/dataModel";
import type {
  CurrentOpenMenu,
  StageViewLayout,
} from "shared/be/convex/Rtc/Rooms/Live/LiveRoom.Types";
import { FullContainerLoadingSpinner } from "web-shared/src/components/loading";
import {
  LiveRoomStreamVMContext,
  useMbRtcMgr,
  useMyId,
} from "web-shared/src/web-context";
import { ControlPanel } from "../control-panel";
import { BackstageRoom } from "./backstage/backstage";
import { LiveRoomRightDrawer } from "./menu/live-room-right-drawer";
import { StageView } from "./stage-view";

export const LiveRoom: React.FC<{
  baseSessionId: Id<"rtcSession">;
  roomId: Id<"rtcLiveRooms">;
}> = ({ baseSessionId, roomId }) => {
  const rtcClient = useMbRtcMgr();

  useEffect(() => {
    console.log("LIVE ROOM! : ", roomId);
  }, [roomId]);

  if (rtcClient === null) {
    return <FullContainerLoadingSpinner />;
  }

  return (
    <LiveRoomLoaded
      baseSessionId={baseSessionId}
      roomId={roomId}
      rtcClient={rtcClient}
    />
  );
};

const LiveRoomLoaded: React.FC<{
  baseSessionId: Id<"rtcSession">;
  roomId: Id<"rtcLiveRooms">;
  rtcClient: GenericRtcMgr<StreamVideoClient, Call>;
}> = ({ baseSessionId, roomId, rtcClient }) => {
  const myId = useMyId();
  const audioPlayer = useAudioPlayer();
  const {
    stageViewLayout,
    isBackstage,
    currentOpenMenu,
    streamConfig,
    channelMgr,
    liveRoomVM,
  } = useSetupLiveRoom<StreamVideoClient, Call>({
    baseSessionId,
    roomId,
    rtcClient,
    audioPlayer,
    myId,
  });

  const isStageViewMaximized = useObservableEagerState(
    liveRoomVM.stageView.isStageViewMaximized$
  );

  useEffect(() => {
    console.log("HELLO ROOM! : ", liveRoomVM.roomId);
  }, [liveRoomVM.roomId]);

  if (
    stageViewLayout === undefined ||
    isStageViewMaximized === undefined ||
    streamConfig === undefined ||
    isBackstage === undefined
  ) {
    return <FullContainerLoadingSpinner />;
  }

  return (
    <LiveRoomVMContext.Provider value={liveRoomVM}>
      <StreamVideo client={rtcClient.client}>
        <StreamCall call={channelMgr}>
          <BackgroundFiltersProvider>
            <LiveRoomStream
              isBackstage={isBackstage}
              stageViewLayout={stageViewLayout}
              isStageViewMaximized={isStageViewMaximized}
              currentOpenMenu={currentOpenMenu}
            />
          </BackgroundFiltersProvider>
        </StreamCall>
      </StreamVideo>
    </LiveRoomVMContext.Provider>
  );
};

const LiveRoomStream: React.FC<{
  isBackstage: boolean;
  stageViewLayout: StageViewLayout;
  isStageViewMaximized: boolean;
  currentOpenMenu: CurrentOpenMenu | null;
}> = ({
  isBackstage,
  stageViewLayout,
  isStageViewMaximized,
  currentOpenMenu,
}) => {
  const { baseSessionId, roomId } = useLiveRoomVM();
  const channelMgr = useCall();

  const { applyBackgroundBlurFilter, disableBackgroundFilter } =
    useBackgroundFilters();

  const { liveRoomStreamVM } = useSetupLiveRoomStreamVM<Call>({
    baseSessionId,
    roomId,
    channelMgr,
    applyBackgroundBlurFilterToStream: (blurIntensity) => {
      return applyBackgroundBlurFilter(blurIntensity);
    },
    disableBackgroundBlurFilter: () => {
      return disableBackgroundFilter();
    },
  });

  return (
    <LiveRoomStreamVMContext.Provider value={liveRoomStreamVM}>
      {isBackstage ? (
        <BackstageRoom roomId={roomId} />
      ) : (
        <LiveRoomLayoutView
          stageAreaView={
            <StageView
              stageViewLayout={stageViewLayout}
              isMaximized={isStageViewMaximized}
            />
          }
          maximizedView={
            isStageViewMaximized ? (
              <StageView
                stageViewLayout={stageViewLayout}
                isMaximized={isStageViewMaximized}
              />
            ) : null
          }
          rightDrawer={
            <LiveRoomRightDrawer
              baseSessionId={baseSessionId}
              roomId={roomId}
              currentOpenMenu={currentOpenMenu}
            />
          }
          controlPanel={
            <ControlPanel baseSessionId={baseSessionId} roomId={roomId} />
          }
        />
      )}
    </LiveRoomStreamVMContext.Provider>
  );
};

export const LiveRoomLayoutView: React.FC<{
  maximizedView: React.ReactNode | null;
  stageAreaView: React.ReactNode;
  rightDrawer: React.ReactNode | null;
  controlPanel: React.ReactNode;
}> = ({ maximizedView, stageAreaView, rightDrawer, controlPanel }) => {
  if (maximizedView !== null) {
    return (
      <div className="w-screen h-screen bg-vid-black-900 flex flex-col p-6 relative overflow-hidden">
        {maximizedView}
      </div>
    );
  }

  return (
    <div className="w-screen h-screen bg-vid-black-900 flex flex-col relative overflow-hidden">
      <div className="flex-1 min-h-0 flex gap-4 pl-2 pr-6 pb-6 pt-6">
        <div className="flex-1 flex flex-col gap-4 relative">
          {stageAreaView}
        </div>
        {rightDrawer}
      </div>

      <div className="basis-[100px] grow-0 shrink-0 bg-white flex flex-col justify-center">
        {controlPanel}
      </div>
    </div>
  );
};
