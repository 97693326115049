import { useMutation, useQuery } from "convex/react";
import {
  combine,
  createEffect,
  createEvent,
  createStore,
  sample,
} from "effector";
import { useUnit } from "effector-react";
import { CalendarPickerVM } from "frontend-shared/src/shared-vms/calendar-picker.vm";
import { BaseSearchableDropdownVM } from "frontend-shared/src/shared-vms/searchable-dropdown.vm";
import { useOnce } from "frontend-shared/src/util";
import { useEffect, useMemo } from "react";
import { api } from "shared/be/convex/_generated/api";
import type { Id } from "shared/be/convex/_generated/dataModel";
import { PrimaryButton } from "web-shared/src/components/button";
import { CalendarDropdown } from "web-shared/src/components/form/calendar-picker.input";
import { FullContainerLoadingSpinner } from "web-shared/src/components/loading";
import { BaseSearchableSelect } from "../../../components/ui/select";

function useSetupFormForCp(p: { onSuccessSubmit?: () => void }) {
  const allMyHps = useQuery(
    api.Practitioner.PractitionerRelationshipFns.getMyActivePractitioners
  );
  const scheduleNewHcSession = useMutation(
    api.User.Cp.CpFns.scheduleNewHcCalEvent
  );

  const vm = useMemo(() => {
    if (allMyHps === undefined) return undefined;

    return new FormVM({
      allMyHps: allMyHps.practitioners.map((c) => ({
        id: c.practitionerId,
        name: c.name,
      })),
      submit: async ({ hpId, startsAt }) => {
        // Convert from UTC back to local time
        const date = new Date(startsAt);
        const offsetMs = date.getTimezoneOffset() * 60 * 1000;
        const localTime = startsAt + offsetMs;

        await scheduleNewHcSession({
          forHpId: hpId,
          startsAt: localTime,
          durationSeconds: 60 * 60,
        });
      },
      onSuccessSubmit: p.onSuccessSubmit,
    });
  }, [allMyHps]);

  return vm;
}

class FormVM {
  selectHpVm: SelectHpVm;
  calendarPickerVM = new CalendarPickerVM();

  submitted = createEvent();

  $validationError = createStore<string | null>(null);

  submitFx = createEffect<
    { hpId: Id<"users">; startsAt: number },
    void,
    Error
  >();

  constructor(p: {
    allMyHps: { id: Id<"users">; name: string }[];
    submit: (p: { hpId: Id<"users">; startsAt: number }) => Promise<void>;
    onSuccessSubmit?: () => void;
  }) {
    this.selectHpVm = new SelectHpVm({ allMyHps: p.allMyHps });

    sample({
      clock: this.submitted,
      source: {
        selectedHp: this.selectHpVm.$selectedItem,
        selectedDate: this.calendarPickerVM.$selectedDate,
      },
      filter: ({ selectedHp, selectedDate }) =>
        selectedHp !== null && selectedDate !== null,
      fn: ({ selectedHp, selectedDate }) => ({
        hpId: selectedHp!.id as Id<"users">,
        startsAt: selectedDate!.getTime(),
      }),
      target: this.submitFx,
    });

    this.submitFx.use(p.submit);

    this.$validationError.on(
      combine(
        this.selectHpVm.$selectedItem,
        this.calendarPickerVM.$selectedDate
      ),
      () => null
    );

    this.$validationError.on(this.submitted, () => {
      const selectedHp = this.selectHpVm.$selectedItem.getState();
      const selectedDate = this.calendarPickerVM.$selectedDate.getState();

      const errors: string[] = [];

      if (!selectedHp) {
        errors.push("Please select a therapist/coach");
      }

      if (!selectedDate) {
        errors.push("Please select a date and time");
      }

      return errors.length > 0 ? errors.join(", ") : null;
    });

    this.submitFx.doneData.watch(() => {
      p.onSuccessSubmit?.();
    });
  }
}

export const NewHCSessionFormForCp: React.FC<{
  onSuccessSubmit: () => void;
}> = ({ onSuccessSubmit }) => {
  const vm = useSetupFormForCp({
    onSuccessSubmit: () => {
      onSuccessSubmit();
    },
  });

  if (vm === undefined) return <FullContainerLoadingSpinner />;

  return <NewHCSessionFormLoaded vm={vm} />;
};

const NewHCSessionFormLoaded: React.FC<{
  vm: FormVM;
}> = ({ vm }) => {
  const isSubmitting = useUnit(vm.submitFx.pending);
  const validationError = useUnit(vm.$validationError);

  return (
    <div className="flex-1 flex flex-col gap-6">
      <div className="self-stretch">
        <HpSearchableDropdown
          onSelect={(hp) => {
            vm.selectHpVm.itemSelected({
              id: hp.id,
              label: hp.name,
            });
          }}
          allMyHps={vm.selectHpVm.allMyHps}
        />
      </div>
      <CalendarDropdown vm={vm.calendarPickerVM} />
      <div className="flex-1 flex flex-col justify-end">
        <div className="flex flex-col gap-4">
          {validationError && (
            <div className="text-red-500">{validationError}</div>
          )}
          <PrimaryButton
            title="Schedule session"
            onClick={() => {
              vm.submitted();
            }}
            isLoading={isSubmitting}
          />
        </div>
      </div>
    </div>
  );
};

class SelectHpVm extends BaseSearchableDropdownVM<{
  id: string;
  label: string;
}> {
  allMyHps: { id: Id<"users">; name: string }[];

  constructor(p: { allMyHps: { id: Id<"users">; name: string }[] }) {
    super(p.allMyHps.map((c) => ({ id: c.id, label: c.name })));
    this.allMyHps = p.allMyHps;
  }
}

const HpSearchableDropdown: React.FC<{
  onSelect: (hp: { id: Id<"users">; name: string }) => void;
  allMyHps: { id: Id<"users">; name: string }[];
}> = ({ onSelect, allMyHps }) => {
  const vm = useOnce(() => new SelectHpVm({ allMyHps: [] }));
  const [selectedItem, items] = useUnit([vm.$selectedItem, vm.$items]);

  useEffect(() => {
    vm.itemsSet(allMyHps.map((c) => ({ id: c.id, label: c.name })));
  }, [allMyHps]);

  return (
    <BaseSearchableSelect
      label="Therapist/Coach"
      placeholder="Select a therapist/coach..."
      items={items}
      value={selectedItem}
      onChange={(item) => {
        if (item) {
          onSelect({ id: item.id as Id<"users">, name: item.label });
          vm.itemSelected(item);
        }
      }}
    />
  );
};
