import { Authenticated, AuthLoading, Unauthenticated } from "convex/react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import type { Id } from "shared/be/convex/_generated/dataModel";
import type { AuthedDestination } from "shared/be/convex/User/User.Types";
import { FullContainerLoadingSpinner } from "web-shared/src/components/loading";
import { AuthedSuccess } from "web-shared/src/global/authed-provider";

export const UGuard: React.FC<{
  navigate: (to: string) => void;
  matchRouteForAuthedDestination: (
    currentHref: string,
    destination: AuthedDestination
  ) => string | null;
  contextMenuOverlay: {
    onSelectClient: (clientId: Id<"users">) => void;
    onSelectCommunity: (communitySlug: string) => void;
  };
  onUnauthenticated: () => void;
  children: React.ReactNode;
}> = ({
  navigate,
  matchRouteForAuthedDestination,
  contextMenuOverlay,
  onUnauthenticated,
  children,
}) => {
  return (
    <>
      <Authenticated>
        <AuthedSuccess
          navigate={navigate}
          matchRouteForAuthedDestination={matchRouteForAuthedDestination}
          contextMenuOverlay={contextMenuOverlay}
        >
          {children}
        </AuthedSuccess>
      </Authenticated>
      <AuthLoading>
        <FullContainerLoadingSpinner />
      </AuthLoading>
      <Unauthenticated>
        <UnAuthedView onUnauthenticated={onUnauthenticated} />
      </Unauthenticated>
    </>
  );
};

const UnAuthedView: React.FC<{
  onUnauthenticated: () => void;
}> = ({ onUnauthenticated }) => {
  useEffect(() => {
    onUnauthenticated();
  }, []);

  return <FullContainerLoadingSpinner />;
};
