import { useQuery } from "convex/react";
import { useUnit } from "effector-react";
import { BaseSearchableDropdownVM } from "frontend-shared/src/shared-vms/searchable-dropdown.vm";
import { useOnce } from "frontend-shared/src/util";
import type React from "react";
import { useEffect } from "react";
import { api } from "shared/be/convex/_generated/api";
import type { Id } from "shared/be/convex/_generated/dataModel";
import { BaseSearchableSelect } from "web-shared/src/components/ui/select";

class ClientSelectVM extends BaseSearchableDropdownVM<{
  id: string;
  label: string;
}> {
  constructor(p: { allMyClients: { id: string; name: string }[] }) {
    super(p.allMyClients.map((c) => ({ id: c.id, label: c.name })));
  }
}

export const ClientSearchableDropdown: React.FC<{
  onSelect: (client: { id: Id<"users">; name: string }) => void;
}> = ({ onSelect }) => {
  const allMyClients = useQuery(api.User.Hp.HpFns.getMyClients);

  const vm = useOnce(() => new ClientSelectVM({ allMyClients: [] }));
  const [selectedItem, items] = useUnit([vm.$selectedItem, vm.$items]);

  useEffect(() => {
    if (allMyClients !== undefined) {
      vm.itemsSet(allMyClients.map((c) => ({ id: c.clientId, label: c.name })));
    }
  }, [allMyClients]);

  return (
    <BaseSearchableSelect
      label="Client"
      placeholder="Select a client..."
      items={items}
      value={selectedItem}
      onChange={(item) => {
        if (item) {
          onSelect({ id: item.id as Id<"users">, name: item.label });
          vm.itemSelected(item);
        }
      }}
    />
  );
};
