import * as S from "@effect/schema/Schema";
import { Brand } from "effect";

export type ZeroIndexedMonth = number & Brand.Brand<"ZeroIndexedMonth">;
export const ZeroIndexedMonth = Brand.nominal<ZeroIndexedMonth>();
export type OneIndexedMonth = number & Brand.Brand<"OneIndexedMonth">;
export const OneIndexedMonth = Brand.nominal<OneIndexedMonth>();

export function toOneIndexedMonth(p: {
  ziMonthIdx: ZeroIndexedMonth;
}): OneIndexedMonth {
  return OneIndexedMonth(p.ziMonthIdx + 1);
}

export function toZeroIndexedMonth(p: {
  oiMonthIdx: OneIndexedMonth;
}): ZeroIndexedMonth {
  return ZeroIndexedMonth(p.oiMonthIdx - 1);
}

export const RecurrenceFrequency = S.Union(
  S.Literal("daily"),
  S.Literal("weekly")
);
export type RecurrenceFrequency = S.Schema.Type<typeof RecurrenceFrequency>;

export const RecurrenceFrequencyChoice = S.Union(
  S.Literal("none"),
  RecurrenceFrequency
);
export type RecurrenceFrequencyChoice = S.Schema.Type<
  typeof RecurrenceFrequencyChoice
>;

export const SimpleRecurrenceRule = S.Struct({
  frequency: RecurrenceFrequency,
  interval: S.Number,
});

export type SimpleRecurrenceRule = S.Schema.Type<typeof SimpleRecurrenceRule>;

export interface DayOfYear {
  oneIndexedMonth: OneIndexedMonth;
  year: number;
  day: number;
}

export interface MonthOfYear {
  oiMonthIdx: OneIndexedMonth;
  year: number;
}

export type CalendarEventsByDay<T> = {
  doy: DayOfYear;
  events: T[];
};

export type NearbyCalendarMonths<Appt> = {
  prevMonth: CalendarEventsByDay<Appt>[];
  thisMonth: CalendarEventsByDay<Appt>[];
  nextMonth: CalendarEventsByDay<Appt>[];
};
