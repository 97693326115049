import * as S from "@effect/schema/Schema";
import { z } from "zod";
import { AppEnvSchema, type AppEnv } from "./types/app-env.types";

export function parseAppEnv(input: string): AppEnv {
  const parsed = AppEnvSchema.safeParse(input);
  if (parsed.success) {
    return parsed.data;
  } else {
    throw new Error("Invalid AppEnv value");
  }
}

export interface HpProfile {
  bio: string;
  modalities: { name: string; slug: string }[];
}

export const ModalityT = z.object({
  name: z.string(),
  slug: z.string(),
});

export type Modality = z.infer<typeof ModalityT>;
