import { useMutation } from "convex/react";
import { pipe } from "fp-ts/function";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { RD } from "frontend-shared/prelude";
import { api } from "shared/be/convex/_generated/api";
import { FullContainerLoadingSpinner } from "web-shared/src/components/loading";
import { useWebGlobalDisplayVM } from "web-shared/src/web-context";

export const RegisterNewClientForm: React.FC = () => {
  const { register, handleSubmit } = useForm<{ email: string }>();
  const webDisplayVM = useWebGlobalDisplayVM();
  const [submitResult, setSubmitResult] = useState<
    RD.RemoteData<unknown, { msg: string }>
  >(RD.initial);

  const inviteClientFn = useMutation(
    api.Practitioner.PractitionerRelationshipFns.inviteClient
  );

  return (
    <div className="py-16 flex flex-col mt-8 gap-8">
      <form
        className="flex flex-col gap-8"
        onSubmit={handleSubmit((data) => {
          console.log(data);
          setSubmitResult(RD.pending);

          inviteClientFn({
            clientEmail: data.email,
            autoApproveIfClientExists: true,
          }).then((result) => {
            if (result.success) {
              webDisplayVM.globalSheetVM.closeSheet();

              setTimeout(() => {
                webDisplayVM.toastVM.showToast({
                  id: "register-new-client-form",
                  title: result.msg,
                  duration: 4000,
                  action: {
                    label: "Ok",
                    onClick: () => {},
                  },
                });
              }, 300);
            } else {
              setSubmitResult(RD.failure({ msg: result.msg }));
            }
          });
        })}
      >
        <h5>
          {`Enter your client's email address. We'll send them an email with a link to register and notify you when they approve.`}
        </h5>
        <input
          type="email"
          placeholder="email"
          className="text-input"
          autoCapitalize="off"
          autoCorrect="off"
          {...register("email", { required: true })}
        />
        <button disabled={RD.isPending(submitResult)} className="btn-light">
          {pipe(
            submitResult,
            RD.fold(
              () => <div>Add client</div>,
              () => <FullContainerLoadingSpinner />,
              (e: unknown) => <div>{JSON.stringify(e)}</div>,
              () => <div>Successfully added client</div>
            )
          )}
        </button>
      </form>
      {RD.isFailure(submitResult) && (
        <div className="text-red-500">
          {(submitResult.error as { msg: string }).msg}
        </div>
      )}
    </div>
  );
};
