import { Search } from "lucide-react";
import { useObservableEagerState } from "observable-hooks";
import { Rx } from "frontend-shared/prelude";

export const InputContainer: React.FC<{
  label: { text: string; color: "black" | "purple" };
  titleSize?: "sm" | "md";
  children: React.ReactNode;
}> = ({ label, titleSize = "sm", children }) => {
  const textSizeClass = titleSize === "sm" ? "text-sm" : "text-base";
  return (
    <div className="flex flex-col p-6 gap-3 border border-vid-black-200 rounded-[12px]">
      <div className={`${textSizeClass} text-vid-black-900 font-medium`}>
        {label.text}
      </div>
      {children}
    </div>
  );
};

export const TextInput = ({
  label,
  value,
  onChange,
  type = "text",
  placeholder,
  disabled = false,
  maxLength,
  autoFocus = false,
  required = false,
}: {
  label: string;
  value: string;
  onChange: (value: string) => void;
  type?: "text" | "email" | "password";
  placeholder?: string;
  disabled?: boolean;
  maxLength?: number;
  autoFocus?: boolean;
  required?: boolean;
}) => {
  return (
    <div className="text-input flex flex-col">
      <label>{label}</label>
      <input
        type={type}
        value={value}
        placeholder={placeholder}
        disabled={disabled}
        maxLength={maxLength}
        autoFocus={autoFocus}
        required={required}
        autoCorrect={type === "email" || type === "password" ? "off" : "on"}
        autoCapitalize={type === "email" ? "off" : "on"}
        onChange={(e) => {
          onChange(e.target.value);
        }}
      />
    </div>
  );
};

export const NumberedInput = ({
  label,
  value,
  onChange,
}: {
  label: string;
  value: number | null;
  onChange: (value: number | null) => void;
}) => {
  return (
    <div className="text-input flex flex-col">
      <label>{label}</label>
      <input
        type="number"
        value={value === null ? "" : value}
        onChange={(e) => {
          const newValue = e.target.value;
          onChange(newValue === "" ? null : Number(newValue));
        }}
      />
    </div>
  );
};

export const TextInput$ = ({
  label,
  value$,
}: {
  label: string;
  value$: Rx.BehaviorSubject<string>;
}) => {
  const value = useObservableEagerState(value$);
  return (
    <TextInput
      label={label}
      value={value}
      onChange={(value) => value$.next(value)}
    />
  );
};

export const TextAreaInput = ({
  label,
  value,
  onChange,
}: {
  label: string;
  value: string;
  onChange: (value: string) => void;
}) => {
  return (
    <div className="text-input flex flex-col">
      <label>{label}</label>
      <textarea value={value} onChange={(e) => onChange(e.target.value)} />
    </div>
  );
};

export const TextAreaInput$ = ({
  label,
  value$,
}: {
  label: string;
  value$: Rx.BehaviorSubject<string>;
}) => {
  const value = useObservableEagerState(value$);
  return (
    <TextAreaInput
      label={label}
      value={value}
      onChange={(value) => value$.next(value)}
    />
  );
};

export const SearchInput: React.FC<{
  value: string;
  onChange: (value: string) => void;
  className?: string;
  placeholder?: string;
  inputClassName?: string;
}> = ({
  value,
  onChange,
  className = "",
  placeholder = "Search members",
  inputClassName = "",
}) => {
  return (
    <div
      className={`relative bg-vid-black-100 pl-[54px] rounded-full h-[60px] flex items-center ${className}`}
    >
      <div className="absolute left-4">
        <Search className="text-vid-black-400 h-5 w-5" />
      </div>
      <input
        type="text"
        value={value}
        onChange={(e) => onChange(e.target.value)}
        placeholder={placeholder}
        className={`flex items-center bg-transparent outline-none w-full pr-4 text-[18px] font-roboto-flex placeholder:text-vid-black-400 ${inputClassName}`}
      />
    </div>
  );
};
