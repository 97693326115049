import type { ConvexReactClient } from "convex/react";
import * as O from "fp-ts/Option";
import * as TE from "fp-ts/TaskEither";
import { E, RD } from "frontend-shared/prelude";
import * as Rx from "rxjs";
import { FetchableStateAtom } from "../../util";

export class BaseStateMgr {
  convex: ConvexReactClient;

  constructor(p: { convex: ConvexReactClient }) {
    this.convex = p.convex;
  }

  mkFetchableState<V, E = any>(fetchTE: TE.TaskEither<E, V>, initialValue?: V) {
    return new FetchableStateAtom(fetchTE, initialValue);
  }
}

export class BaseFormStateMgr<
  FormData,
  SubmitResult,
  SubmitError extends unknown = unknown,
> extends BaseStateMgr {
  formData$: Rx.BehaviorSubject<FormData>;
  openDropdownId$ = new Rx.BehaviorSubject<keyof FormData | null>(null);
  submitResult$ = new Rx.BehaviorSubject<
    RD.RemoteData<SubmitError, SubmitResult>
  >(RD.initial);

  onSubmit: (formData: FormData) => TE.TaskEither<SubmitError, SubmitResult>;

  constructor(p: {
    convex: ConvexReactClient;
    mbInitialData: O.Option<FormData>;
    defaultData: FormData;
    onSubmit: (formData: FormData) => TE.TaskEither<SubmitError, SubmitResult>;
  }) {
    super({ convex: p.convex });
    this.formData$ = new Rx.BehaviorSubject(
      O.isSome(p.mbInitialData) ? p.mbInitialData.value : p.defaultData
    );
    this.onSubmit = p.onSubmit;
  }

  submit = (onSuccess?: () => void) => {
    this.submitResult$.next(RD.pending);
    console.log("SUBMITTING PAYLOAD ", this.formData$.value);
    this.onSubmit(this.formData$.value)().then((er) => {
      console.log("SUBMIT RESULT ", er);
      this.submitResult$.next(RD.fromEither(er));
      if (E.isRight(er) && onSuccess) {
        onSuccess();
      }
    });
  };

  setFormValue<K extends keyof FormData>(key: K, value: FormData[K]) {
    this.formData$.next({
      ...this.formData$.value,
      [key]: value,
    });
  }

  setOpenDropdownId<K extends keyof FormData>(isOpen: boolean, id: K) {
    console.log("SETTING OPEN DROPDOWN ID ", id, isOpen);
    this.openDropdownId$.next(isOpen ? id : null);
  }
}
