import React from "react";
import { useTypedParams } from "react-router-typesafe-routes/dom";
import { UROUTES } from "shared/routes/u.routes";
import { EditCourseDash } from "web-shared/src/domains/courses/edit-course-dash";
import type { Id } from "shared/be/convex/_generated/dataModel";

export const EditCoursePage: React.FC = () => {
  const params = useTypedParams(UROUTES.COURSES.COURSE_ID);
  const courseId = params.courseId as Id<"courseInfo">;
  return <EditCourseDash courseId={courseId} />;
};
