import { useAction, useMutation } from "convex/react";
import { Match, Option } from "effect";
import {
  combine,
  createEffect,
  createEvent,
  createStore,
  sample,
  type StoreWritable,
} from "effector";
import { useUnit } from "effector-react";
import { debounce } from "patronum";
import { useEffect, useMemo, useState } from "react";
import { epipe } from "frontend-shared/prelude";
import { api } from "shared/be/convex/_generated/api";
import type { Id } from "shared/be/convex/_generated/dataModel";
import type {
  CommunityPostSetup,
  EmbedContent,
} from "shared/be/convex/Community/CommunityDiscussion.Types";
import { type PostMention } from "shared/be/convex/Community/CommunityDiscussion.Types";
import type {
  FileLike,
  FileSchemas,
  UploadFileToCloudinaryResponse,
} from "shared/schemas/file.schemas";
import {
  MediaSelectAndUploadVM,
  type MediaSelectDeps,
} from "../media/media-select-upload.vm";

type SubmitPostAction = (args: {
  postId: Id<"communityPosts">;
  content?: string;
  media?: {
    publicId: string;
    mediaType: FileSchemas.MediaType;
  } | null;
  embedContent?: EmbedContent | null;
}) => Promise<void>;

export type MediaInputState =
  | { _tag: "USER_WILL_UPLOAD" }
  | { _tag: "EMBEDDED_IN_CONTENT"; embedData: EmbedContent };

export class CreateCommunityPostVM {
  readonly contentVM: PostTextContentVM;
  readonly mediaVM: MediaSelectAndUploadVM;

  submitPostEvt = createEvent();
  submitPostFx = createEffect<void, void>();
  $isSubmitting = this.submitPostFx.pending;
  $isSubmitComplete = createStore<boolean>(false);

  readonly $mediaInputState = createStore<MediaInputState>({
    _tag: "USER_WILL_UPLOAD",
  });

  constructor(p: {
    setup: CommunityPostSetup;
    communitySlug: string;
    submitPost: SubmitPostAction;
    mediaUploadDeps: MediaSelectDeps;
    saveContentToServer: (p: {
      textContent: string;
      embedContent: EmbedContent | null;
      mentions: PostMention[];
    }) => Promise<void>;
    getPresignedUploadUrl: (p: {
      mediaType: FileSchemas.MediaType;
    }) => Promise<{
      uploadUrl: string;
    }>;
    getProcessedMediaUrl: (p: {
      publicId: string;
      mediaType: FileSchemas.MediaType;
    }) => Promise<{ processedUrl: string }>;
    onMediaSuccessfullyUploadedToCloudinary: (p: {
      publicId: string;
      format: string;
      mediaType: FileSchemas.MediaType;
    }) => Promise<any>;
    mentionableUsers: Array<{ id: string; name: string }>;
  }) {
    this.contentVM = new PostTextContentVM({
      saveContentToServer: p.saveContentToServer,
      mentionableUsers: p.mentionableUsers,
    });
    this.mediaVM = new MediaSelectAndUploadVM({
      deps: p.mediaUploadDeps,
      getPresignedUrl: p.getPresignedUploadUrl,
      getProcessedMediaUrl: (r) => p.getProcessedMediaUrl(r),
      onMediaSuccessfullyUploadedToCloudinary:
        p.onMediaSuccessfullyUploadedToCloudinary,
    });

    const media$ = combine({
      publicId: this.mediaVM.$publicId,
      mediaType: this.mediaVM.$mediaType,
    }).map(({ publicId, mediaType }) =>
      epipe(
        Option.all([
          Option.fromNullable(publicId),
          Option.fromNullable(mediaType),
        ]),
        Option.map(([publicId, mediaType]) => ({
          publicId,
          mediaType,
        })),
        Option.getOrNull
      )
    );

    const content = this.contentVM.getCombinedData();
    const formData$ = combine({
      content,
      media: media$,
    });

    this.submitPostFx.use(async () => {
      const data = formData$.getState();
      if (!data.content) {
        throw new Error("Content is required");
      }

      await p.submitPost({
        postId: p.setup.postId,
        content: data.content.text,
        media: data.media,
        embedContent: data.content.youtube,
      });
    });

    this.$isSubmitComplete.on(this.submitPostFx.done, () => true);

    this.submitPostEvt.watch(() => {
      this.submitPostFx();
    });

    // Update media input state when YouTube embed is detected
    sample({
      clock: this.contentVM.$youTubeEmbedData,
      fn: (youTubeData) => {
        return {
          _tag: "EMBEDDED_IN_CONTENT",
          embedData: youTubeData,
        } as MediaInputState;
      },
      target: this.$mediaInputState,
    });

    // Clear any uploaded media when switching to embedded content
    sample({
      clock: this.$mediaInputState,
      filter: (state) => state._tag === "EMBEDDED_IN_CONTENT",
      target: this.mediaVM.reset,
    });
  }
}

type SaveContentToServerArgs = {
  textContent: string;
  embedContent: EmbedContent | null;
  mentions: PostMention[];
};

class PostTextContentVM {
  public textChanged = createEvent<string>();
  public $text = createStore<string>("");
  public $debouncedText = createStore<string>("");
  public $youTubeEmbedData = createStore<EmbedContent | null>(null);

  // New mention-related stores and events
  public $mentions = createStore<PostMention[]>([]);
  public $mentionableUsers: StoreWritable<Array<{ id: string; name: string }>>;
  public setMentionableUsers =
    createEvent<Array<{ id: string; name: string }>>();

  public debouncedTextChanged = debounce(this.textChanged, 1000);
  public saveContentEff = createEffect<SaveContentToServerArgs, void>();

  private youtubeRegex =
    /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:shorts\/|(?:watch\?v=))|(?:youtu\.be\/))([a-zA-Z0-9_-]{11})/;
  private mentionRegex = /@([^@\n]+?)(?=@|\n|$)/g;

  private normalizeString(str: string): string {
    return (
      str
        .trim()
        // Remove zero-width spaces and other invisible characters
        .replace(/[\u200B-\u200D\uFEFF]/g, "")
        // Normalize whitespace
        .replace(/\s+/g, " ")
    );
  }

  constructor(p: {
    saveContentToServer: (content: {
      textContent: string;
      embedContent: EmbedContent | null;
      mentions: PostMention[];
    }) => Promise<void>;
    mentionableUsers: Array<{ id: string; name: string }>;
  }) {
    this.$mentionableUsers = createStore<Array<{ id: string; name: string }>>(
      p.mentionableUsers
    );

    // Hook up the saveContentEff to use the provided saveContentToServer function
    this.saveContentEff.use(p.saveContentToServer);

    this.$text.on(this.textChanged, (_, text) => text);
    this.$debouncedText.on(this.debouncedTextChanged, (_, text) => text);

    // Handle mention detection when text changes
    sample({
      clock: this.textChanged,
      source: {
        users: this.$mentionableUsers,
        existingMentions: this.$mentions,
      },
      fn: ({ users, existingMentions }, text) => {
        console.log("[PostTextContentVM] Processing text for mentions:", text);
        const newMentions: PostMention[] = [];
        let match;

        while ((match = this.mentionRegex.exec(text)) !== null) {
          const rawMatchedName = match[1];
          const matchedName = this.normalizeString(rawMatchedName);
          const startIndex = match.index;
          const endIndex = startIndex + match[0].length;

          const user = users.find(
            (u) => this.normalizeString(u.name) === matchedName
          );
          if (user) {
            console.log("[PostTextContentVM] Matched mention to user:", user);
            newMentions.push({
              id: user.id,
              name: user.name,
              startIndex,
              endIndex,
            });
          }
        }

        // Combine existing mentions with new ones, removing duplicates by ID
        const allMentions = [...existingMentions];
        newMentions.forEach((newMention) => {
          const existingIndex = allMentions.findIndex(
            (m) => m.id === newMention.id
          );
          if (existingIndex === -1) {
            allMentions.push(newMention);
          } else {
            allMentions[existingIndex] = newMention;
          }
        });

        return allMentions;
      },
      target: this.$mentions,
    });

    // Save content when text changes (debounced) or mentions change
    sample({
      clock: [this.debouncedTextChanged, this.$mentions],
      source: {
        text: this.$text,
        youTubeEmbed: this.$youTubeEmbedData,
        mentions: this.$mentions,
      },
      fn: ({ text, youTubeEmbed, mentions }): SaveContentToServerArgs => {
        console.log("[PostTextContentVM] Saving with state:", {
          text,
          mentions,
          youTubeEmbed,
        });
        return {
          textContent: text,
          embedContent: youTubeEmbed,
          mentions,
        };
      },
      target: this.saveContentEff,
    });

    // Handle YouTube embeds (existing code)
    sample({
      clock: this.textChanged,
      fn: (text) => {
        const match = this.youtubeRegex.exec(text);
        if (!match) return null;

        const videoId = match[1];
        return {
          url: `https://www.youtube.com/embed/${videoId}`,
          type: "youtube",
        } as EmbedContent;
      },
      target: this.$youTubeEmbedData,
    });
  }

  public getCombinedData() {
    return combine({
      text: this.$debouncedText,
      youtube: this.$youTubeEmbedData,
      mentions: this.$mentions,
    });
  }
}

export function useSetupCreateCommunityPostVM(p: {
  communitySlug: string;
  postSetup: CommunityPostSetup;
  onSuccessSubmit: (p: { communitySlug: string }) => void;
  uploadFileToCloudinary: (p: {
    file: FileLike;
    secureUploadUrl: string;
  }) => Promise<UploadFileToCloudinaryResponse>;
  selectFile: () => Promise<FileLike | null>;
}) {
  const { communitySlug, postSetup, onSuccessSubmit } = p;
  const onMediaSuccessfullyUploadedToCloudinary = useAction(
    api.Community.CommunityScreenFns.onMediaSuccessfullyUploadedToCloudinary
  );

  const saveContentToServer = useMutation(
    api.Community.CommunityScreenFns.onDraftContentChanged
  );

  const getAssumedMediaDownloadUrlThenCache = useAction(
    api.Community.CommunityScreenFns.getAssumedMediaDownloadUrlThenCache
  );

  const onSubmitPost = useMutation(
    api.Community.CommunityScreenFns.onSubmitPost
  );

  useEffect(() => {
    vm.submitPostFx.done.watch(() => {
      onSuccessSubmit({ communitySlug });
    });
  }, []);

  const vm = useMemo(
    () =>
      new CreateCommunityPostVM({
        setup: postSetup,
        communitySlug: communitySlug,
        mentionableUsers: postSetup.taggableMembers,
        submitPost: async () => {
          await onSubmitPost({
            postId: postSetup.postId,
          });
        },
        saveContentToServer: async (content) => {
          await saveContentToServer({
            postId: postSetup.postId,
            newContent: content.textContent,
            embedContent: content.embedContent,
            mentions: content.mentions,
          });
        },
        mediaUploadDeps: {
          selectMedia: p.selectFile,
          getPreviewUrl: (fileLike) => fileLike.uri ?? fileLike.path ?? "",
          uploadToCloudinary: (fileLike, uploadUrl) => {
            return p.uploadFileToCloudinary({
              file: fileLike,
              secureUploadUrl: uploadUrl,
            });
          },
        },
        getPresignedUploadUrl: async (p) => {
          return Match.value(p).pipe(
            Match.when({ mediaType: "image" }, () => ({
              uploadUrl: postSetup.imageUploadUrl,
            })),
            Match.when({ mediaType: "video" }, () => ({
              uploadUrl: postSetup.videoUploadUrl,
            })),
            Match.when({ mediaType: "audio" }, () => ({
              uploadUrl: "TODO",
            })),
            Match.exhaustive
          );
        },
        onMediaSuccessfullyUploadedToCloudinary: async (p) => {
          await onMediaSuccessfullyUploadedToCloudinary({
            postId: postSetup.postId,
            publicId: p.publicId,
            format: "TODO",
            mediaType: p.mediaType,
          });
        },
        getProcessedMediaUrl: async (p) => {
          const { url } = await getAssumedMediaDownloadUrlThenCache({
            postId: postSetup.postId,
            publicId: p.publicId,
            mediaType: p.mediaType,
          });

          return {
            processedUrl: url,
          };
        },
      }),
    [postSetup]
  );
  const mediaInputState = useUnit(vm.$mediaInputState);

  return {
    vm,
    mediaInputState,
  };
}

export function useInitCreatePostForm(p: { communitySlug: string }) {
  const { communitySlug } = p;
  const onOpenCreatePostForm = useAction(
    api.Community.CommunityScreenFns.onOpenCreatePostForm
  );
  const [postSetup, setupPostSetup] = useState<CommunityPostSetup | undefined>(
    undefined
  );

  useEffect(() => {
    onOpenCreatePostForm({ communitySlug }).then((p) => {
      setupPostSetup(p);
    });
  }, []);

  return {
    postSetup,
  };
}
