import { Match } from "effect";
import { useCommunityEventDetailsVM } from "frontend-shared/src/domains/community/event-details.vm";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import type { Id } from "shared/be/convex/_generated/dataModel";
import type {
  CalEventInstanceId,
  CommunityEventDetailsPanelST,
} from "shared/be/convex/Calendar/Calendar.Types";
import { UROUTES } from "shared/routes/u.routes";
import { AvatarCircles } from "web-shared/src/components/avatar.tc";
import {
  InversePrimaryButton,
  PrimaryButton,
} from "web-shared/src/components/button";
import { FullContainerLoadingSpinner } from "web-shared/src/components/loading";
import { ThreeDotsMenu } from "web-shared/src/components/ui/dropdown-menu";
import { useWebGlobalDisplayVM } from "../../../../web-context";

export const CommunityEventDetailsPanel: React.FC<{
  instanceId: CalEventInstanceId;
  communitySlug: string;
  closePanel: (
    p: {
      message: string;
      closePanel: boolean;
      reload: boolean;
    } | null
  ) => void;
}> = ({ instanceId, communitySlug, closePanel }) => {
  const nav = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const webDisplayVM = useWebGlobalDisplayVM();

  const {
    fullyRemoveCommunityCalEvent,
    removeCommunityCalEventSingleInstance,
    onSubmitCta,
    inviteAllInCommunityToEvent,
    getEventShareableLink,
    panelData,
  } = useCommunityEventDetailsVM(instanceId);

  const onCardClick = (panelData: CommunityEventDetailsPanelST) => {
    setIsSubmitting(true);
    onSubmitCta({
      templateId: panelData.templateId as Id<"calEventTemplates">,
      instanceId,
      cta: panelData.button.cta,
    })
      .then((r) => {
        console.log("RESULT OF SUBMIT CTA!!! ", r);
        closePanel(null);

        const nextAction: () => void = Match.value(r).pipe(
          Match.when({ nextAction: "GO_TO_SESSION" }, ({ baseSessionId }) => {
            return () =>
              nav(
                UROUTES.SESSIONS.SESSION_ID.RTC.buildPath({
                  sessionId: baseSessionId,
                })
              );
          }),
          Match.when({ nextAction: "CLOSE_AND_REFRESH" }, () => {
            return () =>
              closePanel({
                message: "",
                closePanel: true,
                reload: true,
              });
          }),
          Match.when({ nextAction: "DO_NOTHING" }, () => {
            return () => {};
          }),
          Match.exhaustive
        );

        nextAction();
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  if (panelData === undefined) {
    return <FullContainerLoadingSpinner />;
  }

  if (panelData == null) {
    return <div>Event not found</div>;
  }

  return (
    <div className="flex flex-col gap-4 p-4">
      <div className="flex justify-end px-4">
        <ThreeDotsMenu
          size={30}
          menuItems={[
            {
              label: "Remove this event from cal",
              onClick: () => {
                closePanel({
                  message: "Removed event",
                  closePanel: true,
                  reload: true,
                });
                removeCommunityCalEventSingleInstance({
                  instanceId,
                }).then(() => {});
              },
            },
            {
              label: "Remove all recurring events",
              onClick: () => {
                closePanel({
                  message: "Removed events",
                  closePanel: true,
                  reload: true,
                });
                fullyRemoveCommunityCalEvent({
                  templateId: panelData.templateId as Id<"calEventTemplates">,
                }).then(() => {});
                webDisplayVM.reloadDash();
              },
            },
            {
              label: "Invite all in community",
              onClick: () => {
                closePanel({
                  message: "Invited all in community",
                  closePanel: true,
                  reload: true,
                });
                inviteAllInCommunityToEvent({
                  communitySlug,
                  instanceId,
                }).then(() => {});
              },
            },
            {
              label: "Get shareable link",
              onClick: () => {
                getEventShareableLink({
                  instanceId,
                  communitySlug,
                }).then((r) => {
                  navigator.clipboard.writeText(r.inviteUrl);
                  closePanel({
                    message: "Copied shareable link to clipboard",
                    closePanel: false,
                    reload: false,
                  });
                });
              },
            },
          ]}
        />
      </div>
      <div className="text-2xl font-bold">{panelData.title}</div>
      <div className="text-md text-vid-black-500">{panelData.description}</div>
      {panelData.attendees.length > 0 && (
        <AvatarCircles
          sources={panelData.attendees.map((a) => a.user.profilePhoto)}
          size={44}
        />
      )}
      <div className="text-md text-vid-black-500">
        {panelData.occurenceMessage}
      </div>
      <div>
        {Match.value(panelData.button).pipe(
          Match.when({ style: "REGULAR" }, () => (
            <PrimaryButton
              title={panelData.button.title}
              isLoading={isSubmitting}
              onClick={() => {
                onCardClick(panelData);
              }}
            />
          )),
          Match.when({ style: "INVERSE" }, () => (
            <InversePrimaryButton
              title={panelData.button.title}
              isLoading={isSubmitting}
              onClick={() => {
                onCardClick(panelData);
              }}
            />
          )),
          Match.exhaustive
        )}
      </div>
    </div>
  );
};
