import { useQuery } from "convex/react";
import { api } from "shared/be/convex/_generated/api";
import type { Id } from "shared/be/convex/_generated/dataModel";
import { InvoiceDetailView } from "web-shared/src/domains/invoices/invoice-detail-view";

export const InvoicesTab: React.FC<{
  sessionId: Id<"rtcSession">;
}> = ({ sessionId }) => {
  const invoice = useQuery(api.Screens.U.Sessions.ReviewScreenFns.getInvoice, {
    baseSessionId: sessionId,
  });

  if (invoice === undefined) {
    return null;
  }

  return <InvoiceDetailView invoice={invoice} />;
};
