import { useAuthActions } from "@convex-dev/auth/react";
import { useQuery } from "convex/react";
import { useOnce } from "frontend-shared/src/util";
import { Search } from "lucide-react";
import React, { useMemo } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { api } from "shared/be/convex/_generated/api";
import type { SimpleUser } from "shared/be/convex/User/User.Types";
import { UROUTES } from "shared/routes/u.routes";
import { ImageSrc } from "shared/types/miscellaneous.types";
import { PrimaryButton } from "web-shared/src/components/button";
import { DashboardLayout } from "web-shared/src/components/dashboard/dashboard-layout";
import {
  CalendarIcon,
  ClientsIcon,
  CommunityIcon,
  InvoicesIcon,
  SettingsIcon,
} from "web-shared/src/components/dashboard/main-nav-links";
import { StartASessionForm } from "web-shared/src/domains/hp/clients/start-a-session-form.fc";
import {
  useMe,
  useUMgr,
  useWebGlobalDisplayVM,
} from "web-shared/src/web-context";
import { HpStateContext, PortalWebStateMgr } from "./hp.webstatemgr";

interface HpDashboardLayoutDashProps {
  stateMgr: PortalWebStateMgr;
  signOut: () => Promise<void>;
  location: { pathname: string };
  children: React.ReactNode;
}

export const HpDashboardLayoutDash: React.FC<HpDashboardLayoutDashProps> = ({
  stateMgr,
  signOut,
  location,
  children,
}) => {
  const me = useMe();
  const webDisplayVM = useWebGlobalDisplayVM();

  return (
    <HpStateContext.Provider value={stateMgr}>
      <DashboardLayout
        stateMgr={stateMgr.dashboardState}
        mainContent={children}
        navbar={{
          currentDashboardPage: getCurrentDashboardPageFromUrl(
            location.pathname
          ),
          middleSection: (
            <div className="flex-1 flex justify-center items-center">
              <DashboardSearchInput />
            </div>
          ),
          profileButton: {
            to: UROUTES.HP.MY.DASHBOARD.SETTINGS.ACCOUNT.path,
            profilePhoto: me.profilePhoto
              ? ImageSrc.fromURL(me.profilePhoto)
              : null,
          },
          hamburger: {
            links: [
              {
                to: UROUTES.HP.MY.DASHBOARD.CLIENTS.path,
                name: "Dashboard",
                icon: () => <DashboardIcon />,
              },
              {
                to: UROUTES.HP.MY.DASHBOARD.CLIENTS.path,
                name: "Clients",
                icon: (isSelected) => <ClientsIcon isSelected={isSelected} />,
              },
              {
                to: UROUTES.HP.MY.DASHBOARD.CALENDAR.path,
                name: "Calendar",
                icon: (isSelected) => <CalendarIcon isSelected={isSelected} />,
              },
              {
                to: UROUTES.HP.MY.DASHBOARD.INVOICES.path,
                name: "Invoices",
                icon: (isSelected) => <InvoicesIcon isSelected={isSelected} />,
              },
              {
                to: UROUTES.HP.MY.DASHBOARD.SETTINGS.ACCOUNT.path,
                name: "Settings",
                icon: (isSelected) => <SettingsIcon isSelected={isSelected} />,
              },
            ],
            onSignout: () => {
              signOut().then((_) => {
                window.location.href = "/";
              });
            },
          },
        }}
        leftMenu={{
          onLogoClick: () => {
            window.location.href = UROUTES.HP.MY.DASHBOARD.CLIENTS.path;
          },
          extraTop: <StartSessionButton />,
          topLinks: [
            {
              to: UROUTES.HP.MY.DASHBOARD.CLIENTS.path,
              name: "Clients",
              icon: (isSelected) => <ClientsIcon isSelected={isSelected} />,
            },
            {
              to: UROUTES.HP.MY.DASHBOARD.COMMUNITY_TAB.COMMUNITIES.path,
              name: "Community",
              icon: (isSelected) => <CommunityIcon isSelected={isSelected} />,
            },
            {
              to: UROUTES.HP.MY.DASHBOARD.CALENDAR.path,
              name: "Calendar",
              icon: (isSelected) => <CalendarIcon isSelected={isSelected} />,
            },
            {
              to: UROUTES.HP.MY.DASHBOARD.INVOICES.path,
              name: "Invoices",
              icon: (isSelected) => <InvoicesIcon isSelected={isSelected} />,
            },
            {
              to: UROUTES.HP.MY.DASHBOARD.SETTINGS.ACCOUNT.path,
              name: "Settings",
              icon: (isSelected) => <SettingsIcon isSelected={isSelected} />,
            },
          ],
        }}
      />
    </HpStateContext.Provider>
  );
};

const DashboardSearchInput: React.FC = () => {
  const webStateMgr = useWebGlobalDisplayVM();
  return (
    <div
      className={`relative bg-vid-black-100 pl-[54px] rounded-full h-[60px] w-[500px] flex items-center`}
      onClick={() => {
        webStateMgr.globalSearchCommandVM.clickSearchbarEvt();
      }}
    >
      <div className="absolute left-4">
        <Search className="text-vid-black-400 h-5 w-5" />
      </div>
      <div
        className={`flex items-center bg-transparent outline-none w-full pr-4 text-[18px] font-roboto-flex text-vid-black-400`}
      >
        Search
      </div>
    </div>
  );
};

function getCurrentDashboardPageFromUrl(url: string) {
  const dashboardPageRegex = /\/hp\/my\/dashboard\/(\w+)(\/|$)/;
  const match = url.match(dashboardPageRegex);
  if (match && match[1]) {
    return match[1].charAt(0).toUpperCase() + match[1].slice(1);
  }
  return "Dashboard"; // Default return if no specific page is found
}

const StartSessionButton: React.FC = () => {
  const webDisplayVM = useWebGlobalDisplayVM();

  const myActiveClients = useQuery(
    api.Screens.Hp.Dashboard.ClientsScreenFns.getMyActiveClients
  );

  const myClients = useMemo(() => {
    return myActiveClients
      ? myActiveClients.clients.map(
          (c) =>
            ({
              id: c.clientId,
              name: c.name,
              profilePhoto: c.profilePhoto,
            }) satisfies SimpleUser
        )
      : [];
  }, [myActiveClients]);

  return (
    <PrimaryButton
      title="Start session"
      onClick={() => {
        webDisplayVM.globalSheetVM.openSheet(
          {
            title: "Start a session",
            type: { tag: "create", icon: "profile-add" },
          },
          <StartASessionForm
            onClose={() => {
              window.location.reload();
            }}
            forClient={{ _tag: "SELECT", myClients }}
          />
        );
      }}
    />
  );
};

function DashboardIcon() {
  return (
    <svg width="24" height="25" fill="none" viewBox="0 0 24 25">
      <g>
        <g
          stroke="#690DFF"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        >
          <path d="M10.5 20.23V4.43c0-1.5-.64-2.1-2.23-2.1H4.23c-1.59 0-2.23.6-2.23 2.1v15.8c0 1.5.64 2.1 2.23 2.1h4.04c1.59 0 2.23-.6 2.23-2.1z"></path>
          <path d="M22 8.85V4.31c0-1.41-.64-1.98-2.23-1.98h-4.04c-1.59 0-2.23.57-2.23 1.98v4.53c0 1.42.64 1.98 2.23 1.98h4.04c1.59.01 2.23-.56 2.23-1.97z"></path>
          <path d="M22 20.1v-4.04c0-1.59-.64-2.23-2.23-2.23h-4.04c-1.59 0-2.23.64-2.23 2.23v4.04c0 1.59.64 2.23 2.23 2.23h4.04c1.59 0 2.23-.64 2.23-2.23z"></path>
        </g>
      </g>
    </svg>
  );
}
