import React from "react";
import arrowDownSvg from "web-shared/src/assets/images/arrow-down.svg";

interface FormFieldContainerProps {
  label?: string | React.ReactNode;
  error?: string;
  labelClassName?: string;
  children: React.ReactNode;
  onClick?: () => void;
  showDownArrow?: boolean;
}

export const FormFieldContainer: React.FC<FormFieldContainerProps> = ({
  label,
  error,
  labelClassName = "",
  children,
  onClick,
  showDownArrow = true,
}) => {
  return (
    <div
      className={`w-full border border-vid-black-200 px-6 py-[18px] rounded-[12px] flex items-center`}
      onClick={onClick}
    >
      <div className={`flex-1 flex flex-col justify-between`}>
        {label && (
          <label
            className={`text-vid-purple font-roboto-flex font-semibold ${labelClassName}`}
          >
            {label}
          </label>
        )}
        {children}
        {error && <p className="mt-1 text-sm text-red-500">{error}</p>}
      </div>
      <div>
        {showDownArrow && <img src={arrowDownSvg} className="w-5 h-5" />}
      </div>
    </div>
  );
};
