import { CpDashboardCalendarPage } from "@pages/cp/dashboard/calendar.page";
import { RouteObject } from "react-router-dom";
import { CPROUTES } from "shared/routes/cp.routes";
import { UROUTES } from "shared/routes/u.routes";
import {
  CpSettingsAccountDash,
  CpSettingsLayout,
} from "web-shared/src/domains/cp/settings-dash";
import {
  HpInfoDashboardLayout,
  PastSessionsWithHp,
} from "web-shared/src/domains/cp/hp-info-dashboard";
import { CpDashboardCommunityDash } from "web-shared/src/domains/cp/community/community-dash";
import { CpCommunityDiscussion } from "web-shared/src/domains/cp/community/community-discussion";
import { CpLayout } from "../pages/cp/cp.layout";
import { CpDashboardLayout } from "../pages/cp/dashboard/dashboard.layout";
import { CpDashboardHomePage } from "../pages/cp/dashboard/home.page";

console.log(
  "CPROUTES! ",
  CPROUTES.DASHBOARD.ROOT.relativePath,
  CPROUTES.DASHBOARD.CHILDREN.HOME.relativePath
);

export const cpRoutes: RouteObject = {
  path: "",
  element: <CpLayout />,
  children: [
    {
      path: CPROUTES.ROOT.DASHBOARD.relativePath,
      element: <CpDashboardLayout />,
      children: [
        {
          path: CPROUTES.DASHBOARD.CHILDREN.HOME.relativePath,
          element: <CpDashboardHomePage />,
        },
        {
          path: CPROUTES.DASHBOARD.CHILDREN.HPS.HP_ID.relativePath,
          element: <HpInfoDashboardLayout />,
          children: [
            {
              path: "past-sessions",
              element: <PastSessionsWithHp />,
            },
          ],
        },
        {
          path: CPROUTES.DASHBOARD.CHILDREN.CALENDAR.relativePath,
          element: <CpDashboardCalendarPage />,
        },
        {
          path: CPROUTES.DASHBOARD.CHILDREN.COMMUNITY_TAB.COMMUNITIES
            .relativePath,
          element: <CpDashboardCommunityDash />,
          children: [
            {
              path: CPROUTES.DASHBOARD.CHILDREN.COMMUNITY_TAB.COMMUNITIES
                .COMMUNITY.relativePath,
              children: [
                {
                  path: CPROUTES.DASHBOARD.CHILDREN.COMMUNITY_TAB.COMMUNITIES
                    .COMMUNITY.DISCUSSION.relativePath,
                  element: <CpCommunityDiscussion />,
                },
              ],
            },
          ],
        },
        {
          path: CPROUTES.DASHBOARD.CHILDREN.SETTINGS.relativePath,
          element: (
            <CpSettingsLayout
              topTabs={[
                { title: "Account", path: "account", param: "account" },
              ]}
            />
          ),
          children: [
            {
              path: "account",
              element: <CpSettingsAccountDash />,
            },
          ],
        },
      ],
    },
  ],
};
