import * as React from "react";
import { useEffect, useRef, useState } from "react";

type MentionData = {
  id: string;
  display: string;
};

type MentionableTextareaProps = {
  value: string;
  onChange: (value: string) => void;
  data: MentionData[];
  readOnly?: boolean;
  placeholder?: string;
  style?: React.CSSProperties;
};

export const MentionableTextarea: React.FC<MentionableTextareaProps> = ({
  value,
  onChange,
  data,
  readOnly = false,
  placeholder,
  style,
}) => {
  const [mentionQuery, setMentionQuery] = useState<string | null>(null);
  const [suggestions, setSuggestions] = useState<MentionData[]>([]);
  const [cursorPosition, setCursorPosition] = useState<{
    top: number;
    left: number;
  } | null>(null);
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const suggestionsRef = useRef<HTMLDivElement>(null);
  const overlayRef = useRef<HTMLDivElement>(null);

  // Function to render highlighted content
  const renderHighlightedContent = () => {
    let content = value;
    // const regex = /@(\w+)/g;
    // const regex = /@([\w]+(?:\s[\w]+)*)/g;
    const regex = /@([\w]+(?:\s[\w]+)*)\u200B?(?=\s|$)/g;

    let match;
    let lastIndex = 0;
    const parts = [];

    while ((match = regex.exec(content)) !== null) {
      const mentionText = match[0];
      const name = match[1];

      // Check if this mention matches a user or is a partial match
      const isValidMention = data.some((user) => user.display === name);
      const isPartialMention =
        mentionQuery !== null &&
        data.some((user) =>
          user.display.toLowerCase().startsWith(name.toLowerCase())
        );

      if (isValidMention || isPartialMention) {
        // Add text before mention
        if (match.index > lastIndex) {
          parts.push(
            <span key={`text-${lastIndex}`}>
              {content.slice(lastIndex, match.index)}
            </span>
          );
        }

        // Add highlighted mention
        parts.push(
          <span
            key={`mention-${match.index}`}
            className="text-purple-600 font-medium"
          >
            {mentionText}
          </span>
        );

        lastIndex = match.index + mentionText.length;
      } else {
        if (match.index > lastIndex) {
          parts.push(
            <span key={`text-${lastIndex}`}>
              {content.slice(lastIndex, match.index)}
            </span>
          );
        }
        parts.push(<span key={`text-${match.index}`}>{mentionText}</span>);
        lastIndex = match.index + mentionText.length;
      }
    }

    // Add remaining text
    if (lastIndex < content.length) {
      parts.push(
        <span key={`text-${lastIndex}`}>{content.slice(lastIndex)}</span>
      );
    }

    return parts;
  };

  useEffect(() => {
    if (mentionQuery !== null) {
      console.log("[MentionableTextarea] Filtering with query:", mentionQuery);
      console.log("[MentionableTextarea] Available data:", data);
      const filtered = data
        .filter((item) =>
          item.display.toLowerCase().includes(mentionQuery.toLowerCase())
        )
        .slice(0, 5);
      console.log("[MentionableTextarea] Filtered suggestions:", filtered);
      setSuggestions(filtered);
    } else {
      setSuggestions([]);
    }
  }, [mentionQuery, data]);

  const handleInput = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newValue = e.target.value;
    const cursorPos = e.target.selectionStart;

    // Find if we're in the middle of typing a mention
    let mentionStart = newValue.lastIndexOf("@", cursorPos);
    if (mentionStart !== -1) {
      const textAfterMention = newValue.slice(mentionStart + 1, cursorPos);
      console.log("[MentionableTextarea] Found @ at position:", mentionStart);
      console.log("[MentionableTextarea] Text after @:", textAfterMention);
      if (!textAfterMention.includes(" ") && textAfterMention.length > 0) {
        setMentionQuery(textAfterMention);
        // Calculate position for suggestions dropdown
        if (textareaRef.current) {
          const { top, left } = getCaretCoordinates(
            textareaRef.current,
            mentionStart
          );
          console.log("[MentionableTextarea] Setting dropdown position:", {
            top,
            left,
          });
          setCursorPosition({ top, left });
        }
      } else {
        setMentionQuery(null);
        setCursorPosition(null);
      }
    } else {
      setMentionQuery(null);
      setCursorPosition(null);
    }

    onChange(newValue);
  };

  const insertMention = (mention: MentionData) => {
    if (!textareaRef.current) return;

    const cursorPos = textareaRef.current.selectionStart;
    const mentionStart = value.lastIndexOf("@", cursorPos);

    // Add zero-width space after mention
    const newValue =
      value.slice(0, mentionStart) +
      `@${mention.display}\u200B ` +
      value.slice(cursorPos);

    onChange(newValue);
    setMentionQuery(null);
    setCursorPosition(null);
  };

  return (
    <div className="relative w-full">
      <div className="relative">
        <textarea
          ref={textareaRef}
          value={value}
          onChange={handleInput}
          readOnly={readOnly}
          placeholder={placeholder}
          className="w-full resize-none focus:outline-none focus:ring-0 absolute inset-0"
          style={{
            ...style,
            color: "transparent",
            caretColor: "black",
            backgroundColor: "transparent",
          }}
        />
        <div
          ref={overlayRef}
          className="w-full pointer-events-none whitespace-pre-wrap break-words text-black"
          style={{
            ...style,
            minHeight: style?.minHeight || "100px",
          }}
        >
          {renderHighlightedContent()}
        </div>
      </div>
      {suggestions.length > 0 && cursorPosition && (
        <div
          ref={suggestionsRef}
          className="fixed z-[9999] bg-white shadow-lg rounded-lg border border-gray-200 max-h-48 overflow-y-auto min-w-[200px]"
          style={{
            top: `${cursorPosition.top + 20}px`,
            left: Math.min(cursorPosition.left, window.innerWidth - 220),
          }}
        >
          <div className="p-2 text-sm text-gray-500 border-b">
            Mentionable users ({suggestions.length})
          </div>
          {suggestions.map((suggestion) => (
            <div
              key={suggestion.id}
              className="px-4 py-2 hover:bg-gray-100 cursor-pointer flex items-center gap-2"
              onClick={() => insertMention(suggestion)}
            >
              <span className="text-purple-600">@</span>
              {suggestion.display}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

// Helper function to get caret coordinates
function getCaretCoordinates(element: HTMLTextAreaElement, position: number) {
  const rect = element.getBoundingClientRect();
  const div = document.createElement("div");
  const text = element.value.substr(0, position);

  // Copy styles from textarea
  const computed = window.getComputedStyle(element);
  const properties = [
    "fontFamily",
    "fontSize",
    "fontWeight",
    "paddingLeft",
    "paddingTop",
    "lineHeight",
    "whiteSpace",
    "wordWrap",
    "wordBreak",
    "border",
    "borderWidth",
  ];

  properties.forEach((prop) => {
    // @ts-ignore
    div.style[prop] = computed[prop];
  });

  // Match textarea width and set content
  div.style.width = computed.width;
  div.textContent = text;

  // Temporarily append to measure
  document.body.appendChild(div);
  const { offsetWidth: textWidth, offsetHeight: textHeight } = div;
  document.body.removeChild(div);

  return {
    left:
      rect.left +
      (textWidth % parseInt(computed.width)) +
      parseInt(computed.paddingLeft),
    top: rect.top + textHeight - parseInt(computed.paddingTop),
  };
}
