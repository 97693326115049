import { useLiveRoomVM } from "frontend-shared/src/sessions/live-room.vm";
import { NoteToolsPanel } from "web-shared/src/domains/sessions/rtc/rooms/live/menu/tools/notes-tools-panel";

export const AnnotationToolsPanel: React.FC = () => {
  const liveRoomVM = useLiveRoomVM();

  return (
    <div className="flex flex-col gap-4 self-stretch">
      <NoteToolsPanel baseSessionId={liveRoomVM.baseSessionId} />
    </div>
  );
};
