import { useMutation, useQuery } from "convex/react";
import { Match } from "effect";
import { useOnce } from "frontend-shared/src/util";
import { useState } from "react";
import { Outlet } from "react-router-dom";
import { useTypedParams } from "react-router-typesafe-routes/dom";
import type { HpGlobalProfile } from "shared/be/convex/User/Hp/Hp.Types";
import { api } from "shared/be/convex/_generated/api";
import type { Id } from "shared/be/convex/_generated/dataModel";
import { UROUTES } from "shared/routes/u.routes";
import {
  InversePrimaryButton,
  PrimaryButton,
} from "web-shared/src/components/button";
import { InfoSection } from "web-shared/src/components/info-section";
import { FullContainerLoadingSpinner } from "web-shared/src/components/loading";
import { HorizontalTabs } from "web-shared/src/components/tabs";
import {
  CreateAProfileCard,
  CreateAProfileDialog,
} from "web-shared/src/domains/anon/create-a-profile";
import {
  AvailabilityCalendar,
  AvailabilityCalendarVM,
} from "web-shared/src/domains/hp/availability-calendar";
import { MyCommunitiesProfileCard } from "web-shared/src/domains/user/my-profile-card";
import { useUMgr } from "web-shared/src/web-context";
import { CommunityPostCvx } from "./community/community-post/community-discussion";
import { CommunityDashLayout, SettingsButton } from "./shared/dash.components";

function useGlobalProfile() {
  const { userId } = useTypedParams(
    UROUTES.HP.MY.DASHBOARD.COMMUNITY_TAB.GLOBAL.USER_PROFILE.USER_ID
  );
  return useQuery(api.Community.CommunityMemberScreenFns.getAboutInfo, {
    userId: userId as Id<"users">,
  });
}

export const GlobalPractitionerDashAuthedView: React.FC = () => {
  const globalProfile = useGlobalProfile();
  const uMgr = useUMgr();
  const onMessageClickMutation = useMutation(
    api.Community.CommunityMemberScreenFns.onMessageButtonClick
  );

  const onFollowClickMutation = useMutation(
    api.Community.CommunityUsersFns.onFollowClick
  );

  const onMessageClick = (p: { forUserId: Id<"users"> }) => {
    onMessageClickMutation({
      recipientId: p.forUserId,
    }).then((roomId) => {
      uMgr.messengerVM.setOpenStateEvt("OPEN");
      uMgr.messengerVM.setViewStateEvt({
        _tag: "CHAT_ROOM",
        roomId,
      });
    });
  };

  if (globalProfile === undefined) {
    return <FullContainerLoadingSpinner />;
  }

  return (
    <GlobalPractitionerDashView
      profile={globalProfile}
      onMessageClick={onMessageClick}
      onFollowClick={(args) => {
        onFollowClickMutation({
          userIWantToFollow: args.forUserId,
          action: args.action,
        });
      }}
    />
  );
};

export const GlobalPractitionerDashUnAuthedView: React.FC = () => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const globalProfile = useGlobalProfile();

  if (globalProfile === undefined) {
    return <FullContainerLoadingSpinner />;
  }

  return (
    <>
      <GlobalPractitionerDashView
        profile={globalProfile}
        onMessageClick={() => {
          setDialogOpen(true);
        }}
        onFollowClick={() => {
          setDialogOpen(true);
        }}
      />
      <CreateAProfileDialog
        open={dialogOpen}
        onOpenChange={setDialogOpen}
        trigger={<button className="hidden" />}
      />
    </>
  );
};

const GlobalPractitionerDashView: React.FC<{
  profile: HpGlobalProfile;
  onMessageClick: (p: { forUserId: Id<"users"> }) => void;
  onFollowClick: (p: {
    forUserId: Id<"users">;
    action: "follow" | "unfollow";
  }) => void;
}> = ({ profile, onMessageClick, onFollowClick }) => {
  const amIFollowingThisUser = useQuery(
    api.Community.CommunityUsersFns.amIFollowingThisUser,
    {
      userIWantToFollow: profile.simpleProfile.id,
    }
  );

  if (amIFollowingThisUser === undefined) {
    return <FullContainerLoadingSpinner />;
  }

  return (
    <CommunityDashLayout
      isFullWidth={false}
      topBanner={{
        opacity: 1,
        // maxHeight: "100px",
        props: {
          communityColorName: "blue",
          circleImageUrl: profile.simpleProfile.profilePhoto,
          title: {
            _tag: "COMPONENT",
            component: (
              <div className="flex items-center gap-4">
                <PurpleCheckIcon />
                <h2 className="text-vid-black-900 text-[36px]">
                  {profile.simpleProfile.name}
                </h2>
              </div>
            ),
          },
          nextToTitleFc: <></>,
          rightView: (
            <DashButtonRightView
              forUserId={profile.simpleProfile.id}
              amIFollowingThisUser={amIFollowingThisUser}
              onMessageClick={onMessageClick}
              onFollowClick={onFollowClick}
            />
          ),
        },
      }}
    >
      <div className="flex items-center gap-40">
        <HorizontalTabs
          tabs={[
            { title: "Discussion", linkTo: "discussion" },
            { title: "Events", linkTo: "events" },
            { title: "About", linkTo: "about" },
            { title: "Schedule", linkTo: "schedule" },
          ]}
        />
        <SettingsButton
          menuItems={[
            ({ active }) => (
              <div
                className={`p-4 cursor-pointer ${active ? "bg-vid-black-100" : ""}`}
                onClick={() => {
                  onFollowClick({
                    forUserId: profile.simpleProfile.id,
                    action: amIFollowingThisUser ? "unfollow" : "follow",
                  });
                }}
              >
                {amIFollowingThisUser ? "Following" : "Follow"}
              </div>
            ),
          ]}
        />
      </div>
      <div className="flex-1 flex flex-col">
        <Outlet />
      </div>
    </CommunityDashLayout>
  );
};

const DashButtonRightView: React.FC<{
  amIFollowingThisUser: boolean;
  forUserId: Id<"users">;
  onFollowClick: (p: {
    forUserId: Id<"users">;
    action: "follow" | "unfollow";
  }) => void;
  onMessageClick: (p: { forUserId: Id<"users"> }) => void;
}> = ({ amIFollowingThisUser, forUserId, onFollowClick, onMessageClick }) => {
  return (
    <div className="flex items-center gap-3 mt-16">
      <PrimaryButton
        title={amIFollowingThisUser ? "Following" : "Follow"}
        mode={amIFollowingThisUser ? "success" : "primary"}
        onClick={() => {
          onFollowClick({
            forUserId,
            action: amIFollowingThisUser ? "unfollow" : "follow",
          });
        }}
        dims={{ width: 167 }}
      />
      <InversePrimaryButton
        title="Message"
        onClick={() => onMessageClick({ forUserId })}
      />
    </div>
  );
};

const ContentContainer: React.FC<{
  children: React.ReactNode;
  rightContent?:
    | { _tag: "CUSTOM"; content: React.ReactNode }
    | { _tag: "USER_PROFILE_CARD"; profile: HpGlobalProfile | undefined }
    | { _tag: "NONE" };
}> = ({ children, rightContent = { _tag: "CUSTOM", content: undefined } }) => {
  return (
    <div className="flex-1 flex  gap-4  mt-6">
      <div className="flex-1 flex flex-col">{children}</div>
      {Match.value(rightContent).pipe(
        Match.when({ _tag: "CUSTOM" }, ({ content }) => content),
        Match.when({ _tag: "USER_PROFILE_CARD" }, ({ profile }) => (
          <MyCommunitiesProfileCard globalProfile={profile} />
        )),
        Match.when({ _tag: "NONE" }, () => null),
        Match.exhaustive
      )}
    </div>
  );
};

export const DiscussionTab: React.FC = () => {
  const { userId } = useTypedParams(
    UROUTES.HP.MY.DASHBOARD.COMMUNITY_TAB.GLOBAL.USER_PROFILE.USER_ID
  );
  const resp = useQuery(
    api.Community.CommunityMemberScreenFns.onPostsTabMount,
    {
      userId: userId as Id<"users">,
    }
  );

  if (resp === undefined) {
    return <FullContainerLoadingSpinner />;
  }

  return (
    <ContentContainer
      rightContent={
        resp.showAnonCreateProfileCard
          ? { _tag: "CUSTOM", content: <CreateAProfileCard /> }
          : { _tag: "CUSTOM", content: <CreateAProfileCard /> } // eventually should be this maybe { _tag: "NONE" }
      }
    >
      {resp.posts.length === 0 ? (
        <div className="text-vid-black-500 mt-4  text-lg">
          Nothing posted yet
        </div>
      ) : (
        resp.posts.map((post) => (
          <CommunityPostCvx key={post._id} enrichedPost={post} />
        ))
      )}
    </ContentContainer>
  );
};

export const AboutTab: React.FC = () => {
  const { userId } = useTypedParams(
    UROUTES.HP.MY.DASHBOARD.COMMUNITY_TAB.GLOBAL.USER_PROFILE.USER_ID
  );
  const profile = useQuery(
    api.Community.CommunityMemberScreenFns.getAboutInfo,
    {
      userId: userId as Id<"users">,
    }
  );

  if (profile === undefined) {
    return <FullContainerLoadingSpinner />;
  }

  return (
    <ContentContainer rightContent={{ _tag: "USER_PROFILE_CARD", profile }}>
      <InfoSection
        header={{ _tag: "STANDARD", title: "About", rightHeader: undefined }}
      >
        <div className="self-center w-[544px] text-vid-black-700">
          {profile.bio}
        </div>
      </InfoSection>
    </ContentContainer>
  );
};

export const EventsTab: React.FC = () => {
  const { userId } = useTypedParams(
    UROUTES.HP.MY.DASHBOARD.COMMUNITY_TAB.GLOBAL.USER_PROFILE.USER_ID
  );
  const myEvents = useQuery(
    api.Community.CommunityMemberScreenFns.getUserCreatedEvents,
    {
      userId: userId as Id<"users">,
    }
  );

  if (myEvents === undefined) {
    return <FullContainerLoadingSpinner />;
  }

  if (myEvents.length === 0) {
    return <div className="text-vid-black-500 mt-4 text-lg">No events</div>;
  }

  return (
    <div className="flex-1 flex flex-col self-stretch gap-4 mt-6">
      {myEvents.map((event) => (
        <div>{event.title}</div>
      ))}
    </div>
  );
};

function useGetHpProfile() {
  const { userId } = useTypedParams(
    UROUTES.HP.MY.DASHBOARD.COMMUNITY_TAB.GLOBAL.USER_PROFILE.USER_ID
  );
  return useQuery(api.Community.CommunityMemberScreenFns.getAboutInfo, {
    userId: userId as Id<"users">,
  });
}

export const ScheduleTab: React.FC = () => {
  const profile = useGetHpProfile();
  const myAvailability = useQuery(
    api.Community.CommunityMemberScreenFns.getHpAvailability,
    profile
      ? {
          userId: profile.simpleProfile.id,
        }
      : "skip"
  );
  console.log("MY AVAILABILITY", myAvailability);
  const vm = useOnce(() => new AvailabilityCalendarVM());
  return (
    <ContentContainer rightContent={{ _tag: "USER_PROFILE_CARD", profile }}>
      <AvailabilityCalendar vm={vm} />
    </ContentContainer>
  );
};

/* Extra Components */
/* Icons */

const PurpleCheckIcon: React.FC = () => {
  const bgIcon = (
    <svg width="39" height="39" viewBox="0 0 39 39" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.484 1.24369C18.1423 -0.414564 20.8577 -0.414564 22.5159 1.24369L25.277 4.00479C25.3498 4.07752 25.53 4.20427 25.7937 4.31359C26.0581 4.42322 26.2716 4.45945 26.3663 4.45945H30.2718C32.6227 4.45945 34.5405 6.37723 34.5405 8.72815V12.6336C34.5405 12.7283 34.5767 12.9418 34.6864 13.2063C34.7957 13.47 34.9225 13.6502 34.9952 13.7229L37.7563 16.484C39.4146 18.1423 39.4146 20.8577 37.7563 22.516L34.9952 25.2771C34.9225 25.3498 34.7957 25.53 34.6864 25.7937C34.5767 26.0582 34.5405 26.2717 34.5405 26.3664V30.2716C34.5405 32.6224 32.6228 34.5405 30.2718 34.5405H26.3663C26.2716 34.5405 26.0581 34.5768 25.7937 34.6864C25.53 34.7957 25.3498 34.9225 25.277 34.9952L22.5159 37.7563C20.8577 39.4146 18.1423 39.4146 16.484 37.7563L13.7229 34.9952C13.6502 34.9225 13.47 34.7957 13.2063 34.6864C12.9419 34.5768 12.7284 34.5405 12.6336 34.5405H8.72821C6.37719 34.5405 4.45945 32.6224 4.45945 30.2716V26.3664C4.45945 26.2584 4.42106 26.0393 4.31358 25.7801C4.20485 25.5179 4.0827 25.3524 4.0238 25.2958L4.00472 25.2771L1.24369 22.516C-0.414557 20.8577 -0.414567 18.1423 1.24368 16.484L4.00472 13.7229C4.07746 13.6502 4.20424 13.47 4.31358 13.2063C4.42322 12.9418 4.45945 12.7283 4.45945 12.6336V8.72815C4.45945 6.37719 6.37732 4.45945 8.72821 4.45945H12.6336C12.7284 4.45945 12.9419 4.42322 13.2063 4.31359C13.47 4.20426 13.6502 4.07752 13.7229 4.0048L16.484 1.24369Z"
        fill="#690DFF"
      />
    </svg>
  );

  const checkIcon = (
    <svg width="17" height="13" viewBox="0 0 17 13" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.1791 0.4245C16.3986 0.670465 16.5219 1.00402 16.5219 1.35182C16.5219 1.69961 16.3986 2.03316 16.1791 2.27913L7.40481 12.1102C7.28886 12.2401 7.15119 12.3432 6.99968 12.4135C6.84816 12.4838 6.68576 12.52 6.52176 12.52C6.35776 12.52 6.19536 12.4838 6.04385 12.4135C5.89233 12.3432 5.75466 12.2401 5.63871 12.1102L1.27926 7.22656C1.16745 7.10556 1.07827 6.96083 1.01692 6.80081C0.955563 6.64079 0.92327 6.46868 0.921919 6.29452C0.920568 6.12037 0.950188 5.94765 1.00905 5.78646C1.06791 5.62527 1.15483 5.47882 1.26475 5.35567C1.37466 5.23252 1.50536 5.13513 1.64923 5.06918C1.7931 5.00323 1.94725 4.97004 2.10268 4.97155C2.25812 4.97307 2.41173 5.00925 2.55455 5.07799C2.69738 5.14673 2.82655 5.24665 2.93454 5.37193L6.52137 9.39073L14.5231 0.4245C14.6318 0.302616 14.7609 0.205928 14.9029 0.13996C15.045 0.0739929 15.1973 0.0400391 15.3511 0.0400391C15.5049 0.0400391 15.6572 0.0739929 15.7993 0.13996C15.9413 0.205928 16.0704 0.302616 16.1791 0.4245Z"
        fill="white"
      />
    </svg>
  );

  return (
    <div className="flex w-[39px] aspect-square relative flex flex-col justify-center items-center">
      <div className="absolute inset-0"> {bgIcon} </div>
      <div className="absolute inset-0 flex justify-center items-center">
        {checkIcon}
      </div>
    </div>
  );
};
