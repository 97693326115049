import { useTypedParams } from "react-router-typesafe-routes/dom";
import type { Id } from "shared/be/convex/_generated/dataModel";
import { UROUTES } from "shared/routes/u.routes";
import { CourseApp } from "web-shared/src/domains/learning/courses/course-app";

export const CourseAppPage: React.FC = () => {
  const { courseId } = useTypedParams(UROUTES.COURSES.COURSE_ID);

  return (
    <div className="w-screen h-screen flex flex-col">
      <CourseApp courseId={courseId as Id<"courseInfo">} />
    </div>
  );
};
