import { FullContainerLoadingSpinner } from "web-shared/src/components/loading";
import { useConvexAuth } from "convex/react";
import { ROUTES } from "shared/routes/routes";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export const OAuthCallbackPage: React.FC = () => {
  const nav = useNavigate();
  const { isAuthenticated, isLoading } = useConvexAuth();

  useEffect(() => {
    if (isAuthenticated && !isLoading) {
      nav(ROUTES.AUTH.SUCCESS.path);
    }
  }, [isAuthenticated, nav]);

  return <FullContainerLoadingSpinner />;
};
