import { useAuthActions } from "@convex-dev/auth/react";
import { Effect } from "effect";
import { useSetupMyProfileForm } from "frontend-shared/src/users/my-profile-form.vm.js";
import { LogOut } from "lucide-react";
import React from "react";
import { Link } from "react-router-dom";
import { ANON_ROUTES } from "shared/routes/routes.js";
import { UROUTES } from "shared/routes/u.routes";
import { domSelectFile, FileUploadUtils } from "../../../utils/file.utils.js";
import { useScrollToAnchor } from "../../../utils/utils.js";
import { MyProfileForm } from "../../user/my-profile-form";
import { SettingsContainer } from "./components/settings.components.js";

export const HpSettingsAccountPortal: React.FC<{
  me: {
    email: string | undefined;
    id: string;
  };
  onProfileUpdated: () => void;
}> = ({ me, onProfileUpdated }) => {
  useScrollToAnchor(100);

  return (
    <div className="flex flex-col gap-4">
      <PracticeInformationView
        onProfileUpdated={() => {
          onProfileUpdated();
        }}
      />
      <ExtraView myId={me.id} />
      <AccountInformationView myEmail={me.email} />
      <DangerZoneView />
    </div>
  );
};

const AccountInformationView: React.FC<{
  myEmail: string | undefined;
}> = ({ myEmail }) => {
  const { signOut } = useAuthActions();
  return (
    <SettingsContainer id="account-info" title="Account">
      <div className="w-full h-full flex flex-col gap-12">
        <div className="flex flex-col gap-2">
          <h4 className="text-sm font-bold">Email</h4>
          <p>{myEmail}</p>
        </div>
        <div
          className="flex items-center gap-2 cursor-pointer"
          onClick={() => {
            // Effect.runPromise(hpState.uMgr.signOut).then();
            signOut();
          }}
        >
          <LogOut className="w-5 h-5" />
          <h4 className="text-sm">Sign out</h4>
        </div>
      </div>
    </SettingsContainer>
  );
};

const PracticeInformationView: React.FC<{
  onProfileUpdated: () => void;
}> = ({ onProfileUpdated }) => {
  const myProfileFormVM = useSetupMyProfileForm({
    formConfig: {
      showModalityInput: true,
      showBioInput: true,
      showPsychTodayUrlInput: false,
    },
    selectPhoto: domSelectFile,
    uploadFileLikeToCloudinary: (file, uploadUrl) =>
      Effect.runPromise(
        FileUploadUtils.uploadFileLikeToCloudinary({
          secureUploadUrl: uploadUrl,
          file,
        })
      ),
  });
  return (
    <SettingsContainer id="practice-info" title="Practice Information">
      <div className="w-full h-full flex justify-center items-center">
        <MyProfileForm
          onSuccessSubmit={() => {
            onProfileUpdated();
          }}
          vm={myProfileFormVM}
          button={{ title: "Save" }}
        />
      </div>
    </SettingsContainer>
  );
};

const ExtraView: React.FC<{
  myId: string;
}> = ({ myId }) => {
  return (
    <div className="flex flex-col gap-4">
      <SettingsContainer id="global-profile" title={"Global Profile"}>
        <Link
          to={ANON_ROUTES.MY_GLOBAL_PROFILE.DISCUSSION.buildPath({
            userId: myId,
          })}
          className="text-vid-purple"
        >
          View my global profile
        </Link>
      </SettingsContainer>
      <SettingsContainer id="extra" title="Extra">
        <div className="flex flex-col gap-4">
          <h4>I am also a client</h4>
          <Link to={UROUTES.CP.DASHBOARD.HOME.path} className="text-blue-500">
            Go to client portal
          </Link>
        </div>
      </SettingsContainer>
    </div>
  );
};

const DangerZoneView: React.FC = () => {
  return (
    <SettingsContainer id="danger-zone" title="Danger Zone">
      <div className="flex flex-col gap-4">
        <div className="flex gap-2 items-center justify-between">
          <p>Fully delete your account</p>
          <button
            className="bg-red-100 text-white p-4 rounded-md w-[200px]"
            onClick={() => {}}
          >
            Delete Account
          </button>
        </div>
      </div>
    </SettingsContainer>
  );
};
