import type { Id } from "shared/be/convex/_generated/dataModel";
import { useMutation, useQuery } from "convex/react";
import { api } from "shared/be/convex/_generated/api";
import { FullContainerLoadingSpinner } from "web-shared/src/components/loading";
import { Match } from "effect";
import {
  InversePrimaryButton,
  PrimaryButton,
} from "web-shared/src/components/button";
import type { EventInviteResponseCta } from "shared/be/convex/Calendar/Calendar.Types";

export const HcCalEventTemplatePanel: React.FC<{
  eventTemplateId: Id<"calEventTemplates">;
}> = ({ eventTemplateId }) => {
  const templateDetails = useQuery(
    api.Calendar.HcCalEventFns.getHcEventTemplateDetails,
    {
      templateId: eventTemplateId,
    }
  );

  if (templateDetails === undefined) {
    return <FullContainerLoadingSpinner />;
  }

  const { template, myParticipationInfo, myRole, allOtherParticipants } =
    templateDetails;

  return (
    <div>
      <div>
        <h4>Meow!</h4>
        <h1>{template.title}</h1>
        <p>{template.eventType}</p>
        <h4>{JSON.stringify(template.info)}</h4>
        <h4>
          {myParticipationInfo?.inviteStatus}{" "}
          {myParticipationInfo?.attendanceStatus}
        </h4>{" "}
        {allOtherParticipants.map((p) => (
          <div key={p.user.id}>
            {p.user.name} {p.participationInfo.inviteStatus}{" "}
            {p.participationInfo.attendanceStatus}
          </div>
        ))}
      </div>
      <h4>Role: {myRole}</h4>
      <div className="flex flex-col space-y-2">
        {Match.value(myParticipationInfo?.inviteStatus).pipe(
          Match.when("INVITED", () => {
            return (
              <div className="flex items-center space-x-2">
                <InviteResponseCtaButton
                  cta={"ACCEPT"}
                  eventTemplateId={eventTemplateId}
                />
                <InviteResponseCtaButton
                  cta={"REJECT"}
                  eventTemplateId={eventTemplateId}
                />
              </div>
            );
          }),
          Match.when("ACCEPTED", () => {
            return <div></div>;
          }),
          Match.when("DECLINED", () => {
            return <div></div>;
          }),
          Match.when("REMOVED", () => {
            return <div></div>;
          }),
          Match.when("HOST", () => {
            return <div>You are the host</div>;
          }),
          Match.when(undefined, () => {
            return <div>You are not a participant</div>;
          }),
          Match.exhaustive
        )}
      </div>
    </div>
  );
};

const InviteResponseCtaButton: React.FC<{
  cta: EventInviteResponseCta;
  eventTemplateId: Id<"calEventTemplates">;
}> = ({ cta, eventTemplateId }) => {
  const handleInviteResponseCta = useMutation(
    api.Calendar.HcCalEventFns.handleInviteResponseCta
  );

  const onClick = () => {
    handleInviteResponseCta({
      eventTemplateId,
      cta,
    });
  };

  return Match.value(cta).pipe(
    Match.when("ACCEPT", () => {
      return <PrimaryButton title="Accept" onClick={onClick} />;
    }),
    Match.when("CANCEL", () => {
      return <InversePrimaryButton title="Cancel" onClick={onClick} />;
    }),
    Match.when("RESCHEDULE", () => {
      return <InversePrimaryButton title="Reschedule" onClick={onClick} />;
    }),
    Match.when("REJECT", () => {
      return <InversePrimaryButton title="Reject" onClick={onClick} />;
    }),
    Match.exhaustive
  );
};
