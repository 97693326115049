import { useQuery } from "convex/react";
import { Option } from "effect";
import { useSetupCommunitiesDash } from "frontend-shared/src/domains/community/communities-dash.vm";
import React from "react";
import { useNavigate } from "react-router-dom";
import { api } from "shared/be/convex/_generated/api";
import type { Id } from "shared/be/convex/_generated/dataModel";
import type { CommunityCardST } from "shared/be/convex/Screens/Community/CommunityScreens.Types";
import type { SimpleUser } from "shared/be/convex/User/User.Types";
import { UROUTES } from "shared/routes/u.routes";
import { AvatarCircles } from "web-shared/src/components/avatar.tc";
import { FullContainerLoadingSpinner } from "web-shared/src/components/loading";
import {
  CommunityPostCvx,
  WriteAPostView,
} from "web-shared/src/domains/communities/community/community-post/community-discussion";
import { SelectCommunityAndThenPostForm } from "web-shared/src/domains/communities/community/community-post/create-community-post.form";
import {
  CommunityCardContainer,
  CommunityCardContainerSkeleton,
} from "web-shared/src/domains/communities/community/components/community-card";
import { CommunityEventCardContainer } from "web-shared/src/domains/communities/community/events/community-event-card";
import { MemberCard } from "web-shared/src/domains/communities/community/members/member-card";
import { CreateCommunityForm } from "web-shared/src/domains/communities/create-community.form";
import { MyCommunitiesProfileCard } from "web-shared/src/domains/user/my-profile-card";
import { useToastVM, useWebGlobalDisplayVM } from "web-shared/src/web-context";

type ViewerType = "HP" | "CP";

export const CommunitiesHomeDash: React.FC<{ viewer?: ViewerType }> = ({
  viewer = "HP",
}) => {
  const toast = useToastVM();
  const navigate = useNavigate();
  const webDisplayVM = useWebGlobalDisplayVM();

  const setup = useSetupCommunitiesDash();

  if (Option.isNone(setup.loaded)) {
    return <FullContainerLoadingSpinner />;
  }

  const { globalProfile, communitiesForMeList, recentlyJoinedUsers } =
    setup.loaded.value;

  const getCommunityDiscussionPath = (communitySlug: string) => {
    return viewer === "HP"
      ? UROUTES.HP.MY.DASHBOARD.COMMUNITY_TAB.COMMUNITIES.COMMUNITY.DISCUSSION.buildPath(
          {
            community: communitySlug,
          }
        )
      : UROUTES.CP.DASHBOARD.COMMUNITY_TAB.COMMUNITIES.COMMUNITY.DISCUSSION.buildPath(
          {
            community: communitySlug,
          }
        );
  };

  return (
    <div className="flex-1 flex flex-col gap-8 static lg:relative max-w-full">
      <div className="flex-1 flex gap-16">
        <div className="flex flex-col gap-9 max-w-full lg:w-[714px]">
          <WriteAPostView
            profilePhotoUrl={globalProfile.simpleProfile.profilePhoto}
            onWritePostClick={() => {
              webDisplayVM.globalSheetVM.openSheet(
                {
                  title: "Create a post",
                  type: { tag: "create" },
                },
                <SelectCommunityAndThenPostForm
                  onSuccessSubmit={({ communitySlug }) => {
                    toast.showToastEvt({
                      id: "post-submitted",
                      title: "Your post is live!",
                      duration: 1000,
                    });

                    setTimeout(() => {
                      navigate(getCommunityDiscussionPath(communitySlug));
                    }, 1000);

                    webDisplayVM.globalSheetVM.closeSheet();
                  }}
                />
              );
            }}
          />
          <RecentlyJoinedUsersSection
            recentlyJoinedUsers={recentlyJoinedUsers}
          />
          <div className="flex flex-col gap-4">
            <SectionTitle title="Communities" />
            <CommunitiesListSection
              communityCards={communitiesForMeList}
              viewer={viewer}
              getCommunityDiscussionPath={getCommunityDiscussionPath}
            />
          </div>
          {setup.upcomingEventsForMe === undefined ? (
            <CommunityCardContainerSkeleton widthPx="220px" />
          ) : setup.upcomingEventsForMe.cards.length > 0 ? (
            <div className="flex flex-col gap-4">
              <SectionTitle title="Upcoming events" />
              <div className="flex gap-2 items-center overflow-x-auto">
                {setup.upcomingEventsForMe.cards.map((e) => (
                  <CommunityEventCardContainer
                    key={e.instance._id}
                    initialCardData={e}
                    showCommunityName={true}
                  />
                ))}
              </div>
            </div>
          ) : (
            <div></div>
          )}
          <TimelineFeedSection />
        </div>
        <div className="flex flex-col">
          <MyCommunitiesProfileCard globalProfile={globalProfile} />
        </div>
      </div>
    </div>
  );
};

const TimelineFeedSection: React.FC = () => {
  const timelineFeedPosts = useQuery(
    api.Community.CommunitiesHomeScreenFns.getTimelineFeed,
    {}
  );

  if (timelineFeedPosts === undefined) {
    return (
      <div className="h-[300px] flex flex-col">
        <FullContainerLoadingSpinner />
      </div>
    );
  }

  if (timelineFeedPosts.length === 0) {
    return <div></div>;
  }

  return (
    <div className="flex flex-col gap-4 mt-4">
      <h4 className="text-xl font-medium leading-6 ">Recent posts</h4>
      {timelineFeedPosts.map((post) => (
        <CommunityPostCvx key={post._id} enrichedPost={post} />
      ))}
    </div>
  );
};

const SectionTitle: React.FC<{
  title: string;
}> = ({ title }) => {
  return <h4 className="text-[24px]  leading-6 ">{title}</h4>;
};

const RecentlyJoinedUsersSection: React.FC<{
  recentlyJoinedUsers: SimpleUser[];
}> = ({ recentlyJoinedUsers }) => {
  const navigate = useNavigate();

  return (
    <div className="flex flex-col gap-4">
      <SectionTitle title="Recently joined" />
      <div className="self-stretch flex justify-between gap-4 lg:min-w-0 overflow-x-auto">
        {recentlyJoinedUsers.map((u, idx) => (
          <MemberCard
            key={u.id}
            name={u.name}
            profilePhoto={u.profilePhoto}
            userId={u.id as Id<"users">}
            index={idx}
            modalities={[]}
            isAvailable={true}
          />
        ))}
      </div>
    </div>
  );
};

const CommunitiesListSection: React.FC<{
  communityCards: (typeof CommunityCardST.Encoded)[];
  viewer: ViewerType;
  getCommunityDiscussionPath: (communitySlug: string) => string;
}> = ({ communityCards, getCommunityDiscussionPath }) => {
  const navigate = useNavigate();
  const webDisplayVM = useWebGlobalDisplayVM();

  return (
    <div className="self-stretch flex justify-between gap-4 lg:min-w-0 overflow-x-auto">
      {communityCards.map((c) => (
        <CommunityCardContainer
          key={c.community.slug}
          height="medium"
          headerBackgroundType={{
            _tag: "FIXED",
            color: c.community.color ?? "#690DFF",
          }}
          innerCircleComponent={
            <div className="w-10 h-10 lg:w-[100px] lg:h-[100px] bg-vid-black-200 rounded-full" />
          }
          title={c.community.name}
          content={
            <div className="flex flex-col items-center gap-2 mt-2">
              {c.members.profilePhotos.length > 0 && (
                <AvatarCircles
                  sources={[...c.members.profilePhotos.slice(0, 3)]}
                />
              )}
              <div className="text-vid-black-400">{c.members.countMsg}</div>
            </div>
          }
          button={{
            title: "View",
            isDisabled: false,
          }}
          onClick={() => {
            navigate(getCommunityDiscussionPath(c.community.slug));
          }}
        />
      ))}

      <CommunityCardContainer
        key={"create-community"}
        height="medium"
        headerBackgroundType={{
          _tag: "FIXED",
          color: "#000000",
        }}
        innerCircleComponent={
          <div className="w-10 h-10 lg:w-[100px] lg:h-[100px] bg-vid-black-200 rounded-full" />
        }
        title={"Create a community"}
        content={
          <div className="flex flex-col gap-2">
            <div></div>
            <div className="text-vid-black-400">members</div>
          </div>
        }
        onClick={() => {
          webDisplayVM.globalSheetVM.openSheet(
            {
              title: "Create a community",
              type: { tag: "create" },
            },
            <CreateCommunityForm />
          );
        }}
        button={{
          title: "Create",
        }}
      />
    </div>
  );
};
