import React from "react";
import { UROUTES } from "shared/routes/u.routes";
import { DashboardLayout } from "web-shared/src/components/dashboard/dashboard-layout";
import { PortalWebStateMgr } from "web-shared/src/domains/hp/dashboard/hp.webstatemgr";
import {
  CalendarIcon,
  CommunityIcon,
  HomeIcon,
  SettingsIcon,
} from "web-shared/src/components/dashboard/main-nav-links";

interface CpDashboardLayoutDashProps {
  stateMgr: PortalWebStateMgr;
  signOut: () => Promise<void>;
  location: { pathname: string };
  children: React.ReactNode;
}

export const CpDashboardLayoutDash: React.FC<CpDashboardLayoutDashProps> = ({
  stateMgr,
  signOut,
  location,
  children,
}) => {
  return (
    <DashboardLayout
      stateMgr={stateMgr.dashboardState}
      leftMenu={{
        onLogoClick: () => {
          window.location.href = UROUTES.CP.DASHBOARD.HOME.path;
        },
        topLinks: [
          {
            name: "Home",
            to: UROUTES.CP.DASHBOARD.HOME.path,
            icon: (isSelected) => <HomeIcon isSelected={isSelected} />,
          },
          {
            name: "Community",
            to: UROUTES.CP.DASHBOARD.COMMUNITY_TAB.COMMUNITIES.path,
            icon: (isSelected) => <CommunityIcon isSelected={isSelected} />,
          },
          {
            name: "Calendar",
            to: UROUTES.CP.DASHBOARD.CALENDAR.path,
            icon: (isSelected) => <CalendarIcon isSelected={isSelected} />,
          },
          {
            name: "Settings",
            to: `${UROUTES.CP.DASHBOARD.SETTINGS.ACCOUNT.path}#profile`,
            icon: (isSelected) => <SettingsIcon isSelected={isSelected} />,
          },
        ],
      }}
      navbar={{
        currentDashboardPage: getCurrentDashboardPageFromUrl(location.pathname),
        middleSection: <></>,
        hamburger: {
          links: [],
          onSignout: () => {
            signOut().then(() => {
              window.location.href = "/";
            });
          },
        },
        profileButton: {
          to: UROUTES.CP.DASHBOARD.SETTINGS.path,
          profilePhoto: null,
        },
      }}
      mainContent={children}
    />
  );
};

function getCurrentDashboardPageFromUrl(path: string): string {
  /*
  /cp/dashboard/settings => Settings
  /cp/dashboard/home => Home
  /cp/dashboard/portal/1 => Portal
  */

  const segments = path.split("/");
  const lastSeg = segments[segments.length - 1]; // Returns the last segment
  return lastSeg.charAt(0).toUpperCase() + lastSeg.slice(1);
}
