import { ConvexAuthProvider } from "@convex-dev/auth/react";
import * as Sentry from "@sentry/react";
import { ConvexReactClient, useConvexAuth } from "convex/react";
import { useOnce } from "frontend-shared/src/util";
import { useEffect } from "react";
import { TooltipProvider } from "web-shared/src/components/ui/tooltip";
import {
  WebGlobalDisplayVM,
  WebGlobalVmContext,
} from "web-shared/src/web-context";
import ilLogoSvg from "../assets/images/il-logo.svg";
import { FullScreenLoadingSpinner } from "../components/loading";
import { AudioProvider } from "frontend-shared/src/mgrs/media-player.statemgr";
import { WebkitAudioPlayer } from "../web-audio-player";

const CONVEX_URL = import.meta.env["VITE_CONVEX_URL"]!;

export const RootProvider: React.FC<{
  children: React.ReactNode;
  nav: (path: string) => void;
  rootUrl: string;
  authInUrl: string;
  uUrl: string;
}> = ({ children, nav, rootUrl, authInUrl, uUrl }) => {
  const webConvexReact = useOnce(() => new ConvexReactClient(CONVEX_URL));
  const rootWebStateMgr = useOnce(() => new WebGlobalDisplayVM());

  return (
    <ConvexAuthProvider client={webConvexReact}>
      <AudioProvider AudioPlayerClass={WebkitAudioPlayer}>
        <AuthStateHandler
          nav={nav}
          rootUrl={rootUrl}
          authInUrl={authInUrl}
          uUrl={uUrl}
        >
          <Sentry.ErrorBoundary fallback={<ErrorPage />}>
            <WebGlobalVmContext.Provider value={rootWebStateMgr}>
              <TooltipProvider>{children}</TooltipProvider>
            </WebGlobalVmContext.Provider>
          </Sentry.ErrorBoundary>
        </AuthStateHandler>
      </AudioProvider>
    </ConvexAuthProvider>
  );
};

function useInitialAuthRedirect(p: {
  nav: (path: string) => void;
  rootUrl: string;
  authInUrl: string;
  uUrl: string;
}) {
  const { isAuthenticated, isLoading } = useConvexAuth();

  useEffect(() => {
    const isRootUrl = location.pathname === p.rootUrl;

    if (isLoading) {
      return;
    }

    if (isRootUrl) {
      if (isAuthenticated) {
        p.nav(p.uUrl);
      } else {
        p.nav(p.authInUrl);
      }
    }
  }, [isAuthenticated, isLoading, p.nav, p.authInUrl, p.uUrl]);
}

const AuthStateHandler: React.FC<{
  children: React.ReactNode;
  nav: (path: string) => void;
  rootUrl: string;
  authInUrl: string;
  uUrl: string;
}> = ({ children, nav, rootUrl, authInUrl, uUrl }) => {
  const { isLoading } = useConvexAuth();
  useInitialAuthRedirect({ nav, rootUrl, authInUrl, uUrl });

  if (isLoading) {
    return <FullScreenLoadingSpinner />;
  }

  return children;
};

const ErrorPage: React.FC = () => {
  return (
    <div className="w-screen h-screen flex flex-col items-center justify-center bg-vid-black-50">
      <div className="flex flex-col items-center gap-6 p-8 rounded-lg bg-white shadow-default-card max-w-md">
        <img src={ilLogoSvg} alt="Logo" className="w-16 h-16" />
        <div className="flex flex-col items-center gap-2 text-center">
          <h1 className="text-2xl font-medium text-vid-black-900">
            Something went wrong
          </h1>
          <p className="text-vid-black-700">
            An unexpected error has occurred. Please try refreshing the page or
            contact support if the problem persists.
          </p>
        </div>
        <button
          onClick={() => window.location.reload()}
          className="bg-vid-purple hover:bg-btn-purple text-white rounded-buttons px-6 py-3 transition-colors"
        >
          Refresh Page
        </button>
      </div>
    </div>
  );
};
