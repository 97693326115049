import {
  combine,
  createEffect,
  createEvent,
  createStore,
  sample,
} from "effector";
import { useUnit } from "effector-react";
import { createState, useOnce } from "frontend-shared/src/util";
import { CircleEllipsis, FileText, FolderIcon, Video, X } from "lucide-react";
import React from "react";
import { InfoSection } from "web-shared/src/components/info-section";

type FormErrors = {
  title?: string;
  description?: string;
};

class CourseCreationFormVM {
  // Events
  inputDescriptionEvt = createEvent<string>();
  setPdfEvt = createEvent<string>();
  setVideoEvt = createEvent<string>();
  submitEvt = createEvent<void>();
  validateEvt = createEvent<void>();

  // Stores
  titleVM = createState<string | null>(null);
  $description = createStore<string | null>(null);
  $pdfFile = createStore<string | null>(null);
  $videoFile = createStore<string | null>(null);
  $errors = createStore<FormErrors>({});
  $isValid = createStore(false);

  submitFx = createEffect<
    {
      communitySlug: string;
      title: string;
      description: string;
      pdfFile: string | undefined;
      videoFile: string | undefined;
    },
    void
  >();

  constructor(args: {
    communitySlug: string;
    onSubmitClick: (submitArgs: {
      communitySlug: string;
      title: string;
      description: string;
      pdfFile: string | undefined;
      videoFile: string | undefined;
    }) => void;
  }) {
    // Connect events to stores
    this.$description.on(this.inputDescriptionEvt, (_, desc) => desc);
    this.$pdfFile.on(this.setPdfEvt, (_, pdf) => pdf);
    this.$videoFile.on(this.setVideoEvt, (_, video) => video);

    this.submitFx.use(args.onSubmitClick);

    const $allFormData = combine({
      title: this.titleVM.store,
      description: this.$description,
      pdfFile: this.$pdfFile,
      videoFile: this.$videoFile,
    });

    // Validation logic
    sample({
      clock: [this.validateEvt, $allFormData],
      source: $allFormData,
      fn: (data) => {
        const errors: FormErrors = {};

        if (!data.title?.trim()) {
          errors.title = "Title is required";
        }

        if (!data.description?.trim()) {
          errors.description = "Description is required";
        }

        return errors;
      },
      target: this.$errors,
    });

    // Update isValid based on errors
    sample({
      clock: this.$errors,
      fn: (errors) => Object.keys(errors).length === 0,
      target: this.$isValid,
    });

    // Handle submit with validation
    sample({
      clock: this.submitEvt,
      source: $allFormData,
      target: this.validateEvt,
    });

    sample({
      clock: this.submitEvt,
      source: { data: $allFormData, isValid: this.$isValid },
      filter: ({ isValid }) => isValid,
      fn: ({ data }) => ({
        title: data.title!,
        description: data.description!,
        pdfFile: data.pdfFile ?? undefined,
        videoFile: data.videoFile ?? undefined,
        communitySlug: args.communitySlug,
      }),
      target: this.submitFx,
    });
  }
}

export const CourseCreationForm: React.FC<{
  communitySlug: string;
  onSubmitClick: (submitArgs: {
    communitySlug: string;
    title: string;
    description: string;
    pdfFile: string | undefined;
    videoFile: string | undefined;
  }) => void;
}> = ({ communitySlug, onSubmitClick }) => {
  const vm = useOnce(
    () => new CourseCreationFormVM({ communitySlug, onSubmitClick })
  );
  const { title, description, pdfFile, videoFile, errors } = useUnit({
    title: vm.titleVM.store,
    description: vm.$description,
    pdfFile: vm.$pdfFile,
    videoFile: vm.$videoFile,
    errors: vm.$errors,
  });

  return (
    <div className="flex flex-col w-full max-w-2xl mx-auto bg-white rounded-lg">
      {/* Header with close button */}
      <div className="flex justify-between items-center p-6">
        <div className="flex flex-col items-center w-full gap-4">
          <div className="w-20 h-20 bg-vid-black-50 rounded-full flex items-center justify-center">
            <FileText className="w-8 h-8 text-vid-black-600" />
          </div>
          <h1 className="text-2xl font-semibold text-center">Create course</h1>
        </div>
      </div>

      {/* Form sections */}
      <div className="flex flex-col gap-4 p-6">
        {/* Course Title */}
        <InfoSection header={{ _tag: "STANDARD", title: "Course title" }}>
          <div className="flex flex-col gap-2">
            <div className="flex items-center gap-3">
              <FileText className="w-5 h-5 text-vid-black-600" />
              <input
                type="text"
                value={title ?? ""}
                onChange={(e) => vm.titleVM.event(e.target.value)}
                placeholder="Enter course title"
                className={`w-full p-3 bg-transparent focus:outline-none text-vid-black-900 ${
                  errors.title ? "border-red-500" : ""
                }`}
              />
              <span className="text-red-500">*</span>
            </div>
            {errors.title && (
              <span className="text-red-500 text-sm ml-8">{errors.title}</span>
            )}
          </div>
        </InfoSection>

        {/* Course Description */}
        <InfoSection header={{ _tag: "STANDARD", title: "Course description" }}>
          <div className="flex flex-col gap-2">
            <div className="flex items-start gap-3">
              <FileText className="w-5 h-5 text-vid-black-600 mt-3" />
              <textarea
                value={description ?? ""}
                onChange={(e) => vm.inputDescriptionEvt(e.target.value)}
                placeholder="Enter course description"
                rows={3}
                className={`w-full p-3 bg-transparent focus:outline-none text-vid-black-900 resize-none ${
                  errors.description ? "border-red-500" : ""
                }`}
              />
              <span className="text-red-500">*</span>
            </div>
            {errors.description && (
              <span className="text-red-500 text-sm ml-8">
                {errors.description}
              </span>
            )}
          </div>
        </InfoSection>

        {/* Course Content */}
        <InfoSection header={{ _tag: "STANDARD", title: "Course content" }}>
          <div className="flex items-center gap-3">
            <FolderIcon className="w-5 h-5 text-vid-black-600" />
            <div className="flex-1">
              <button className="text-community-purple hover:underline">
                Upload PDF
              </button>
              <p className="text-sm text-vid-black-500">
                {pdfFile ?? "course.pdf"}
              </p>
            </div>
          </div>
        </InfoSection>

        {/* Record Video */}
        <InfoSection header={{ _tag: "STANDARD", title: "Record video" }}>
          <div className="flex items-center gap-3">
            <Video className="w-5 h-5 text-vid-black-600" />
            <div className="flex-1">
              <button className="text-community-purple hover:underline">
                Record or upload video
              </button>
              <p className="text-sm text-vid-black-500">
                {videoFile ?? "video.mp3"}
              </p>
            </div>
          </div>
        </InfoSection>

        {/* Quiz */}
        <InfoSection header={{ _tag: "STANDARD", title: "Quiz" }}>
          <div className="flex items-center justify-between w-full">
            <div className="flex items-center gap-3">
              <CircleEllipsis className="w-5 h-5 text-vid-black-600" />
              <span className="text-vid-black-900">EMDR Quiz</span>
            </div>
            <button className="p-2 hover:bg-vid-black-50 rounded-full">
              <X className="w-5 h-5 text-vid-black-600" />
            </button>
          </div>
        </InfoSection>
      </div>

      {/* Submit Button */}
      <div className="flex justify-end p-6 border-t border-vid-black-200">
        <button
          className="bg-community-purple text-white px-6 py-2 rounded-lg hover:opacity-90 transition-opacity disabled:opacity-50 disabled:cursor-not-allowed"
          onClick={() => vm.submitEvt()}
        >
          Create Course
        </button>
      </div>
    </div>
  );
};
