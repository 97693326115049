import { useMutation } from "convex/react";
import React, { useState } from "react";
import { api } from "shared/be/convex/_generated/api";
import { PrimaryButton } from "../../components/button";
import { useWebGlobalDisplayVM } from "../../web-context";
import { TextInput } from "../../components/text.input";

export const RequestHpForm: React.FC = () => {
  const [hpEmail, setHpEmail] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const webDisplayVM = useWebGlobalDisplayVM();
  const requestHp = useMutation(
    api.Practitioner.PractitionerRelationshipFns.requestPractitioner
  );

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      const result = await requestHp({
        practitionerEmail: hpEmail,
      });

      webDisplayVM.globalSheetVM.closeSheet();
      webDisplayVM.showToast({
        id: "request-hp-success",
        title: "Request sent to therapist",
      });
    } catch (error) {
      console.error("Error requesting therapist:", error);
      webDisplayVM.showToast({
        id: "request-hp-error",
        title: "Failed to send request",
        type: "error",
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="flex flex-col gap-6">
      <div className="flex flex-col gap-2">
        <label className="text-sm text-gray-700">Therapist Email</label>
        <TextInput
          value={hpEmail}
          onChange={(e) => setHpEmail(e.target.value)}
          placeholder="Enter therapist's email"
          type="email"
          required
        />
      </div>

      <PrimaryButton
        title="Send Request"
        type="submit"
        disabled={isSubmitting || !hpEmail}
        isLoading={isSubmitting}
      />
    </form>
  );
};
