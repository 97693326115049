import { useMutation, useQuery } from "convex/react";
import { createEffect, createEvent, createStore, sample } from "effector";
import { useNow, useOnce, useQuery$ } from "frontend-shared/src/util";
import "react-calendar/dist/Calendar.css";
import "react-clock/dist/Clock.css";
import "react-datetime-picker/dist/DateTimePicker.css";
import * as Rx from "rxjs";
import { api } from "shared/be/convex/_generated/api";
import type { Id } from "shared/be/convex/_generated/dataModel";
import type { PastHcApptsRowItemETO } from "shared/be/convex/Screens/Hp/Dashboard/Clients/Clients.Types";
import type { SimpleUser } from "shared/be/convex/User/User.Types";
import { isRight } from "shared/util";

export function useSetupHpClientsDash() {
  const now = useNow();
  const resendInviteClientEmail = useMutation(
    api.Screens.Hp.Dashboard.ClientsScreenFns.onResendInviteClientEmail
  );

  const myPendingReqsForMeToBePractitioner = useQuery(
    api.Practitioner.PractitionerRelationshipFns.getPendingRequestsFromClients,
    {}
  );

  const respondToClientRequest = useMutation(
    api.Practitioner.PractitionerRelationshipFns.respondToClientRequest
  );

  const myActiveClients = useQuery(
    api.Practitioner.PractitionerRelationshipFns.getMyActiveClients,
    {}
  );

  const myOngoingHcSessions = useQuery(
    api.Rtc.Hc.HcSessionFns.getMyOngoingHcSessionsForHpPortal,
    {}
  );

  const myUpcomingHcAppts = useQuery(
    api.Screens.Hp.Dashboard.ClientsScreenFns.getMyUpcomingHcAppts,
    { now }
  );

  const myPastHcAppts = useQuery(
    api.Screens.Hp.Dashboard.ClientsScreenFns.getMyPastHcAppts,
    {}
  );

  const endHcSession = useMutation(
    api.Screens.Hp.Dashboard.ClientsScreenFns.onEndSessionClick
  );

  return {
    resendInviteClientEmail,
    myPendingReqsForMeToBePractitioner,
    myActiveClients,
    myOngoingHcSessions,
    myUpcomingHcAppts,
    myPastHcAppts,
    endHcSession,
    respondToClientRequest,
  };
}

export function useGetClientInfoDash(p: { clientId: Id<"users"> }): {
  client: SimpleUser | undefined;
  pastSessions$: Rx.BehaviorSubject<PastHcApptsRowItemETO[] | undefined>;
} {
  const client = useQuery(
    api.Screens.Hp.Dashboard.Clients.ClientDetailDashFns.getClientProfile,
    {
      clientId: p.clientId,
    }
  );

  const pastSessions$ = useQuery$(
    api.Screens.Hp.Dashboard.Clients.ClientDetailDashFns
      .getPastSessionsWithClient,
    { clientId: p.clientId }
  );

  return { client, pastSessions$ };
}

class AddAClientVM {
  readonly $email = createStore<string>("");
  readonly setEmail = createEvent<string>();
  readonly submitPressed = createEvent();
  readonly $isSubmitting = createStore(false);
  readonly $error = createStore<string | null>(null);
  readonly $submitResult = createStore<{ msg: string } | null>(null);

  readonly submitFx = createEffect(
    async ({
      email,
      mutation,
    }: {
      email: string;
      mutation: ReturnType<
        typeof useMutation<
          typeof api.Screens.Hp.Dashboard.ClientsScreenFns.handleSubmitAddClient
        >
      >;
    }) => {
      const result = await mutation({
        clientEmail: email,
        autoApproveIfClientExists: true,
      });

      if (isRight(result)) {
        return result.right;
      } else {
        throw new Error(result.left.msg);
      }
    }
  );

  constructor(
    mutation: ReturnType<
      typeof useMutation<
        typeof api.Screens.Hp.Dashboard.ClientsScreenFns.handleSubmitAddClient
      >
    >
  ) {
    this.$email.on(this.setEmail, (_, email) => email);

    this.$isSubmitting
      .on(this.submitFx.doneData, () => false)
      .on(this.submitFx.failData, () => false)
      .on(this.submitFx, () => true);

    this.$error
      .on(this.submitFx.failData, (_, error: Error) => error.message)
      .on(this.submitFx.doneData, () => null)
      .on(this.setEmail, () => null);

    this.$submitResult
      .on(this.submitFx.doneData, (_, result) => result)
      .on(this.submitFx.failData, () => null)
      .on(this.setEmail, () => null);

    sample({
      clock: this.submitPressed,
      source: this.$email,
      filter: (email) => email.length > 0,
      fn: (email) => ({ email, mutation }),
      target: this.submitFx,
    });
  }
}

export function useSetupAddAClientVM() {
  const handleSubmitAddClient = useMutation(
    api.Screens.Hp.Dashboard.ClientsScreenFns.handleSubmitAddClient
  );

  const vm = useOnce(() => new AddAClientVM(handleSubmitAddClient));

  return vm;
}
