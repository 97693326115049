import {
  useCall,
  useCallStateHooks,
  type Call,
} from "@stream-io/video-react-sdk";
import { useQuery } from "convex/react";
import { format } from "date-fns";
import { Match } from "effect";
import BirdseyeWaves from "frontend-shared/src/resources/meditation/birdseye-waves.mp4";
import Candle from "frontend-shared/src/resources/meditation/candle.mp4";
import WaterStream from "frontend-shared/src/resources/meditation/stream.mp4";
import {
  useGetClockInfo,
  useLiveRoomVM,
} from "frontend-shared/src/sessions/live-room.vm";
import { MaximizeIcon } from "lucide-react";
import { useObservableEagerState } from "observable-hooks";
import React from "react";
import { useEffect, useState } from "react";
import { api } from "shared/be/convex/_generated/api";
import type { Id } from "shared/be/convex/_generated/dataModel";
import type {
  KnownVisualizationMediaFile,
  ParticipantFocusedLayout,
  SelectedContent,
  StageViewLayout,
  TitleDisplaySection,
} from "shared/be/convex/Rtc/Rooms/Live/LiveRoom.Types";
import type { SimpleUser } from "shared/be/convex/User/User.Types";
import { ImageSrc } from "shared/types/miscellaneous.types";
import { isNullOrUndefined } from "shared/util";
import alarmSvg from "web-shared/src/assets/images/alarm.svg";
import {
  AvatarCircle,
  AvatarCircles,
} from "web-shared/src/components/avatar.tc";
import { EmdrBallRtcContent } from "web-shared/src/domains/sessions/rtc/rooms/live/menu/tools/emdr/emdr-ball-vis-code";
import { CustomParticipantView } from "web-shared/src/domains/sessions/rtc/rooms/live/participant-view";
import { SessionWrapup } from "../../../analysis/wrapup/wrapup";
import { ConnectingAnimation } from "../../camera";
import { BreakoutRoomsDnd } from "../breakout-rooms-dnd";
import { QuickActionButtonPanel } from "./quick-action-panel";
import { useLiveRoomStreamVM } from "../../../../../web-context";

function useTempShowConnecting() {
  const [showConnecting, setShowConnecting] = useState(true);
  useEffect(() => {
    setTimeout(() => setShowConnecting(false), 1000);
  }, []);
  return showConnecting;
}

export const StageView: React.FC<{
  stageViewLayout: StageViewLayout;
  isMaximized: boolean;
}> = ({ stageViewLayout, isMaximized }) => {
  const { stageView } = useLiveRoomVM();

  return (
    <div className="flex-1 min-h-0 relative">
      <div
        className={`absolute inset-0 flex ${isMaximized ? "invisible" : ""}`}
      >
        <div className="self-stretch flex flex-col-reverse pb-2 pr-2">
          <QuickActionButtonPanel />
        </div>
        <div className="flex-1 flex flex-col gap-2">
          <StageTopNav />
          <div className="flex-1 flex min-h-0">
            <div className="flex-1 flex flex-col">
              {!isMaximized && (
                <StageMainContentView
                  stageViewLayout={stageViewLayout}
                  toggleMaximized={() => {
                    stageView.toggleStageViewMaximized();
                  }}
                />
              )}
            </div>
          </div>
        </div>
      </div>

      {isMaximized && (
        <div className="absolute inset-0 z-10 flex flex-col">
          <StageTopNav />
          <div className="flex-1 relative">
            <div className="absolute inset-0">
              <ContentFocusedView
                toggleMaximized={() => {
                  stageView.toggleStageViewMaximized();
                }}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const StageMainContentView: React.FC<{
  stageViewLayout: StageViewLayout;
  toggleMaximized: () => void;
}> = ({ stageViewLayout, toggleMaximized }) => {
  const streamVM = useLiveRoomStreamVM();

  const currentScreenShareUser = useObservableEagerState(
    streamVM.currentScreenShareUser$
  );

  if (currentScreenShareUser && !currentScreenShareUser.amITheScreensharer) {
    return (
      <OtherParticipantSharingScreenView
        otherUserSharing={currentScreenShareUser.user}
      />
    );
  }

  return Match.value(stageViewLayout).pipe(
    Match.when(null, () => {
      return (
        <div className="flex-1 flex flex-col gap-2">
          <div className="text-white text-lg font-bold">
            No stage view layout
          </div>
        </div>
      );
    }),
    Match.when({ tag: "PARTICIPANTS_FOCUSED" }, ({ layout }) => {
      return <ParticipantFocusedView layout={layout} />;
    }),
    Match.when({ tag: "CONTENT_FOCUSED" }, ({ participantFocusedLayout }) => {
      return (
        <div className="flex-1 flex flex-col gap-2">
          <div className="flex-1 flex flex-col min-h-0">
            <div className="flex-1 relative">
              <ContentFocusedView
                toggleMaximized={() => {
                  toggleMaximized();
                }}
              />
            </div>
          </div>
          <div className="basis-[200px] flex flex-col min-h-0">
            <ParticipantFocusedView layout={participantFocusedLayout} />
          </div>
        </div>
      );
    }),
    Match.exhaustive
  );
};

const OtherParticipantSharingScreenView: React.FC<{
  otherUserSharing: SimpleUser;
}> = ({ otherUserSharing }) => {
  return (
    <div className="flex-1 flex gap-4">
      <ParticipantVideo user={otherUserSharing} />
    </div>
  );
};

const ParticipantFocusedView: React.FC<{
  layout: ParticipantFocusedLayout;
}> = ({ layout }) => {
  console.log("rendering participant focused view", layout);

  return Match.value(layout).pipe(
    Match.when(
      { tag: "ONE_ON_ONE" },
      ({ bigParticipant, smallParticipant }) => {
        console.log("rendering one on one", bigParticipant, smallParticipant);
        return (
          <div className="flex-1 flex flex-col relative">
            <ParticipantVideo user={bigParticipant} />
            <div className="absolute bottom-4 right-4 w-[200px] aspect-video">
              <ParticipantVideo user={smallParticipant} />
            </div>
          </div>
        );
      }
    ),
    Match.when({ tag: "SELF_VIEW" }, ({ me }) => {
      return <ParticipantVideo user={me} />;
    }),
    Match.when(
      { tag: "SPEAKER_VIEW" },
      ({ mainSpeaker, otherParticipants }) => {
        return (
          <div className="flex-1 flex flex-col relative">
            <ParticipantVideo user={mainSpeaker} />
            <div className="absolute top-4 right-4 flex gap-2">
              {otherParticipants.map((p) => (
                <div key={p.id} className="w-[160px] aspect-video">
                  <ParticipantVideo user={p} />
                </div>
              ))}
            </div>
          </div>
        );
      }
    ),
    Match.when({ tag: "GALLERY" }, ({ participants }) => {
      return <GalleryView participants={participants} />;
    }),
    Match.exhaustive
  );
};

const GalleryView: React.FC<{
  participants: SimpleUser[];
}> = ({ participants }) => {
  return (
    <div
      className={`flex-1 grid gap-2 ${getGalleryGridLayout(participants.length)}`}
    >
      {participants.map((p) => (
        <ParticipantVideo user={p} />
      ))}
    </div>
  );
};

function getGalleryGridLayout(participantCount: number): string {
  switch (participantCount) {
    case 1:
      return "grid-cols-1";
    case 2:
      return "grid-cols-2";
    case 3:
      return "grid-cols-2";
    case 4:
      return "grid-cols-2 grid-rows-2";
    case 5:
    case 6:
      return "grid-cols-3 grid-rows-2";
    case 7:
    case 8:
    case 9:
      return "grid-cols-3 grid-rows-3";
    default:
      return "grid-cols-4 grid-rows-3"; // For larger numbers
  }
}

const ParticipantVideo: React.FC<{
  user: SimpleUser;
}> = ({ user }) => {
  const call = useCall();

  if (call === undefined) {
    return <ConnectingAnimation isConnected={false} />;
  }

  return <ParticipantVideoConnected user={user} call={call} />;
};

const ParticipantVideoConnected: React.FC<{
  user: SimpleUser;
  call: Call;
}> = ({ user }) => {
  const { useParticipants } = useCallStateHooks();
  const participants = useParticipants();
  const participant = participants.find((p) => p.userId === user.id);

  if (isNullOrUndefined(participant)) {
    return <ParticipantVideoPlaceholder participant={user} />;
  }

  console.log("rendering participant video", participant);

  return (
    <div className="flex-1 flex flex-col min-h-0 relative min-w-[200px] min-h-[200px]">
      <CustomParticipantView participant={participant} user={user} />
    </div>
  );
};

export const ParticipantVideoPlaceholder: React.FC<{
  participant: SimpleUser;
}> = ({ participant }) => {
  return (
    <div
      className="flex-1 flex flex-col justify-center items-center gap-2 bg-vid-black-900 text-white border border-blue-400 rounded-[20px] p-4"
      // style={style}
    >
      <AvatarCircle
        mbProfilePhoto={ImageSrc.fromMbUrl(participant.profilePhoto)}
        size={60}
      />
      <h4 className="font-bold text-lg">{participant.name}</h4>
    </div>
  );
};

const ContentFocusedView: React.FC<{
  toggleMaximized: () => void;
}> = ({ toggleMaximized }) => {
  const { roomId, baseSessionId, selectedContent$ } = useLiveRoomVM();
  const selectedContent = useObservableEagerState(selectedContent$);

  if (isNullOrUndefined(selectedContent)) {
    return <div className="absolute inset-0">No content selected</div>;
  }

  return (
    <div className="absolute inset-0 flex flex-col rounded-[12px]">
      {viewForSelectedContent(selectedContent, roomId, baseSessionId)}
      <div
        className="absolute bottom-4 right-4 cursor-pointer z-20"
        onClick={toggleMaximized}
      >
        <MaximizeIcon className="text-white" />
      </div>
    </div>
  );
};

function viewForSelectedContent(
  selectedContent: SelectedContent,
  roomId: Id<"rtcLiveRooms">,
  sessionId: Id<"rtcSession">
) {
  return Match.value(selectedContent).pipe(
    Match.when({ tag: "VISUALIZATION" }, ({ mediaFile }) => {
      return <VisulaziationContentView mediaFile={mediaFile} />;
    }),
    Match.when({ tag: "EMDR" }, () => {
      return <EmdrBallRtcContent roomId={roomId} />;
    }),
    Match.when({ tag: "BREAKOUT_ROOMS" }, ({ brSessionId }) => {
      return <BreakoutRoomsDnd brSessionId={brSessionId} />;
    }),
    Match.when({ tag: "REVIEW" }, () => {
      return (
        <div className="flex-1 flex flex-col bg-white overflow-y-clip max-h-[90%] rounded-[12px] p-8">
          <SessionWrapup sessionId={sessionId} />
        </div>
      );
    }),
    Match.exhaustive
  );
}

const VisulaziationContentView: React.FC<{
  mediaFile: KnownVisualizationMediaFile | null;
}> = ({ mediaFile }) => {
  if (mediaFile === null) {
    return null;
  }

  return (
    <video
      className="absolute inset-0 w-full h-full object-cover"
      src={matchMeditationVideo(mediaFile)}
      autoPlay
      loop
      muted
    />
  );
};

const StageTopNav: React.FC = () => {
  const { stageView, roomId } = useLiveRoomVM();
  const titleDisplaySection = useObservableEagerState(
    stageView.titleDisplaySection$
  );

  if (!titleDisplaySection) {
    return <div className="h-[50px] w-full"></div>;
  }

  return (
    <StageTopNavView
      titleDisplaySection={titleDisplaySection}
      clockSection={<ClockSection />}
      roomId={roomId}
    />
  );
};

const MessageContainer: React.FC<{
  children: React.ReactNode;
  backgroundColor?: string;
  px?: string;
  py?: string;
}> = ({ children, backgroundColor, px, py }) => {
  const [isExpanded, setIsExpanded] = React.useState(false);
  const [isVisible, setIsVisible] = React.useState(false);
  const [isPulsing, setIsPulsing] = React.useState(false);

  React.useEffect(() => {
    // First make it visible
    setIsVisible(true);
    // Then trigger the expansion after a small delay
    const expandTimer = setTimeout(() => {
      setIsExpanded(true);
      // Start pulsing after expansion
      setIsPulsing(true);
      // Stop pulsing after 2 seconds
      const pulseTimer = setTimeout(() => setIsPulsing(false), 4000);
      return () => clearTimeout(pulseTimer);
    }, 50);
    return () => clearTimeout(expandTimer);
  }, []);

  return (
    <div
      style={{ width: isExpanded ? "auto" : "55px" }}
      className={`border border-vid-black-800 rounded-[30px] flex items-center gap-2 text-white 
        ${px ? `px-${px}` : "px-2"} 
        ${py ? `py-${py}` : "py-1"} 
        h-full ${backgroundColor ? `bg-${backgroundColor}` : ""}
        transition-[width] duration-3000 ease-out
        ${isVisible ? "opacity-100" : "opacity-0"}
        ${isPulsing ? "animate-pulse" : ""}
        overflow-hidden`}
    >
      <div
        className={`flex items-center gap-2 whitespace-nowrap ${
          isExpanded ? "opacity-100 translate-x-0" : "opacity-0 -translate-x-2"
        } transition-all duration-2000`}
      >
        {children}
      </div>
    </div>
  );
};

const TopItemContainer: React.FC<{
  children: React.ReactNode;
  backgroundColor?: string;
  onClick?: () => void;
  px?: string;
  py?: string;
}> = ({ children, backgroundColor, onClick, px, py }) => {
  return (
    <div
      className={`border border-vid-black-800 rounded-[30px] flex items-center gap-2 text-white 
        ${px ? `px-${px}` : "px-2"} 
        ${py ? `py-${py}` : "py-1"} 
        h-full ${backgroundColor ? `bg-${backgroundColor}` : ""}`}
      onClick={onClick}
    >
      {children}
    </div>
  );
};

const ClockSection: React.FC = () => {
  const { baseSessionId, roomId } = useLiveRoomVM();
  const { mainCountdownClockInfo$, specialClockDisplay$ } = useGetClockInfo({
    baseSessionId,
    roomId,
  });
  const mainCountdownClock = useObservableEagerState(mainCountdownClockInfo$);
  const specialClockDisplay = useObservableEagerState(specialClockDisplay$);
  const currentBroadcastMessage = useQuery(
    api.Rtc.Rooms.LiveRoomFns.getCurrentBroadcastMessage,
    {
      roomId,
    }
  );

  if (
    mainCountdownClock === undefined ||
    currentBroadcastMessage === undefined
  ) {
    return null;
  }

  if (currentBroadcastMessage !== null) {
    return (
      <MessageContainer backgroundColor="vid-purple" px="4" py="2">
        <div>{currentBroadcastMessage}</div>
      </MessageContainer>
    );
  }

  return (
    <div className="flex items-center gap-2">
      {specialClockDisplay && (
        <CountdownClockView
          timeLeftSeconds={specialClockDisplay.secondsLeft}
          backgroundColor={Match.value(specialClockDisplay.color).pipe(
            Match.when("red", () => "red-400"),
            Match.when("purple", () => "vid-purple"),
            Match.exhaustive
          )}
          showSeconds={true}
        />
      )}
      <ClockSectionView mainCountdownClock={mainCountdownClock} />
    </div>
  );
};

const ClockSectionView: React.FC<{
  mainCountdownClock: {
    secondsLeft: number;
    showWarning: string | null;
  };
}> = ({ mainCountdownClock }) => {
  return (
    <CountdownClockView
      timeLeftSeconds={mainCountdownClock.secondsLeft}
      showSeconds={false}
      backgroundColor={
        mainCountdownClock.showWarning ? "vid-purple" : undefined
      }
      message={mainCountdownClock.showWarning ?? undefined}
    />
  );
};

const CountdownClockView: React.FC<{
  timeLeftSeconds: number | null;
  onClick?: () => void;
  backgroundColor?: string;
  showSeconds?: boolean;
  message?: string;
}> = ({ timeLeftSeconds, onClick, backgroundColor, showSeconds, message }) => {
  const hasContent = message || timeLeftSeconds !== null;

  if (message) {
    return (
      <MessageContainer backgroundColor={backgroundColor} px="5">
        <img src={alarmSvg} alt="Alarm icon" width={20} height={20} />
        <div>{message}</div>
      </MessageContainer>
    );
  }

  return (
    <TopItemContainer
      backgroundColor={backgroundColor}
      onClick={onClick}
      px="5"
    >
      <img src={alarmSvg} alt="Alarm icon" width={20} height={20} />
      <div
        className={`transition-all duration-300 ${hasContent ? "opacity-100 translate-x-0" : "opacity-0 -translate-x-2"}`}
      >
        {timeLeftSeconds !== null &&
          (showSeconds
            ? format(new Date(timeLeftSeconds * 1000), "m:ss")
            : `${Math.floor(timeLeftSeconds / 60)}`)}
      </div>
    </TopItemContainer>
  );
};

const StageTopNavView: React.FC<{
  titleDisplaySection: TitleDisplaySection;
  clockSection: React.ReactNode;
  roomId: Id<"rtcLiveRooms">;
}> = ({ titleDisplaySection, clockSection, roomId }) => {
  console.log("rendering stage top nav", roomId);
  return (
    <div className="flex justify-between gap-4">
      <TopItemContainer>
        {Match.value(titleDisplaySection).pipe(
          Match.when(
            { tag: "TITLE_AND_PARTICIPANTS" },
            ({ participants, title }) => (
              <div className="flex items-center gap-2 pr-4">
                <AvatarCircles
                  sources={participants.map((p) => p.profilePhoto)}
                  size={40}
                  padding={0}
                />
                {title && <div className="text-white font-medium">{title}</div>}
              </div>
            )
          ),
          Match.when({ tag: "APPROVE_USER_ENTERED" }, ({ userName }) => (
            <div>{userName}</div> // TODO
          )),
          Match.exhaustive
        )}
        {/* <div className="text-white">{roomId}</div> */}
      </TopItemContainer>
      {clockSection}
    </div>
  );
};

const matchMeditationVideo = (mediaFile: KnownVisualizationMediaFile) => {
  return Match.value(mediaFile).pipe(
    Match.when("candle.mp4", () => Candle),
    Match.when("birdseye-waves.mp4", () => BirdseyeWaves),
    Match.when("stream.mp4", () => WaterStream),
    Match.exhaustive
  );
};
