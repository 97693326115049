import { useMutation } from "convex/react";
import { formatDistanceToNowStrict } from "date-fns";
import { Option } from "effect";
import { useCommunityDashVM } from "frontend-shared/src/domains/community/community-dash.vm";
import { ReplyStateMgr } from "frontend-shared/src/mgrs/state-mgrs/community.statemgr";
import { useObservableEagerState } from "observable-hooks";
import * as React from "react";
import { Link } from "react-router-dom";
import { RD } from "frontend-shared/prelude";
import type { CommunityPostReplyInfo } from "shared/be/convex/Community/Community.Types";
import {
  type EmbedContent,
  type EnrichedCommunityPost,
  type PostMention,
} from "shared/be/convex/Community/CommunityDiscussion.Types";
import { api } from "shared/be/convex/_generated/api";
import { UROUTES } from "shared/routes/u.routes";
import type { FileSchemas } from "shared/schemas/file.schemas";
import { ImageSrc } from "shared/types/miscellaneous.types";
import { PaperAirplaneIcon } from "web-shared/src/assets/images/paper-airplane.icon";
import { AvatarCircle } from "web-shared/src/components/avatar.tc";
import { FullContainerLoadingSpinner } from "web-shared/src/components/loading";
import { MentionableTextarea } from "web-shared/src/components/mentionable-textarea";
import { useMe, useWebGlobalDisplayVM } from "web-shared/src/web-context";
import { CreateCommunityPostFormCool } from "./create-community-post.form";

type Poster = {
  id: string;
  name: string;
  profilePhoto: string | null;
  isAvailableForPractice: boolean;
};

export const CommunityDiscussionTab: React.FC = () => {
  return (
    <div className="flex-1 flex flex-col gap-8 overflow-y-auto overscroll-x-none">
      <WriteAPostSection />
      <DiscussionFeed />
    </div>
  );
};

export const CommunityPostCvx: React.FC<{
  enrichedPost: EnrichedCommunityPost;
}> = ({ enrichedPost: post }) => {
  const onClickPostLikeButton = useMutation(
    api.Community.CommunityScreenFns.onClickPostLikeButton
  );
  const [replyStateMgr, setReplyStateMgr] = React.useState<
    Option.Option<ReplyStateMgr>
  >(Option.none());

  const onSubmitComment = useMutation(
    api.Community.CommunityScreenFns.onSubmitPostComment
  );

  return (
    <CommunityPostView
      key={post._id}
      mainInfo={{
        author: {
          name: post.author.name,
          profilePhoto: post.author.profilePhoto ?? null,
          id: post.author._id,
          isAvailableForPractice: false,
        },
        textContent: post.content,
        embedContent: post.embededContent,
        postedAt: new Date(post.postedAt),
        media: post.media
          ? {
              mainUrl: post.media.cachedDownloadUrl!,
              mediaType: post.media.mediaType,
            }
          : null,
        numLikes: post.reactions.length,
        isLikedByMe: post.isLikedByMe,
        mentions: post.mentions,
      }}
      isLikeDisabled={false}
      onSuccessSubmit={() => {
        setReplyStateMgr(Option.none());
      }}
      onCommentButtonClick={() => {
        setReplyStateMgr(
          Option.some(
            new ReplyStateMgr({
              postId: post._id,
              communitySlug: post.communitySlug,
              allCommunityMembers: [],
              submitReply: async (r) => {
                console.log("Successfully submitted reply! ", r);
                onSubmitComment({
                  postId: post._id,
                  commentContent: r.reply.content,
                }).catch();
              },
            })
          )
        );
      }}
      myReplyState={replyStateMgr}
      onLikePostClick={() =>
        onClickPostLikeButton({
          postId: post._id,
          currentLikeState: "unliked",
        })
      }
      onUnlikePostClick={() =>
        onClickPostLikeButton({
          postId: post._id,
          currentLikeState: "liked",
        })
      }
      replies={post.comments.map((c) => ({
        id: c._id,
        content: c.content,
        author: {
          id: c.authorId,
          name: c.author?.name ?? "",
          profilePhoto: c.author?.profilePhoto ?? null,
        },
        createdAt: new Date(c.repliedAt),
      }))}
    />
  );
};

export const CommunityPostView: React.FC<{
  mainInfo: {
    author: {
      name: string;
      profilePhoto: string | null;
      id: string;
      isAvailableForPractice: boolean;
    };
    textContent: string;
    embedContent: EmbedContent | null;
    postedAt: Date;
    media: { mainUrl: string; mediaType: FileSchemas.MediaType } | null;
    numLikes: number;
    isLikedByMe: boolean;
    mentions: PostMention[];
  };
  replies: CommunityPostReplyInfo[];
  isLikeDisabled: boolean;
  onCommentButtonClick: () => void;
  onSuccessSubmit: () => void;
  myReplyState: Option.Option<ReplyStateMgr>;
  onLikePostClick: () => void;
  onUnlikePostClick: () => void;
}> = ({
  mainInfo,
  replies,
  isLikeDisabled,
  myReplyState,
  onCommentButtonClick,
  onSuccessSubmit,
  onLikePostClick,
  onUnlikePostClick,
}) => {
  const {
    author,
    textContent,
    postedAt,
    media,
    numLikes,
    isLikedByMe,
    embedContent,
    mentions,
  } = mainInfo;

  return (
    <div className="flex flex-col max-w-[800px] lg:border lg:border-vid-black-200 rounded-lg lg:p-4">
      <PosterInfoSection postedAt={postedAt} poster={author} />
      <div className="px-1 py-2 lg:py-8 lg:px-4">
        <MentionableTextarea
          value={textContent}
          onChange={() => {}}
          data={mentions.map((m) => ({ id: m.id, display: m.name }))}
          readOnly={true}
          style={{
            minHeight: "auto",
            padding: 0,
            border: "none",
          }}
        />
      </div>
      <PostContent embedContent={embedContent} media={media} />
      <div className="flex gap-5 justify-between p-3 lg:p-6 w-full text-sm font-medium leading-4 whitespace-nowrap bg-white border border-[color:var(--Vidalify-Black-200,#DBDAEC)] max-md:flex-wrap max-md:px-5 max-md:max-w-full">
        <div className="self-stretch">
          {numLikes > 0 && (
            <div className="flex gap-3 justify-between text-gray-900">
              <img
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/6e009f647cc2c68c63e731310e044ad885937197095639739574fdec9df478ca?"
                className="w-6 aspect-square"
              />
              <div className="justify-center px-px py-1 my-auto aspect-[0.94]">
                {numLikes}
              </div>
            </div>
          )}
        </div>
        <div className="flex gap-5 justify-between text-neutral-400">
          {!isLikedByMe ? (
            <button
              className="flex gap-2 justify-between"
              disabled={isLikeDisabled}
              onClick={() => {
                onLikePostClick();
              }}
            >
              <UnlikedHeartSvg />
              <div className="hidden lg:flex justify-center px-px py-1 my-auto lg:aspect-[1.53]">
                Like
              </div>
            </button>
          ) : (
            <button
              disabled={isLikeDisabled}
              onClick={() => {
                onUnlikePostClick();
              }}
            >
              <LikedHeartSvg />
            </button>
          )}
          <div
            className="flex gap-2 justify-between cursor-pointer"
            onClick={() => {
              onCommentButtonClick();
            }}
          >
            <img
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/dd92debdbd1ee3fc4e91c304661aa4208cbb663e6423b216aaf4fb351bc7cab3?"
              className="w-6 aspect-square"
            />
            <div className="hidden lg:flex justify-center px-px py-1 my-auto aspect-[3.71]">
              Comment
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col gap-8 pt-4">
        <div className="flex flex-col gap-6">
          {Option.isSome(myReplyState) && (
            <div>
              <PostCommentView
                replyStateMgr={myReplyState.value}
                onSuccessSubmit={onSuccessSubmit}
              />
            </div>
          )}
          {[...replies].reverse().map((r) => (
            <ReplyItem key={r.id} reply={r} />
          ))}
        </div>
      </div>
    </div>
  );
};

const PostContent: React.FC<{
  embedContent: EmbedContent | null;
  media: { mainUrl: string; mediaType: FileSchemas.MediaType } | null;
}> = ({ embedContent, media }) => {
  if (embedContent) {
    return <EmbedContentView embedContent={embedContent} />;
  }

  if (media) {
    return <MediaContent media={media} />;
  }

  return <EmptyContent />;
};

const MediaContent: React.FC<{
  media: { mainUrl: string; mediaType: FileSchemas.MediaType };
}> = ({ media }) => (
  <div className="relative w-full h-[300px]">
    {media.mediaType === "video" ? (
      <video
        src={media.mainUrl}
        className="absolute inset-0 w-full h-full object-contain lg:object-cover"
        controls
        loop
        playsInline
      />
    ) : (
      <img
        src={media.mainUrl}
        className="absolute inset-0 w-full h-full object-contain lg:object-cover"
        alt=""
      />
    )}
  </div>
);

const EmptyContent: React.FC = () => (
  <div className="h-16 w-full bg-zinc-300" />
);

const EmbedContentView: React.FC<{ embedContent: EmbedContent }> = ({
  embedContent,
}) => {
  return (
    <div className="flex flex-col gap-2 rounded-lg border border-gray-200 p-4">
      {embedContent.thumbnail && (
        <img
          src={embedContent.thumbnail}
          alt={embedContent.title || "Embed thumbnail"}
          className="w-full h-48 object-cover rounded-lg"
        />
      )}
      <div className="flex flex-col gap-1">
        {embedContent.title && (
          <h3 className="font-medium text-lg">{embedContent.title}</h3>
        )}
        {embedContent.description && (
          <p className="text-gray-600 text-sm line-clamp-2">
            {embedContent.description}
          </p>
        )}
        {embedContent.type === "youtube" ? (
          <iframe
            src={embedContent.url.replace("watch?v=", "embed/")}
            title={embedContent.title || "YouTube video"}
            className="w-full aspect-video rounded-lg"
            allowFullScreen
          />
        ) : (
          <a
            href={embedContent.url}
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-600 hover:underline text-sm mt-1"
          >
            {embedContent.url}
          </a>
        )}
      </div>
    </div>
  );
};

const PostCommentView: React.FC<{
  replyStateMgr: ReplyStateMgr;
  onSuccessSubmit: () => void;
}> = ({ replyStateMgr, onSuccessSubmit }) => {
  const me = useMe();
  const inputValue = useObservableEagerState(replyStateMgr.myReplyText$);
  const rdSubmitResult = useObservableEagerState(replyStateMgr.rdSubmitResult$);
  const isInputtable = RD.isInitial(rdSubmitResult);

  return (
    <div className="flex gap-4 items-center max-md:flex-wrap relative">
      <AvatarCircle
        mbProfilePhoto={ImageSrc.fromMbUrl(me.profilePhoto)}
        size={40}
      />
      <div className="flex-1 h-14 relative">
        <div className="absolute inset-0 bg-transparent rounded-[500px] flex items-center">
          <MentionableTextarea
            value={inputValue}
            onChange={(v) => replyStateMgr.onInputChange(v)}
            data={replyStateMgr.p.allCommunityMembers.map((m) => ({
              id: m.id,
              display: m.name,
            }))}
            readOnly={!isInputtable}
            placeholder="Write a comment"
            style={{
              padding: "14px 48px 24px 24px",
              // border: "0.1px solid #DBDAEC",
              borderRadius: "500px",
            }}
          />
        </div>
        <div className="absolute bottom-0 right-0 top-0 flex justify-center items-center mt-2">
          <button
            className={`text-sm lg:text-base w-16 font-medium mb-2 ${
              !isInputtable
                ? "opacity-50 pointer-events-none"
                : "cursor-pointer text-vid-purple"
            }`}
            onClick={() => {
              replyStateMgr.onSubmitCommentClick().then(() => {
                onSuccessSubmit();
              });
            }}
          >
            <PaperAirplaneIcon />
          </button>
        </div>
      </div>
      {!isInputtable && (
        <div
          className={`
        absolute inset-0 flex justify-center items-center
        bg-gradient-to-r from-slate-200 via-slate-100 to-slate-200 bg-[length:200%_100%] animate-pulse-gradient 
        rounded-[500px] 
        opacity-80
        `}
        >
          {/* <LoadingSpinner /> */}
        </div>
      )}
    </div>
  );
};

const ReplyItem: React.FC<{ reply: CommunityPostReplyInfo }> = ({ reply }) => {
  return (
    <div className="flex gap-4 items-start">
      <AvatarCircle
        mbProfilePhoto={
          reply.author.profilePhoto
            ? ImageSrc.fromURL(reply.author.profilePhoto)
            : null
        }
        size={40}
      />
      <div className="flex-1 flex flex-col gap-3 bg-vid-black-100 rounded-[12px] p-2 lg:p-4 text-sm lg:text-base">
        <div className="flex items-center">
          <h4 className="font-semibold text-sm text-vid-black-900 flex items-center gap-[6px]">
            {reply.author.name} <span>•</span>
            <span className="text-vid-black-400">
              {formatTimeDistance(reply.createdAt)}
            </span>
          </h4>
        </div>
        <MentionableTextarea
          value={reply.content}
          onChange={() => {}}
          data={[]}
          readOnly={true}
          style={{
            color: "#5D5467",
          }}
        />
      </div>
    </div>
  );
};

const PosterInfoSection: React.FC<{ postedAt: Date; poster: Poster }> = ({
  postedAt,
  poster,
}) => {
  return (
    <Link
      to={UROUTES.HP.MY.DASHBOARD.COMMUNITY_TAB.GLOBAL.USER_PROFILE.USER_ID.ABOUT.buildPath(
        { userId: poster.id }
      )}
      className="flex gap-4 justify-between cursor-pointer"
    >
      {poster.profilePhoto && (
        <img
          src={poster.profilePhoto}
          className="w-10 h-10 lg:h-14 lg:w-14 aspect-square rounded-full object-cover bg-gray-100"
        />
      )}
      <div className="flex flex-col flex-1 my-auto">
        <div className="flex flex-col lg:flex-row gap-0 lg:gap-2 text-gray-900">
          <div className="font-semibold grow text-sm lg:text-base">
            {poster.name}
          </div>
          <div className="hidden lg:block">•</div>
          <div className="text-neutral-400 text-xs lg:text-base">
            {formatTimeDistance(postedAt)}
          </div>
        </div>
        {/* {poster.isAvailableForPractice && <AvailableForPracticeTag />} */}
      </div>
    </Link>
  );
};

const UnlikedHeartSvg: React.FC = () => {
  return (
    <svg width="24" height="24" fill="none" viewBox="0 0 24 24">
      <path
        fill="#fff"
        stroke="#1D1626"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M12.62 20.81c-.34.12-.9.12-1.24 0C8.48 19.82 2 15.69 2 8.69 2 5.6 4.49 3.1 7.56 3.1c1.82 0 3.43.88 4.44 2.24a5.53 5.53 0 014.44-2.24C19.51 3.1 22 5.6 22 8.69c0 7-6.48 11.13-9.38 12.12z"
      ></path>
    </svg>
  );
};

const LikedHeartSvg: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        fill="#690DFF"
        stroke="#690DFF"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M12.62 20.81c-.34.12-.9.12-1.24 0C8.48 19.82 2 15.69 2 8.69 2 5.6 4.49 3.1 7.56 3.1c1.82 0 3.43.88 4.44 2.24a5.53 5.53 0 014.44-2.24C19.51 3.1 22 5.6 22 8.69c0 7-6.48 11.13-9.38 12.12z"
      ></path>
    </svg>
  );
};

export const WriteAPostView: React.FC<{
  profilePhotoUrl: string | null;
  onWritePostClick: () => void;
}> = ({ profilePhotoUrl, onWritePostClick }) => {
  return (
    <div
      className="flex gap-4 justify-between px-5 py-4 bg-white rounded-xl border border-solid border-[color:var(--Vidalify-Black-200,#DBDAEC)] max-md:flex-wrap max-md:pl-5 cursor-pointer"
      onClick={onWritePostClick}
    >
      <div className="flex gap-3.5 justify-between w-full">
        <div className="self-stretch flex flex-col justify-center relative w-[56px] aspect-square">
          <AvatarCircle
            mbProfilePhoto={ImageSrc.fromMbUrl(profilePhotoUrl)}
            size={56}
          />
          <div className="absolute bottom-0 right-0 w-4 aspect-square p-[3px] z-20 bg-white flex flex-col rounded-full">
            <div className="flex-1 bg-available-green rounded-full" />
          </div>
        </div>
        <div className="grow justify-center items-start py-5 pr-16 pl-7 text-base font-medium leading-5 text-start whitespace-nowrap bg-slate-50 rounded-[500px] text-neutral-400 max-md:px-5 max-md:max-w-full">
          Write a post
        </div>
      </div>
      <div className="flex gap-0 pr-3.5 my-auto text-sm font-medium leading-4 text-center text-violet-800 whitespace-nowrap">
        <img
          loading="lazy"
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/66b4435ae7ea40d1c30cb8991b7be7ea34172d0a6d6874a790170499abf47bbc?"
          className="my-auto w-6 aspect-square"
        />
        <div className="grow justify-center px-1.5 py-3 bg-white rounded-xl">
          Add photo/video{" "}
        </div>
      </div>
    </div>
  );
};

const DiscussionFeed: React.FC = () => {
  const vm = useCommunityDashVM();

  const postsRes = useObservableEagerState(vm.postsRes$);

  if (postsRes === undefined) {
    return <FullContainerLoadingSpinner />;
  }

  return (
    <div className="flex flex-col gap-8">
      <div className="flex flex-col gap-8">
        {postsRes.posts.map((post) => (
          <CommunityPostCvx key={post._id} enrichedPost={post} />
        ))}
      </div>
    </div>
  );
};

export const WriteAPostSection: React.FC = () => {
  const vm = useCommunityDashVM();
  const community = vm.communityInfo;
  const webDisplayVM = useWebGlobalDisplayVM();
  const me = useMe();

  const handleWritePostClick = () => {
    webDisplayVM.globalSheetVM.openSheet(
      {
        title: "Write a post",
        type: { tag: "create" },
      },
      <CreateCommunityPostFormCool
        communitySlug={community.slug}
        onSuccessSubmit={() => {
          webDisplayVM.globalSheetVM.closeSheet();
          webDisplayVM.toastVM.showToast({
            id: "create-community-post-success",
            title: "Your post is live!",
          });
        }}
      />
    );
  };

  return (
    <WriteAPostView
      profilePhotoUrl={me.profilePhoto}
      onWritePostClick={handleWritePostClick}
    />
  );
};

const formatTimeDistance = (date: Date): string => {
  return formatDistanceToNowStrict(date, { addSuffix: false })
    .replace(/ minutes?/, "m")
    .replace(/ hours?/, "h")
    .replace(/ days?/, "d")
    .replace(/about /, "")
    .replace(/^1 day ago$/, "Yesterday");
};
