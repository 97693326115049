import { useMutation, useQuery } from "convex/react";
import { api } from "shared/be/convex/_generated/api";
import type { Id } from "shared/be/convex/_generated/dataModel";
import { format } from "date-fns";
import { FullContainerLoadingSpinner } from "web-shared/src/components/loading";
import { Match } from "effect";
import {
  InversePrimaryButton,
  PrimaryButton,
} from "web-shared/src/components/button";
import type { EventInviteResponseCta } from "shared/be/convex/Calendar/Calendar.Types";

export const ViewHcSessionDetailsPanel: React.FC<{
  instanceId: Id<"calEvents">;
}> = ({ instanceId }) => {
  const sessionDetails = useQuery(
    api.Calendar.HcCalEventFns.getHcInstanceDetails,
    {
      instanceId,
    }
  );

  if (!sessionDetails) {
    return <FullContainerLoadingSpinner />;
  }

  const { instance, mbHcSession, myParticipationInfo } = sessionDetails;

  return (
    <div className="flex flex-col space-y-6 p-6">
      {/* Session Time Details */}
      <div className="flex flex-col space-y-2">
        <h2 className="text-lg font-semibold text-gray-900">Session Time</h2>
        <div className="flex flex-col space-y-1 text-gray-600">
          <div className="flex items-center space-x-2">
            <span className="font-medium">Start:</span>
            <span>{format(new Date(instance.startTime), "PPp")}</span>
          </div>
          <div className="flex items-center space-x-2">
            <span className="font-medium">End:</span>
            <span>{format(new Date(instance.endTime), "PPp")}</span>
          </div>
          <div className="flex items-center space-x-2">
            <span className="font-medium">Duration:</span>
            <span>{instance.durationMinutes} minutes</span>
          </div>
          {myParticipationInfo.attendanceStatus && (
            <div className="flex items-center space-x-2">
              <span className="font-medium">Attendance Status:</span>
              <span>{myParticipationInfo.attendanceStatus}</span>
            </div>
          )}
          {myParticipationInfo.inviteStatus && (
            <div className="flex items-center space-x-2">
              <span className="font-medium">Invite Status:</span>
              <span>{myParticipationInfo.inviteStatus}</span>
            </div>
          )}
        </div>
      </div>

      <div className="flex flex-col space-y-2">
        {Match.value(myParticipationInfo.inviteStatus).pipe(
          Match.when("INVITED", () => {
            return (
              <div className="flex items-center space-x-2">
                <InviteResponseCtaButton
                  cta={"ACCEPT"}
                  instanceId={instanceId}
                />
                <InviteResponseCtaButton
                  cta={"REJECT"}
                  instanceId={instanceId}
                />
              </div>
            );
          }),
          Match.when("ACCEPTED", () => {
            return <div></div>;
          }),
          Match.when("DECLINED", () => {
            return <div></div>;
          }),
          Match.when("REMOVED", () => {
            return <div></div>;
          }),
          Match.when("HOST", () => {
            return <div>You are the host</div>;
          }),
          Match.exhaustive
        )}
      </div>

      {/* HC Session Details if available */}
      {mbHcSession && (
        <div className="flex flex-col space-y-2">
          <h2 className="text-lg font-semibold text-gray-900">
            HC Session Details
          </h2>
          <div className="flex flex-col space-y-1 text-gray-600">
            <div className="flex items-center space-x-2">
              <span className="font-medium">Session ID:</span>
              <span>{mbHcSession._id}</span>
            </div>
            {/* Add more HC session specific details here as needed */}
          </div>
        </div>
      )}
    </div>
  );
};

const InviteResponseCtaButton: React.FC<{
  cta: EventInviteResponseCta;
  instanceId: Id<"calEvents">;
}> = ({ cta, instanceId }) => {
  const handleInviteResponseCta = useMutation(
    api.Calendar.HcCalEventFns.handleInstanceInviteResponseCta
  );

  const onClick = () => {
    handleInviteResponseCta({
      instanceId,
      cta,
    });
  };

  return Match.value(cta).pipe(
    Match.when("ACCEPT", () => {
      return <PrimaryButton title="Accept" onClick={onClick} />;
    }),
    Match.when("CANCEL", () => {
      return <InversePrimaryButton title="Cancel" onClick={onClick} />;
    }),
    Match.when("RESCHEDULE", () => {
      return <InversePrimaryButton title="Reschedule" onClick={onClick} />;
    }),
    Match.when("REJECT", () => {
      return <InversePrimaryButton title="Reject" onClick={onClick} />;
    }),
    Match.exhaustive
  );
};
