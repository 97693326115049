import { createEffect, createEvent, createStore, sample } from "effector";
import type { Id } from "shared/be/convex/_generated/dataModel";

export type InviteResult = {
  invite: any; // Replace with proper type from your Convex types
  redemptionId: Id<"inviteRedemptions">;
  redirectPath: string;
};

export class InviteVM {
  // Events
  validateInviteFx = createEffect(
    async (params: { inviteId: Id<"inviteLinks">; email?: string }) => {
      const { inviteId, email } = params;
      return await this.validateAndTrackInvite({ inviteId, email });
    }
  );

  setErrorEvt = createEvent<string>();
  clearErrorEvt = createEvent();

  // Stores
  $error = createStore<string | null>(null)
    .on(this.setErrorEvt, (_, error) => error)
    .reset(this.clearErrorEvt);

  $inviteResult = createStore<InviteResult | null>(null)
    .on(this.validateInviteFx.doneData, (_, result) => result)
    .reset(this.clearErrorEvt);

  constructor(
    private validateAndTrackInvite: (args: {
      inviteId: Id<"inviteLinks">;
      email?: string;
    }) => Promise<InviteResult>
  ) {
    // Handle validation errors
    sample({
      clock: this.validateInviteFx.failData,
      fn: (error: Error) => error.message,
      target: this.setErrorEvt,
    });
  }

  // Helper to store pending invite in localStorage
  storePendingInvite(result: InviteResult) {
    localStorage.setItem(
      "pendingInvite",
      JSON.stringify({
        inviteId: result.invite._id,
        redemptionId: result.redemptionId,
        redirectPath: result.redirectPath,
      })
    );
  }

  // Helper to get and clear pending invite
  getPendingInvite() {
    const pendingInviteStr = localStorage.getItem("pendingInvite");
    if (pendingInviteStr) {
      localStorage.removeItem("pendingInvite");
      try {
        return JSON.parse(pendingInviteStr);
      } catch (err) {
        console.error("Error processing pending invite:", err);
      }
    }
    return null;
  }
}
