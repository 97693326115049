import { matchRouteForAuthedDestination } from "@/src/domains/locations/route-maps";
import { Outlet, useNavigate } from "react-router-dom";
import { ROUTES } from "shared/routes/routes";
import { UROUTES } from "shared/routes/u.routes";
import { UGuard } from "web-shared/src/domains/user/user-guard";

export const ULayout: React.FC = () => {
  const nav = useNavigate();
  return (
    <UGuard
      navigate={nav}
      matchRouteForAuthedDestination={matchRouteForAuthedDestination}
      contextMenuOverlay={{
        onSelectClient: (clientId) => {
          nav(
            UROUTES.HP.MY.DASHBOARD.CLIENTS.CLIENT_ID.PAST_SESSIONS.buildPath({
              clientId: clientId,
            })
          );
        },
        onSelectCommunity: (communitySlug) => {
          nav(
            UROUTES.HP.MY.DASHBOARD.COMMUNITY_TAB.COMMUNITIES.COMMUNITY.DISCUSSION.buildPath(
              {
                community: communitySlug,
              }
            )
          );
        },
      }}
      onUnauthenticated={() => {
        nav(ROUTES.AUTH.AUTHIN.path);
      }}
    >
      <Outlet />
    </UGuard>
  );
};
