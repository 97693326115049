import { Match } from "effect";
import type { AbstractAudioPlayer } from "frontend-shared/src/mgrs/media-player.statemgr";
import meditationMp3 from "frontend-shared/src/resources/meditation.mp3";
import metalClink from "frontend-shared/src/resources/metal-clink.mp3";
import endingMeditationBell from "frontend-shared/src/resources/ending-meditation-bell.mp3";
import type { KnownMediaSoundFile } from "shared/be/convex/Rtc/Session.Types";

export class WebkitAudioPlayer implements AbstractAudioPlayer {
  private audioContext: AudioContext;
  private sourceNode: AudioBufferSourceNode | null = null;
  private gainNode: GainNode;
  private audioBuffer: AudioBuffer | null = null;
  private isLoading: boolean = false;

  constructor(private onDone: () => void) {
    this.audioContext = new (window.AudioContext ||
      (window as any).webkitAudioContext)();
    this.gainNode = this.audioContext.createGain();
    this.gainNode.connect(this.audioContext.destination);
  }

  setOnDone(onDone: () => void) {
    this.onDone = onDone;
  }

  async setMediaAudioBuffer(p: { source: string }) {
    const response = await fetch(p.source);
    const arrayBuffer = await response.arrayBuffer();
    this.audioBuffer = await this.audioContext.decodeAudioData(arrayBuffer);
  }

  async setSource(source: KnownMediaSoundFile): Promise<void> {
    if (this.isLoading) return;

    try {
      this.isLoading = true;
      this.audioBuffer = null;
      const sourceUrl = this.sourceForKnownMediaFile(source);
      const response = await fetch(sourceUrl);
      if (!response.ok) {
        throw new Error(`Failed to fetch audio: ${response.statusText}`);
      }
      const arrayBuffer = await response.arrayBuffer();
      this.audioBuffer = await this.audioContext.decodeAudioData(arrayBuffer);
    } catch (error) {
      console.error("Failed to load audio:", error);
      this.audioBuffer = null;
      throw error;
    } finally {
      this.isLoading = false;
    }
  }

  private sourceForKnownMediaFile(source: KnownMediaSoundFile): string {
    return Match.value(source).pipe(
      Match.when("meditation.mp3", () => meditationMp3),
      Match.when("simple-singing-bowl.mp3", () => meditationMp3),
      Match.when("ocean-waves.mp3", () => meditationMp3),
      Match.when("click", () => metalClink),
      Match.when("beep", () => meditationMp3),
      Match.when("ending-meditation-bell", () => endingMeditationBell),
      Match.exhaustive
    );
  }

  async ensureAudioContext(): Promise<void> {
    if (this.audioContext.state === "suspended") {
      await this.audioContext.resume();
    }
  }

  async setSourceAndPlay(
    knownMediaFile: KnownMediaSoundFile,
    options?: { autoStopAfterNSeconds?: number }
  ) {
    await this.ensureAudioContext();
    await this.setSource(knownMediaFile)
      .then((_) => {
        console.log("PLAYING AUDIO BUFFER: ", knownMediaFile);
        this.playAudioBuffer(options);
      })
      .catch((error) => {
        console.error("Error setting source and playing:", error);
      });
  }

  playAudioBuffer(options?: { autoStopAfterNSeconds?: number }): void {
    if (!this.audioBuffer) return;

    this.stop();

    this.sourceNode = this.audioContext.createBufferSource();
    this.sourceNode.buffer = this.audioBuffer;
    this.sourceNode.connect(this.gainNode);

    this.sourceNode.onended = () => {
      this.onDone();
    };

    this.sourceNode.start();

    if (options?.autoStopAfterNSeconds) {
      setTimeout(() => {
        this.stop();
      }, options.autoStopAfterNSeconds * 1000);
    }
  }

  pause() {
    this.audioContext.suspend();
  }

  stop() {
    if (this.sourceNode) {
      try {
        this.sourceNode.stop();
        this.sourceNode.disconnect();
        this.sourceNode = null;
      } catch (error) {
        console.error("Error stopping audio:", error);
      }
    }
    this.audioContext.resume();
    this.onDone();
  }

  async playWithGainEnvelope(params: {
    gain: number;
    duration: number;
    startOffset?: number;
  }) {
    if (!this.audioBuffer) return;

    try {
      await this.audioContext.resume();
      this.stop();

      this.sourceNode = this.audioContext.createBufferSource();
      this.sourceNode.buffer = this.audioBuffer;
      this.sourceNode.connect(this.gainNode);

      const initialGain = params.gain * 20;
      this.gainNode.gain.cancelScheduledValues(this.audioContext.currentTime);
      this.gainNode.gain.setValueAtTime(
        initialGain,
        this.audioContext.currentTime
      );

      // Get the current loaded source type
      const currentSource =
        this.sourceNode.buffer === this.audioBuffer ? "beep" : "other";
      const durationMultiplier = currentSource === "beep" ? 3 : 1.5;

      this.gainNode.gain.linearRampToValueAtTime(
        0,
        this.audioContext.currentTime + params.duration * durationMultiplier
      );

      this.sourceNode.start(0, params.startOffset ?? 0);

      this.sourceNode.onended = () => {
        this.onDone();
      };
    } catch (error) {
      console.error("Error during audio playback:", error);
    }
  }
}

// Remove the commented-out WebAudioPlayer class and usage example
