import type { DragEndEvent } from "@dnd-kit/core";
import { useMutation } from "convex/react";
import {
  combine,
  createEffect,
  createEvent,
  type Store,
  type StoreWritable,
} from "effector";
import { useLiveRoomVM } from "frontend-shared/src/sessions/live-room.vm";
import { createState, useOnce, useQueryStore } from "frontend-shared/src/util";
import { useEffect, useState } from "react";
import { api } from "shared/be/convex/_generated/api";
import type { Doc, Id } from "shared/be/convex/_generated/dataModel";
import type { DraftBreakoutRoom } from "shared/be/convex/Rtc/Rooms/Room.Types";

export interface BrSessionInfo {
  _id: Id<"rtcBreakoutRoomSessions">;
  status: "DRAFT" | "ACTIVE" | "ENDED";
}

export function useSetupBreakoutRoomsFormVM(): {
  setup: BrSessionInfo | undefined;
  activeSession: Doc<"rtcBreakoutRoomSessions"> | null;
} {
  const { baseSessionId } = useLiveRoomVM();
  const [state, setState] = useState<{
    setup: BrSessionInfo | undefined;
    activeSession: Doc<"rtcBreakoutRoomSessions"> | null;
  }>({
    setup: undefined,
    activeSession: null,
  });

  const onOpenBreakoutRoomsPanel = useMutation(
    api.Rtc.Rooms.Breakout.BreakoutRoomSetupFns.onOpenBreakoutRoomsPanel
  );

  useEffect(() => {
    onOpenBreakoutRoomsPanel({
      sessionId: baseSessionId,
    }).then(({ activeSession, draftSession }) => {
      if (activeSession) {
        setState({
          setup: undefined,
          activeSession,
        });
      } else if (draftSession) {
        setState({
          setup: {
            _id: draftSession._id,
            status: draftSession.status,
          },
          activeSession: null,
        });
      }
    });
  }, [baseSessionId]);

  return state;
}

export function useBreakoutRoomsVM(p: {
  brSessionId: Id<"rtcBreakoutRoomSessions">;
}): SetupBreakoutRoomsVM {
  const { baseSessionId } = useLiveRoomVM();

  const usersToAssign = useQueryStore(
    api.Rtc.Rooms.Breakout.BreakoutRoomSetupFns.getCurrentAssignments,
    {
      brSessionId: p.brSessionId,
    }
  );

  const usersInRooms = useQueryStore(
    api.Rtc.Rooms.Breakout.BreakoutRoomSetupFns.getCurrentAssignments,
    {
      brSessionId: p.brSessionId,
    }
  );

  const setNumberOfRooms = useMutation(
    api.Rtc.Rooms.Breakout.BreakoutRoomSetupFns.setNumberOfRooms
  );

  const updateRoomAssignments = useMutation(
    api.Rtc.Rooms.Breakout.BreakoutRoomSetupFns.updateRoomAssignments
  ).withOptimisticUpdate((localStore, args) => {
    localStore.setQuery(
      api.Rtc.Rooms.Breakout.BreakoutRoomSetupFns.getCurrentAssignments,
      { brSessionId: args.brSessionId },
      args.assignments.map((assignment) => ({
        brSessionRoomId: assignment.brSessionRoomId,
        users: assignment.users,
        title: `Room ${assignment.brSessionRoomId}`,
      }))
    );
  });

  const launchBreakoutRooms = useMutation(
    api.Rtc.Rooms.Breakout.BreakoutRoomSetupFns.launchBreakoutRooms
  );

  const endBreakoutRooms = useMutation(
    api.Rtc.Rooms.Breakout.BreakoutRoomControlFns.endBreakoutRooms
  );

  const vm = useOnce(
    () =>
      new SetupBreakoutRoomsVM({
        usersToAssign: usersToAssign.store,
        usersInRooms: usersInRooms.store,
        setNumberOfBreakoutRooms: (numBreakoutRooms) =>
          setNumberOfRooms({
            sessionId: baseSessionId,
            brSessionId: p.brSessionId,
            numRooms: numBreakoutRooms,
          }),
        setUsersInRoomsOnServer: (usersInRooms) =>
          updateRoomAssignments({
            sessionId: baseSessionId,
            brSessionId: p.brSessionId,
            assignments: usersInRooms.map((room) => ({
              brSessionRoomId: room.brSessionRoomId,
              users: room.users,
            })),
          }),
        cancel: async () => {
          return endBreakoutRooms({
            sessionId: baseSessionId,
            brSessionId: p.brSessionId,
          });
        },
        submit: () =>
          launchBreakoutRooms({
            sessionId: baseSessionId,
            brSessionId: p.brSessionId,
          }),
      })
  );

  return vm;
}

class SetupBreakoutRoomsVM {
  $usersInRooms: StoreWritable<DraftBreakoutRoom[] | null>;
  $usersToAssign: StoreWritable<DraftBreakoutRoom[] | null>;
  $unassignedUsers: Store<Id<"users">[] | null>;
  $activeUser = createState<Id<"users"> | null>(null);

  dragEndEvt = createEvent<DragEndEvent>();

  submitFx = createEffect<void, void>();
  cancelFx = createEffect<void, void>();
  setUsersInRoomsFx = createEffect<
    { brSessionRoomId: string; users: Id<"users">[] }[],
    void
  >();
  setNumberOfBreakoutRoomsFx = createEffect<number, null>();

  moveUserToUnassigned = createEvent<Id<"users">>();
  moveUserToRoom = createEvent<{ userId: Id<"users">; roomId: string }>();

  constructor(args: {
    usersToAssign: StoreWritable<DraftBreakoutRoom[] | null>;
    usersInRooms: StoreWritable<DraftBreakoutRoom[] | null>;
    setNumberOfBreakoutRooms: (numBreakoutRooms: number) => Promise<null>;
    setUsersInRoomsOnServer: (
      usersInRooms: { brSessionRoomId: string; users: Id<"users">[] }[]
    ) => void;
    submit: () => Promise<null>;
    cancel: () => Promise<null>;
  }) {
    this.$usersInRooms = args.usersInRooms;
    this.$usersToAssign = args.usersToAssign;
    this.setUsersInRoomsFx.use(args.setUsersInRoomsOnServer);
    this.setNumberOfBreakoutRoomsFx.use(args.setNumberOfBreakoutRooms);
    this.submitFx.use(async () => {
      await args.submit();
    });

    this.cancelFx.use(async () => {
      await args.cancel();
    });

    this.$unassignedUsers = combine(
      this.$usersToAssign,
      this.$usersInRooms,
      (usersToAssign, usersInRooms) => {
        if (!usersToAssign || !usersInRooms) return [];

        const assignedUsers = new Set(
          usersInRooms.flatMap((room) => room.users)
        );

        return usersToAssign
          .flatMap((room) => room.users)
          .filter((userId) => !assignedUsers.has(userId));
      }
    );

    this.moveUserToRoom.watch(({ userId, roomId }) => {
      const usersInRooms = this.$usersInRooms.getState();

      if (!usersInRooms) return;

      // First, remove the user from all rooms
      const cleanedRooms = usersInRooms.map((room) => ({
        ...room,
        users: room.users.filter((id) => id !== userId),
      }));

      // Then add the user to the target room
      const newUsersInRooms = cleanedRooms.map((room) => {
        if (room.brSessionRoomId === roomId) {
          return { brSessionRoomId: roomId, users: [...room.users, userId] };
        }
        return { brSessionRoomId: room.brSessionRoomId, users: room.users };
      });

      this.setUsersInRoomsFx(newUsersInRooms);
    });
  }

  setActiveUser(user: Id<"users"> | null) {
    this.$activeUser.event(user);
  }

  submit() {
    this.submitFx();
  }

  cancel() {
    this.cancelFx();
  }
}
