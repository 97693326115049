import { createEvent, createStore, sample } from "effector";
import type { SoundType } from "shared/be/convex/Rtc/Emdr/Emdr.Types";
import type { EmdrSoundType } from "shared/be/convex/Rtc/Rooms/Activity/Activity.Types";
import { AbstractAudioPlayer } from "frontend-shared/src/mgrs/media-player.statemgr";

export interface EmdrBallConfig {
  ballSize: number;
  containerWidth: number;
  frequency: number;
}

export interface AudioConfig {
  soundType: SoundType;
  soundDuration: number;
}

interface PlaySoundArgs {
  gain: number;
  soundType: SoundType;
  soundDuration: number;
}

export class EmdrBallVisVM {
  readonly $position = createStore(0);
  readonly $direction = createStore(1);
  readonly $isPlaying = createStore(false);
  readonly $frequency = createStore(1);
  readonly $ballSize = createStore(24);
  readonly $containerWidth = createStore(600);
  readonly $soundType = createStore<EmdrSoundType>("click");
  readonly $soundDuration = createStore(0.1);
  readonly $gain = createStore(1);
  readonly $isAudioLoading = createStore(true);

  // Events
  readonly setPosition = createEvent<number>();
  readonly setFrequency = createEvent<number>();
  readonly setBallSize = createEvent<number>();
  readonly setContainerWidth = createEvent<number>();
  readonly tick = createEvent();
  readonly configChanged = createEvent<EmdrBallConfig>();
  readonly playStateChanged = createEvent<boolean>();
  readonly setSoundType = createEvent<EmdrSoundType>();
  readonly setSoundDuration = createEvent<number>();
  readonly audioRequested = createEvent();
  readonly loadAudioRequested = createEvent<SoundType>();
  readonly setAudioLoadingEvt = createEvent<boolean>();

  // Public events that components can subscribe to
  readonly passMade = createEvent();
  readonly playSoundEvt = createEvent<PlaySoundArgs>();

  private intervalId: NodeJS.Timer | null = null;
  private audioPlayer: AbstractAudioPlayer;

  constructor(cargs: { audioPlayer: AbstractAudioPlayer }) {
    this.audioPlayer = cargs.audioPlayer;

    // First sample handles position updates
    sample({
      clock: this.tick,
      source: {
        position: this.$position,
        direction: this.$direction,
        frequency: this.$frequency,
        ballSize: this.$ballSize,
        containerWidth: this.$containerWidth,
      },
      filter: this.$isPlaying,
      fn: ({ position, direction, frequency, ballSize, containerWidth }) => {
        const frameTime = 1000 / 60;
        const range = containerWidth - ballSize;
        const pixelsPerSecond = (containerWidth * (frequency / 2)) / 3;
        const distancePerFrame = (pixelsPerSecond * frameTime) / 1000;
        const newPos = position + distancePerFrame * direction;

        // Clamp the position between 0 and range
        if (newPos >= range) return range;
        if (newPos <= 0) return 0;
        return newPos;
      },
      target: this.$position,
    });

    // Add this new sample to handle direction changes
    sample({
      clock: this.$position,
      source: {
        direction: this.$direction,
        ballSize: this.$ballSize,
        containerWidth: this.$containerWidth,
      },
      filter: (_, pos) => {
        const range = _.containerWidth - _.ballSize;
        return pos <= 0 || pos >= range;
      },
      fn: ({ direction }) => {
        return direction * -1;
      },
      target: this.$direction,
    });

    // Separate sample to trigger passMade when hitting boundaries
    sample({
      clock: this.$position,
      source: {
        ballSize: this.$ballSize,
        containerWidth: this.$containerWidth,
      },
      filter: (source, pos) => {
        const range = source.containerWidth - source.ballSize;
        return pos >= range || pos <= 0;
      },
      target: this.passMade,
    });

    sample({
      clock: this.passMade,
      source: {
        gain: this.$gain,
        soundType: this.$soundType,
        soundDuration: this.$soundDuration,
        isAudioLoading: this.$isAudioLoading,
      },
      filter: ({ isAudioLoading }) => !isAudioLoading,
      fn: ({ gain, soundType, soundDuration }) => ({
        gain,
        soundType,
        soundDuration,
      }),
      target: this.playSoundEvt,
    });

    this.playSoundEvt.watch((args) => {
      this.audioPlayer.playWithGainEnvelope({
        gain: args.gain,
        duration: args.soundDuration,
        startOffset: 0.2,
      });
    });

    this.$isPlaying.on(this.playStateChanged, (_, isPlaying) => isPlaying);
    this.$frequency.on(this.setFrequency, (_, frequency) => frequency);
    this.$ballSize.on(this.setBallSize, (_, ballSize) => ballSize);
    this.$containerWidth.on(
      this.setContainerWidth,
      (_, containerWidth) => containerWidth
    );

    this.$isPlaying.watch((isPlaying) => {
      console.log("isPlaying", isPlaying);
      if (isPlaying) {
        this.startAnimation();
      } else {
        console.log("stopping animation");
        this.stopAnimation();
        this.audioPlayer.stop();
      }
    });

    // Audio-related logic
    this.$soundType.on(this.setSoundType, (_, soundType) => soundType);
    this.$soundDuration.on(this.setSoundDuration, (_, duration) => duration);

    // Update gain when sound type changes
    sample({
      clock: this.$soundType,
      fn: (soundType) => {
        switch (soundType) {
          case "click":
            return 5;
          case "beep":
            return 7;
          default:
            return 1;
        }
      },
      target: this.$gain,
    });

    sample({
      clock: this.$soundType,
      fn: (soundType) => this.durationForSoundType(soundType),
      target: this.$soundDuration,
    });

    // Trigger audio when passing boundaries
    sample({
      clock: this.passMade,
      target: this.audioRequested,
    });

    // Update audio loading state
    this.$isAudioLoading.on(
      this.setAudioLoadingEvt,
      (_, isLoading) => isLoading
    );
  }

  private startAnimation = () => {
    const frameTime = 1000 / 60; // 60 FPS
    this.intervalId = setInterval(() => {
      this.tick();
    }, frameTime);
  };

  private stopAnimation = () => {
    if (this.intervalId !== null) {
      clearInterval(this.intervalId);
      this.intervalId = null;
    }
  };

  // Public methods
  updateConfig(config: EmdrBallConfig) {
    this.configChanged(config);
  }

  setPlayState(isPlaying: boolean) {
    this.playStateChanged(isPlaying);
  }

  destroy() {
    this.stopAnimation();
  }

  durationForSoundType(soundType: SoundType): number {
    switch (soundType) {
      case "click":
        return 0.07;
      case "beep":
        return 0.3;
      default:
        return 0.07;
    }
  }

  setAudioLoading(isLoading: boolean) {
    this.setAudioLoadingEvt(isLoading);
  }
}
