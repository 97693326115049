import type { Call, StreamVideoClient } from "@stream-io/video-react-sdk";
import { useQuery } from "convex/react";
import { Option } from "effect";
import { useUnit } from "effector-react";
import { useSetupUStateMgr } from "frontend-shared/src/mgrs/state-mgrs/user/user.statemgr";
import { useGetMyInitialAuthedDestination } from "frontend-shared/src/onboard/authin.vm";
import { useNotificationToasts } from "frontend-shared/src/toasts/use-notifications-toast";
import { X } from "lucide-react";
import { useEffect, useState } from "react";
import { api } from "shared/be/convex/_generated/api";
import type { Id } from "shared/be/convex/_generated/dataModel";
import type {
  AuthedDestination,
  SimpleUserWithProfilePhoto,
} from "shared/be/convex/User/User.Types";
import { ImageSrc } from "shared/types/miscellaneous.types";
import {
  useWebAppSession,
  useWebGlobalDisplayVM,
  UWebStateMgr,
  UWebStateMgrContext,
  type DialogVM,
} from "web-shared/src/web-context";
import { AvatarCircle } from "../components/avatar.tc";
import { FullScreenLoadingSpinner } from "../components/loading";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
  CommandSeparator,
} from "../components/ui/command";
import { Dialog, DialogContent } from "../components/ui/dialog";
import {
  DefaultSheetHeader,
  Sheet,
  SheetContent,
} from "../components/ui/sheet";
import { Toaster } from "../components/ui/toast";
import { Messenger } from "../domains/chat/messenger";
import type { WebRtcMgr } from "../web-rtc.mgr";

export function useHandleInitialNavigation(
  navigate: (path: string) => void,
  getRouteForAuthedDestination: (
    currentHref: string,
    destination: AuthedDestination
  ) => string | null
) {
  const [hasNavigatedIfNeeded, setHasNavigatedIfNeeded] = useState(false);
  const destination = useGetMyInitialAuthedDestination();
  const me = useQuery(api.User.UserFns.getAssumedSimpleMe, {});

  useEffect(() => {
    if (destination === undefined) {
      return;
    }

    console.log("DESTINATION IN USE HANDLE INITIAL NAVIGATION! ", destination);

    // Check for pending invite
    if (destination.tag !== "ONBOARD") {
      const pendingInviteStr = localStorage.getItem("pendingInvite");
      if (pendingInviteStr) {
        try {
          const pendingInvite = JSON.parse(pendingInviteStr);
          localStorage.removeItem("pendingInvite"); // Clear it
          navigate(pendingInvite.redirectPath);
          setHasNavigatedIfNeeded(true);
          return;
        } catch (err) {
          console.error("Error processing pending invite:", err);
        }
      }
    }

    // Normal navigation flow
    const currentPath = window.location.pathname;
    const nextRoute = getRouteForAuthedDestination(currentPath, destination);
    if (nextRoute !== null) {
      navigate(nextRoute);
    }
    setHasNavigatedIfNeeded(true);
  }, [destination]);

  return { me, hasNavigatedIfNeeded };
}

function useSetupGlobalWebAuthedContext(me: SimpleUserWithProfilePhoto) {
  useWebAppSession();

  const webStateMgr = useWebGlobalDisplayVM();
  const isSearching = useUnit(webStateMgr.globalSearchCommandVM.$isSearching);

  const [mbOpenSheet] = useUnit([webStateMgr.globalSheetVM.openVM.store]);

  const mbUStateMgr = useSetupUStateMgr<
    Call,
    StreamVideoClient,
    WebRtcMgr,
    UWebStateMgr
  >(
    me,
    (p) =>
      new UWebStateMgr({
        ...p,
        userId: me.id,
        toastVM: webStateMgr.toastVM,
      })
  );

  console.log("MB U STATE MGR! ", mbUStateMgr);

  useNotificationToasts(webStateMgr.toastVM);

  return {
    mbUStateMgr,
    webStateMgr,
    isSearching,
    mbOpenSheet,
  };
}

export const AuthedSuccess: React.FC<{
  navigate: (path: string) => void;
  matchRouteForAuthedDestination: (
    currentHref: string,
    destination: AuthedDestination
  ) => string | null;
  contextMenuOverlay: {
    onSelectClient: (clientId: Id<"users">) => void;
    onSelectCommunity: (communitySlug: string) => void;
  };
  children: React.ReactNode;
}> = ({
  navigate,
  matchRouteForAuthedDestination,
  contextMenuOverlay,
  children,
}) => {
  const { me, hasNavigatedIfNeeded } = useHandleInitialNavigation(
    navigate,
    matchRouteForAuthedDestination
  );

  if (me === undefined || !hasNavigatedIfNeeded) {
    return <FullScreenLoadingSpinner />;
  }

  return (
    <AuthedViewProvider me={me} contextMenuOverlay={contextMenuOverlay}>
      {children}
    </AuthedViewProvider>
  );
};

const AuthedViewProvider: React.FC<{
  me: SimpleUserWithProfilePhoto;
  contextMenuOverlay: {
    onSelectClient: (clientId: Id<"users">) => void;
    onSelectCommunity: (communitySlug: string) => void;
  };
  children: React.ReactNode;
}> = ({ me, contextMenuOverlay, children }) => {
  const { mbUStateMgr, webStateMgr, isSearching, mbOpenSheet } =
    useSetupGlobalWebAuthedContext(me);

  if (Option.isNone(mbUStateMgr)) {
    return <FullScreenLoadingSpinner />;
  }

  return (
    <UWebStateMgrContext.Provider value={mbUStateMgr.value}>
      <Toaster vm={webStateMgr.toastVM} />
      <AppDialog vm={webStateMgr.globalDialogVM} />
      <Sheet open={mbOpenSheet !== null}>
        {mbOpenSheet && (
          <SheetContent
            className="h-full flex flex-col"
            onClose={() => webStateMgr.globalSheetVM.closeSheet()}
          >
            <div className="flex-1 flex flex-col gap-4">
              <DefaultSheetHeader
                title={mbOpenSheet!.config.title}
                type={mbOpenSheet!.config.type}
              />
              <div className="flex-1 flex flex-col pt-8">
                {mbOpenSheet!.content}
              </div>
            </div>
          </SheetContent>
        )}
      </Sheet>
      {children}
      <Messenger
        vm={mbUStateMgr.value.messengerVM}
        roomsIBelongTo$={mbUStateMgr.value.p.roomsIBelongTo$}
        onRoomClick={(roomId) => {
          mbUStateMgr.value.messengerVM.setViewStateEvt({
            _tag: "CHAT_ROOM",
            roomId,
          });
        }}
      />
      {isSearching && (
        <div className="absolute inset-0 z-50 bg-black/50 flex flex-col items-center py-8 px-8">
          <div className="flex justify-end w-full">
            <button
              onClick={() => {
                webStateMgr.globalSearchCommandVM.closeSearchEvt();
              }}
              className="text-white hover:text-gray-200 p-4 border rounded-full "
            >
              <X size={48} />
            </button>
          </div>
          <ContextMenuOverlay
            onSelectClient={(clientId) => {
              contextMenuOverlay.onSelectClient(clientId);
            }}
            onSelectCommunity={(communitySlug) => {
              contextMenuOverlay.onSelectCommunity(communitySlug);
            }}
          />
        </div>
      )}
    </UWebStateMgrContext.Provider>
  );
};

const AppDialog: React.FC<{
  vm: DialogVM;
}> = ({ vm }) => {
  const [mbOpen] = useUnit([vm.openVM.store]);

  return (
    <Dialog open={mbOpen !== null}>
      <DialogContent
        onClose={() => {
          vm.openVM.event(null);
        }}
        className="max-w-2xl h-[700px] flex flex-col"
      >
        {mbOpen}
      </DialogContent>
    </Dialog>
  );
};

const ContextMenuOverlay: React.FC<{
  onSelectClient: (clientId: Id<"users">) => void;
  onSelectCommunity: (communitySlug: string) => void;
}> = ({ onSelectClient, onSelectCommunity }) => {
  const webStateMgr = useWebGlobalDisplayVM();
  const myActiveClients = useQuery(
    api.Screens.Hp.Dashboard.ClientsScreenFns.getMyActiveClients
  );

  const allPublicCommunities = useQuery(
    api.Community.CommunityFns.getAllPublicCommunities
  );

  if (myActiveClients === undefined || allPublicCommunities === undefined) {
    return null;
  }

  return (
    <div className="w-[700px] min-h-[500px]">
      <Command className="rounded-lg border shadow-md">
        <CommandInput
          placeholder="Search for clients and communities..."
          className="h-16 text-xl px-4 placeholder:text-black-400"
          autoFocus
        />
        <CommandList>
          <CommandEmpty>No results found.</CommandEmpty>
          <CommandGroup heading="Clients">
            {myActiveClients.clients.map((client) => (
              <CommandItem
                key={client.clientId}
                onSelect={() => {
                  onSelectClient(client.clientId);
                  webStateMgr.globalSearchCommandVM.closeSearchEvt();
                }}
              >
                <div className="flex items-center gap-2 cursor-pointer">
                  <AvatarCircle
                    mbProfilePhoto={ImageSrc.fromMbUrl(client.profilePhoto)}
                    size={48}
                  />
                  <div className="flex flex-col">
                    <div className="text-sm font-medium">{client.name}</div>
                    <div className="text-xs text-gray-500">{client.email}</div>
                  </div>
                </div>
              </CommandItem>
            ))}
          </CommandGroup>
          <CommandSeparator />
          <CommandGroup heading="Communities">
            {allPublicCommunities.map((community) => (
              <CommandItem
                key={community._id}
                onSelect={() => {
                  onSelectCommunity(community.slug);
                  webStateMgr.globalSearchCommandVM.closeSearchEvt();
                }}
              >
                <div className="flex items-center gap-2 cursor-pointer">
                  <div className="text-lg font-medium">{community.name}</div>
                </div>
              </CommandItem>
            ))}
          </CommandGroup>
        </CommandList>
      </Command>
    </div>
  );
};
