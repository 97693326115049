import { v } from "convex/values";
import type { Id } from "../../_generated/dataModel";
import { knownMediaSoundFileSchema } from "../Session.Types";

/* A user is always in 1 of 4 possible rooms:

1. Admissions Lobby
2. Backstage 
3. Main room
4. Breakout room

Where main and breakout are live rooms.
*/

export const liveRoomType = v.union(
  v.object({
    tag: v.literal("MAIN"),
  }),
  v.object({
    tag: v.literal("BREAKOUT"),
    name: v.string(),
    brSessionRoomId: v.string(),
    description: v.optional(v.string()),
  })
);
export type LiveRoomType = typeof liveRoomType.type;

export function MainRoom(): LiveRoomType {
  return { tag: "MAIN" };
}
export function BreakoutRoom(args: {
  name: string;
  brSessionRoomId: string;
  description?: string;
}): LiveRoomType {
  return { tag: "BREAKOUT", ...args };
}

export type PreLiveRoom = "ADMISSIONS_LOBBY";
export function AdmissionsLobby(): PreLiveRoom {
  return "ADMISSIONS_LOBBY";
}

export type PostLiveRoomType = "EXIT" | "REVIEW";

export type RoomType = "ADMISSIONS_LOBBY" | "BACKSTAGE" | "LIVE";

type SomePreliveRoom = { tag: "PRE_LIVE"; roomType: PreLiveRoom };
export function SomePreliveRoom(roomType: PreLiveRoom): SomePreliveRoom {
  return { tag: "PRE_LIVE", roomType };
}

export type CurrentRoom =
  | SomePreliveRoom
  | { tag: "POST_LIVE"; roomType: PostLiveRoomType }
  | {
      tag: "LIVE";
      roomId: Id<"rtcLiveRooms">;
      roomType: "MAIN" | "BREAKOUT";
      isBackstage: boolean;
    };

export function PreliveRoom(roomType: PreLiveRoom): CurrentRoom {
  return { tag: "PRE_LIVE", roomType };
}

export function LiveRoom(
  roomId: Id<"rtcLiveRooms">,
  isMain: boolean,
  isBackstage: boolean
): CurrentRoom {
  return {
    tag: "LIVE",
    roomId,
    roomType: isMain ? "MAIN" : "BREAKOUT",
    isBackstage,
  };
}

export function PostLiveRoom(roomType: PostLiveRoomType): CurrentRoom {
  return { tag: "POST_LIVE", roomType };
}

export const draftBreakoutRoom = v.object({
  brSessionRoomId: v.string(), // A simple id relative to the breakout room session. For example "room-1"
  title: v.string(),
  users: v.array(v.id("users")),
  description: v.optional(v.string()),
});
export type DraftBreakoutRoom = typeof draftBreakoutRoom.type;

export const soundConfig = v.object({
  soundFile: knownMediaSoundFileSchema,
  volume: v.optional(v.number()),
  shouldLoop: v.boolean(),
  durationSeconds: v.optional(v.number()),
});
export type SoundConfig = typeof soundConfig.type;
