import { clsx, type ClassValue } from "clsx";
import { Effect } from "effect";
import { E, TE, epipe } from "frontend-shared/prelude";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import * as Rx from "rxjs";
import { twMerge } from "tailwind-merge";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function useBehaviorSubject<T>(subject: Rx.BehaviorSubject<T>) {
  const [value, setValue] = useState(subject.getValue());
  useEffect(() => {
    const sub = subject.subscribe((s) => {
      console.log("SUBJECT! ", s);
      setValue(s);
    });
    return () => {
      console.log("UNUSBCRIBING!");
      sub.unsubscribe();
    };
  }, [subject]);
  return value;
}

export function convertObservableToBehaviorSubject<T>(
  observable: Rx.Observable<T>,
  initValue: T
): Rx.BehaviorSubject<T> {
  const subject = new Rx.BehaviorSubject(initValue);

  observable.subscribe(subject);

  return subject;
}

export function convertObservableToSubject<T>(
  observable: Rx.Observable<T>
): Rx.Subject<T> {
  const subject = new Rx.Subject<T>();

  observable.subscribe(subject);

  return subject;
}

export function currentObservableValue<T>(
  observable: Rx.Observable<T>
): Promise<T> {
  return new Promise((resolve) => {
    const sub = observable.subscribe((v) => {
      resolve(v);
      sub.unsubscribe();
    });
  });
}

export function safeParseInt(s: string): number | undefined {
  const n = parseInt(s);
  return isNaN(n) ? undefined : n;
}

export function useScrollToAnchor(offset: number = 100) {
  const location = useLocation();
  useEffect(() => {
    if (location.hash) {
      const id = location.hash.replace("#", "");
      const element = document.getElementById(id);
      if (element) {
        const elementPosition = element.getBoundingClientRect().top;
        const offsetPosition = elementPosition + window.pageYOffset - offset;

        window.scrollTo({
          top: offsetPosition,
          behavior: "smooth",
        });
      }
    }
  }, [location]);
}

export function isNonNull<T>(value: T): value is NonNullable<T> {
  return value != null;
}

export function delayP(ms: number) {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
}

export function taskEitherToEff<E, V>(
  te: TE.TaskEither<E, V>
): Effect.Effect<V, E, never> {
  return epipe(
    Effect.promise(te),
    Effect.flatMap((eres) =>
      E.isLeft(eres) ? Effect.fail(eres.left) : Effect.succeed(eres.right)
    )
  );
}
