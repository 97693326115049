// stores/toast.ts
import { createEffect, createEvent, createStore, sample } from "effector";
import type { Id } from "shared/be/convex/_generated/dataModel";

export type ToastProps = Toast;
export type Toast = {
  id: string;
  title?: string;
  description?: string;
  duration?: number;
  type?: "default" | "success" | "error";
  requiresAcknowledgment?: boolean;
  notificationId?: Id<"notifInAppQueue">;
  position?: "top" | "bottom";
  action?: {
    label: string;
    onClick: () => void;
  };
};

export type NotificationMarkReadFn = (params: {
  notificationIds: Id<"notifInAppQueue">[];
  status?: "READ" | "DISMISSED";
}) => Promise<null>;

export class ToastVM {
  // Public stores
  readonly $toasts = createStore<Toast[]>([]);

  // Public events
  readonly showToastEvt = createEvent<Toast>();
  readonly dismissToastEvt = createEvent<string>();
  readonly dismissAllToastsEvt = createEvent<void>();
  readonly dismissWithActionEvt = createEvent<{
    toastId: string;
    notificationIds?: Id<"notifInAppQueue">[];
  }>();

  readonly autoDismissToast = createEvent<{
    toastId: string;
    notificationId?: Id<"notifInAppQueue">;
  }>();

  // Effects for backend mutations
  readonly markNotificationReadFx = createEffect<
    Id<"notifInAppQueue">[],
    void
  >();
  readonly markNotificationDismissedFx = createEffect<
    Id<"notifInAppQueue">[],
    void
  >();

  constructor(markNotificationRead?: NotificationMarkReadFn) {
    if (markNotificationRead) {
      // Set up the read effect
      this.markNotificationReadFx.use(async (notificationIds) => {
        await markNotificationRead({ notificationIds });
      });

      // Set up the dismiss effect
      this.markNotificationDismissedFx.use(async (notificationIds) => {
        await markNotificationRead({
          notificationIds,
          status: "DISMISSED",
        });
      });
    }

    // Initialize stores
    this.$toasts = createStore<Toast[]>([]);

    // Initialize events

    // Setup logic
    sample({
      clock: this.showToastEvt,
      source: this.$toasts,
      fn: (toasts, newToast) => {
        const toast = {
          ...newToast,
          id: this.generateId(),
        };
        return [...toasts, toast];
      },
      target: this.$toasts,
    });

    sample({
      clock: this.dismissToastEvt,
      source: this.$toasts,
      fn: (toasts, toastId) => toasts.filter((t) => t.id !== toastId),
      target: this.$toasts,
    });

    sample({
      clock: this.dismissAllToastsEvt,
      fn: () => [],
      target: this.$toasts,
    });

    // Auto-dismiss logic
    sample({
      clock: this.showToastEvt,
      filter: (toast) =>
        Boolean(toast.duration && !toast.requiresAcknowledgment),
      fn: (toast) => {
        setTimeout(() => {
          this.autoDismissToast({
            toastId: toast.id,
            notificationId: toast.notificationId,
          });
        }, toast.duration!);
        return toast;
      },
    });

    // Remove toast UI for auto-dismiss
    sample({
      clock: this.autoDismissToast,
      source: this.$toasts,
      fn: (toasts, { toastId }) => toasts.filter((t) => t.id !== toastId),
      target: this.$toasts,
    });

    // Mark as dismissed in backend
    sample({
      clock: this.autoDismissToast,
      filter: ({ notificationId }) => Boolean(notificationId),
      fn: ({ notificationId }) => [notificationId!],
      target: this.markNotificationDismissedFx,
    });

    // Split into two samples
    // 1. Handle toast removal
    sample({
      clock: this.dismissWithActionEvt,
      source: this.$toasts,
      fn: (toasts, { toastId }) => toasts.filter((t) => t.id !== toastId),
      target: this.$toasts,
    });

    // 2. Handle marking notifications as read
    sample({
      clock: this.dismissWithActionEvt,
      filter: ({ notificationIds }) => Boolean(notificationIds?.length),
      fn: ({ notificationIds }) => notificationIds!,
      target: this.markNotificationReadFx,
    });
  }

  private generateId(): string {
    return Math.random().toString(36).substr(2, 9);
  }

  showToast(toast: Toast) {
    this.showToastEvt(toast);
  }

  dismissToast(toastId: string) {
    this.dismissToastEvt(toastId);
  }

  dismissAllToasts() {
    this.dismissAllToastsEvt();
  }

  dismissWithAction(
    toastId: string,
    notificationIds?: Id<"notifInAppQueue">[]
  ) {
    this.dismissWithActionEvt({ toastId, notificationIds });
  }
}
