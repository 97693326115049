import * as SheetPrimitive from "@radix-ui/react-dialog";
import { cva, type VariantProps } from "class-variance-authority";
import { Match } from "effect";
import { EyeIcon, Image, PlusIcon, X } from "lucide-react";
import * as React from "react";
import type { SheetType } from "web-shared/src/web-context";
import { cn } from "../../utils/utils";

const Sheet = SheetPrimitive.Root;

const SheetTrigger = SheetPrimitive.Trigger;

const SheetClose = SheetPrimitive.Close;

const SheetPortal = SheetPrimitive.Portal;

const SheetOverlay = React.forwardRef<
  React.ElementRef<typeof SheetPrimitive.Overlay>,
  React.ComponentPropsWithoutRef<typeof SheetPrimitive.Overlay>
>(({ className, ...props }, ref) => (
  <SheetPrimitive.Overlay
    className={cn(
      "fixed inset-0 z-50 bg-black/80  data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0",
      className
    )}
    {...props}
    ref={ref}
  />
));
SheetOverlay.displayName = SheetPrimitive.Overlay.displayName;

const sheetVariants = cva(
  "fixed z-50 gap-4 bg-background p-6 shadow-lg transition ease-in-out data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:duration-300 data-[state=open]:duration-500",
  {
    variants: {
      side: {
        top: "inset-x-0 top-0 border-b data-[state=closed]:slide-out-to-top data-[state=open]:slide-in-from-top",
        bottom:
          "inset-x-0 bottom-0 border-t data-[state=closed]:slide-out-to-bottom data-[state=open]:slide-in-from-bottom",
        left: "inset-y-0 left-0 h-full w-3/4 border-r data-[state=closed]:slide-out-to-left data-[state=open]:slide-in-from-left sm:max-w-sm",
        right:
          "inset-y-0 right-0 h-full w-3/4 border-l rounded-tl-[12px] rounded-bl-[12px] data-[state=closed]:slide-out-to-right data-[state=open]:slide-in-from-right sm:max-w-sm",
      },
    },
    defaultVariants: {
      side: "right",
    },
  }
);

interface SheetContentProps
  extends React.ComponentPropsWithoutRef<typeof SheetPrimitive.Content>,
    VariantProps<typeof sheetVariants> {
  onClose: () => void;
}

const SheetContent = React.forwardRef<
  React.ElementRef<typeof SheetPrimitive.Content>,
  SheetContentProps
>(({ side = "right", className, children, ...props }, ref) => (
  <SheetPortal>
    <SheetOverlay />
    <SheetPrimitive.Content
      ref={ref}
      className={cn(
        sheetVariants({ side }),
        className,
        "bg-white overflow-y-auto"
      )}
      {...props}
    >
      {children}
      <SheetPrimitive.Close
        onClick={props.onClose}
        className="absolute right-4 top-4 rounded-sm opacity-70 ring-offset-background transition-opacity hover:opacity-100 focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 disabled:pointer-events-none data-[state=open]:bg-secondary"
      >
        <X className="h-4 w-4" />
        <span className="sr-only">Close</span>
      </SheetPrimitive.Close>
    </SheetPrimitive.Content>
  </SheetPortal>
));
SheetContent.displayName = SheetPrimitive.Content.displayName;

const SheetHeader = ({
  className,
  ...props
}: React.HTMLAttributes<HTMLDivElement>) => (
  <div
    className={cn(
      "flex flex-col space-y-2 text-center sm:text-left",
      className
    )}
    {...props}
  />
);
SheetHeader.displayName = "SheetHeader";

const SheetFooter = ({
  className,
  ...props
}: React.HTMLAttributes<HTMLDivElement>) => (
  <div
    className={cn(
      "flex flex-col-reverse sm:flex-row sm:justify-end sm:space-x-2",
      className
    )}
    {...props}
  />
);
SheetFooter.displayName = "SheetFooter";

const SheetTitle = React.forwardRef<
  React.ElementRef<typeof SheetPrimitive.Title>,
  React.ComponentPropsWithoutRef<typeof SheetPrimitive.Title>
>(({ className, ...props }, ref) => (
  <SheetPrimitive.Title
    ref={ref}
    className={cn("text-lg font-semibold text-foreground", className)}
    {...props}
  />
));
SheetTitle.displayName = SheetPrimitive.Title.displayName;

const SheetDescription = React.forwardRef<
  React.ElementRef<typeof SheetPrimitive.Description>,
  React.ComponentPropsWithoutRef<typeof SheetPrimitive.Description>
>(({ className, ...props }, ref) => (
  <SheetPrimitive.Description
    ref={ref}
    className={cn("text-sm text-muted-foreground", className)}
    {...props}
  />
));
SheetDescription.displayName = SheetPrimitive.Description.displayName;

export {
  Sheet,
  SheetClose,
  SheetContent,
  SheetDescription,
  SheetFooter,
  SheetHeader,
  SheetOverlay,
  SheetPortal,
  SheetTitle,
  SheetTrigger,
};

export const DefaultSheetHeader: React.FC<{
  title: string;
  type: SheetType;
}> = ({ title, type }) => {
  return (
    <SheetHeader className="flex flex-col items-center gap-4">
      <div className="p-4 aspect-square rounded-full flex items-center justify-center bg-bg-gray">
        {Match.value(type).pipe(
          Match.when({ tag: "edit" }, () => <PencilIcon />),
          Match.when({ tag: "create" }, ({ icon }) => {
            return Match.value(icon).pipe(
              Match.when("profile-add", () => <ProfileAddIconSvg />),
              Match.when(undefined, () => (
                <PlusIcon className="w-16 h-16 text-muted-foreground" />
              )),
              Match.exhaustive
            );
          }),
          Match.when({ tag: "view" }, () => (
            <EyeIcon className="w-16 h-16 text-muted-foreground" />
          )),
          Match.when({ tag: "image" }, () => (
            <Image className="w-16 h-16 text-muted-foreground" />
          )),
          Match.exhaustive
        )}
      </div>
      <div className="text-lg font-medium">{title}</div>
    </SheetHeader>
  );
};

export const DefaultSheetContainer: React.FC<{
  children: React.ReactNode;
  title: string;
  type: SheetType;
}> = ({ children, title, type }) => {
  return (
    <div className="flex flex-col gap-4">
      <DefaultSheetHeader title={title} type={type} />
      {children}
    </div>
  );
};

const PencilIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="50"
      height="50"
      viewBox="0 0 50 50"
      fill="none"
    >
      <path
        d="M22.918 4.16699H18.7513C8.33464 4.16699 4.16797 8.33366 4.16797 18.7503V31.2503C4.16797 41.667 8.33464 45.8337 18.7513 45.8337H31.2513C41.668 45.8337 45.8346 41.667 45.8346 31.2503V27.0837"
        stroke="#3A3A3A"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M33.415 6.29195L16.9983 22.7086C16.3733 23.3336 15.7483 24.5628 15.6233 25.4586L14.7274 31.7294C14.3941 34.0003 15.9983 35.5836 18.2691 35.2711L24.5399 34.3753C25.4149 34.2503 26.6441 33.6253 27.29 33.0003L43.7066 16.5836C46.54 13.7503 47.8733 10.4586 43.7066 6.29195C39.54 2.12528 36.2483 3.45862 33.415 6.29195Z"
        stroke="#3A3A3A"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M31.0625 8.64551C32.4583 13.6247 36.3542 17.5205 41.3542 18.9372"
        stroke="#3A3A3A"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const ProfileAddIconSvg = () => (
  <svg width={50} height={50} viewBox="0 0 50 50" fill="none">
    <path
      d="M38.5404 40.625H30.207"
      stroke="#3A3A3A"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M34.375 44.7918V36.4585"
      stroke="#3A3A3A"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M25.3334 22.6457C25.1251 22.6248 24.8751 22.6248 24.6459 22.6457C19.6876 22.479 15.7501 18.4165 15.7501 13.4165C15.7292 8.31234 19.8751 4.1665 24.9792 4.1665C30.0834 4.1665 34.2292 8.31234 34.2292 13.4165C34.2292 18.4165 30.2709 22.479 25.3334 22.6457Z"
      stroke="#3A3A3A"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M24.9805 45.4377C21.1888 45.4377 17.418 44.4793 14.543 42.5627C9.5013 39.1877 9.5013 33.6877 14.543 30.3335C20.2721 26.5002 29.668 26.5002 35.3971 30.3335"
      stroke="#3A3A3A"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
