import { useUnit } from "effector-react";
import ReactMarkdown from "react-markdown";
import { useTypedParams } from "react-router-typesafe-routes/dom";
import type { Id } from "shared/be/convex/_generated/dataModel";
import { UROUTES } from "shared/routes/u.routes";
import { FullContainerLoadingSpinner } from "web-shared/src/components/loading";
import { EditLessonVM, useSetupEditLessonVM } from "./edit-lesson.vm";

export const EditLessonPage: React.FC = () => {
  const { courseId, lessonId } = useTypedParams(
    UROUTES.COURSES.COURSE_ID.ADMIN.LESSON_ID.EDIT_LESSON
  );

  const vm = useSetupEditLessonVM(lessonId as Id<"courseLessons">);

  if (vm === undefined) {
    return <FullContainerLoadingSpinner />;
  }

  return <Loaded vm={vm} />;
};

const Loaded: React.FC<{
  vm: EditLessonVM;
}> = ({ vm }) => {
  const { lesson, sections } = useUnit({
    lesson: vm.$lesson,
    sections: vm.$sections,
  });

  return (
    <div className="flex h-screen bg-vid-black-50">
      <div className="w-80 bg-white border-r border-vid-black-200 overflow-y-auto">
        <div className="p-6">
          <h1 className="text-2xl font-semibold text-vid-black-900">
            Edit Lesson
          </h1>
          <input
            type="text"
            value={lesson.title}
            onChange={(e) => vm.setTitleEvt(e.target.value)}
            className="mt-4 w-full px-3 py-2 border border-vid-black-200 rounded-lg"
            placeholder="Lesson Title"
          />
        </div>

        <div className="p-4">
          <h2 className="font-medium text-vid-black-900 mb-4">Sections</h2>
          <div className="flex flex-col gap-2">
            {sections.map((section, index) => (
              <div
                key={index}
                className="p-4 bg-white border border-vid-black-200 rounded-lg"
              >
                <div className="flex justify-between items-center mb-2">
                  <select
                    value={section.type}
                    onChange={(e) =>
                      vm.updateSectionTypeEvt({
                        index,
                        type: e.target.value as any,
                      })
                    }
                    className="px-2 py-1 border border-vid-black-200 rounded"
                  >
                    <option value="VIDEO">Video</option>
                    <option value="TEXT">Text</option>
                    <option value="QUIZ">Quiz</option>
                    <option value="INTERACTIVE">Interactive</option>
                  </select>
                  <button
                    onClick={() => vm.removeSectionEvt(index)}
                    className="text-vid-black-500 hover:text-vid-black-700"
                  >
                    ✕
                  </button>
                </div>

                {section.type === "VIDEO" && (
                  <input
                    type="text"
                    value={section.content.videoUrl || ""}
                    onChange={(e) =>
                      vm.updateSectionContentEvt({
                        index,
                        content: { videoUrl: e.target.value },
                      })
                    }
                    className="w-full px-3 py-2 border border-vid-black-200 rounded"
                    placeholder="Video URL"
                  />
                )}

                {section.type === "TEXT" && (
                  <textarea
                    value={section.content.markdownContent || ""}
                    onChange={(e) =>
                      vm.updateSectionContentEvt({
                        index,
                        content: { markdownContent: e.target.value },
                      })
                    }
                    className="w-full px-3 py-2 border border-vid-black-200 rounded"
                    placeholder="Markdown Content"
                    rows={4}
                  />
                )}

                {/* Quiz and Interactive sections would need more complex editors */}
              </div>
            ))}
          </div>

          <button
            onClick={() => vm.addSectionEvt()}
            className="mt-4 w-full px-4 py-2 bg-vid-purple text-white hover:bg-darkish-purple rounded-lg"
          >
            Add Section
          </button>
        </div>
      </div>

      <div className="flex-1 p-8 overflow-y-auto">
        <div className="max-w-4xl mx-auto">
          <h1 className="text-3xl font-semibold text-vid-black-900 mb-8">
            {lesson.title}
          </h1>

          {sections.map((section, index) => {
            switch (section.type) {
              case "VIDEO":
                return section.content.videoUrl ? (
                  <div
                    key={index}
                    className="aspect-video bg-vid-black-900 rounded-lg mb-8"
                  >
                    <iframe
                      className="w-full h-full rounded-lg"
                      src={
                        section.content.videoUrl
                          .replace("watch?v=", "embed/")
                          .split("&")[0]
                      }
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    />
                  </div>
                ) : null;
              case "TEXT":
                return section.content.markdownContent ? (
                  <div
                    key={index}
                    className="prose prose-lg max-w-none prose-headings:text-vid-black-900 prose-p:text-vid-black-700"
                  >
                    <ReactMarkdown>
                      {section.content.markdownContent}
                    </ReactMarkdown>
                  </div>
                ) : null;
              default:
                return null;
            }
          })}
        </div>
      </div>

      <div className="fixed bottom-0 left-0 right-0 border-t border-vid-black-200 bg-white p-4">
        <div className="max-w-4xl mx-auto flex justify-end">
          <button
            onClick={() => vm.saveLessonEvt()}
            className="px-4 py-2 bg-vid-purple text-white hover:bg-darkish-purple rounded-lg"
          >
            Save Changes
          </button>
        </div>
      </div>
    </div>
  );
};
