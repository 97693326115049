import { Match } from "effect";
import { useUnit } from "effector-react";
import {
  useOnOpenEmdrActivityForm,
  useSetupEmdrActivityVM,
  type EmdrActivityVM,
} from "frontend-shared/src/sessions/rooms/activity/emdr-activity.vm";
import { CheckIcon } from "lucide-react";
import { useObservableEagerState } from "observable-hooks";
import type { Id } from "shared/be/convex/_generated/dataModel";
import type {
  ActivityPlayState,
  EmdrSoundType,
} from "shared/be/convex/Rtc/Rooms/Activity/Activity.Types";
import { getColor, type ColorKey } from "shared/utils/color.utils";
import cloudBeepIcon from "web-shared/src/assets/images/cloud-beep-icon.svg";
import {
  InversePrimaryButton,
  PrimaryButton,
} from "web-shared/src/components/button";
import { Slider } from "web-shared/src/components/ui/slider";
import type { Rx } from "frontend-shared/prelude";
import {
  FullContainerLoadingSpinner,
  LoadingSpinner,
} from "../../../../../../../../components/loading";

export const EmdrPanel: React.FC<{
  sessionId: Id<"rtcSession">;
  roomId: Id<"rtcLiveRooms">;
}> = ({ sessionId, roomId }) => {
  const ids = useOnOpenEmdrActivityForm({
    baseSessionId: sessionId,
    roomId,
  });

  if (ids === undefined) {
    return <FullContainerLoadingSpinner />;
  }

  return (
    <EmdrPanelLoaded activityId={ids.activityId} sessionId={ids.sessionId} />
  );
};

const EmdrPanelLoaded: React.FC<{
  activityId: Id<"rtcRoomActivityInstance">;
  sessionId: Id<"rtcRoomEmdrSession">;
}> = ({ activityId, sessionId }) => {
  const vm = useSetupEmdrActivityVM({
    activityId,
    sessionId,
  });

  return (
    <div className="flex flex-col gap-8">
      <SoundSelectSection vm={vm} />
      <BallFrequencySlider vm={vm} />
      <ColorSelectSection vm={vm} />
      <MediaControlsView
        activityPlayState$={vm.args.playState$}
        onPrepare={() => {
          vm.args.startPreparing().catch(console.error);
        }}
        onPause={() => {
          vm.args.onPause().catch(console.error);
        }}
        onStop={() => {
          vm.args.onStop().catch(console.error);
        }}
      />
    </div>
  );
};

export const MediaControlsView: React.FC<{
  activityPlayState$: Rx.BehaviorSubject<ActivityPlayState | null | undefined>;
  onPrepare: () => void;
  onPause: () => void;
  onStop: () => void;
}> = ({ activityPlayState$, onPrepare, onPause, onStop }) => {
  const activityPlayState = useObservableEagerState(activityPlayState$);
  console.log("activityPlayState", activityPlayState);
  return Match.value(activityPlayState).pipe(
    Match.when("playing", () => (
      <PrimaryButton title="Pause" onClick={onPause} />
    )),
    Match.when("paused", () => (
      <div className="flex gap-2">
        <InversePrimaryButton title="Play" onClick={onPrepare} />
        <PrimaryButton title="Stop" onClick={onStop} />
      </div>
    )),
    Match.when("stopped", () => (
      <PrimaryButton title="Play" onClick={onPrepare} />
    )),
    Match.when("finished", () => <PrimaryButton title="Start" />),
    Match.when("preparing", () => (
      <PrimaryButton title="Preparing" isLoading={true} />
    )),
    Match.when("ending", () => (
      <div className="flex gap-2">
        <LoadingSpinner />
        <p className="text-vid-black-900 font-sans text-base font-normal leading-[120%]">
          Ending...
        </p>
      </div>
    )),
    Match.when(null, () => <PrimaryButton title="Start" onClick={onPrepare} />),
    Match.when(undefined, () => (
      <PrimaryButton title="Start" onClick={onPrepare} />
    )),
    Match.exhaustive
  );
};

const SoundSelectSection: React.FC<{
  vm: EmdrActivityVM;
}> = ({ vm }) => {
  const [bounceSound] = useUnit([vm.args.bounceSoundVM.store]);

  const SoundRowItem: React.FC<{
    title: string;
    slug: EmdrSoundType;
    icon: string;
    selectedSound: EmdrSoundType | undefined | null;
  }> = ({ title, slug, icon, selectedSound }) => {
    const isSelected = selectedSound === slug;

    return (
      <div
        className="self-stretch flex flex-row items-center justify-between gap-2 cursor-pointer"
        onClick={() => {
          vm.setBounceSound(slug);
        }}
      >
        <div className="flex flex-row items-center gap-2">
          <div
            className={`w-4 h-4 rounded-full border border-vid-black-900 ${
              isSelected ? "bg-vid-purple" : "bg-transparent"
            }`}
          />
          <p className="text-vid-black-900 font-sans text-base font-normal leading-[120%]">
            {title}
          </p>
        </div>
        <img src={icon} alt={title} />
      </div>
    );
  };

  return (
    <ToolsSectionContainer
      title="Sound"
      content={
        <div className="flex flex-col w-full gap-6">
          <SoundRowItem
            title="Beep"
            slug="beep"
            icon={cloudBeepIcon}
            selectedSound={bounceSound}
          />
          <SoundRowItem
            title="Click"
            slug="click"
            icon={cloudBeepIcon}
            selectedSound={bounceSound}
          />
        </div>
      }
    />
  );
};

const BallFrequencySlider: React.FC<{
  vm: EmdrActivityVM;
}> = ({ vm }) => {
  const [ballFrequency] = useUnit([vm.args.ballFrequencyVM.store]);

  return (
    <ToolsSectionContainer
      title="Ball Frequency"
      content={
        <Slider
          value={[ballFrequency ?? 2]}
          max={10}
          step={0.2}
          onValueChange={(value) => {
            vm.setBallFrequency(value[0]);
          }}
        />
      }
    />
  );
};

const ToolsSectionContainer: React.FC<{
  title: string;
  content: React.ReactNode;
}> = ({ title, content }) => {
  return (
    <div className="flex flex-col items-start w-[312px] p-6 gap-4 border border-vid-black-200 rounded-lg">
      <h2 className="w-full text-vid-black-900 font-sans text-base font-semibold leading-[120%]">
        {title}
      </h2>
      {content}
    </div>
  );
};

const ALL_COLORS: ColorKey[] = ["blue", "green", "purple", "red", "yellow"];

const ColorSelectSection: React.FC<{
  vm: EmdrActivityVM;
}> = ({ vm }) => {
  const [ballColor] = useUnit([vm.args.ballColorVM.store]);

  if (ballColor === undefined) {
    return null;
  }

  return (
    <ToolsSectionContainer
      title="Color"
      content={
        <div className="flex items-center justify-between gap-2 w-full">
          {ALL_COLORS.map((color) => {
            return (
              <div
                key={color}
                className={`w-[34px] aspect-square rounded-full border border-vid-black-900 flex items-center justify-center cursor-pointer`}
                style={{
                  backgroundColor: getColor(color),
                }}
                onClick={() => {
                  vm.setBallColor(color);
                }}
              >
                {ballColor === color && (
                  <CheckIcon className="w-4 h-4 text-vid-white" color="white" />
                )}
              </div>
            );
          })}
        </div>
      }
    />
  );
};
