import { useMutation, useQuery } from "convex/react";
import { useEffect, useState } from "react";
import { api } from "shared/be/convex/_generated/api";
import type { Id } from "shared/be/convex/_generated/dataModel";
import type { ActivityPlayState } from "shared/be/convex/Rtc/Rooms/Activity/Activity.Types";
import type { KnownVisualizationMediaFile } from "shared/be/convex/Rtc/Rooms/Live/LiveRoom.Types";
import type { KnownMediaSoundFile } from "shared/be/convex/Rtc/Session.Types";

export function useOnOpenMeditationActivityForm(p: {
  baseSessionId: Id<"rtcSession">;
  roomId: Id<"rtcLiveRooms">;
}): Id<"rtcRoomActivityInstance"> | undefined {
  const [activityId, setActivityId] = useState<
    Id<"rtcRoomActivityInstance"> | undefined
  >(undefined);
  const getOrCreate = useMutation(
    api.Rtc.Rooms.Activity.MeditationActivityFns
      .getOrCreateNewMeditationActivity
  );

  useEffect(() => {
    getOrCreate({
      roomId: p.roomId,
    }).then(setActivityId);
  }, [p.roomId]);

  return activityId;
}

export function useOnSetupMeditationActivityForm(p: {
  activityId: Id<"rtcRoomActivityInstance">;
}): {
  timerMinutesSetting: number | null | undefined;
  setTimerMinutesSetting: (timerMinutesSetting: number | null) => void;
  playState: ActivityPlayState | null | undefined;
  visualizationMediaFile: KnownVisualizationMediaFile | null | undefined;
  setMeditationVisualizationMediaFile: (
    visualizationMediaFile: KnownVisualizationMediaFile | null
  ) => void;
  soundFile: KnownMediaSoundFile | null | undefined;
  setMeditationSoundFile: (soundFile: KnownMediaSoundFile) => void;
  onPlayMeditationActivity: () => void;
  onPauseMeditationActivity: () => void;
  onStopMeditationActivity: () => void;
} {
  console.log("ACTIVITY ID IN SETUP! ", p.activityId);
  const timerMinutesSetting = useQuery(
    api.Rtc.Rooms.Activity.MeditationActivityFns
      .getMeditationTimerMinutesSetting,
    {
      activityId: p.activityId,
    }
  );

  const setTimerMinutesSetting = useMutation(
    api.Rtc.Rooms.Activity.MeditationActivityFns
      .setMeditationTimerMinutesSetting
  ).withOptimisticUpdate((localStore, args) => {
    localStore.setQuery(
      api.Rtc.Rooms.Activity.MeditationActivityFns
        .getMeditationTimerMinutesSetting,
      {
        activityId: args.activityId,
      },
      args.timerMinutesSetting
    );
  });

  const visualizationMediaFile = useQuery(
    api.Rtc.Rooms.Activity.MeditationActivityFns
      .getMeditationVisualizationMediaFile,
    {
      activityId: p.activityId,
    }
  );

  const setMeditationVisualizationMediaFile = useMutation(
    api.Rtc.Rooms.Activity.MeditationActivityFns
      .setMeditationVisualizationMediaFile
  ).withOptimisticUpdate((localStore, args) => {
    localStore.setQuery(
      api.Rtc.Rooms.Activity.MeditationActivityFns
        .getMeditationVisualizationMediaFile,
      {
        activityId: args.activityId,
      },
      args.visualizationMediaFile
    );
  });

  const soundFile = useQuery(
    api.Rtc.Rooms.Activity.MeditationActivityFns.getMeditationSoundFile,
    {
      activityId: p.activityId,
    }
  );

  const setMeditationSoundFile = useMutation(
    api.Rtc.Rooms.Activity.MeditationActivityFns.setMeditationSoundFile
  ).withOptimisticUpdate((localStore, args) => {
    localStore.setQuery(
      api.Rtc.Rooms.Activity.MeditationActivityFns.getMeditationSoundFile,
      {
        activityId: args.activityId,
      },
      args.soundFile
    );
  });

  const onPlayMeditationActivity = useMutation(
    api.Rtc.Rooms.Activity.MeditationActivityFns.startMeditationActivity
  );

  const onPauseMeditationActivity = useMutation(
    api.Rtc.Rooms.Activity.MeditationActivityFns.pauseMeditationActivity
  );

  const onStopMeditationActivity = useMutation(
    api.Rtc.Rooms.Activity.MeditationActivityFns.finishMeditationActivity
  );

  const playState = useQuery(
    api.Rtc.Rooms.Activity.MeditationActivityFns.getMeditationActivityPlayState,
    {
      activityId: p.activityId,
    }
  );

  console.log("PLAY STATE YO!!!! ", playState);

  return {
    timerMinutesSetting,
    setTimerMinutesSetting: (timerMinutesSetting: number | null) => {
      setTimerMinutesSetting({
        activityId: p.activityId,
        timerMinutesSetting,
      });
    },
    playState,
    visualizationMediaFile,
    setMeditationVisualizationMediaFile: (
      visualizationMediaFile: KnownVisualizationMediaFile | null
    ) => {
      setMeditationVisualizationMediaFile({
        activityId: p.activityId,
        visualizationMediaFile,
      });
    },
    soundFile,
    setMeditationSoundFile: (soundFile: KnownMediaSoundFile) => {
      setMeditationSoundFile({
        activityId: p.activityId,
        soundFile,
      });
    },
    onPlayMeditationActivity: () => {
      onPlayMeditationActivity({
        activityId: p.activityId,
      });
    },
    onPauseMeditationActivity: () => {
      onPauseMeditationActivity({
        activityId: p.activityId,
      });
    },
    onStopMeditationActivity: () => {
      onStopMeditationActivity({
        activityId: p.activityId,
      });
    },
  };
}
