import { useAuthActions } from "@convex-dev/auth/react";
import { useUnit } from "effector-react";
import { useSetupAuthinVM } from "frontend-shared/src/onboard/authin.vm";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "shared/routes/routes";
import { PrimaryButton } from "../../components/button";
import { FullContainerLoadingSpinner } from "../../components/loading";
import { TextInput } from "../../components/text.input";
import {
  SigninWithAppleButton,
  SigninWithGoogleButton,
} from "./authin-form.components";
import { InsightLiveLogoWithText } from "../../assets/images/il-logo-with-text";

type AuthMode = "SIGNUP" | "SIGNIN";

// Authin is a way of saying its either register (aka signup) or signin
export const AuthinForm: React.FC<{
  onAuthinSuccess?: () => void;
}> = ({ onAuthinSuccess }) => {
  const authinVM = useSetupAuthinVM({
    setEmailForLocalStorage: (email) => {
      localStorage.setItem("emailForSignIn", email);
    },
    emailVerifyRedirectUrl: `${window.location.origin}/auth/verify-email`,
  });

  const [authMode, errorMsg, showEmailForm] = useUnit([
    authinVM.authModeVM.store,
    authinVM.$errorMsg,
    authinVM.$showEmailForm,
  ]);

  // Add this to track if we're in OTP verification
  const [isInOtpFlow, setIsInOtpFlow] = useState(false);

  useEffect(() => {
    authinVM.authinSuccessEvt.watch(() => {
      console.log("WATCH AUTHIN SUCCESS!", onAuthinSuccess);
      onAuthinSuccess?.();
      window.location.href = ROUTES.AUTH.SUCCESS.path;
    });
  }, []);

  return (
    <div className="flex flex-col items-center gap-8 bg-white rounded-[12px] p-8 min-w-[550px] max-w-[600px]">
      <div className="flex w-full p-1 bg-vid-black-50 rounded-lg">
        <button
          className={`flex-1 px-6 py-2.5 text-sm font-medium rounded-md transition-all duration-200
            ${
              authMode === "SIGNUP"
                ? "bg-white text-vid-black-900 shadow-sm"
                : "text-vid-black-500 hover:text-vid-black-700"
            }`}
          onClick={() => authinVM.authModeVM.event("SIGNUP")}
        >
          Sign Up
        </button>
        <button
          className={`flex-1 px-6 py-2.5 text-sm font-medium rounded-md transition-all duration-200
            ${
              authMode === "SIGNIN"
                ? "bg-white text-vid-black-900 shadow-sm"
                : "text-vid-black-500 hover:text-vid-black-700"
            }`}
          onClick={() => authinVM.authModeVM.event("SIGNIN")}
        >
          Sign In
        </button>
      </div>
      <div className="flex flex-col items-center">
        <InsightLiveLogoWithText />
      </div>
      <div className="flex flex-col items-center gap-6">
        <div className="text-[48px] font-semibold text-vid-purple w-[480px] text-center">
          {authMode === "SIGNUP" ? "Create your account" : "Welcome back"}
        </div>

        {!isInOtpFlow && <OauthButtons authMode={authMode} />}

        <div className="flex flex-col items-center gap-4">
          {!showEmailForm ? (
            <button
              onClick={() => authinVM.toggleEmailForm(true)}
              className="text-sm text-vid-black-500 hover:text-vid-black-700 transition-colors"
            >
              {authMode === "SIGNUP"
                ? "Don't have a Google or Apple account? Use email instead"
                : "Use email to sign in instead"}
            </button>
          ) : (
            <>
              {!isInOtpFlow && <Break authMode={authMode} />}

              <SigninWithEmailOTPSection
                authMode={authMode}
                setIsInOtpFlow={setIsInOtpFlow}
              />
            </>
          )}
        </div>
        {errorMsg && <div className="text-red-500 font-medium">{errorMsg}</div>}

        <button
          onClick={() =>
            authinVM.authModeVM.event(
              authMode === "SIGNUP" ? "SIGNIN" : "SIGNUP"
            )
          }
          className="text text-vid-purple hover:text-vid-purple-600 transition-colors font-medium"
        >
          {authMode === "SIGNUP"
            ? "Already have an account? Sign in"
            : "Don't have an account? Sign up"}
        </button>
      </div>
    </div>
  );
};

const Break: React.FC<{ authMode: AuthMode }> = ({ authMode }) => {
  return (
    <div className="flex items-center w-full gap-3">
      <div className="h-px bg-gray-300 flex-1" />
      <div className="text-sm text-gray-500">
        {authMode === "SIGNUP" ? "Or sign up with" : "Or sign in with"}
      </div>
      <div className="h-px bg-gray-300 flex-1" />
    </div>
  );
};

const SigninWithEmailOTPSection: React.FC<{
  authMode: AuthMode;
  setIsInOtpFlow: (isInOtp: boolean) => void;
}> = ({ authMode, setIsInOtpFlow }) => {
  const [step, setStep] = useState<"signIn" | { email: string }>("signIn");

  useEffect(() => {
    setIsInOtpFlow(step !== "signIn");
  }, [step, setIsInOtpFlow]);

  if (step === "signIn") {
    return (
      <EmailInputStep
        authMode={authMode}
        onOtpSent={(email) => setStep({ email })}
      />
    );
  }

  return (
    <OtpVerificationStep email={step.email} onBack={() => setStep("signIn")} />
  );
};

const EmailInputStep: React.FC<{
  authMode: AuthMode;
  onOtpSent: (email: string) => void;
}> = ({ authMode, onOtpSent }) => {
  const { signIn } = useAuthActions();
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState("");

  const sendOtp = async (p: { email: string }) => {
    setIsLoading(true);
    const formData = new FormData();
    formData.append("email", p.email);
    try {
      await signIn("resend-otp", formData);
      onOtpSent(p.email);
    } catch (error) {
      console.error("Error sending OTP:", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <form
      className="flex flex-col items-center gap-2 w-[380px]"
      onSubmit={(event) => {
        event.preventDefault();
        const email = event.currentTarget["email"].value;
        sendOtp({ email });
      }}
    >
      <TextInput
        label="Email"
        type="email"
        name="email"
        autoComplete="email"
        placeholder="Enter your email"
        autoCorrect="off"
        autoCapitalize="off"
        spellCheck="false"
        value={email ?? ""}
        onChange={(e) => {
          setEmail(e.target.value);
        }}
      />

      <PrimaryButton
        title={
          authMode === "SIGNUP"
            ? "Sign up with email link"
            : "Sign in with email link"
        }
        type="submit"
        disabled={!email}
        isLoading={isLoading}
        dims={{ width: 380 }}
      />
    </form>
  );
};

const OtpVerificationStep: React.FC<{
  email: string;
  onBack: () => void;
}> = ({ email, onBack }) => {
  const navigate = useNavigate();
  const { signIn } = useAuthActions();
  const [isLoading, setIsLoading] = useState(false);

  const handleResendOtp = async () => {
    onBack();
    const formData = new FormData();
    formData.append("email", email);
    try {
      await signIn("resend-otp", formData);
    } catch (error) {
      console.error("Error resending OTP:", error);
    }
  };

  return (
    <div className="flex flex-col items-center gap-4 w-[380px]">
      <div className="text-center">
        <div className="text-lg font-medium mb-2">Enter verification code</div>
        <div className="text text-vid-black-500">We sent a code to {email}</div>
      </div>

      <form
        className="flex flex-col items-center gap-4 w-full"
        onSubmit={async (event) => {
          event.preventDefault();
          setIsLoading(true);
          const code = event.currentTarget["code"].value;
          const formData = new FormData();
          formData.append("code", code);
          formData.append("email", email);
          try {
            await signIn("resend-otp", formData);
            window.location.href = ROUTES.AUTH.SUCCESS.path;
          } catch (error) {
            console.error("Error verifying code:", error);
            setIsLoading(false);
          }
        }}
      >
        <TextInput
          label="Verification Code"
          name="code"
          placeholder="Enter verification code"
          type="text"
          autoComplete="one-time-code"
          autoFocus
        />

        <PrimaryButton
          title="Verify Code"
          type="submit"
          isLoading={isLoading}
          dims={{ width: 380 }}
        />
      </form>

      <div className="flex flex-col items-center gap-2 text-sm">
        <button
          type="button"
          disabled={isLoading}
          onClick={onBack}
          className="text-vid-purple hover:text-vid-purple-600 transition-colors disabled:opacity-50"
        >
          Use a different email
        </button>

        <button
          type="button"
          disabled={isLoading}
          onClick={handleResendOtp}
          className="text-vid-black-500 hover:text-vid-black-700 transition-colors disabled:opacity-50"
        >
          Didn't receive the code? Send again
        </button>
      </div>
    </div>
  );
};

const redirectTo = window.location.origin + ROUTES.AUTH.OAUTH_CALLBACK.path;

console.log("redirectTo", redirectTo);

const OauthButtons: React.FC<{ authMode: AuthMode }> = ({ authMode }) => {
  const { signIn } = useAuthActions();
  const [isSigningIn, setIsSigningIn] = useState(false);

  if (isSigningIn) {
    return <FullContainerLoadingSpinner />;
  }

  return (
    <div className="flex flex-col items-center gap-2">
      <SigninWithGoogleButton
        text={
          authMode === "SIGNUP" ? "Sign up with Google" : "Sign in with Google"
        }
        onClick={() => {
          console.log("signing in google!");
          setIsSigningIn(true);
          signIn("google", { redirectTo })
            .then((r) => {
              console.log("signed in google!", r);
            })
            .catch((e) => {
              console.log("signIn google error!", e);
              setIsSigningIn(false);
            });
        }}
      />
      <SigninWithAppleButton
        text={
          authMode === "SIGNUP" ? "Sign up with Apple" : "Sign in with Apple"
        }
        onClick={() => {
          setIsSigningIn(true);
          signIn("apple", { redirectTo })
            .then((r) => {
              console.log("signed in apple!", r);
            })
            .catch((e) => {
              console.log("signIn apple error!", e);
              setIsSigningIn(false);
            });
        }}
      />
    </div>
  );
};
