import {
  RadioGroup,
  RadioGroupItem,
} from "web-shared/src/components/ui/radio-group";
import { Match } from "effect";
import { useState } from "react";
import type { Id } from "shared/be/convex/_generated/dataModel";
import { InvoicesTab } from "./invoices-tab";
import { NotesTabWrapup } from "./notes-tab";
import { WrapupSummaryTab } from "./summary-tab";

type Tab = "Moments" | "Invoices" | "Summary";

export const SessionWrapup: React.FC<{
  sessionId: Id<"rtcSession">;
}> = ({ sessionId }) => {
  const [tab, setTab] = useState<Tab>("Moments");

  return (
    <div className="flex-1 flex flex-col gap-4 max-w-[941px] max-h-[90%] bg-white">
      <RadioGroup
        value={tab}
        onValueChange={(value) => setTab(value as Tab)}
        className="flex gap-[257px] px-9 py-4 items-center justify-center border rounded-[60px] border-vid-black-200"
      >
        <TabLink tab="Moments" />
        <TabLink tab="Summary" />
        <TabLink tab="Invoices" />
      </RadioGroup>
      <div className="flex-1 min-h-0 flex flex-col gap-4">
        {Match.value(tab).pipe(
          Match.when("Moments", () => <NotesTabWrapup sessionId={sessionId} />),
          Match.when("Invoices", () => <InvoicesTab sessionId={sessionId} />),
          Match.when("Summary", () => (
            <WrapupSummaryTab sessionId={sessionId} />
          )),
          Match.exhaustive
        )}
      </div>
    </div>
  );
};

const TabLink: React.FC<{
  tab: Tab;
}> = ({ tab }) => {
  return (
    <div className="flex items-center space-x-2">
      <RadioGroupItem value={tab} id={tab} />
      <label
        htmlFor={tab}
        className="text-vid-black-900 text-[26px] font-roboto-flex line-clamp-1 ml-2 cursor-pointer"
      >
        {tab}
      </label>
    </div>
  );
};
