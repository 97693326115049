import { useMemo } from "react";
import { Link, Outlet, useLocation } from "react-router-dom";
import { UROUTES } from "shared/routes/u.routes";
import { PortalSettingsDashLayout } from "../../../components/dashboard/settings-dash-layout";

const TOP_TABS = [
  {
    title: "Account",
    path: UROUTES.HP.MY.DASHBOARD.SETTINGS.ACCOUNT.path,
    param: "account",
  },
  {
    title: "Clients And Scheduling",
    path:
      UROUTES.HP.MY.DASHBOARD.SETTINGS.CLIENT_AND_SCHEDULING.path +
      "#reminders",
    param: "clients-and-scheduling",
  },
  {
    title: "Billing & Tax Documents",
    path: UROUTES.HP.MY.DASHBOARD.SETTINGS.BILLING.path,
    param: "billing",
  },
];

type HpSettingsDashLayoutProps = {
  TopLevelTabLink: React.ComponentType<{
    to: string;
    isActive: boolean;
    children: React.ReactNode;
  }>;
};

export const HpSettingsDashLayout: React.FC<HpSettingsDashLayoutProps> = ({
  TopLevelTabLink,
}) => {
  const { pathname } = useLocation();
  const currentTopTab =
    TOP_TABS.find((tab) => pathname.includes(tab.param))?.param || "account";

  const sideTabs = useMemo(() => {
    switch (currentTopTab) {
      case "account":
        return [
          {
            tab: "practice-info",
            to: "#practice-info",
            title: "Practice Information",
          },
          {
            tab: "account-info",
            to: "#account-info",
            title: "Account Information",
          },
          {
            tab: "modalities",
            to: "#modalities",
            title: "Modalities",
          },
          {
            tab: "extra",
            to: "#extra",
            title: "Extra",
          },
        ];
      case "clients-and-scheduling":
        return [{ tab: "reminders", to: "#reminders", title: "Reminders" }];
      case "billing":
        return [
          {
            tab: "billing-info",
            to: "#billing-info",
            title: "Billing Information",
          },
          {
            tab: "tax-documents",
            to: "#tax-documents",
            title: "Tax Documents",
          },
        ];
      default:
        return [];
    }
  }, [currentTopTab]);

  return (
    <PortalSettingsDashLayout
      TopLevelTabLink={TopLevelTabLink}
      LinkComponent={Link}
      topTabs={TOP_TABS}
      sideTabs={sideTabs}
      currentHash={location.hash.slice(1)}
    >
      <Outlet />
    </PortalSettingsDashLayout>
  );
};
