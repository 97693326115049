import { useMutation } from "convex/react";
import { Match } from "effect";
import { useUnit } from "effector-react";
import { useAudioPlayer } from "frontend-shared/src/mgrs/media-player.statemgr";
import { useOnEnterSession } from "frontend-shared/src/sessions/session.vm";
import type React from "react";
import { useEffect, useMemo, useState } from "react";
import type { PostLiveRoomType } from "shared/be/convex/Rtc/Rooms/Room.Types";
import type { NavLocationOnExit } from "shared/be/convex/Rtc/Session.Types";
import { api } from "shared/be/convex/_generated/api";
import type { Id } from "shared/be/convex/_generated/dataModel";
import { isNullOrUndefined } from "shared/util";
import { PrimaryButton } from "web-shared/src/components/button";
import { FullContainerLoadingSpinner } from "web-shared/src/components/loading";
import { SessionWrapup } from "../analysis/wrapup/wrapup";
import { LiveRoom } from "./rooms/live/live-room";
import { UROUTES } from "shared/routes/u.routes";

export const SessionContainer: React.FC<{
  sessionId: Id<"rtcSession">;
}> = ({ sessionId }) => {
  const { sessionVM, mbHasEnteredSession } = useOnEnterSession({ sessionId });
  const myCurrentRoom = useUnit(sessionVM.$myCurrentRoom);
  const audioPlayer = useAudioPlayer();

  useEffect(() => {
    return () => {
      audioPlayer.stop();
    };
  }, []);

  useEffect(() => {
    console.log("SESSION MY CURRENT ROOM! : ", myCurrentRoom);
  }, [myCurrentRoom]);

  if (isNullOrUndefined(myCurrentRoom) || mbHasEnteredSession === null) {
    return <FullContainerLoadingSpinner />;
  }

  if (mbHasEnteredSession.isSessionLocked) {
    return (
      <div className="flex flex-col gap-4 p-16">
        <h4>Session is locked</h4>
        <PrimaryButton
          title="Back to dashboard"
          onClick={() => {
            window.location.href = "/u";
          }}
        />
      </div>
    );
  }

  return Match.value(myCurrentRoom).pipe(
    Match.when({ tag: "LIVE" }, ({ roomId }) => (
      <LiveRoom baseSessionId={sessionId} roomId={roomId} />
    )),
    Match.when({ tag: "PRE_LIVE" }, () => (
      <PreLiveRoom sessionId={sessionId} />
    )),
    Match.when({ tag: "POST_LIVE" }, ({ roomType }) => (
      <PostLiveRoom sessionId={sessionId} roomType={roomType} />
    )),
    Match.exhaustive
  );
};

const PostLiveRoom: React.FC<{
  sessionId: Id<"rtcSession">;
  roomType: PostLiveRoomType;
}> = ({ sessionId, roomType }) => {
  return Match.value(roomType).pipe(
    Match.when("REVIEW", () => <ReviewRoom sessionId={sessionId} />),
    Match.when("EXIT", () => <ExitRoom sessionId={sessionId} />),
    Match.exhaustive
  );
};

const ExitRoom: React.FC<{
  sessionId: Id<"rtcSession">;
}> = ({ sessionId }) => {
  const [navNext, setNavNext] = useState<NavLocationOnExit | undefined>(
    undefined
  );

  const onEnterExitRoom = useMutation(api.Rtc.SessionFns.onEnterExitRoom);

  useEffect(() => {
    onEnterExitRoom({ baseSessionId: sessionId }).then(({ navNext }) => {
      setNavNext(navNext);
    });
  }, []);

  const nextUrl = useMemo(() => {
    return Match.value(navNext).pipe(
      Match.when(
        { tag: "CLIENTS_DASHBOARD" },
        () => UROUTES.HP.MY.DASHBOARD.CLIENTS.path
      ),
      Match.when({ tag: "COMMUNITY_DASHBOARD" }, ({ communitySession }) =>
        UROUTES.HP.MY.DASHBOARD.COMMUNITY_TAB.COMMUNITIES.COMMUNITY.EVENTS.ALL.buildPath(
          {
            community: communitySession.communitySlug,
          }
        )
      ),
      Match.when(undefined, () => undefined),
      Match.exhaustive
    );
  }, [navNext]);

  useEffect(() => {
    if (nextUrl !== undefined) {
      window.location.href = nextUrl;
    }
  }, [nextUrl]);

  return <FullContainerLoadingSpinner />;
};

const ReviewRoom: React.FC<{
  sessionId: Id<"rtcSession">;
}> = ({ sessionId }) => {
  return (
    <div className="flex flex-col gap-4 p-16">
      <div className="flex items-center justify-between">
        <h4>Review</h4>
        <PrimaryButton
          title="Back to dashboard"
          onClick={() => {
            window.location.href = "/u";
          }}
        />
      </div>
      <div className="flex flex-col gap-4">
        <SessionWrapup sessionId={sessionId} />
      </div>
    </div>
  );
};

const PreLiveRoom: React.FC<{
  sessionId: Id<"rtcSession">;
}> = ({ sessionId }) => {
  return (
    <div className="flex flex-col gap-4 items-center justify-center">
      <div>Admissions Lobby</div>
      <div>{sessionId}</div>
    </div>
  );
};
