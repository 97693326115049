import { useMutation, useQuery } from "convex/react";
import { format } from "date-fns";
import { createStore, createEvent, sample } from "effector";
import { useStore, useUnit } from "effector-react";
import { useOnce } from "frontend-shared/src/util";
import { api } from "shared/be/convex/_generated/api";
import { useMyId } from "web-shared/src/web-context";

export class ManageAvailabilityVM {
  // Events
  readonly setSelectedDay = createEvent<number>(); // 0-6 for Sunday-Saturday
  readonly setStartMinute = createEvent<number>();
  readonly setEndMinute = createEvent<number>();
  readonly setExceptionDate = createEvent<Date>();
  readonly setExceptionType = createEvent<"UNAVAILABLE" | "SPECIAL_HOURS">();

  // Stores
  readonly $selectedDay = createStore(1); // Monday by default
  readonly $startMinute = createStore(9 * 60); // 9 AM by default
  readonly $endMinute = createStore(17 * 60); // 5 PM by default
  readonly $exceptionDate = createStore<Date | null>(null);
  readonly $exceptionType = createStore<"UNAVAILABLE" | "SPECIAL_HOURS">(
    "UNAVAILABLE"
  );

  constructor() {
    sample({
      clock: this.setSelectedDay,
      target: this.$selectedDay,
    });

    sample({
      clock: this.setStartMinute,
      target: this.$startMinute,
    });

    sample({
      clock: this.setEndMinute,
      target: this.$endMinute,
    });

    sample({
      clock: this.setExceptionDate,
      target: this.$exceptionDate,
    });

    sample({
      clock: this.setExceptionType,
      target: this.$exceptionType,
    });
  }
}

const minutesToTimeString = (minutes: number) => {
  const hours = Math.floor(minutes / 60);
  const mins = minutes % 60;
  return `${hours.toString().padStart(2, "0")}:${mins.toString().padStart(2, "0")}`;
};

const timeStringToMinutes = (timeString: string) => {
  const [hours, minutes] = timeString.split(":").map(Number);
  return hours * 60 + minutes;
};

const DAYS = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

export const ManageAvailability: React.FC = () => {
  const vm = useOnce(() => new ManageAvailabilityVM());
  const myId = useMyId();

  const selectedDay = useUnit(vm.$selectedDay);
  const startMinute = useUnit(vm.$startMinute);
  const endMinute = useUnit(vm.$endMinute);
  const exceptionDate = useUnit(vm.$exceptionDate);
  const exceptionType = useUnit(vm.$exceptionType);

  const setAvailability = useMutation(
    api.Scheduling.SchedulingFns.setAvailabilityTemplate
  );
  const setException = useMutation(
    api.Scheduling.SchedulingFns.setAvailabilityException
  );
  const updatePreferences = useMutation(
    api.Scheduling.SchedulingFns.updateHpPreferences
  );

  return (
    <div className="flex flex-col gap-8 p-4">
      <section className="flex flex-col gap-4">
        <h2 className="text-xl font-semibold">Weekly Availability</h2>
        <div className="flex flex-col gap-4">
          <div className="flex flex-col gap-2">
            <label className="text-sm font-medium">Day of Week</label>
            <select
              value={selectedDay}
              onChange={(e) => vm.setSelectedDay(Number(e.target.value))}
              className="rounded border p-2"
            >
              {DAYS.map((day, index) => (
                <option key={day} value={index}>
                  {day}
                </option>
              ))}
            </select>
          </div>

          <div className="flex gap-4">
            <div className="flex flex-col gap-2">
              <label className="text-sm font-medium">Start Time</label>
              <input
                type="time"
                value={minutesToTimeString(startMinute)}
                onChange={(e) =>
                  vm.setStartMinute(timeStringToMinutes(e.target.value))
                }
                className="rounded border p-2"
              />
            </div>

            <div className="flex flex-col gap-2">
              <label className="text-sm font-medium">End Time</label>
              <input
                type="time"
                value={minutesToTimeString(endMinute)}
                onChange={(e) =>
                  vm.setEndMinute(timeStringToMinutes(e.target.value))
                }
                className="rounded border p-2"
              />
            </div>
          </div>

          <button
            onClick={() =>
              setAvailability({
                dayOfWeek: selectedDay,
                startMinute,
                endMinute,
              })
            }
            className="rounded bg-blue-500 px-4 py-2 text-white hover:bg-blue-600"
          >
            Set Availability
          </button>
        </div>
      </section>

      <section className="flex flex-col gap-4">
        <h2 className="text-xl font-semibold">Exceptions</h2>
        <div className="flex flex-col gap-4">
          <div className="flex flex-col gap-2">
            <label className="text-sm font-medium">Date</label>
            <input
              type="date"
              value={exceptionDate ? format(exceptionDate, "yyyy-MM-dd") : ""}
              onChange={(e) => vm.setExceptionDate(new Date(e.target.value))}
              className="rounded border p-2"
            />
          </div>

          <div className="flex flex-col gap-2">
            <label className="text-sm font-medium">Exception Type</label>
            <select
              value={exceptionType}
              onChange={(e) =>
                vm.setExceptionType(
                  e.target.value as "UNAVAILABLE" | "SPECIAL_HOURS"
                )
              }
              className="rounded border p-2"
            >
              <option value="UNAVAILABLE">Unavailable (Day Off)</option>
              <option value="SPECIAL_HOURS">Special Hours</option>
            </select>
          </div>

          {exceptionType === "SPECIAL_HOURS" && (
            <div className="flex gap-4">
              <div className="flex flex-col gap-2">
                <label className="text-sm font-medium">Start Time</label>
                <input
                  type="time"
                  value={minutesToTimeString(startMinute)}
                  onChange={(e) =>
                    vm.setStartMinute(timeStringToMinutes(e.target.value))
                  }
                  className="rounded border p-2"
                />
              </div>

              <div className="flex flex-col gap-2">
                <label className="text-sm font-medium">End Time</label>
                <input
                  type="time"
                  value={minutesToTimeString(endMinute)}
                  onChange={(e) =>
                    vm.setEndMinute(timeStringToMinutes(e.target.value))
                  }
                  className="rounded border p-2"
                />
              </div>
            </div>
          )}

          <button
            onClick={() => {
              if (!exceptionDate) return;
              setException({
                date: Math.floor(exceptionDate.getTime() / 1000),
                type: exceptionType,
                startMinute:
                  exceptionType === "SPECIAL_HOURS" ? startMinute : null,
                endMinute: exceptionType === "SPECIAL_HOURS" ? endMinute : null,
              });
            }}
            className="rounded bg-blue-500 px-4 py-2 text-white hover:bg-blue-600"
            disabled={!exceptionDate}
          >
            Set Exception
          </button>
        </div>
      </section>

      <section className="flex flex-col gap-4">
        <h2 className="text-xl font-semibold">Preferences</h2>
        <div className="flex flex-col gap-4">
          <button
            onClick={() =>
              updatePreferences({
                defaultSessionDuration: 60,
                minNoticeMinutes: 60,
                maxAdvanceDays: 30,
                bufferMinutes: 15,
                autoConfirm: true,
              })
            }
            className="rounded bg-blue-500 px-4 py-2 text-white hover:bg-blue-600"
          >
            Update Default Preferences
          </button>
        </div>
      </section>
    </div>
  );
};
