import React from "react";
import { HpSettingsAccountPortal } from "web-shared/src/domains/hp/settings/account-dash.js";
import { useMe } from "web-shared/src/web-context";
import { useHpState } from "web-shared/src/domains/hp/dashboard/hp.webstatemgr";

export const HpDashboardSettingsAccountPage: React.FC<{}> = ({}) => {
  const hpState = useHpState();
  const me = useMe();

  return (
    <HpSettingsAccountPortal
      me={{
        email: me.email,
        id: me.id,
      }}
      onProfileUpdated={() => {
        console.log("PROFILE UPDATED");
        hpState.dashboardState.showBottomToast({
          msg: "Profile updated",
          duration: { _tag: "SECONDS", seconds: 3 },
          reload: true,
        });
      }}
    />
  );
};
