import { useConvex, type ConvexReactClient } from "convex/react";
import { BaseFormStateMgr } from "frontend-shared/src/mgrs/state-mgrs/base.statemgr";
import { useOnce } from "frontend-shared/src/util";
import { useObservableEagerState } from "observable-hooks";
import { useEffect } from "react";
import { O, RD, TE } from "frontend-shared/prelude";
import { api } from "shared/be/convex/_generated/api";
import { PrimaryButton } from "web-shared/src/components/button";
import { TextInput } from "web-shared/src/components/form/form-inputs.fc";
import { useMyId, useWebGlobalDisplayVM } from "web-shared/src/web-context";

interface CreateCommunitySubmit {
  name: string;
  slug: string;
  isPrivate: boolean;
  description?: string;
}

class StateMgr extends BaseFormStateMgr<CreateCommunitySubmit, any, any> {
  constructor(readonly p: { myUserId: string; convex: ConvexReactClient }) {
    super({
      convex: p.convex,
      mbInitialData: O.none,
      defaultData: {
        name: "",
        slug: "",
        isPrivate: false,
      },
      onSubmit: (formData) => {
        return TE.fromTask(() =>
          this.convex.mutation(
            api.Community.CommunitiesHomeScreenFns.onCreateCommunitySubmit,
            {
              name: formData.name,
              slug: formData.slug,
              description: formData.description,
              isPrivate: formData.isPrivate,
            }
          )
        );
      },
    });
  }
}

export const CreateCommunityForm: React.FC = () => {
  const myId = useMyId();
  const convex = useConvex();
  const webDisplayVM = useWebGlobalDisplayVM();

  const stateMgr = useOnce(() => new StateMgr({ myUserId: myId, convex }));
  const formData = useObservableEagerState(stateMgr.formData$);
  const rdSubmitResult = useObservableEagerState(stateMgr.submitResult$);

  useEffect(() => {
    if (RD.isSuccess(rdSubmitResult)) {
      webDisplayVM.showToast({
        id: "create-community",
        title: "Community created",
        reload: true,
      });
    }
  }, [rdSubmitResult]);

  return (
    <div className="flex flex-col gap-4 p-4">
      <TextInput
        label="Name"
        value={formData.name}
        onChange={(name) => stateMgr.setFormValue("name", name)}
      />
      <TextInput
        label="Slug"
        value={formData.slug}
        onChange={(slug) => stateMgr.setFormValue("slug", slug)}
      />
      <TextInput
        label="Description"
        value={formData.description ?? ""}
        onChange={(description) =>
          stateMgr.setFormValue("description", description)
        }
      />
      <div className="flex items-center gap-2">
        <input
          type="checkbox"
          id="isPrivate"
          className="h-4 w-4 border-vid-black-900 border-2 rounded-sm cursor-pointer appearance-none checked:bg-vid-purple"
          checked={formData.isPrivate ?? false}
          onChange={(e) => stateMgr.setFormValue("isPrivate", e.target.checked)}
        />
        <label htmlFor="isPrivate" className="text-sm font-medium">
          Private Community
        </label>
      </div>
      <PrimaryButton title="Create" onClick={() => stateMgr.submit()} />
    </div>
  );
};
