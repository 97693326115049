import { useAuthActions } from "@convex-dev/auth/react";
import React, { useMemo } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { HpDashboardLayoutDash } from "web-shared/src/domains/hp/dashboard/dashboard-layout-dash";
import { PortalWebStateMgr } from "web-shared/src/domains/hp/dashboard/hp.webstatemgr";
import { useUMgr } from "web-shared/src/web-context";

export const HpDashboardLayout: React.FC = () => {
  const { signOut } = useAuthActions();
  const uMgr = useUMgr();
  const stateMgr = useMemo(() => new PortalWebStateMgr(uMgr), [uMgr]);
  const location = useLocation();

  return (
    <HpDashboardLayoutDash
      stateMgr={stateMgr}
      signOut={signOut}
      location={location}
    >
      <Outlet />
    </HpDashboardLayoutDash>
  );
};
