import { useQuery } from "convex/react";
import { createEvent, createStore, sample } from "effector";
import { useUnit } from "effector-react";
import { useOnce } from "frontend-shared/src/util";
import { api } from "shared/be/convex/_generated/api";
import type { Id } from "shared/be/convex/_generated/dataModel";
import type { BookmarkETO } from "shared/be/convex/Rtc/Annotations/Annotation.Types";
import { FullContainerLoadingSpinner } from "web-shared/src/components/loading";
import {
  RecordedSpansCard,
  SavedBookmarksCard,
} from "web-shared/src/domains/sessions/annotations/bookmark.components";
import { TranscriptDisplay } from "../transcript.components";

class WrapupNotesTabVM {
  bookmarkClickEvt = createEvent<BookmarkETO>();
  spanClickEvt = createEvent<{
    momentId: Id<"sessionMoments">;
    startSis: number;
    label: string;
  }>();
  goToTranscriptEvt = createEvent<{
    momentId: Id<"sessionMoments">;
  }>();

  $curSelectedBookmark = createStore<BookmarkETO | null>(null);
  $curSelectedSpan = createStore<{
    momentId: Id<"sessionMoments">;
    startSis: number;
    label: string;
  } | null>(null);
  $selectedMoment = createStore<{
    momentId: Id<"sessionMoments">;
  } | null>(null);

  constructor() {
    // When a bookmark is clicked, convert it to transcript position format
    sample({
      clock: this.bookmarkClickEvt,
      fn: (bookmark) => ({
        momentId: bookmark.momentId,
      }),
      target: this.goToTranscriptEvt,
    });

    // When a span is clicked, it's already in the right format
    sample({
      clock: this.spanClickEvt,
      fn: (span) => ({
        momentId: span.momentId,
      }),
      target: this.goToTranscriptEvt,
    });

    // Update current transcript position
    sample({
      clock: this.goToTranscriptEvt,
      target: this.$selectedMoment,
    });

    // Keep bookmark selection in sync and clear span selection
    sample({
      clock: this.bookmarkClickEvt,
      target: this.$curSelectedBookmark,
    });
    sample({
      clock: this.bookmarkClickEvt,
      fn: () => null,
      target: this.$curSelectedSpan,
    });

    // Keep span selection in sync and clear bookmark selection
    sample({
      clock: this.spanClickEvt,
      target: this.$curSelectedSpan,
    });
    sample({
      clock: this.spanClickEvt,
      fn: () => null,
      target: this.$curSelectedBookmark,
    });
  }
}

export const NotesTabWrapup: React.FC<{
  sessionId: Id<"rtcSession">;
}> = ({ sessionId }) => {
  const bookmarks = useQuery(
    api.Screens.U.Sessions.ReviewScreenFns.getSessionBookmarks,
    {
      baseSessionId: sessionId as Id<"rtcSession">,
    }
  );
  const spans = useQuery(
    api.Screens.U.Sessions.ReviewScreenFns.getSessionSpans,
    {
      baseSessionId: sessionId,
    }
  );

  const vm = useOnce(() => new WrapupNotesTabVM());
  const curBookmark = useUnit(vm.$curSelectedBookmark);
  const curSpan = useUnit(vm.$curSelectedSpan);
  const currentTranscriptPosition = useUnit(vm.$selectedMoment);

  const recentTranscript = useQuery(
    api.Screens.U.Sessions.ReviewScreenFns.getSessionTranscript,
    {
      baseSessionId: sessionId,
      paginationOpts: {
        numItems: 10,
        cursor: null,
      },
    }
  );

  const transcriptAtMoment = useQuery(
    api.Screens.U.Sessions.ReviewScreenFns.getTranscriptAtMoment,
    currentTranscriptPosition?.momentId
      ? {
          baseSessionId: sessionId,
          momentId: currentTranscriptPosition?.momentId,
        }
      : "skip"
  );

  if (
    recentTranscript === undefined ||
    bookmarks === undefined ||
    spans === undefined
  ) {
    return <FullContainerLoadingSpinner />;
  }

  return (
    <div className="flex-1 flex flex-col gap-4 bg-white">
      <div className="flex gap-4">
        <div className="flex flex-col gap-12">
          <SavedBookmarksCard
            bookmarks={bookmarks.map((b) => ({
              color: b.color,
              label: b.label,
              secondsIntoSession: b.startSis,
              onClick: {
                tag: "ACTION",
                action: ({ Sis }) => {
                  console.log("Sis! ", Sis);
                  vm.bookmarkClickEvt({ ...b, startSis: Sis });
                },
              },
            }))}
            selectedBookmarkSis={curBookmark?.startSis}
          />
          <RecordedSpansCard
            spans={spans}
            onClick={{
              tag: "ACTION",
              action: (span) => {
                console.log("SPANS! ", span);
                vm.spanClickEvt(span);
              },
            }}
            selectedSpanId={curSpan?.momentId}
          />
        </div>
        <div className="flex-1">
          <TranscriptDisplay
            transcript={transcriptAtMoment ?? recentTranscript}
            selectedSegmentsBySis={transcriptAtMoment?.highlightedSegmentsBySis}
          />
        </div>
      </div>
    </div>
  );
};
