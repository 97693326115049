import { Option } from "effect";
import * as Rx from "rxjs";
import * as RxO from "rxjs/operators";
import { RD } from "frontend-shared/prelude";
import type { Id } from "shared/be/convex/_generated/dataModel";

export type CommunityPageTab = "Discussion" | "Learning" | "Members" | "Events";

export class ReplyStateMgr {
  myReplyText$ = new Rx.BehaviorSubject<string>("");
  rdSubmitResult$ = new Rx.BehaviorSubject<RD.RemoteData<any, any>>(RD.initial);

  atMentionQuery$ = new Rx.BehaviorSubject<Option.Option<string>>(
    Option.none()
  );

  mbMatchingMentions$ = this.atMentionQuery$.pipe(
    RxO.map((mbq) => {
      return mbq.pipe(
        Option.map((q) => {
          if (q.length < 2) {
            return this.p.allCommunityMembers.slice(0, 5);
          }
          return this.p.allCommunityMembers
            .filter((m) => m.name.toLowerCase().includes(q.toLowerCase()))
            .slice(0, 5);
        })
      );
    })
  );

  constructor(
    readonly p: {
      communitySlug: string;
      allCommunityMembers: { id: string; name: string }[];
      postId: Id<"communityPosts">;
      submitReply: (p: {
        reply: { postId: Id<"communityPosts">; content: string };
      }) => Promise<void>;
    }
  ) {}

  onInputChange(v: string) {
    this.myReplyText$.next(v);

    const lastWord = v.split(" ").pop();
    if (lastWord && lastWord.startsWith("@")) {
      this.atMentionQuery$.next(Option.some(lastWord.slice(1)));
    } else {
      this.atMentionQuery$.next(Option.none());
    }
  }

  onAddMention(mid: string) {
    const matchingMember = this.p.allCommunityMembers.find(
      (mm) => mm.id === mid
    );
    console.log("MATCHING MEMBER! ", matchingMember);
    this.atMentionQuery$.next(Option.none());
  }

  onSubmitCommentClick = async () => {
    if (this.myReplyText$.value.trim().length < 2) {
      return;
    }
    this.rdSubmitResult$.next(RD.pending);
    await this.p.submitReply({
      reply: {
        content: this.myReplyText$.value,
        postId: this.p.postId,
      },
    });
    this.rdSubmitResult$.next(RD.success(undefined));
    this.rdSubmitResult$.next(RD.initial);
  };
}
