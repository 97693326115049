import { v } from "convex/values";
import type { Doc, Id } from "../_generated/dataModel";
import type { SimpleUser } from "../User/User.Types";

export const lockedState = v.union(
  v.object({
    tag: v.literal("LOCKED"),
    lockedBy: v.id("users"),
    lockedAt: v.number(),
  }),
  v.object({ tag: v.literal("UNLOCKED") })
);
export type LockedState = typeof lockedState.type;

export const sessionParticipantRole = v.union(
  v.literal("HOST"),
  v.literal("CO_HOST"),
  v.literal("PARTICIPANT")
);
export type SessionParticipantRole = typeof sessionParticipantRole.type;

export const knownMediaSoundFileSchema = v.union(
  v.literal("meditation.mp3"),
  v.literal("simple-singing-bowl.mp3"),
  v.literal("ocean-waves.mp3"),
  v.literal("click"),
  v.literal("beep"),
  v.literal("ending-meditation-bell")
);
export type KnownMediaSoundFile = typeof knownMediaSoundFileSchema.type;

export type NavLocationOnExit =
  | { tag: "CLIENTS_DASHBOARD" }
  | {
      tag: "COMMUNITY_DASHBOARD";
      communitySession: Doc<"rtcCommunitySessions">;
    };

export interface PastHcSessionInfo {
  sessionId: Id<"rtcHcSessions">;
  hpInfo: SimpleUser;
  clientInfo: SimpleUser;
  startedAt: number;
  endedAt: number;
}
