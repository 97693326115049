import { HpDashboardSettingsAccountPage } from "@/src/pages/u/hp/dashboard/settings/account.page";
import { HpDashboardSettingsBillingPage } from "@/src/pages/u/hp/dashboard/settings/billing-settings.page";
import { HpSettingsClientAndSchedulingPage } from "@/src/pages/u/hp/dashboard/settings/clients-and-scheduling.page";
import { HpDashboardCalendarPage } from "@hppages/dashboard/calendar/calendar.page";
import { HpMyDashboardClientsPage } from "@hppages/dashboard/clients/clients.page";
import { CommunitiesLandingPage } from "@hppages/dashboard/community/communities/communities-landing.page";
import { HpDashboardCommunityPageLayout } from "@hppages/dashboard/community/community/community.layout";
import { CommunityEventsTabLayout } from "@hppages/dashboard/community/community/events/community-events.tab";
import { HpDashboardLayout } from "@hppages/dashboard/dashboard.layout";
import { HpMyDashboardInvoicesPage } from "@hppages/dashboard/invoices.page";
import { HpSettingsLayout } from "@hppages/dashboard/settings/settings.layout";
import { type RouteObject } from "react-router-dom";
import { HPROUTES } from "shared/routes/hp/hp.routes";
import { CommunityMembersTab } from "web-shared/src/domains/communities/community/community-dash";
import { CommunityEventsTabAll } from "web-shared/src/domains/communities/community/community-events.tab";
import { CommunityDiscussionTab } from "web-shared/src/domains/communities/community/community-post/community-discussion";
import { CommunityLearningTabContent } from "web-shared/src/domains/communities/community/learning-tab-content";
import {
  AboutTab,
  DiscussionTab,
  EventsTab,
  GlobalPractitionerDashAuthedView,
  ScheduleTab,
} from "web-shared/src/domains/communities/global-practitioner-dash";
import {
  ChatWithClient,
  ClientInfoDashboardLayout,
  PastSessionsWithClient,
} from "web-shared/src/domains/hp/clients/client-info-dashboard";

const communityRoutes: RouteObject = {
  children: [
    {
      children: [
        {
          path: HPROUTES.MY.DASHBOARD.COMMUNITY_TAB.COMMUNITIES.relativePath,
          element: <CommunitiesLandingPage />,
        },
        {
          element: <HpDashboardCommunityPageLayout />,
          children: [
            {
              path: HPROUTES.MY.DASHBOARD.COMMUNITY_TAB.COMMUNITIES.COMMUNITY
                .DISCUSSION.relativePath,
              element: <CommunityDiscussionTab />,
            },

            {
              path: HPROUTES.MY.DASHBOARD.COMMUNITY_TAB.COMMUNITIES.COMMUNITY
                .LEARNING.relativePath,
              element: <CommunityLearningTabContent />,
            },
            {
              children: [
                {
                  path: HPROUTES.MY.DASHBOARD.COMMUNITY_TAB.COMMUNITIES
                    .COMMUNITY.MEMBERS.relativePath,
                  element: <CommunityMembersTab />,
                },
              ],
            },
            {
              element: <CommunityEventsTabLayout />,
              children: [
                {
                  path: HPROUTES.MY.DASHBOARD.COMMUNITY_TAB.COMMUNITIES
                    .relativePath,
                },
                {
                  path: HPROUTES.MY.DASHBOARD.COMMUNITY_TAB.COMMUNITIES
                    .COMMUNITY.EVENTS.ALL.relativePath,
                  element: <CommunityEventsTabAll />,
                },
              ],
            },
          ],
        },
      ],
    },
  ],
};

export const hpRouteViews: RouteObject[] = [
  {
    element: <HpDashboardLayout />,
    children: [
      {
        path: HPROUTES.MY.DASHBOARD.CLIENTS.relativePath,
        element: <HpMyDashboardClientsPage />,
      },
      {
        path: HPROUTES.MY.DASHBOARD.CLIENTS.CLIENT_ID.relativePath,
        element: <ClientInfoDashboardLayout />,
        children: [
          {
            path: "past-sessions",
            element: <PastSessionsWithClient />,
          },
          {
            path: "chat",
            element: <ChatWithClient />,
          },
        ],
      },
      communityRoutes,
      {
        path: HPROUTES.MY.DASHBOARD.COMMUNITY_TAB.GLOBAL.USER_PROFILE.USER_ID
          .relativePath,
        element: <GlobalPractitionerDashAuthedView />,
        children: [
          {
            path: "discussion",
            element: <DiscussionTab />,
          },
          {
            path: "events",
            element: <EventsTab />,
          },
          {
            path: "about",
            element: <AboutTab />,
          },
          {
            path: "schedule",
            element: <ScheduleTab />,
          },
        ],
      },
      {
        path: HPROUTES.MY.DASHBOARD.INVOICES.relativePath,
        element: <HpMyDashboardInvoicesPage />,
      },
      {
        path: HPROUTES.MY.DASHBOARD.CALENDAR.relativePath,
        element: <HpDashboardCalendarPage />,
      },
      {
        // path: HPROUTES.MY.DASHBOARD.SETTINGS.relativePath,
        element: <HpSettingsLayout />,
        children: [
          {
            path: HPROUTES.MY.DASHBOARD.SETTINGS.ACCOUNT.relativePath,
            element: <HpDashboardSettingsAccountPage />,
          },
          {
            path: HPROUTES.MY.DASHBOARD.SETTINGS.CLIENT_AND_SCHEDULING
              .relativePath,
            element: <HpSettingsClientAndSchedulingPage />,
          },
          {
            path: HPROUTES.MY.DASHBOARD.SETTINGS.BILLING.relativePath,
            element: <HpDashboardSettingsBillingPage />,
          },
        ],
      },
    ],
  },
];
