import { Authenticated, AuthLoading, Unauthenticated } from "convex/react";
import React from "react";
import { useNavigate } from "react-router-dom";
import {
  useTypedParams,
  useTypedSearchParams,
} from "react-router-typesafe-routes/dom";
import type { Id } from "shared/be/convex/_generated/dataModel";
import { ROUTES } from "shared/routes/routes";
import { FullContainerLoadingSpinner } from "web-shared/src/components/loading";
import {
  AuthedInviteHandler,
  UnauthenticatedInviteHandler,
} from "web-shared/src/domains/invite/invite-handler";

export const InvitePage: React.FC = () => {
  const nav = useNavigate();
  const { inviteId } = useTypedParams(ROUTES.INVITES.INVITE_ID);
  const [{ email }] = useTypedSearchParams(ROUTES.INVITES.INVITE_ID);

  return (
    <div className="flex-1 w-full">
      <Authenticated>
        <AuthedInviteHandler
          inviteId={inviteId as Id<"inviteLinks">}
          email={email}
          onNavigate={(path) => nav(path)}
        />
      </Authenticated>
      <AuthLoading>
        <FullContainerLoadingSpinner />
      </AuthLoading>
      <Unauthenticated>
        <UnauthenticatedInviteHandler
          inviteId={inviteId as Id<"inviteLinks">}
          email={email}
          onNavigate={(path) => nav(path)}
        />
      </Unauthenticated>
    </div>
  );
};
