import { useMutation } from "convex/react";
import {
  combine,
  createEffect,
  createEvent,
  createStore,
  sample,
} from "effector";
import { useUnit } from "effector-react";
import { FormFocusVM } from "frontend-shared/src/form-focus.vm";
import { CalendarPickerVM } from "frontend-shared/src/shared-vms/calendar-picker.vm";
import React, { useMemo } from "react";
import { api } from "shared/be/convex/_generated/api";
import type { RecurrenceFrequencyChoice } from "shared/utils/calendar.types";
import { RRuleUtils } from "shared/utils/calendar.utils";
import { getTime } from "date-fns";
import { PrimaryButton } from "web-shared/src/components/button";
import { CalendarDropdown } from "web-shared/src/components/form/calendar-picker.input";
import { FullContainerLoadingSpinner } from "web-shared/src/components/loading";
import {
  DurationPickerVM,
  DurationSelector,
} from "web-shared/src/components/session-form.components";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "web-shared/src/components/ui/dropdown-menu";
import { useWebGlobalDisplayVM } from "web-shared/src/web-context";

function useSetupForm(p: {
  communitySlug: string;
  onSuccessSubmit?: () => void;
}) {
  const scheduleEvent = useMutation(
    api.Calendar.CommunityCalEventFns.createCommunityCalEvent
  );

  const vm = useMemo(() => {
    return new FormVM({
      submit: async (payload) => {
        // Convert from UTC back to local time
        const date = new Date(payload.date.getTime());
        const offsetMs = date.getTimezoneOffset() * 60 * 1000;
        const localTime = payload.date.getTime() + offsetMs;

        await scheduleEvent({
          communitySlug: p.communitySlug,
          title: payload.name,
          startsAt: localTime,
          description: null,
          durationSeconds: payload.durationInMinutes * 60,
          rrule: payload.rrule,
        });
      },
      onSuccessSubmit: p.onSuccessSubmit,
    });
  }, []);

  return vm;
}

class FormVM {
  calendarPickerVM = new CalendarPickerVM();
  durationPickerVM = new DurationPickerVM();
  recurringInputVM = new RecurringInputVM();

  submitted = createEvent();
  $name = createStore<string>("");
  setName = createEvent<string>();

  $validationError = createStore<string | null>(null);

  submitFx = createEffect<
    {
      name: string;
      date: Date;
      durationInMinutes: number;
      rrule: string | null;
    },
    void,
    Error
  >();

  constructor(p: {
    submit: (p: {
      name: string;
      date: Date;
      durationInMinutes: number;
      rrule: string | null;
    }) => Promise<void>;
    onSuccessSubmit?: () => void;
  }) {
    this.$name.on(this.setName, (_, name) => name);

    this.submitFx.use(p.submit);

    this.$validationError.on(
      combine(this.$name, this.calendarPickerVM.$selectedDate),
      () => null
    );

    sample({
      clock: this.submitted,
      source: {
        name: this.$name,
        selectedDate: this.calendarPickerVM.$selectedDate,
        durationInMinutes: this.durationPickerVM.$selectedDurationMins,
        recurringStatus: this.recurringInputVM.$selectedFrequency,
      },
      filter: ({ name, selectedDate }) => name !== "" && selectedDate !== null,
      fn: ({ name, selectedDate, durationInMinutes, recurringStatus }) => ({
        name,
        date: selectedDate!,
        durationInMinutes,
        rrule:
          recurringStatus === "none"
            ? null
            : RRuleUtils.asRRuleString({
                frequency: recurringStatus,
                interval: 1,
              }),
      }),
      target: this.submitFx,
    });

    this.$validationError.on(this.submitted, () => {
      const name = this.$name.getState();
      const selectedDate = this.calendarPickerVM.$selectedDate.getState();

      const errors: string[] = [];

      if (!name) {
        errors.push("Please enter an event name");
      }

      if (!selectedDate) {
        errors.push("Please select a date and time");
      }

      return errors.length > 0 ? errors.join(", ") : null;
    });

    this.submitFx.doneData.watch(() => {
      p.onSuccessSubmit?.();
    });
  }
}

export const ScheduleCommunityEventForm: React.FC<{
  communitySlug: string;
}> = ({ communitySlug }) => {
  const webDisplayVM = useWebGlobalDisplayVM();
  const vm = useSetupForm({
    communitySlug,
    onSuccessSubmit: () => {
      webDisplayVM.toastVM.showToast({
        id: "event-scheduled",
        title: "Event scheduled successfully",
      });
      webDisplayVM.globalSheetVM.closeSheet();
      webDisplayVM.reloadDash();
    },
  });

  if (vm === undefined) return <FullContainerLoadingSpinner />;

  return <ScheduleCommunityEventFormLoaded vm={vm} />;
};

const ScheduleCommunityEventFormLoaded: React.FC<{
  vm: FormVM;
}> = ({ vm }) => {
  const isSubmitting = useUnit(vm.submitFx.pending);
  const validationError = useUnit(vm.$validationError);
  const name = useUnit(vm.$name);

  return (
    <div className="flex-1 flex flex-col gap-6">
      <div className="self-stretch">
        <input
          type="text"
          value={name}
          onChange={(e) => vm.setName(e.target.value)}
          onFocus={() =>
            FormFocusVM.getInstance().setFocusedField("event-name")
          }
          placeholder="Event name..."
          className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
        />
      </div>
      <DurationSelector vm={vm.durationPickerVM} />
      <div className="">
        <RecurringInput vm={vm.recurringInputVM} />
      </div>
      <CalendarDropdown vm={vm.calendarPickerVM} />
      <div className="flex-1" />
      <div className="flex-1 flex flex-col justify-end">
        <div className="flex flex-col gap-4">
          {validationError && (
            <div className="text-red-500">{validationError}</div>
          )}
          <PrimaryButton
            title="Schedule event"
            onClick={() => {
              vm.submitted();
            }}
            isLoading={isSubmitting}
          />
        </div>
      </div>
    </div>
  );
};

class RecurringInputVM {
  $selectedFrequency = createStore<RecurrenceFrequencyChoice>("none");
  setFrequency = createEvent<RecurrenceFrequencyChoice>();

  constructor() {
    this.$selectedFrequency.on(this.setFrequency, (_, frequency) => frequency);
  }
}

const RecurringInput: React.FC<{ vm: RecurringInputVM }> = ({ vm }) => {
  const frequency = useUnit(vm.$selectedFrequency);
  const ALL_RECURRING_STATUSES: RecurrenceFrequencyChoice[] = [
    "none",
    "daily",
    "weekly",
  ];

  return (
    <DropdownMenu>
      <DropdownMenuTrigger
        onClick={() => FormFocusVM.getInstance().setFocusedField("recurring")}
        className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 flex justify-between items-center"
      >
        <span className="text-gray-700">Recurring</span>
        <span className="capitalize">{frequency}</span>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="w-[--radix-dropdown-menu-trigger-width]">
        {ALL_RECURRING_STATUSES.map((status) => (
          <DropdownMenuItem
            key={status}
            onClick={() => vm.setFrequency(status)}
            className="capitalize"
          >
            {status}
          </DropdownMenuItem>
        ))}
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
