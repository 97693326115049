// hooks/useNotificationToasts.ts
import { useMutation, useQuery } from "convex/react";
import { useEffect } from "react";
import { api } from "shared/be/convex/_generated/api";
import { ToastVM } from "./toast.vm";

export function useNotificationToasts(vm: ToastVM) {
  const notifications = useQuery(
    api.Notifications.NotifFns.getUnreadNotifications
  );

  const markInAppNotificationStatus = useMutation(
    api.Notifications.NotifFns.markInAppStatus
  );

  useEffect(() => {
    vm.markNotificationReadFx.use(async (notificationIds) => {
      await markInAppNotificationStatus({
        notificationIds,
        status: "READ",
      });
    });
  }, []);

  useEffect(() => {
    console.log("in app notifications", notifications);
    if (!notifications?.length) return;

    // Keep track of shown toast IDs to prevent duplicates
    const shownToastIds = new Set<string>();

    notifications.forEach((notification) => {
      // Skip if we've already shown this notification
      if (shownToastIds.has(notification._id)) return;

      const toastId = Math.random().toString(36).substr(2, 9);
      shownToastIds.add(notification._id);

      console.log("SHOWING TOAST", notification);

      vm.showToast({
        id: notification._id,
        title: notification.props.title,
        description: notification.props.message,
        duration: notification.props.duration,
        requiresAcknowledgment: Boolean(notification.props.action),
        notificationId: notification._id,
        ...(notification.props.action && {
          action: {
            label: notification.props.action.label,
            onClick: () => {
              vm.dismissWithActionEvt({
                toastId,
                notificationIds: [notification._id],
              });
            },
          },
        }),
      });
    });

    // Cleanup function to handle component unmount or notifications change
    return () => {
      // You might want to add a method to your VM to clean up toasts
      // vm.clearToasts();
    };
  }, [notifications]);

  return null;
}
