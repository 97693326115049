import { useMutation, useQuery } from "convex/react";
import { Match } from "effect";
import {
  EyeOffIcon,
  LockIcon,
  ScreenShareIcon,
  UnlockIcon,
} from "lucide-react";
import { useObservableEagerState } from "observable-hooks";
import { api } from "shared/be/convex/_generated/api";
import type { Id } from "shared/be/convex/_generated/dataModel";
import type { SettingsMenuViewState } from "shared/be/convex/Rtc/Rooms/Live/LiveRoom.Types";
import { ButtonWithIcon } from "web-shared/src/components/button";
import { FullContainerLoadingSpinner } from "web-shared/src/components/loading";
import { FormRadioCard } from "web-shared/src/components/ui/radio-group";
import type { Rx } from "frontend-shared/prelude";
import {
  useLiveRoomStreamVM,
  useWebGlobalDisplayVM,
} from "web-shared/src/web-context";

export const SettingsMenu: React.FC<{
  viewState$: Rx.BehaviorSubject<SettingsMenuViewState | undefined>;
  sessionId: Id<"rtcSession">;
  setSettingsMenuViewState: (viewState: SettingsMenuViewState) => void;
}> = ({ viewState$, sessionId, setSettingsMenuViewState }) => {
  const viewState = useObservableEagerState(viewState$);
  const webDisplayVM = useWebGlobalDisplayVM();

  const isSessionLocked = useQuery(api.Rtc.SessionFns.isSessionLocked, {
    baseSessionId: sessionId,
  });

  const changeLockState = useMutation(
    api.Rtc.SessionFns.changeLockState
  ).withOptimisticUpdate((localState, _) => {
    const isLocked = localState.getQuery(api.Rtc.SessionFns.isSessionLocked, {
      baseSessionId: sessionId,
    });

    if (isLocked === undefined) {
      return undefined;
    }

    localState.setQuery(
      api.Rtc.SessionFns.isSessionLocked,
      {
        baseSessionId: sessionId,
      },
      !isLocked
    );
  });

  if (viewState === undefined) {
    return <FullContainerLoadingSpinner />;
  }

  return Match.value(viewState).pipe(
    Match.when({ tag: "FULL_MENU" }, () => (
      <div className="flex flex-col gap-4">
        <ButtonWithIcon
          content={{
            title: "Background filters",
            description: "Apply background filters like blurring",
            icon: <EyeOffIcon color="#3E3648" size={20} />,
          }}
          onClick={() => {
            setSettingsMenuViewState({
              tag: "SETTINGS_SELECTION",
              setting: "background-filter",
            });
          }}
        />
        <ButtonWithIcon
          content={{
            title: "Screenshare",
            description: "Share your screen with others",
            icon: <ScreenShareIcon color="#3E3648" size={20} />,
          }}
          onClick={() => {
            setSettingsMenuViewState({
              tag: "SETTINGS_SELECTION",
              setting: "screenshare",
            });
          }}
        />
        {isSessionLocked ? (
          <ButtonWithIcon
            content={{
              title: "Unlock session",
              description:
                "The session is LOCKED. Click to unlock and allow others to join",
              icon: <LockIcon color="#3E3648" size={20} />,
            }}
            onClick={() => {
              changeLockState({
                baseSessionId: sessionId,
                lockAction: "UNLOCK",
              })
                .then((_) => {
                  webDisplayVM.showToast({
                    id: "session-unlocked",
                    title: "Session unlocked",
                    duration: 3000,
                  });
                })
                .catch((e) => {
                  webDisplayVM.showToast({
                    id: "session-unlock-error",
                    title: "Error unlocking session",
                    type: "error",
                    position: "top",
                    description: e.data,
                    duration: 3000,
                  });
                });
            }}
          />
        ) : (
          <ButtonWithIcon
            content={{
              title: "Lock session",
              description:
                "The session is UNLOCKED. Click to lock and prevent others from joining",
              icon: <UnlockIcon color="#3E3648" size={20} />,
            }}
            onClick={() => {
              changeLockState({
                baseSessionId: sessionId,
                lockAction: "LOCK",
              })
                .then((_) => {
                  webDisplayVM.showToast({
                    id: "session-locked",
                    title: "Session locked",
                    duration: 3000,
                  });
                })
                .catch((e: { data: string }) => {
                  webDisplayVM.showToast({
                    id: "session-lock-error",
                    title: "Error locking session",
                    description: e.data,
                    position: "top",
                    type: "error",
                    duration: 3000,
                  });
                });
            }}
          />
        )}
      </div>
    )),
    Match.when({ tag: "SETTINGS_SELECTION" }, ({ setting }) => {
      return Match.value(setting).pipe(
        Match.when("background-filter", () => <BackgroundFilterSettingsMenu />),
        Match.when("screenshare", () => <ScreenshareSettingsMenu />),
        Match.orElse(() => <div></div>)
      );
    }),
    Match.exhaustive
  );
};

const BackgroundFilterSettingsMenu: React.FC = () => {
  const liveRoomStreamVM = useLiveRoomStreamVM();

  const isBlurred = useObservableEagerState(
    liveRoomStreamVM.isBackgroundBlurred$
  );

  return (
    <div className="flex flex-col gap-4">
      <FormRadioCard
        title="Background filter"
        radioProps={{
          options: [
            { name: "Blur", value: "blur" },
            { name: "None", value: "none" },
          ],
          value: isBlurred ? "blur" : "none",
          onChange: (value) => {
            console.log(value);
            liveRoomStreamVM.setIsBackgroundBlurred(value === "blur");
          },
        }}
      />
    </div>
  );
};

const ScreenshareSettingsMenu: React.FC = () => {
  const liveRoomStreamVM = useLiveRoomStreamVM();
  const currentScreenShareUser = useObservableEagerState(
    liveRoomStreamVM.currentScreenShareUser$
  );

  return (
    <div className="flex flex-col gap-4">
      <FormRadioCard
        title="Screenshare"
        radioProps={{
          options: [
            { name: "Enabled", value: "enabled" },
            { name: "Disabled", value: "disabled" },
          ],
          value: currentScreenShareUser?.amITheScreensharer
            ? "enabled"
            : "disabled",
          onChange: (value) => {
            liveRoomStreamVM.setIsScreenshareEnabled(value === "enabled");
          },
        }}
      />
    </div>
  );
};
