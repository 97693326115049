import {
  useLiveRoomVM,
  useSetupControlPanel,
} from "frontend-shared/src/sessions/live-room.vm";
import type React from "react";
import type { Id } from "shared/be/convex/_generated/dataModel";
import {
  CameraStrikethruSvg,
  CameraSvg,
} from "web-shared/src/assets/images/camera.svg";
import { InsightLiveLogoWithText } from "web-shared/src/assets/images/il-logo-with-text";
import magicPenSvg from "web-shared/src/assets/images/magicpen.svg";

export interface ControlPanelSectionViewProps {
  isAudioMute: boolean;
  isVideoMute: boolean;
  onAudioButtonClick: () => void;
  onVideoButtonClick: () => void;
  onEndCallButtonClick: () => void;
  onMagicPenClick: () => void;
  onSettingsClick: () => void;
}

export const ControlPanel: React.FC<{
  baseSessionId: Id<"rtcSession">;
  roomId: Id<"rtcLiveRooms">;
}> = ({ baseSessionId, roomId }) => {
  const {
    isAudioMute,
    isVideoMute,
    onEndCallButtonClick,
    onMagicPenClick,
    onSettingsClick,
  } = useSetupControlPanel({
    baseSessionId,
    roomId,
  });

  const liveRoomVM = useLiveRoomVM();

  return (
    <ControlPanelSectionView
      isAudioMute={isAudioMute}
      isVideoMute={isVideoMute}
      onAudioButtonClick={() => {
        liveRoomVM.setIsAudioMuted(isAudioMute ? "unmute" : "mute");
      }}
      onVideoButtonClick={() => {
        liveRoomVM.setIsVideoMuted(isVideoMute ? "unmute" : "mute");
      }}
      onEndCallButtonClick={onEndCallButtonClick}
      onMagicPenClick={onMagicPenClick}
      onSettingsClick={onSettingsClick}
    />
  );
};

export const ControlPanelSectionView: React.FC<
  ControlPanelSectionViewProps
> = ({
  isAudioMute,
  isVideoMute,
  onAudioButtonClick,
  onVideoButtonClick,
  onEndCallButtonClick,
  onMagicPenClick,
  onSettingsClick,
}) => {
  return (
    <ControlPanelContainerView
      centralControls={
        <DefaultCallControlButtonsView
          audioButton={{
            isMuted: isAudioMute,
            onClick: () => {
              onAudioButtonClick();
            },
          }}
          videoButton={{
            isMuted: isVideoMute,
            onClick: () => {
              onVideoButtonClick();
            },
          }}
          onEndCallButtonClick={() => {
            onEndCallButtonClick();
          }}
        />
      }
      rightControls={
        <MenuButtonControls
          onMagicPenClick={onMagicPenClick}
          onSettingsClick={onSettingsClick}
        />
      }
    />
  );
};

const MenuButtonControls: React.FC<{
  onMagicPenClick: () => void;
  onSettingsClick: () => void;
}> = ({ onMagicPenClick, onSettingsClick }) => {
  return (
    <div className="flex gap-4 items-center">
      <RoundButtonContainer
        onClick={() => {
          onMagicPenClick();
        }}
        bgColor="white"
      >
        <img src={magicPenSvg} />
      </RoundButtonContainer>
      <RoundButtonContainer
        onClick={() => {
          onSettingsClick();
        }}
        bgColor="white"
      >
        <SettingsIcon />
      </RoundButtonContainer>
    </div>
  );
};

export const ControlPanelContainerView: React.FC<{
  centralControls: React.ReactNode;
  rightControls: React.ReactNode;
}> = ({ centralControls, rightControls }) => {
  return (
    <div className="flex py-8 px-4 md:px-8 items-center bg-white">
      <div className="flex-1 hidden md:flex items-center">
        <InsightLiveLogoWithText />
      </div>
      <div className="flex-1 flex items-center justify-center">
        {centralControls}
      </div>
      <div className="flex-1 flex items-center justify-end">
        {rightControls}
      </div>
    </div>
  );
};

const DefaultCallControlButtonsView: React.FC<{
  audioButton: {
    isMuted: boolean;
    onClick: () => void;
  };
  videoButton: {
    isMuted: boolean;
    onClick: () => void;
  };
  onEndCallButtonClick: () => void;
  isAudioOnlySession?: boolean;
}> = ({
  audioButton,
  videoButton,
  onEndCallButtonClick,
  isAudioOnlySession,
}) => {
  return (
    <div className="flex-1 flex gap-4 md:gap-8 justify-center items-center">
      {/* <div className="text-white">{JSON.stringify(sessionState.hp)}</div> */}
      <MuteAudioButton
        onClick={() => {
          audioButton.onClick();
        }}
        isMuted={audioButton.isMuted}
      />

      {!isAudioOnlySession && (
        <MuteVideoButton
          onClick={() => {
            videoButton.onClick();
          }}
          isMuted={videoButton.isMuted}
        />
      )}
      <EndLiveSessionBtn
        onClick={() => {
          onEndCallButtonClick();
        }}
      />
    </div>
  );
};

const EndLiveSessionBtn: React.FC<{
  onClick: () => void;
}> = ({ onClick }) => {
  return (
    <button
      className="bg-vid-purple text-gray-800 w-[60px] h-[60px] rounded-full flex justify-center items-center"
      style={{
        // borderRadius: "10px",
        border: "1px solid #DBDAEC",
        boxShadow: "0px 3.141px 47.119px 0px rgba(99, 100, 113, 0.27",
      }}
      onClick={onClick}
    >
      <CloseCallSvg />
    </button>
  );
};

const RoundButtonContainer: React.FC<{
  children: React.ReactNode;
  onClick: () => void;
  bgColor: "red" | "white";
}> = ({ children, onClick, bgColor }) => {
  return (
    <button
      className={`
        w-[60px] h-[60px] rounded-full border px-4 py-2 flex justify-center items-center 
        ${bgColor === "red" ? "bg-btn-red" : "bg-white"}
        `}
      style={{
        boxShadow:
          "0px 3.141261577606201px 47.11892318725586px rgba(126, 129, 211, 0.27)",
      }}
      onClick={onClick}
    >
      {children}
    </button>
  );
};

export const MuteAudioButton: React.FC<{
  isMuted: boolean;
  onClick: () => void;
}> = ({ isMuted, onClick }) => {
  return (
    <RoundButtonContainer
      onClick={() => {
        onClick();
      }}
      bgColor={isMuted ? "red" : "white"}
    >
      {isMuted ? <MicStrikethruSvg /> : <MicSvg />}
    </RoundButtonContainer>
  );
};

export const MuteVideoButton: React.FC<{
  isMuted: boolean;
  onClick: () => void;
}> = ({ isMuted, onClick }) => {
  return (
    <RoundButtonContainer
      onClick={() => {
        onClick();
      }}
      bgColor={isMuted ? "red" : "white"}
    >
      {isMuted ? <CameraStrikethruSvg /> : <CameraSvg />}
    </RoundButtonContainer>
  );
};

const CloseCallSvg = () => {
  return (
    <svg width="45" height="18" fill="none" viewBox="0 0 45 18">
      <path
        fill="#fff"
        d="M7.85 14.159a3.181 3.181 0 01-.743-1.185 5.157 5.157 0 01-.3-1.502c-.045-.91.088-1.733.406-2.493.31-.751.77-1.44 1.388-2.06.901-.9 2.077-1.652 3.527-2.236 1.45-.583 3.049-1.016 4.79-1.29a35.937 35.937 0 015.374-.424c1.83.009 3.615.15 5.356.424 1.733.283 3.324.707 4.747 1.282 1.432.583 2.607 1.334 3.527 2.254a6.467 6.467 0 011.387 2.024c.327.75.486 1.582.46 2.492-.009 1.123-.354 2.015-1.008 2.67a2.349 2.349 0 01-.875.556 2.038 2.038 0 01-1.087.098l-4.94-.84a4.28 4.28 0 01-.973-.265 1.973 1.973 0 01-.663-.416 1.696 1.696 0 01-.442-.813 4.25 4.25 0 01-.133-1.122l-.026-1.37a.67.67 0 00-.212-.495 1.134 1.134 0 00-.23-.177c-.097-.044-.177-.07-.247-.106-.451-.133-1.105-.239-1.954-.31a42.76 42.76 0 00-2.678-.114c-.946.008-1.838.035-2.705.106-.848.07-1.493.185-1.953.327-.062.026-.142.053-.23.088a.863.863 0 00-.256.186.688.688 0 00-.212.512l-.01 1.335c0 .442-.043.822-.14 1.131-.08.327-.222.592-.443.813-.167.168-.38.31-.654.425a4.834 4.834 0 01-.963.274l-5.003.848c-.389.071-.733.045-1.052-.08-.309-.132-.583-.3-.83-.547z"
      ></path>
    </svg>
  );
};

const MicSvg = () => {
  return (
    <svg width="30" height="30" fill="none" viewBox="0 0 30 30">
      <path
        stroke="#3A3A3A"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M15 19.375c2.763 0 5-2.238 5-5V7.5c0-2.763-2.237-5-5-5-2.762 0-5 2.237-5 5v6.875c0 2.762 2.238 5 5 5z"
      ></path>
      <path
        stroke="#3A3A3A"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M5.438 12.063v2.124c0 5.275 4.287 9.563 9.562 9.563 5.275 0 9.563-4.288 9.563-9.563v-2.124M15 23.75v3.75"
      ></path>
    </svg>
  );
};

const MicStrikethruSvg = () => {
  return (
    <svg width="30" height="30" fill="none" viewBox="0 0 30 30">
      <path
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M20 7.875V7.5c0-2.763-2.238-5-5-5s-5 2.237-5 5v6.25M11.3 17.738a4.966 4.966 0 003.7 1.637c2.762 0 5-2.238 5-5v-.625"
      ></path>
      <path
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M8.475 21.188A9.56 9.56 0 0015 23.75c5.275 0 9.563-4.288 9.563-9.563v-2.124M5.438 12.063v2.124c0 1.325.262 2.575.75 3.726M25.088 3.55L4.913 23.737M15 23.75v3.75"
      ></path>
    </svg>
  );
};

export const SettingsIcon: React.FC<{}> = ({}) => {
  return (
    <svg width="24" height="24" fill="none" viewBox="0 0 24 24">
      <path
        stroke="#1D1626"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="1.5"
        d="M12 15a3 3 0 100-6 3 3 0 000 6z"
      ></path>
      <path
        stroke="#1D1626"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="1.5"
        d="M2 12.88v-1.76c0-1.04.85-1.9 1.9-1.9 1.81 0 2.55-1.28 1.64-2.85-.52-.9-.21-2.07.7-2.59l1.73-.99c.79-.47 1.81-.19 2.28.6l.11.19c.9 1.57 2.38 1.57 3.29 0l.11-.19c.47-.79 1.49-1.07 2.28-.6l1.73.99c.91.52 1.22 1.69.7 2.59-.91 1.57-.17 2.85 1.64 2.85 1.04 0 1.9.85 1.9 1.9v1.76c0 1.04-.85 1.9-1.9 1.9-1.81 0-2.55 1.28-1.64 2.85.52.91.21 2.07-.7 2.59l-1.73.99c-.79.47-1.81.19-2.28-.6l-.11-.19c-.9-1.57-2.38-1.57-3.29 0l-.11.19c-.47.79-1.49 1.07-2.28.6l-1.73-.99a1.899 1.899 0 01-.7-2.59c.91-1.57.17-2.85-1.64-2.85-1.05 0-1.9-.86-1.9-1.9z"
      ></path>
    </svg>
  );
};
