import { createContext, useContext, useEffect } from "react";
import type { KnownMediaSoundFile } from "shared/be/convex/Rtc/Session.Types";
import { useOnce } from "../util";

export abstract class AbstractAudioPlayer {
  abstract setOnDone: (onDone: () => void) => void;
  abstract setSource: (source: KnownMediaSoundFile) => void;
  abstract playAudioBuffer: (options?: {
    autoStopAfterNSeconds?: number;
  }) => void;
  abstract setSourceAndPlay: (
    source: KnownMediaSoundFile,
    options?: { autoStopAfterNSeconds?: number }
  ) => void;
  abstract pause: () => void;
  abstract stop: () => void;
  abstract playWithGainEnvelope: (params: {
    gain: number;
    duration: number;
    startOffset?: number;
  }) => void;
}

const AudioPlayerContext = createContext<AbstractAudioPlayer | null>(null);

export function AudioProvider<T extends AbstractAudioPlayer>({
  children,
  AudioPlayerClass,
}: {
  children: React.ReactNode;
  AudioPlayerClass: new (onDone: () => void) => T;
}) {
  const audioPlayer = useOnce(() => {
    return new AudioPlayerClass(() => {});
  });

  // Clean up audio context on unmount
  useEffect(() => {
    return () => {
      audioPlayer.stop();
    };
  }, [audioPlayer]);

  return (
    <AudioPlayerContext.Provider value={audioPlayer}>
      {children}
    </AudioPlayerContext.Provider>
  );
}

export function useAudioPlayer() {
  const context = useContext(AudioPlayerContext);
  if (!context) {
    throw new Error("useAudioPlayer must be used within an AudioProvider");
  }
  return context;
}
