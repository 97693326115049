import { HpLayout } from "@pages/u/hp/hp.layout";
import { USessionReviewPage } from "@pages/u/sessions/[sessionId]/review/review.page";
import { USessionRtcPage } from "@pages/u/sessions/[sessionId]/rtc/rtc.page";
import { ULayout } from "@pages/u/u.layout";
import { type RouteObject } from "react-router-dom";
import { HPROUTES } from "shared/routes/routes";
import { UROUTES, USESSIONS_ROUTES } from "shared/routes/u.routes";
import { DoYouSeeClientsPage } from "../pages/onboard/do-you-see-clients.page";
import { InputProfilePage } from "../pages/onboard/input-profile.page";
import { cpRoutes } from "./cp-route-views";
import { hpRouteViews } from "./hp-route-views";
import { CourseAppPage } from "../pages/u/courses/[courseId]/course-app.page";
import { EditCoursePage } from "../pages/u/courses/[courseId]/admin/edit-course.page";
import { EditLessonPage } from "../pages/u/courses/[courseId]/admin/lessons/[lessonId]/edit-lesson.page";
import { CommunityAdminDashboardPage } from "../pages/u/hp/dashboard/community/community/admin/community-admin-dash.page";
import { ManageAvailability } from "@/pages/scheduling/manage-availability";
import { Availability } from "@/pages/scheduling/availability";

console.log("UROUTES", UROUTES.path);
console.log("HPROUTES", HPROUTES.path);

export const uRouteViews: RouteObject = {
  path: UROUTES.path,
  element: <ULayout />,
  children: [
    {
      path: UROUTES.SCHEDULING.MANAGE_AVAILABILITY.path,
      element: <ManageAvailability />,
    },
    {
      path: UROUTES.SCHEDULING.AVAILABILITY.path,
      element: <Availability />,
    },
    {
      path: UROUTES.COMMUNITIES.COMMUNITY.ADMIN.path,
      element: <CommunityAdminDashboardPage />,
    },
    {
      path: UROUTES.COURSES.COURSE_ID.COURSE_APP.path,
      element: <CourseAppPage />,
    },
    {
      path: UROUTES.COURSES.COURSE_ID.ADMIN.EDIT_COURSE.path,
      element: <EditCoursePage />,
    },
    {
      path: UROUTES.COURSES.COURSE_ID.ADMIN.LESSON_ID.EDIT_LESSON.path,
      element: <EditLessonPage />,
    },
    {
      path: UROUTES.ONBOARD.DO_YOU_SEE_CLIENTS.path,
      element: <DoYouSeeClientsPage />,
    },
    {
      path: UROUTES.ONBOARD.INPUT_PROFILE.path,
      element: <InputProfilePage />,
    },
    {
      element: <HpLayout />,
      children: hpRouteViews,
    },
    cpRoutes,
    {
      path: USESSIONS_ROUTES.SESSION_ID.RTC.relativePath,
      element: <USessionRtcPage />,
    },
    {
      path: USESSIONS_ROUTES.SESSION_ID.REVIEW.relativePath,
      element: <USessionReviewPage />,
    },
  ],
};
