import { Match } from "effect";
import { useUnit } from "effector-react";
import meditation from "frontend-shared/src/resources/meditation.mp3";
import metalClink from "frontend-shared/src/resources/metal-clink.mp3";
import { EmdrBallVisVM } from "frontend-shared/src/sessions/emdr/emdr-ball-vis.vm";
import { useLiveRoomVM } from "frontend-shared/src/sessions/live-room.vm";
import {
  useKeyOfObservableAsState,
  useOnce,
  useQuery$,
} from "frontend-shared/src/util";
import { useObservableEagerState } from "observable-hooks";
import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { api } from "shared/be/convex/_generated/api";
import type { Id } from "shared/be/convex/_generated/dataModel";
import type { SoundType } from "shared/be/convex/Rtc/Emdr/Emdr.Types";
import type { EmdrSoundType } from "shared/be/convex/Rtc/Rooms/Activity/Activity.Types";
import type { KnownMediaSoundFile } from "shared/be/convex/Rtc/Session.Types";
import { isNotNullOrUndefined } from "shared/util";
import { getColor, type ColorKey } from "shared/utils/color.utils";
import { FullContainerLoadingSpinner } from "web-shared/src/components/loading";
import { RxO, type Rx } from "frontend-shared/prelude";

interface EmdrState {
  ballFrequency: number;
  ballColor: ColorKey;
  playState:
    | "playing"
    | "paused"
    | "preparing"
    | "finished"
    | "stopped"
    | "off";
  soundType: SoundType;
}

interface EMDRComponentProps {
  state$: Rx.Observable<EmdrState>;
  onPass?: () => void;
}

export const EmdrBallRtcContent: React.FC<{
  roomId: Id<"rtcLiveRooms">;
}> = ({ roomId }) => {
  const curActivity$ = useQuery$(
    api.Rtc.Rooms.Activity.EmdrFns.getCurEmdrActivity,
    {
      roomId,
    }
  );
  const curActivity = useObservableEagerState(curActivity$);

  const state$ = useOnce(() =>
    curActivity$.pipe(
      RxO.filter(isNotNullOrUndefined),
      RxO.map(
        ({ session, lifecycle }) =>
          ({
            ballFrequency: session.ballFrequency,
            ballColor: session.ballColor as ColorKey,
            playState: Match.value(lifecycle.playState).pipe(
              Match.when("playing", () => "playing" as const),
              Match.when("paused", () => "paused" as const),
              Match.when("preparing", () => "preparing" as const),
              Match.when("finished", () => "off" as const),
              Match.when("stopped", () => "off" as const),
              Match.when("ending", () => "off" as const),
              Match.when(null, () => "off" as const),
              Match.exhaustive
            ),
            soundType: session.soundType as SoundType,
          }) satisfies EmdrState
      )
    )
  );

  useEffect(() => {
    console.log("curActivity", curActivity);
  }, [curActivity]);

  useEffect(() => {
    state$.subscribe((s) => {
      console.log("state$", s);
    });
  }, [state$]);

  if (curActivity === undefined) {
    return <FullContainerLoadingSpinner />;
  }

  return <EMDRBallStageViewCode state$={state$} />;
};

export const EMDRBallStageViewCode: React.FC<EMDRComponentProps> = (props) => {
  const [passCount, setPassCount] = useState(0);

  return (
    <div className="w-full h-full overflow-hidden flex items-center justify-center relative rounded-[12px] border border-vid-black-800">
      <DotBackground
        className="absolute inset-0 z-0"
        dotSpacing={30}
        dotSize={2}
        dotColor="rgba(107, 107, 128, 0.2)"
        backgroundColor="#1a1625"
      />
      <div className="absolute top-4 left-4 z-20 text-white/80 font-medium">
        Passes: {passCount}
      </div>
      <div className="relative z-10 w-full h-full flex items-center justify-center">
        <EMDRBallVideoCode
          {...props}
          onPass={() => setPassCount((c) => c + 1)}
        />
      </div>
    </div>
  );
};

export const EMDRBallVideoCode: React.FC<EMDRComponentProps> = ({ state$ }) => {
  const liveRoomVM = useLiveRoomVM();
  const [isAudioLoaded, setIsAudioLoaded] = useState(false);
  const frequency = useKeyOfObservableAsState(state$, "ballFrequency", 3);
  const color = useKeyOfObservableAsState(state$, "ballColor", "purple");
  const soundType = useKeyOfObservableAsState(
    state$,
    "soundType",
    "beep"
  ) as EmdrSoundType;

  const vm = useOnce(
    () =>
      new EmdrBallVisVM({
        audioPlayer: liveRoomVM.audioPlayer,
      })
  );

  useEffect(() => {
    return () => {
      vm.destroy();
    };
  }, [vm]);

  const [position, isPlaying, vmFrequency] = useUnit([
    vm.$position,
    vm.$isPlaying,
    vm.$frequency,
  ]);

  const containerRef = useRef<HTMLDivElement>(null);
  const [containerWidth, setContainerWidth] = useState<number>(600);

  useLayoutEffect(() => {
    const updateContainerWidth = () => {
      if (containerRef.current) {
        const fullWidth = containerRef.current.getBoundingClientRect().width;
        setContainerWidth(fullWidth);
        vm.setContainerWidth(fullWidth);
      }
    };

    updateContainerWidth();
    const timeoutId = setTimeout(updateContainerWidth, 100);
    window.addEventListener("resize", updateContainerWidth);

    const observer = new MutationObserver(updateContainerWidth);
    if (containerRef.current?.parentElement) {
      observer.observe(containerRef.current.parentElement, {
        attributes: true,
        childList: true,
        subtree: true,
      });
    }

    return () => {
      window.removeEventListener("resize", updateContainerWidth);
      clearTimeout(timeoutId);
      observer.disconnect();
    };
  }, [vm, isAudioLoaded]);

  useEffect(() => {
    if (!soundType) return;

    const loadAudio = async () => {
      setIsAudioLoaded(false);
      vm.setAudioLoading(true);
      try {
        liveRoomVM.audioPlayer.setSource(soundType as KnownMediaSoundFile);
        setIsAudioLoaded(true);
        vm.setAudioLoading(false);
      } catch (error) {
        console.error("Failed to load audio:", error);
        setIsAudioLoaded(false);
        vm.setAudioLoading(false);
      }
    };

    loadAudio();
  }, [soundType, liveRoomVM.audioPlayer, vm]);

  useEffect(() => {
    state$.pipe(RxO.map((s) => s.playState)).subscribe((playState) => {
      vm.setPlayState(playState === "playing");
    });

    state$.pipe(RxO.map((s) => s.ballFrequency)).subscribe((frequency) => {
      vm.setFrequency(frequency);
    });
  }, []);

  const config = {
    ballSize: 24,
    containerWidth,
    transitionDuration: 4000 / frequency,
  };

  if (!isAudioLoaded) {
    return <></>;
  }

  return (
    <div
      className="w-full bg-vid-black-900 rounded-lg relative box-border"
      ref={containerRef}
    >
      <div className="absolute inset-0 flex items-center justify-center">
        <Ball
          {...config}
          size={config.ballSize}
          isPlaying={isPlaying}
          position={position}
          color={color as ColorKey}
          frequency={vmFrequency}
        />
      </div>
    </div>
  );
};

const Ball: React.FC<{
  size: number;
  color: ColorKey;
  isPlaying: boolean;
  position: number;
  frequency: number;
}> = ({ size, color, isPlaying, position, frequency }) => {
  return (
    <div className="relative h-8 w-full">
      <div
        className="absolute top-1/2 -translate-y-1/2 rounded-full"
        style={{
          width: size,
          height: size,
          left: `${position}px`,
          transition: isPlaying
            ? "none"
            : `all ${4000 / frequency}ms ease-in-out`,
          backgroundColor: getColor(color as ColorKey),
        }}
      />
    </div>
  );
};

const DotBackground = ({
  dotSpacing = 20,
  dotSize = 1,
  dotColor = "rgba(107, 107, 128, 0.2)",
  backgroundColor = "#1a1625",
  className = "",
}) => {
  return (
    <div className={`w-full h-full ${className}`}>
      <svg
        className="w-full h-full"
        xmlns="http://www.w3.org/2000/svg"
        preserveAspectRatio="xMidYMid slice"
      >
        <rect width="100%" height="100%" fill={backgroundColor} />
        <defs>
          <pattern
            id="dotPattern"
            x="0"
            y="0"
            width={dotSpacing}
            height={dotSpacing}
            patternUnits="userSpaceOnUse"
          >
            <circle
              cx={dotSpacing / 2}
              cy={dotSpacing / 2}
              r={dotSize}
              fill={dotColor}
            />
          </pattern>
        </defs>
        <rect width="100%" height="100%" fill="url(#dotPattern)" />
      </svg>
    </div>
  );
};
