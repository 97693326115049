import { useMutation, useQuery } from "convex/react";
import { api } from "shared/be/convex/_generated/api";
import type { Id } from "shared/be/convex/_generated/dataModel";
import {
  RecordedSpansCard,
  SavedBookmarksCard,
} from "../../../../../annotations/bookmark.components";
import { useRef, useEffect } from "react";

export const NoteToolsPanel: React.FC<{
  baseSessionId: Id<"rtcSession">;
}> = ({ baseSessionId }) => {
  return (
    <div className="flex flex-col gap-4">
      <NotesPanelSection baseSessionId={baseSessionId} />
      <BookmarksSection baseSessionId={baseSessionId} />
      <RecordedBookends baseSessionId={baseSessionId} />
    </div>
  );
};

const SectionContainer: React.FC<{
  title: string;
  children: React.ReactNode;
}> = ({ children, title }) => {
  return (
    <div className="flex flex-col gap-3 border border-vid-black-200 rounded-lg p-6">
      <div className="flex self-stretch justify-between">
        <h1 className="text-sm font-bold">{title}</h1>
      </div>
      {children}
    </div>
  );
};

const NotesPanelSection: React.FC<{
  baseSessionId: Id<"rtcSession">;
}> = ({ baseSessionId }) => {
  const myNotes = useQuery(api.Rtc.Annotations.AnnotationFns.getMyNotes, {
    baseSessionId,
  });

  const textareaRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    textareaRef.current?.focus();
  }, []);

  if (myNotes === undefined) {
    return null;
  }

  return (
    <SectionContainer title="Notes">
      <textarea
        ref={textareaRef}
        className="flex-1 flex flex-col self-stretch border rounded-lg p-2 bg-vid-black-100 min-h-[108px]"
        value={myNotes.map((n) => n.note).join("\n")}
        onChange={() => {
          // annotationsPanelMgr.notesMgr.updateNotes(e.target.value);
        }}
      />
    </SectionContainer>
  );
};

const BookmarksSection: React.FC<{
  baseSessionId: Id<"rtcSession">;
}> = ({ baseSessionId }) => {
  const updateMomentLabel = useMutation(
    api.Rtc.Participants.Views.MainRoomFns.updateMomentLabel
  );

  const allMoments = useQuery(
    api.Rtc.Participants.Views.MainRoomFns.getAllBookmarks,
    {
      baseSessionId,
    }
  );

  if (allMoments === undefined) {
    return null;
  }

  return (
    <SavedBookmarksCard
      bookmarks={allMoments.map((m) => ({
        secondsIntoSession: m.startSis,
        label: m.label,
        color: m.color,
        onClick: {
          tag: "EDIT",
          onSave: (newLabel) => {
            updateMomentLabel({ momentId: m.momentId, newLabel });
          },
        },
      }))}
    />
  );
};

const RecordedBookends: React.FC<{
  baseSessionId: Id<"rtcSession">;
}> = ({ baseSessionId }) => {
  const updateMomentLabel = useMutation(
    api.Rtc.Participants.Views.MainRoomFns.updateMomentLabel
  );
  const spans = useQuery(api.Rtc.Participants.Views.MainRoomFns.getAllSpans, {
    baseSessionId,
  });

  if (spans === undefined) {
    return null;
  }

  return (
    <RecordedSpansCard
      spans={spans}
      onClick={{
        tag: "EDIT",
        onSave: (bk, newLabel) => {
          updateMomentLabel({ momentId: bk.momentId, newLabel }).catch(
            () => {}
          );
        },
      }}
    />
  );
};
