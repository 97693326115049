import { useConvexAuth } from "convex/react";
import * as React from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { ROUTES } from "shared/routes/routes";
import { FullScreenLoadingSpinner } from "web-shared/src/components/loading";
import { RootProvider } from "web-shared/src/global/root-provider";

export const IndexLayout: React.FC = () => {
  const { isLoading } = useConvexAuth();
  const nav = useNavigate();

  if (isLoading) {
    return <FullScreenLoadingSpinner />;
  }

  return (
    <RootProvider
      nav={nav}
      rootUrl={"/"}
      authInUrl={ROUTES.AUTH.AUTHIN.path}
      uUrl={ROUTES.U.path}
    >
      <div className="w-screen h-screen flex flex-col justify-center relative">
        <Outlet />
      </div>
    </RootProvider>
  );
};
