import { useMutation, useQuery } from "convex/react";
import { useObservableEagerState } from "observable-hooks";
import * as React from "react";
import * as Rx from "rxjs";
import { RD } from "frontend-shared/prelude";
import { api } from "shared/be/convex/_generated/api";
import type { Id } from "shared/be/convex/_generated/dataModel";
import { useOnce } from "../../util";

type ForClient =
  | { _tag: "EXISTING"; client: { id: string } }
  | { _tag: "SELECT" };

export function useStartASessionFormVM(forClient: ForClient) {
  const onStartSession = useMutation(
    api.Screens.Hp.Dashboard.ClientsScreenFns.onSubmitStartSession
  );
  const selectedUserId$ = useOnce(
    () =>
      new Rx.BehaviorSubject<string | null>(
        forClient._tag === "EXISTING" ? forClient.client.id : null
      )
  );

  const selectedUserInfo = useQuery(
    api.User.UserFns.getSimpleUserOT,
    forClient._tag === "EXISTING"
      ? { userId: forClient.client.id as Id<"users"> }
      : "skip"
  );

  const durationInMinutes$ = useOnce(
    () => new Rx.BehaviorSubject<number | null>(60)
  );
  const endingSessionReminderInMinutes$ = useOnce(
    () => new Rx.BehaviorSubject<number | null>(10)
  );
  const [audioOnly, setAudioOnly] = React.useState(false);
  const [rdSubmitResult, setRdSubmitResult] = React.useState<
    RD.RemoteData<{ msg: string }, { navNextUrl: string }>
  >(RD.initial);

  const selectedUserId = useObservableEagerState(selectedUserId$);
  const durationInMinutes = useObservableEagerState(durationInMinutes$);
  const endingSessionReminderInMinutes = useObservableEagerState(
    endingSessionReminderInMinutes$
  );

  return {
    onStartSession,
    selectedUserId$,
    selectedUserId,
    selectedUserInfo,
    durationInMinutes$,
    durationInMinutes,
    endingSessionReminderInMinutes,
    endingSessionReminderInMinutes$,
    audioOnly,
    setAudioOnly,
    rdSubmitResult,
    setRdSubmitResult,
  };
}
