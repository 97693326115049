import { Match } from "effect";
import type { AuthedDestination } from "shared/be/convex/User/User.Types";
import { UROUTES } from "shared/routes/u.routes";

export function matchRouteForAuthedDestination(
  currentHref: string,
  destination: AuthedDestination
): string | null {
  console.log("CURRENT HREF! ", currentHref);
  if (
    currentHref.includes("/u/courses") ||
    currentHref.includes("/u/sessions") ||
    currentHref.includes("/u/communities") ||
    currentHref.includes("/u/scheduling")
  ) {
    console.log("RETURNING NULL! ");
    return null;
  }

  const cpBaseDashUrl = UROUTES.CP.DASHBOARD.path;
  const hpBaseDashUrl = UROUTES.HP.MY.DASHBOARD.path;

  return Match.value(destination).pipe(
    Match.when({ tag: "ONBOARD" }, ({ onboardState }) =>
      Match.value(onboardState).pipe(
        Match.when(
          "DO_YOU_SEE_CLIENTS",
          () => UROUTES.ONBOARD.DO_YOU_SEE_CLIENTS.path
        ),
        Match.when("SET_PROFILE", () => UROUTES.ONBOARD.INPUT_PROFILE.path),
        Match.when("COMPLETED", () => UROUTES.HP.ONBOARD_COMPLETE.path),
        Match.exhaustive
      )
    ),
    Match.when({ tag: "DASHBOARD", dashboard: "HP" }, () => {
      if (
        currentHref.includes(cpBaseDashUrl) ||
        currentHref.includes(hpBaseDashUrl)
      ) {
        return null;
      } else {
        return `${hpBaseDashUrl}/clients`;
      }
    }),
    Match.when({ tag: "DASHBOARD", dashboard: "CP" }, () => {
      if (
        currentHref.includes(cpBaseDashUrl) ||
        currentHref.includes(hpBaseDashUrl)
      ) {
        return null;
      } else {
        return cpBaseDashUrl;
      }
    }),
    Match.exhaustive
  );
}
