import { Match } from "effect";
import { pipe } from "fp-ts/lib/function";
import { O, RD } from "frontend-shared/prelude";
import type { KnownCalendarAppt } from "shared/be/convex/Calendar/Calendar.Types";
import { isSameDay } from "date-fns";
import {
  OneIndexedMonth,
  toZeroIndexedMonth,
  type CalendarEventsByDay,
  type DayOfYear,
} from "shared/utils/calendar.types";
import { CalendarUtils } from "shared/utils/calendar.utils";

const daysOfWeek = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

interface CalendarMonthViewProps {
  rdDaysWithEventsForMonth: RD.RemoteData<
    any,
    CalendarEventsByDay<KnownCalendarAppt>[]
  >;
  dayInFocus: DayOfYear;
  onDayClick: (day: Date) => void;
  onApptClick: (appt: KnownCalendarAppt) => void;
}

export const CalendarMonthView = ({
  rdDaysWithEventsForMonth,
  dayInFocus,
  onDayClick,
  onApptClick,
}: CalendarMonthViewProps) => {
  return pipe(
    rdDaysWithEventsForMonth,
    RD.toOption,
    O.fold(
      () => <CalendarMonthViewLoading dayInFocus={dayInFocus} />,
      (daysWithEventsForMonth) => (
        <CalendarMonthViewLoaded
          daysWithEventsForMonth={daysWithEventsForMonth}
          dayInFocus={dayInFocus}
          onDayClick={onDayClick}
          onApptClick={onApptClick}
        />
      )
    )
  );
};

type LoadedProps = Omit<CalendarMonthViewProps, "rdDaysWithEventsForMonth"> & {
  onApptClick: (appt: KnownCalendarAppt) => void;
  daysWithEventsForMonth: CalendarEventsByDay<KnownCalendarAppt>[];
  isLoading?: boolean;
};

const CalendarMonthViewLoading: React.FC<{ dayInFocus: DayOfYear }> = ({
  dayInFocus,
}) => {
  return (
    <CalendarMonthViewLoaded
      daysWithEventsForMonth={[]}
      dayInFocus={dayInFocus}
      onDayClick={() => {}}
      onApptClick={() => {}}
      isLoading={true}
    />
  );
};

export const CalendarMonthViewLoaded = ({
  daysWithEventsForMonth,
  dayInFocus,
  onDayClick,
  onApptClick,
  isLoading = false,
}: LoadedProps) => {
  const curDoy = CalendarUtils.doyToDate(dayInFocus);
  const curMonth = OneIndexedMonth(curDoy.getMonth() + 1);
  const curYear = curDoy.getFullYear();

  // Get first day of the month and its day of week (0-6, where 0 is Sunday)
  const firstDayOfMonth = new Date(curYear, curDoy.getMonth(), 1);
  const firstDayOfWeek = firstDayOfMonth.getDay();

  return (
    <div
      key={`${curMonth}-${curYear}`}
      className="flex-1 flex flex-col border rounded-xl my-4 h-full"
    >
      <div className="grid grid-cols-7 border rounded-xl">
        {daysOfWeek.map((day, idx) => (
          <div
            key={idx}
            className="h-fit py-2 font-sans text-center font-medium inline-flex justify-center bg-gray-100"
          >
            {day}
          </div>
        ))}
      </div>
      <div className="grid grid-cols-7 rounded-xl">
        {/* Add empty cells for days before the first of the month */}
        {Array.from({ length: firstDayOfWeek }).map((_, index) => (
          <div
            key={`empty-${index}`}
            className="border border-gray-200 flex flex-col justify-start items-center p-1 h-42 aspect-square"
          />
        ))}
        {CalendarUtils.getDaysForMonth({
          monthIdx: toZeroIndexedMonth({ oiMonthIdx: curMonth }),
          year: curYear,
        })
          .map(CalendarUtils.asDOY)
          .map((doy) => {
            const dayDate = CalendarUtils.doyToDate(doy);
            const mbEventDay = daysWithEventsForMonth.find((calEvent) => {
              const eventDate = new Date(calEvent.events[0].startTime);
              return isSameDay(eventDate, dayDate);
            });

            return (
              <div
                key={doy.day}
                className="border border-gray-200 flex flex-col justify-start items-center p-1 h-42 aspect-square overflow-hidden"
                onClick={() =>
                  !isLoading && onDayClick(CalendarUtils.doyToDate(doy))
                }
              >
                <div
                  className={`${
                    doy.day === dayInFocus.day
                      ? "bg-vid-purple text-white p-1 rounded-full"
                      : ""
                  } text-xs text-center font-semibold w-fit h-fit`}
                >
                  {doy.day}
                </div>
                {isLoading ? (
                  <div className="flex flex-col w-full gap-1 mt-1">
                    <div className="h-4 bg-gray-200 rounded animate-pulse" />
                    <div className="h-4 bg-gray-200 rounded animate-pulse" />
                  </div>
                ) : (
                  mbEventDay && (
                    <div
                      onClick={(e) => e.stopPropagation()}
                      className="flex flex-col w-full"
                    >
                      {mbEventDay.events
                        .map((evt) => {
                          // Convert UTC timestamp to local time for display
                          const eventDate = new Date(evt.startTime);
                          const offsetMs =
                            eventDate.getTimezoneOffset() * 60 * 1000;
                          const localTime = new Date(evt.startTime + offsetMs);
                          return {
                            ...evt,
                            startTime: localTime.getTime(),
                          };
                        })
                        .sort((a, b) => a.startTime - b.startTime)
                        .map((appt) => (
                          <CalendarMonthViewEventTile
                            key={appt.instanceId}
                            appt={appt}
                            onApptClick={onApptClick}
                          />
                        ))}
                    </div>
                  )
                )}
              </div>
            );
          })}
      </div>
    </div>
  );
};

const CalendarMonthViewEventTile: React.FC<{
  appt: KnownCalendarAppt;
  onApptClick: (appt: KnownCalendarAppt) => void;
}> = ({ appt, onApptClick }) => {
  return Match.value(appt).pipe(
    Match.when({ tag: "COMMUNITY_EVENT" }, () => (
      <div
        className="bg-vid-black-100 rounded-2xl flex justify-center items-center text-black px-1 py-2 self-stretch cursor-pointer overflow-hidden whitespace-nowrap font-sans"
        onClick={() => onApptClick(appt)}
      >
        <div className="text-center text-xs truncate overflow-hidden">
          {appt.title}
        </div>
      </div>
    )),
    Match.when({ tag: "HC_EVENT" }, () => (
      <div
        className="bg-vid-black-100 rounded-2xl flex justify-center items-center text-black px-1 py-2 self-stretch cursor-pointer overflow-hidden whitespace-nowrap font-sans"
        onClick={() => onApptClick(appt)}
      >
        <div className="text-center text-xs truncate overflow-hidden">
          {appt.title}
        </div>
      </div>
    )),
    Match.orElse(() => <div>{new Date(appt.startTime).toLocaleString()}</div>)
  );
};
