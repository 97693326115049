import { useMutation, useQuery } from "convex/react";
import { Match } from "effect";
import {
  combine,
  createEffect,
  createEvent,
  createStore,
  sample,
} from "effector";
import { useUnit } from "effector-react";
import { CalendarPickerVM } from "frontend-shared/src/shared-vms/calendar-picker.vm";
import { BaseSearchableDropdownVM } from "frontend-shared/src/shared-vms/searchable-dropdown.vm";
import { useMemo } from "react";
import { api } from "shared/be/convex/_generated/api";
import type { Id } from "shared/be/convex/_generated/dataModel";
import { PrimaryButton } from "web-shared/src/components/button";
import { CalendarDropdown } from "web-shared/src/components/form/calendar-picker.input";
import { FullContainerLoadingSpinner } from "web-shared/src/components/loading";
import { ClientSearchableDropdown } from "web-shared/src/domains/hp/clients/select-client-search.input";

class ClientSelectVM extends BaseSearchableDropdownVM<{
  id: string;
  label: string;
}> {
  constructor(p: { allMyClients: { id: string; name: string }[] }) {
    super(p.allMyClients.map((c) => ({ id: c.id, label: c.name })));
  }
}

function useSetupFormForHp(p: { onSuccessSubmit?: () => void }) {
  const initialData = useQuery(
    api.Screens.Hp.Dashboard.CalendarScreenFns
      .getInitialDataForScheduleNewHcSessionForm
  );
  const scheduleNewHcSession = useMutation(
    api.Screens.Hp.Dashboard.CalendarScreenFns.scheduleNewHcCalEvent
  );

  const vm = useMemo(() => {
    if (initialData === undefined) return undefined;

    return new FormVM({
      allMyClients: initialData.allMyClients.map((c) => ({
        id: c.clientId,
        name: c.name,
      })),
      submit: async ({ clientId, startsAt }) => {
        // Convert from UTC back to local time
        const date = new Date(startsAt);
        const offsetMs = date.getTimezoneOffset() * 60 * 1000;
        const localTime = startsAt + offsetMs;

        await scheduleNewHcSession({
          forClientId: clientId,
          startsAt: localTime,
          durationSeconds: 60 * 60,
        });
      },
      onSuccessSubmit: p.onSuccessSubmit,
    });
  }, [initialData]);

  return vm;
}

class FormVM {
  clientSelectVM: ClientSelectVM;
  calendarPickerVM = new CalendarPickerVM();

  submitted = createEvent();

  $validationError = createStore<string | null>(null);

  submitFx = createEffect<
    { clientId: Id<"users">; startsAt: number },
    void,
    Error
  >();

  constructor(p: {
    allMyClients: { id: string; name: string }[];
    submit: (p: { clientId: Id<"users">; startsAt: number }) => Promise<void>;
    onSuccessSubmit?: () => void;
  }) {
    this.clientSelectVM = new ClientSelectVM({ allMyClients: p.allMyClients });

    sample({
      clock: this.submitted,
      source: {
        selectedClient: this.clientSelectVM.$selectedItem,
        selectedDate: this.calendarPickerVM.$selectedDate,
      },
      filter: ({ selectedClient, selectedDate }) =>
        selectedClient !== null && selectedDate !== null,
      fn: ({ selectedClient, selectedDate }) => ({
        clientId: selectedClient!.id as Id<"users">,
        startsAt: selectedDate!.getTime(),
      }),
      target: this.submitFx,
    });

    this.submitFx.use(p.submit);

    this.$validationError.on(
      combine(
        this.clientSelectVM.$selectedItem,
        this.calendarPickerVM.$selectedDate
      ),
      () => null
    );

    this.$validationError.on(this.submitted, () => {
      const selectedClient = this.clientSelectVM.$selectedItem.getState();
      const selectedDate = this.calendarPickerVM.$selectedDate.getState();

      const errors: string[] = [];

      if (!selectedClient) {
        errors.push("Please select a client");
      }

      if (!selectedDate) {
        errors.push("Please select a date and time");
      }

      return errors.length > 0 ? errors.join(", ") : null;
    });

    this.submitFx.doneData.watch(() => {
      p.onSuccessSubmit?.();
    });
  }
}

export const NewHCSessionFormForHp: React.FC<{
  onSuccessSubmit: () => void;
}> = ({ onSuccessSubmit }) => {
  const vm = useSetupFormForHp({
    onSuccessSubmit: () => {
      onSuccessSubmit();
    },
  });

  if (vm === undefined) return <FullContainerLoadingSpinner />;

  return <NewHCSessionFormLoaded vm={vm} />;
};

const NewHCSessionFormLoaded: React.FC<{
  vm: FormVM;
}> = ({ vm }) => {
  const isSubmitting = useUnit(vm.submitFx.pending);
  const validationError = useUnit(vm.$validationError);

  return (
    <div className="flex-1 flex flex-col gap-6">
      <div className="self-stretch">
        <ClientSearchableDropdown
          onSelect={(client) => {
            vm.clientSelectVM.itemSelected({
              id: client.id,
              label: client.name,
            });
          }}
        />
      </div>
      <CalendarDropdown vm={vm.calendarPickerVM} />
      <div className="flex-1 flex flex-col justify-end">
        <div className="flex flex-col gap-4">
          {validationError && (
            <div className="text-red-500">{validationError}</div>
          )}
          <PrimaryButton
            title="Schedule session"
            onClick={() => {
              vm.submitted();
            }}
            isLoading={isSubmitting}
          />
        </div>
      </div>
    </div>
  );
};
